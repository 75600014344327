import { interviewBuilderInitialState } from "../../interviewBuilderInitialState";
import { updateStateAndJSONString } from "./updateStateAndJSONString";
import { jsonStringify } from "../../general/jsonStringify";


export const onResetToInitialState = 
    (setState) => 
    (setJsonString) => 
    () => 
    updateStateAndJSONString
        (setState)
        (setJsonString)
        (jsonStringify(interviewBuilderInitialState));
