import React, { CSSProperties } from "react";
import UserVideo from "../../Components/UserVideo";

type InterviewerFeedsProps = {
  videoConfig?: any;
  renderVideoControls: any;
  screenShareStream: any;
  subscribers: any;
};

export default function InterviewerFeeds(props: InterviewerFeedsProps) {
  const { videoConfig, renderVideoControls, screenShareStream, subscribers } =
    props;

  return (
    <div className="hor-center" style={styles.main}>
      <div style={styles.interviewerArea}>
        {videoConfig.publisher && !screenShareStream && (
          <UserVideo
            isNewStyle
            isMe
            renderVideoControls={renderVideoControls}
            name={
              videoConfig?.publisher?.stream?.connection?.data.split("|")[1]
            }
            streamManager={videoConfig.publisher}
            // isScreenShare={screenShareStream}
          />
        )}
        {!!screenShareStream && (
          <div
            style={{
              height: "7%",
              background: "#000000aa",
              borderRadius: 10,
            }}
          >
            {renderVideoControls()}
          </div>
        )}
        <div style={styles.reverseWrap}>
          {!screenShareStream &&
            subscribers
              .filter(
                (sub) => !sub.stream.connection.data.includes("interviewee")
              )
              .map((sub, i) => (
                <UserVideo
                  isNewStyle
                  key={i}
                  isLeft={i % 2 === 0}
                  name={sub.stream.connection.data.split("|")[1]}
                  streamManager={sub}
                  isHidden={!!screenShareStream}
                />
              ))}
        </div>
      </div>
    </div>
  );
}

export function IntervieweeFeed(props) {
  const { videoConfig, renderVideoControls, screenShareStream } = props;

  return (
    <div className="hor-center" style={styles.main}>
      <div style={styles.interviewerArea}>
        {videoConfig.publisher && !screenShareStream && (
          <UserVideo
            isIntervieweeView
            isNewStyle
            isMe
            renderVideoControls={renderVideoControls}
            name={videoConfig.publisher.stream.connection.data.split("|")[1]}
            streamManager={videoConfig.publisher}
            // isScreenShare={screenShareStream}
          />
        )}
        {!!screenShareStream && (
          <div
            style={{ height: "7%", background: "#000000aa", borderRadius: 10 }}
          >
            {renderVideoControls()}
          </div>
        )}
      </div>
    </div>
  );
}

const sideMargin = 6;

const styles: Record<string, CSSProperties> = {
  main: {
    width: "25%",
    maxWidth: "25%",
    height: `calc(100% - 60px)`,
    zIndex: 2,
    margin: `${sideMargin}px`,
    flexGrow: 1,
    display: "flex",
    alignSelf: "flex-end",
  },
  interviewerArea: {
    width: "100%",
    display: "flex",
    flexDirection: "column-reverse",
    position: "relative",
    height: "100%",
  },
  intervieweeArea: {
    width: "100%",
    display: "flex",
    flexDirection: "column-reverse",
    position: "relative",
    height: "100%",
  },
  reverseWrap: {
    width: "100%",
    height: "50%",
    display: "flex",
    flexWrap: "wrap-reverse",
    alignContent: "flex-start",
  },
};
