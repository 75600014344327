import { useState, useEffect, useRef } from "react";
import { getSocket } from "./api";
import getUserId from "./getUserId";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export default function useAudioStream(params) {
  const { isAudioEnabled } = params;

  const trackRef = useRef<MediaStreamTrack | null>(null);
  const socketRef = useRef<Socket | null>(null);

  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    if (!params?.stage) return;

    let io: Socket<DefaultEventsMap, DefaultEventsMap>;

    (async () => {
      const userId = await getUserId();
      const { stage, channel, interviewInfo = {}, id: interviewId } = params;
      const { candidateName = "" } = interviewInfo;

      const initParams = {
        userId,
        candidateName,
        stage,
        channel,
        interviewId,
      };

      io = getSocket(initParams);
      socketRef.current = io;
    })();

    return () => {
      if (io) io.close();
    };
  }, [params?.stage]);

  // mute and unmute the track depending on isAudioEnabled
  useEffect(() => {
    if (!trackRef?.current) return;
    trackRef.current.enabled = isAudioEnabled;
  }, [trackRef, isAudioEnabled]);

  const startStreamRecording = async () => {
    if (isRecording) return;
    setIsRecording(true);

    // grab the audio stream from user media
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { sampleSize: 16, channelCount: 1, sampleRate: 16000 },
      video: false,
    });

    const [track] = stream.getAudioTracks();
    track.enabled = true;
    trackRef.current = track;

    // initialize the media recorder
    const mimeType = isSafari() ? "audio/mp4" : "audio/webm;codecs=opus";
    const recorder = new MediaRecorder(stream, {
      audioBitsPerSecond: 128000,
      mimeType: mimeType,
    });

    const io = socketRef?.current;
    if (io) {
      io.emit("start_stream");
      // start recorder & pipe audio chunks to the socket
      recorder.start(1000);
      recorder.ondataavailable = (e) => {
        const chunk = e.data;
        io.emit("audio_chunk", chunk);
      };
    }
  };

  const stopStreamRecording = () => {
    const io = socketRef?.current;
    if (io) io.close();
  };

  return {
    startStreamRecording,
    stopStreamRecording,
    socket: socketRef?.current,
  };
}
