import { Storage } from "aws-amplify";
import { stages } from "./stages";

const { REACT_APP_ENV } = process.env;

async function fileExists(interviewId: string, iterStage) {
  const fileName = `recordings/${interviewId}-${iterStage}${
    REACT_APP_ENV === "dev" ? "-dev" : ""
  }.webm`;

  try {
    const files = await Storage.list("recordings/");
    return files.some((file) => file.key === fileName);
  } catch (error) {
    console.error("Error while checking if file exists:", error);
    return false;
  }
}

export default async function getInterviewUrls(interviewId: string) {
  let updateUrls = {};

  for (const iterStage of Object.keys(stages)) {
    const exists = await fileExists(interviewId, iterStage);
    if (exists)
      try {
        const path = `recordings/${interviewId}-${iterStage}${
          REACT_APP_ENV === "dev" ? "-dev" : ""
        }.webm`;
        const result = await Storage.get(path, {
          download: true,
          contentType: "audio/webm",
        });
        if (result.Body instanceof Blob) {
          const blobUrl = URL.createObjectURL(result.Body);
          updateUrls[iterStage] = blobUrl;
        } else {
          updateUrls[iterStage] = null;
        }
      } catch (e) {
        updateUrls[iterStage] = null;
      }
    else updateUrls[iterStage] = null;
  }
  return updateUrls;
}

export async function getInterviewUrlsForNotesModal(interviewId: string) {
  const urls = {};
  for (const iterStage of Object.keys(stages)) {
    const exists = await fileExists(interviewId, iterStage);
    if (exists)
      try {
        const path = `recordings/${interviewId}-${iterStage}${
          REACT_APP_ENV === "dev" ? "-dev" : ""
        }.webm`;
        const result = await Storage.get(path, { contentType: "audio/webm" });
        urls[iterStage] = result;
      } catch (e) {}
    else urls[iterStage] = null;
  }
  return urls;
}
