import { Button, Card, CardBody, CardHeader, Center, Divider, Flex, Input, InputGroup, InputRightElement, Spacer, Tooltip, VStack } from "@chakra-ui/react";
import * as React from "react";
import { AiFillDelete, AiFillEdit, AiOutlinePercentage } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { WeightedAttributeTableWithAddButtons } from "../../Attributes/List/WeightedAttributeTableWithAddButtons";
import { valueHref } from "../Detail/valueHref";
import { titleStyle } from "../../general/titleStyle";
import { setStateForWeightedValueField } from "../setStateForWeightedValueField";
import { onValueDelete } from "./onValueDelete";
import { ValueTitleInput } from "./ValueTitleInput";
import { ValueDescriptionInput } from "./ValueDescriptionInput";

export const ValueCard = ({ customerId, weightedValue: { id, title, description, weightedAttributes, weight }, showActionButtons = true, setState }) => {
    const navigate = useNavigate();
    const possibleEditButtons = showActionButtons
        ? <>
            <Spacer />
            <Center gap={2}>
                <Tooltip label="Edit Value">
                    <Button onClick={() => navigate(valueHref(customerId)(id))}><AiFillEdit /></Button>
                </Tooltip>
                <Tooltip label="Delete Value">
                    <Button onClick={onValueDelete(setState)(id)}><AiFillDelete /></Button>
                </Tooltip>
            </Center>
        </>
        : undefined;

    return <Card shadow='xl' width="100%" mt={5}  >
        <CardHeader>
            <Flex>
                <Center gap={2}>
                    <ValueTitleInput {...{ title, onChange: setStateForWeightedValueField(setState)(id)('title') }} />
                    <InputGroup>
                        <Input
                            placeholder="Weight: 0-100"
                            title="weight"
                            style={titleStyle}
                            onChange={e => setStateForWeightedValueField(setState)(id)('weight')(e.target.value)}
                            value={weight} />
                        <InputRightElement pointerEvents='none'>
                            <AiOutlinePercentage />
                        </InputRightElement>
                    </InputGroup>
                </Center>
                {possibleEditButtons}
            </Flex>
        </CardHeader>
        <CardBody>
            <VStack>
                <ValueDescriptionInput {...{description, onChange: setStateForWeightedValueField(setState)(id)('description') }} />
                <Divider />
                <WeightedAttributeTableWithAddButtons {...{ customerId, valueId: id, weightedAttributes, setState }} /><br />
            </VStack>
        </CardBody>
    </Card>;
};
