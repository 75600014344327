import React, { useState, useEffect, CSSProperties } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";

import { getInterview } from "../../graphql/queries";

import { GraphQL } from "../../utils/api";
import useAudioStream from "../../utils/useAudioStream";

import Feedback from "../Feedback";
import { VideoControlButton, DeviceModal } from "../../Components";
import GreenRoom from "./GreenRoom";
import { ChevronExpand, Viewers } from "./index";
import CandidateFeed from "../Interviewer/CandidateFeed";
import {
  FiAirplay,
  FiMic,
  FiMicOff,
  FiPhoneMissed,
  FiVideo,
  FiVideoOff,
} from "react-icons/fi";
import { IntervieweeFeed } from "../Interviewer/InterviewerFeeds";
import useOpenVidu from "../../utils/useOpenVidu";
import { Interview } from "../../types/Interview";

export default function Interviewee() {
  const location = useLocation();

  const interviewId = qs.parse(location.search).id as string;
  const [interview, setInterview] = useState<Interview | null>(null);

  const [permission, setPermission] = useState<boolean>(false);
  const [pressedEnd, setPressedEnd] = useState<boolean>(false);

  const [deviceModalVisible, setDeviceModalVisible] = useState<boolean>(false);
  const [audioMode, setAudioMode] = useState<string | null>(null);

  const interviewInfo = interview?.interviewInfo;
  const stage = interview?.stage;

  const name = interview?.interviewInfo?.candidateName;
  const role = "interviewee";

  const openVidu = useOpenVidu({
    role,
    name,
    interviewId,
    stage,
  });

  useEffect(() => {
    if (!permission) return;
    openVidu.initPublisher();
  }, [permission, openVidu]);

  // stream audio data to socket for nlp & storage
  const audioStreamParams = {
    ...interview,
    channel: 0,
    isAudioEnabled: openVidu.isAudioEnabled && openVidu.subscribers[0],
    stage,
  };
  const { startStreamRecording, stopStreamRecording } =
    useAudioStream(audioStreamParams);

  const onChangeDevice = (deviceId) => {
    if (audioMode) openVidu.changeAudioFeed(deviceId);
    else openVidu.changeVideoFeed(deviceId);
  };

  // start individual stream recording
  useEffect(() => {
    if (!openVidu.subscribers[0]) return;
    if (!openVidu.publisher) return;
    startStreamRecording();
  }, [openVidu.subscribers, openVidu.publisher, startStreamRecording]);

  useEffect(() => {
    getInterviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewId]);

  const getInterviewData = async () => {
    const query = getInterview;
    const variables = { id: interviewId };
    const res = await GraphQL({ query, variables, authMode: "" });
    const interview = res.getInterview;
    interview.interviewInfo = JSON.parse(interview.interviewInfo);
    setInterview(res.getInterview);
  };

  useEffect(() => {
    const beforeUnloadListener = (event) => {
      event.preventDefault();
      openVidu.leaveSession();
      return (event.returnValue = "");
    };
    window.addEventListener("beforeunload", beforeUnloadListener);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!permission) {
    return <GreenRoom isAutoContinue onContinue={() => setPermission(true)} />;
  }

  const handleLeaveCall = () => {
    openVidu.leaveSession();
    setPressedEnd(true);
    stopStreamRecording();
  };

  if ((pressedEnd || openVidu.isDisconnected) && stage) {
    return (
      <Feedback
        interviewerName={interviewInfo?.interviewers[stage]?.name}
        intervieweeName={interviewInfo?.candidateName}
      />
    );
  }

  function renderVideoControls() {
    return (
      <div style={styles.videoControlsWrap}>
        <VideoControlButton
          disable={openVidu.toggleAudioEnabled}
          state={openVidu.isAudioEnabled}
          cancelText="Mute"
          uncancelText="Unmute"
          activeIcon={FiMic}
          inactiveIcon={FiMicOff}
          canExpand={
            <ChevronExpand
              title="Change Microphone"
              audioMode={true}
              setAudioMode={setAudioMode}
              setDeviceModalVisible={setDeviceModalVisible}
            />
          }
        />
        <VideoControlButton
          disable={openVidu.toggleVideoEnabled}
          state={openVidu.isVideoEnabled}
          cancelText="Stop Video"
          uncancelText="Start Video"
          activeIcon={FiVideo}
          inactiveIcon={FiVideoOff}
          canExpand={
            <ChevronExpand
              title="Change Camera"
              audioMode={false}
              setAudioMode={setAudioMode}
              setDeviceModalVisible={setDeviceModalVisible}
            />
          }
        />
        <VideoControlButton
          disable={openVidu.toggleScreenShare}
          state={openVidu.isSharingScreen}
          cancelText="Stop Share"
          uncancelText="Share"
          activeIcon={FiAirplay}
          inactiveIcon={FiAirplay}
        />
        <VideoControlButton
          disable={handleLeaveCall}
          uncancelText={"Leave"}
          activeIcon={FiPhoneMissed}
          inactiveIcon={FiPhoneMissed}
        />
      </div>
    );
  }

  const interviewerStream = openVidu.subscribers.find((subscriber) =>
    subscriber.stream.connection.data.includes("interviewer")
  );
  const participants = openVidu.subscribers.filter((subscriber) =>
    subscriber.stream.connection.data.includes("participant")
  );

  return (
    <div className="hor-center" style={styles.main}>
      <DeviceModal
        audioMode={audioMode}
        deviceModalVisible={deviceModalVisible}
        setDeviceModalVisible={setDeviceModalVisible}
        deviceList={audioMode ? openVidu.audioDevices : openVidu.videoDevices}
        currentDevice={
          audioMode ? openVidu.audioDeviceId : openVidu.videoDeviceId
        }
        onChangeDevice={onChangeDevice}
      />
      <IntervieweeFeed
        videoConfig={openVidu}
        renderVideoControls={renderVideoControls}
        screenShareStream={openVidu.screenShareStream}
        intervieweeStream={openVidu.publisher}
        subscribers={openVidu.subscribers}
      />
      <CandidateFeed
        intervieweeStream={interviewerStream}
        screenShareStream={openVidu.screenShareStream}
        isInterviewee
        companyName={interview?.companyName}
        stage={stage}
        roleName={interviewInfo.candidateRole.name}
        interviewerName={
          stage && interviewerStream
            ? interviewInfo?.interviewers[stage].name
            : null
        }
      />
      {openVidu.subscribers.length && participants.length > 0 && (
        <Viewers
          subscribers={participants}
          publisher={openVidu.publisher}
          screenShareStream={openVidu.screenShareStream}
        />
      )}
    </div>
  );
}

const styles: Record<string, CSSProperties> = {
  main: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    background: "#999999",
  },
  header: {
    height: 60,
    width: "100%",
    background: "#fafafa",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
  },
  thinBorder: {
    borderTop: "1px solid black",
    marginTop: ".5%",
    marginBottom: ".5%",
  },
  muteButton: {
    borderRadius: 50,
    cursor: "pointer",
  },
  videoControlsWrap: {
    flexDirection: "row",
    height: 60,
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    zIndex: 3,
  },
};
