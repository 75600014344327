import { getUser } from "../../../utils/api";

export const upToDateInterviewers = async (interviewers) => {
  const updated = {};
  const interviewerData = Object.keys(interviewers).map(async (stage) => {
    const interviewerId = interviewers[stage].id;
    if (interviewerId) {
      const user = await getUser(interviewerId);
      if(!user) {        
        console.error(`Error looking up user for interviewer ${interviewerId} for stage ${stage} among interviewers ${JSON.stringify(interviewers, null, 3)}`)
        // return id as name rather than crash the app
        return { stage, name: `${interviewerId} ${interviewerId}`, id: interviewerId, email: '' };
      }
      
      const { firstName, lastName, email, id } = user
      return { stage, name: `${firstName} ${lastName}`, id, email };
    }
  });
  const result = (await Promise.all(interviewerData)).filter(
    (stage) => stage
  );
  result.forEach((stageName) => {
    const { stage, name, id, email } = stageName;
    updated[stage] = { name, id, email };
  });
  return updated;
};
