import * as React from "react";
import { Breadcrumbs } from "../../general/Breadcrumbs";
import { ValuesList } from "./ValuesList";
import { breadcrumbsForAllValues } from "./breadcrumbsForAllValues";
import { usePageTitle } from "../../../../../navigation/usePageTitle";

export const ValuesPage = ({ customerId, weightedValues, setState }) => {
    usePageTitle("Values")
    return (
        <>
            <Breadcrumbs breadcrumbs={breadcrumbsForAllValues(customerId)} mb={5} />
            <ValuesList {...{ customerId, weightedValues, setState }} />
        </>
    );
    
}