import Interview from "./Interview";
import MyLink from "./MyLink";
import QuestionsTab from "./QuestionsTab";
import Questions from "./Questions";
import RoleSelector from "./RoleSelector";
import UsersSelector from "./UsersSelector";
import ParticipantAdd from "./ParticipantAdd";
import { DeleteModal, NotesModal, RoleModal, UrlModal } from "./Modals";
import { cloneDeep } from "lodash";

const discoveryQuestions = [
  "Pain: What is happening with your current role that makes you open to hearing about something potentially stronger?\nHow is it affecting you personally?\nWhat else?",
  "Desire: If you could create your next opportunity from scratch, what would that look like for you? [1]\nWhat would the company look like?\nWhat work would you be doing?\nWhat makes this important to you?",
  "Culture: What type of environment/culture do you do best in? [1]\nWhat is it that makes you thrive in that type of environment?",
  "Size: What size company do you do best in?\nHow small is too small for you?",
  "Role: What type of role would you be looking to play?",
  "Domain: What industries or specializations are you interested in?\nWhy do these areas interest you?",
  "Impact: Tell me about the most significant impact you had in your current role. What have you done that has saved time, generated revenue, or gained recognition from leadership? [3]\nBreak down the exact steps you took to solve the problem \nWhat issues came up?\nWhat were the root of the issues?\nHow were you able to deliver?\nWhy was this important to you?",
  "Skills: What are your core Strengths? [1]\nWhat makes you stand out from your peers?\nInterpersonal skills?\nTechnical Strengths?",
  "Interviewing: To properly pace out the process, where are you in the interview process?\nCurrent Interviews:",
  "Salary: Expectations $ What are your minimum salary requirements?\nBreak it down in terms of salary, bonus & stock (Expectations, not actuals)",
  "Authorization: What is your current work authorization status?",
  "Offer Acceptance Criteria: What are 3 or 4 “Main Criteria” that you would need to see in a company for you to accept an offer with them?",
  "Availability: What is your availability to interview this week? (dates/times)",
  "Commute: Where are you willing to commute daily? (understand limits)",
  "Wrap Up: What questions do you have for me? [2]\n\nWe run a 4 step interview process. The next meeting will be focused on Value alignment, followed by a Working Session conducted by people that would be on your team. And then a Final Interview where we commit to a decision with you. The process usually takes about 10 days. How does that resonate with you?\n\nWhat would you like to have happen next?",
];
// const discoveryQuestions = [
//   "Pain: What is happening with your current role that makes you open to hearing about something potentially stronger? [1]\nHow is it affecting you personally?\nWhat else?",
//   "Desire: If you could create your next opportunity from scratch, what would that look like for you? [1]\n(Elaboration) - Explain that to me...\nWhat would the company look like?\nWhat work would you be doing?\nWhat makes this important to you?",
//   "Culture: What type of environment/culture do you do best in? [1]\nWhat is it that makes you thrive in that type of environment?",
//   "Size: What size company do you do best in?\nHow small is too small for you?",
//   "Role: What type of role would you be looking to play?",
//   "Domain: What industries or specializations are you interested in?\nWhy do these areas interest you?",
//   "Impact: Tell me about the most significant impact you had in your current role. What have you done that has saved time, generated revenue, or gained recognition from leadership? [3]\n(Elaboration) - Walk me through that...\nBreak down the exact steps you took to solve the problem \nWhat issues came up?\nWhat were the root of the issues?\nHow were you able to deliver?\nWhy was this important to you?\n(Too much we) - I'm hearing you say 'we' a lot. Rather than tell me what your team did, please focus precisely on the work you did.",
//   "Skills: What are your core Strengths? [1]\nWhat makes you stand out from your peers?\nInterpersonal skills?\nTechnical Strengths?",
//   "Interviewing: To properly pace out the process, where are you in the interview process?\nCurrent Interviews:",
//   "Salary: Expectations $ What are your minimum salary requirements?\nBreak it down in terms of salary, bonus & stock (Expectations, not actuals)",
//   "Authorization: What is your current work authorization status?",
//   "Offer Acceptance Criteria: What are 3 or 4 “Main Criteria” that you would need to see in a company for you to accept an offer with them?",
//   "Availability: What is your availability to interview this week? (dates/times)",
//   "Commute: Where are you willing to commute daily? (understand limits)",
//   "Wrap Up: What questions do you have for me? [2]\n\nWe run a 4 step interview process. The next meeting will be focused on Value alignment, followed by a Working Session conducted by people that would be on your team. And then a Final Interview where we commit to a decision with you. The process usually takes about 10 days. How does that resonate with you?\n\nWhat would you like to have happen next?",
// ];

const initQuestions = {
  discovery: cloneDeep(discoveryQuestions),
  value1: [],
  working: [],
  value2: [],
};

export default Interview;

export {
  DeleteModal,
  MyLink,
  NotesModal,
  ParticipantAdd,
  Questions,
  QuestionsTab,
  RoleSelector,
  RoleModal,
  UrlModal,
  UsersSelector,
  initQuestions,
};
