import React, { ReactNode } from "react";

export interface PillButtonProps {
  children: ReactNode;
  href?: string;
  target?: string;
}

export const PillButton = ({ children, href, target }: PillButtonProps) => {
  if (href) {
    return (
      <a
        href={href}
        target={target}
        className="px-8 py-2 rounded-full border border-gray-700 justify-end items-center gap-2 inline-flex text-center text-gray-700 text-base font-semibold font-['Montserrat'] leading-normal"
      >
        {children}
      </a>
    );
  }
  return (
    <button className="px-8 py-2 rounded-full border border-gray-700 justify-end items-center gap-2 inline-flex text-center text-gray-700 text-base font-semibold font-['Montserrat'] leading-normal">
      {children}
    </button>
  );
};
