import React, { useState, useEffect } from "react";
import { io } from "socket.io-client";

export interface RealTimeDataProps {
  setData: (value: any) => void;
}

export function RealTimeData({ setData }: RealTimeDataProps) {
  const [ws, setWs] = useState(null);

  useEffect(() => {
    const ws = io.connect(process.env.REACT_APP_ENV === "dev" ? "https://interview-intervention-dev.intertru.com" 
                                                              : "https://interview-intervention.intertru.com", 
     {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
    });
    setWs(ws);
    ws.on("connect", function () {
      ws.send("User has connected!");
    });
    ws.on("predict_legality", function (msg: any) {
      if (msg.body.prediction === "illegal") {
        setData((prevState: any) => [msg.body]);
      }
    });
  }, []);

  return null;
}

export default RealTimeData;
