import React, { MouseEventHandler, PropsWithChildren } from "react";
import { SelectedChartPointer } from "./SelectedChartPointer";
import classNames from "classnames";

export const SelectableBox = ({
  children,
  isSelected,
  isDisabled,
  ...props
}: PropsWithChildren<{
  isSelected: boolean;
  isDisabled: boolean;
  onClick: MouseEventHandler<Element>;
}>) => (
  <div
    className={classNames(
      "relative bg-white p-[18px] mb-0 rounded-[16px] flex flex-col items-center justify-start transition-all duration-300 ease-in-out overflow-hidden col-span-2",
      {
        "border-2 border-[#4785EF] shadow w-[588px] col-span-4": isSelected,
        "w-[264px]": !isSelected,
        "cursor-pointer border-0": !isDisabled,
        "cursor-default dashed-border": isDisabled,
      },
    )}
    {...props}
  >
    {children}
    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full">
      <SelectedChartPointer isSelected={isSelected} />
    </div>
  </div>
);
