import React, { CSSProperties, useEffect, useRef } from "react";
import { StreamManager } from "openvidu-browser";

type UserVideoProps = {
  streamManager: StreamManager;
  mode?: string;
  isNewStyle?: boolean;
  isMe?: boolean;
  isIntervieweeView?: boolean;
  isLeft?: boolean;
  isSharingWindow?: boolean;
  renderVideoControls?: () => any;
  style?: React.CSSProperties;
  name?: string;
  isHidden?: boolean;
};

export default function UserVideo(props: UserVideoProps) {
  const {
    streamManager,
    mode,
    isNewStyle = false,
    isMe = false,
    isIntervieweeView = false,
    isLeft,
    isSharingWindow,
    renderVideoControls = null,
    isHidden,
  } = props;

  const isHiddenStyle: React.CSSProperties = {};
  if (isHidden) {
    isHiddenStyle.position = "absolute";
    isHiddenStyle.zIndex = -1;
  }

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (streamManager && videoRef?.current) {
      streamManager.addVideoElement(videoRef.current);
    }
  }, [videoRef, streamManager]);

  let marginStyle: React.CSSProperties = {};
  if (!isMe) {
    if (isLeft) marginStyle.marginRight = 3;
    else marginStyle.marginLeft = 3;
  }

  if (isNewStyle)
    return (
      <div
        style={{
          position: "relative",
          height: isMe ? `calc(30% + 60px)` : isIntervieweeView ? "24%" : "55%",
          // maxHeight: isMe ? "auto" : 300,
          width: isMe || isIntervieweeView ? "100%" : "calc(50% - 3px)",
          background: "#000000aa",
          borderRadius: 14,
          padding: 6,
          marginTop: 6,
          ...props.style,
          ...marginStyle,
          ...isHiddenStyle,
        }}
      >
        <div style={{ ...styles.newNameLabel, bottom: isMe ? 70 : 10 }}>
          {props.name}
        </div>
        <div
          style={{
            borderRadius: isMe ? "12px 12px 0px 0px" : 12,
            height: "100%",
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <video
            style={{
              height: isMe ? "calc(100% - 60px)" : "100%",
              width: "100%",
            }}
            className={mode === "screenshare" ? "screenshare" : "user-video"}
            autoPlay={true}
            playsInline={true}
            ref={videoRef}
          />
          {!!renderVideoControls && renderVideoControls()}
        </div>
      </div>
    );

  const videoStyle: React.CSSProperties = { borderRadius: 4 };
  if (isSharingWindow) {
    videoStyle.background = "#000000aa";
    videoStyle.objectFit = "contain";
  }
  return (
    <div style={{ ...styles.videoWrapper, ...props.style, ...isHiddenStyle }}>
      <div style={styles.nameLabel}>{props.name}</div>
      <video
        style={videoStyle}
        className={mode === "screenshare" ? "screenshare" : "user-video"}
        autoPlay={true}
        playsInline={true}
        ref={videoRef}
      />
    </div>
  );
}

const styles: Record<string, CSSProperties> = {
  videoWrapper: {
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  nameLabel: {
    position: "absolute",
    background: "black",
    color: "white",
    padding: "0px 4px",

    zIndex: 1,
    fontSize: 14,
    borderRadius: "4px 0 4px 0",
  },
  newNameLabel: {
    position: "absolute",
    marginTop: 3,
    marginLeft: 3,
    display: "flex",
    background: "#00000099",
    color: "white",
    padding: "0px 8px",
    zIndex: 1,
    fontSize: 14,
    borderRadius: 50,
  },
};
