import { isNumberPair } from "../chart/isNumberPair";
import { weightedAverage } from "../chart/weightedAverage";
import { isNotUndefined } from "../isNotUndefined";
import { UnscoredAttributes } from "../state/scores/UnscoredAttributes";
import { IntertruDimensionScores } from "../state/scores/IntertruDimensionScores";
import { ValuesDefintition } from "../state/values/ValuesDefintition";
import { intertruAssessor } from "./intertruAssessor";
import { scoredAttribute } from "./scoredAttribute";

export const intertruDimensionScoresFromML = 
    (unscoredAttributes:UnscoredAttributes) =>
    (valuesDefinition:ValuesDefintition):IntertruDimensionScores =>
    valuesDefinition.weightedValues
        .filter( //only show values where every attribute has at least one scored question associated with it
            wv => 
            wv.weightedAttributes.every(
                expectedAttribute => 
                unscoredAttributes.some(
                    unscoredAttribute => 
                    (
                        expectedAttribute.id === unscoredAttribute.attributeId 
                        && 
                        unscoredAttribute.questionScores.some(qs => qs.score !== undefined)
                    )
                )
            )
        )
        .map(wv => {
            const weightAndScoredAttributePairs = wv.weightedAttributes.map(
                wa => [
                    Number(wa.weight), 
                    scoredAttribute
                        (wa)
                        (unscoredAttributes.find(unscoredAttribute => wa.id === unscoredAttribute.attributeId))
                ] as const
            )
            return ({
                valueId: wv.id,
                details: weightAndScoredAttributePairs.map(([_, score]) => score).filter(isNotUndefined),
                assessor: intertruAssessor,
                score: weightedAverage(
                    weightAndScoredAttributePairs
                        .map(([weight, score]) => [weight, score?.score] as const)
                        .filter(isNumberPair)
                ),
            })
        })
