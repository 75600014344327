import { toast } from "react-toastify";
import { FiCopy } from "react-icons/fi";

export default function MyLink(props) {
  const { title, link } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 8,
        ...props.style,
      }}
    >
      <div style={{ marginRight: 12 }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={link}
          style={styles.link}
        >
          {title}
        </a>
      </div>
      <div
        onClick={() => {
          navigator.clipboard.writeText(link);
          toast.success(`${title} copied.`);
        }}
      >
        <div
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <FiCopy size={20} color="blue" />
          {/* <div style={{ marginLeft: 6, fontWeight: "600" }}>Copy Link</div> */}
        </div>
      </div>
    </div>
  );
}

const styles = {
  link: {
    color: "blue",
    textDecoration: "underline",
  },
};
