export const getCompanyWithRolesAndUsers = /* GraphQL */ `
  query GetCompanyWithRoles($id: ID!, $userStatus: String) {
    getCompany(id: $id) {
      id
      email
      firstName
      lastName
      company
      interviewInfo
      roles {
        items {
          companyID
          id
          name
          workingSession
        }
        nextToken
      }
      interviews {
        nextToken
      }
      users(filter: { status: { eq: $userStatus } }) {
        items {
          id
          role
          firstName
          lastName
          email
          phone
          status
          title
        }
      }
      status
      values1
      values2
      createdAt
      updatedAt
    }
  }
`;
export const listCompaniesWithUsers = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        users {
          items {
            id
            # role
            # firstName
            # lastName
            # email
            # phone
            # status
            # title
          }
        }
        id
        email
        firstName
        lastName
        company
        interviewInfo
        values1
        values2
        hasLoggedIn
        status
        stripeCustomerId
        plan
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyData = /* GraphQL */ `
  query GetCompany($id: ID!, $userStatus: String) {
    getCompany(id: $id) {
      id
      group
      company
      interviewInfo
      values1
      values2
      hasLoggedIn
      stripeCustomerId
      status
      plan
      displayOrder
      createdAt
      updatedAt
      users(filter: { status: { eq: $userStatus } }) {
        items {
          id
          role
          firstName
          lastName
          email
          phone
          status
          title
          companyID
        }
      }
    }
  }
`;

export const getInterviewBuilderData = /* GraphQL */ `
  query GetInterviewBuilderData($id: ID!) {
    getCompany(id: $id) {
      company
      values1
      values2
      interviewBuilderData
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const updateInterviewBuilderData = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      updatedAt
    }
  }
`;

export const getInterviewCount = /* GraphQL */ `
  query SearchInterviews(
    $filter: SearchableInterviewFilterInput
    $sort: [SearchableInterviewSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInterviewAggregationInput]
  ) {
    searchInterviews(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      total
    }
  }
`;

export const getQuestionResponsesByInterviewIdAndStageAndName = /* GraphQL */ `
  query GetQuestionResponsesByInterviewId(
    $interviewId: ID!
    $stage: Stage!
    $interviewerName: String
    $nextToken: String
  ) {
    listQuestionResponses(
      filter: {
        interviewId: { eq: $interviewId }
        stage: { eq: $stage }
        interviewerName: { eq: $interviewerName }
      }
      nextToken: $nextToken
    ) {
      items {
        id
        interviewId
        stage
        rating
        timestamp
        interviewerName
        question
        notes
        order
        subquestionResponses {
          items {
            id
            questionResponseId
            question
            notes
            order
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getQuestionResponseWithSubquestions = /* GraphQL */ `
  query GetQuestionResponse($id: ID!) {
    getQuestionResponse(id: $id) {
      id
      interviewId
      stage
      rating
      timestamp
      interviewerName
      question
      notes
      order
      subquestionResponses {
        items {
          id
          questionResponseId
          question
          notes
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const getInterviewWithAllResponses = /* GraphQL */ `
  query GetInterview($id: ID!) {
    getInterview(id: $id) {
      id
      group
      questions
      interviewInfo
      scheduling
      stage
      companyID
      companyName
      interviewResultData
      questionResponses {
        items {
          id
          interviewId
          stage
          rating
          timestamp
          interviewerName
          question
          notes
          order
          subquestionResponses {
            items {
              id
              questionResponseId
              question
              notes
              order
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recordings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
