import { ThumbsDown } from "../questions/ThumbsDown";
import { ThumbsSideways } from "../questions/ThumbsSideways";
import { ThumbsUp } from "../questions/ThumbsUp";
import { Rating } from "../questions/Rating";

export const ratingForScore = (score: number | undefined): Rating | undefined => 
    score === undefined 
        ? undefined
        : score < 4
            ? ThumbsDown
            : score < 8
                ? ThumbsSideways
                : ThumbsUp;
