import { MdMoreHoriz } from "react-icons/md";

export default function CardText(props) {
  const { state, states, setMoreModalVisible, displayTitle, candidateName } =
    props;

  const handleDotsClick = (event) => {
    event.stopPropagation();
    setMoreModalVisible(true);
  };

  return (
    <>
      <div className={`card-header ${states[state]?.color}`}>
        <p className="overflow-text">{states[state]?.label}</p>
        <div className="expand-options">
          <MdMoreHoriz size={20} onClick={handleDotsClick} />
        </div>
      </div>

      <div className="card-info dark-opacity cursor">
        <p className="card-role-and-customer">{displayTitle}</p>
        <div className="truncate">{candidateName}</div>
      </div>
    </>
  );
}
