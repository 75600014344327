import { useState, useEffect, useRef } from "react";

import { listInterviews, listCompanies } from "../../graphql/queries";
import { LoadingScreen, Modal } from "../../Components";

import "./index.css";
import useGroups from "../../utils/useGroups";
import { useNavigate } from "react-router-dom";
import { GraphQL, getUser } from "../../utils/api";
import { getCognitoUsername } from "../../utils/auth";

import Kanban from "./Kanban";
import DashboardHeader from "./Header";
import { CompaniesModal, NotesModal, ScheduleModal, ParkForLaterModal } from "../Interview/Modals";
import getInterviewState from "../../utils/getInterviewState";
import { usePageTitle } from "../../navigation/usePageTitle";

export default function Dashboard() {
  usePageTitle("Interviews");
  const groups = useGroups();
  const navigate = useNavigate();

  const [interviews, setInterviews] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [allInterviews, setAllInterviews] = useState(null);

  const [companiesModalVisible, setCompaniesModalVisible] = useState(false);
  const [tab, setTab] = useState("inProgress");

  const [selectedInterviewId, setSelectedInterviewId] = useState(null);

  const [selectedInterviewForSchedule, setSelectedInterviewForSchedule] = useState(null);

  const [selectInterviewForPark, setSelectedInterviewForPark] = useState(null);

  const [search, setSearch] = useState("");

  const [filter, setFilter] = useState({
    is_scheduled: { label: "Is Scheduled", active: true },
    not_scheduled: { label: "Not Scheduled", active: true },
    passed: { label: "No Show", active: true },
    offer_accepted: { label: "Offer Accepted", active: true },
    rejected: { label: "Rejected", active: true },
    legacy: { label: "Scheduled", active: true },
    parked: { label: "Parked", active: true },
  });
  const searchRef = useRef(search);

  const [waiting, setWaiting] = useState(false);
  const [released, setReleased] = useState(false);

  let isAdmin = false;
  if (groups) isAdmin = groups.includes("Admin");

  // useEffect(() => {
  //   async function getInterviewStatus() {
  //     const res = await getInterviewState(
  //       "c650a946-c185-418c-9dcd-95f6b7121e2e"
  //     );
  //   }
  //   // getInterviewStatus();
  // }, []);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  useEffect(() => {
    async function getCompanies() {
      if (groups && isAdmin) {
        const query = listCompanies;
        const res = await GraphQL({ query });
        setCompanies(
          res.listCompanies.items.filter(
            (company) => company?.status === "Active"
          )
        );
      }
    }
    if (isAdmin) getCompanies();
  }, [groups, isAdmin]);

  useEffect(() => {
    if (groups) getData();
  }, [groups]);

  async function getData() {
    let nextToken = null;
    let interviews = [];
    do {
      const query = listInterviews;
      const res = await GraphQL({
        query,
        variables: { limit: 1000, nextToken },
      });
      interviews = interviews.concat(res.listInterviews.items);
      nextToken = res.listInterviews.nextToken;
    } while (nextToken);

    const interviewsWithState = await Promise.all(
      interviews.map(async (interview) => {
        const interviewState = await getInterviewState(interview);
        return { ...interview, state: interviewState };
      })
    );
    setAllInterviews(interviewsWithState);

    const user = await getCognitoUsername();

    let formatInterviews = {
      discovery: [],
      value1: [],
      working: [],
      value2: [],
      accepted: [],
      rejected: [],
      parked: [],
    };

    try {
      // if Intertru admin, return data
      if (groups.includes("Admin")) {
        interviews.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        for (const interview of interviews) {
          formatInterviews[interview.stage]?.push(interview);
          setInterviews(formatInterviews);
        }
      } else {
        const { role } = await getUser(user); // fails if Intertru admin
        if (["owner", "admin"].includes(role)) {
          interviews.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );

          for (const interview of interviews) {
            formatInterviews[interview.stage]?.push(interview);
          }
          setInterviews(formatInterviews);
        } else {
          interviews.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );

          for (const interview of interviews) {
            const { interviewers, participants } = JSON.parse(
              interview.interviewInfo
            );
            const interviewersArray = Object.values(interviewers).map(
              (interviewer) => interviewer.id
            );
            const participantsArray = participants.map(
              (participant) => participant.id
            );
            if (interviewersArray.concat(participantsArray).includes(user)) {
              formatInterviews[interview.stage].push(interview);
            }
          }
          setInterviews(formatInterviews);
        }
      }
    } catch (e) {}
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setSelectedInterviewId(null);
        setSelectedInterviewForSchedule(null);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  let current;
  if (interviews) {
    const { discovery, value1, working, value2 } = interviews;
    current = discovery.length + value1.length + working.length + value2.length;
  }
  if (released) {
    getData().then(() => 
      setWaiting(false),
      setReleased(false)
     );
  }
  function parkModalClose() {
    setSelectedInterviewForPark(null);
    getData().then(() => 
      setWaiting(false),
      setReleased(false)
     );
  }
  if (!interviews) return <LoadingScreen />;
  return (
    <div
      style={{ width: "100%", padding: 10, marginTop: 10, overflow: "hidden" }}
    >
      
      {!!selectedInterviewForSchedule && (
        <ScheduleModal
          interviewId={selectedInterviewForSchedule}
          mode="view"
          modalVisible={true}
          onClose={() =>  setSelectedInterviewForSchedule(null)}
        />
      )}
      {!!selectedInterviewId && (
        <NotesModal
          interviewId={selectedInterviewId}
          mode="view"
          onClose={() => setSelectedInterviewId(null)}
        />
      )}
      {!!selectInterviewForPark && (
        <ParkForLaterModal
          interviewId={selectInterviewForPark}
          mode="view"
          modalVisible={true}
          onClose={() => parkModalClose()}
        />
      )}
      <Modal
        style={{ width: "35%" }}
        title="Select A Customer"
        isVisible={companiesModalVisible}
        onClose={() => setCompaniesModalVisible(false)}
      >
        <CompaniesModal {...{ companies, navigate }} />
      </Modal>
      <DashboardHeader
        inProgress={current}
        released={interviews?.rejected.length + interviews?.parked.length}
        {...{
          tab,
          setTab,
          isAdmin,
          setCompaniesModalVisible,
          search,
          setSearch,
          filter,
          setFilter,
        }}
        {...{
          tab,
          setTab,
          isAdmin,
          setCompaniesModalVisible,
          search,
          setSearch,
          filter,
          setFilter,
        }}
      />
      <Kanban
        {...{
          interviews,
          allInterviews,
          tab,
          isAdmin,
          waiting,
          setWaiting,
          setSelectedInterviewId,
          setSelectedInterviewForSchedule,
          setSelectedInterviewForPark,
          setReleased,
          search,
          filter,
        }}
      />
    </div>
  );
}