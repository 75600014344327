import { useState } from "react";
import Interviewer from "./Interviewer";
import { Button, TextInput } from "../Components";

export default function Participant() {
  const [name, setName] = useState("");
  const [join, setJoin] = useState(false);

  if (join) return <Interviewer name={name} isParticipant />;

  return (
    <>
      <div
        className="hor-center"
        style={{ marginTop: "15%", marginBottom: 12 }}
      >
        <TextInput
          label="Name"
          inputStyle={{
            width: 350,
            height: 40,
            border: "1px solid #555",
            borderRadius: 4,
          }}
          style={{ border: "1px solid blue" }}
          value={name}
          onChange={setName}
        />
      </div>
      <div className="hor-center">
        <Button
          onClick={() => {
            if (name) setJoin(true);
          }}
        >
          Join Session
        </Button>
      </div>
    </>
  );
}
