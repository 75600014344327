import { Text } from "@chakra-ui/react";
import * as React from "react";
import { toast } from "react-toastify";
import { attributeDetailHref } from "../Detail/attributeDetailHref";
import { newAttributeAndQuestionsFromLibrary } from "../Library/newAttributeFromLibrary";

export const onAddAttributeClick = 
    (valueId) => 
    (attributeLibraryId) => 
    (navigate) => 
    (setIsOpen) => 
    (setState) => 
    () => 
    {
        setIsOpen(false);
        setState(state => {
            const { newAttribute, questions } = newAttributeAndQuestionsFromLibrary(attributeLibraryId)(state.questions)
            toast.success(<Text>Attribute <i>{newAttribute.title}</i> added</Text>);
            // navigate(attributeDetailHref(valueId, newAttribute.id));
            return {
                ...state,
                questions,
                weightedValues: state.weightedValues.map(
                    weightedValue => weightedValue.id == valueId
                        ? ({
                            ...weightedValue,
                            weightedAttributes: [
                                ...weightedValue.weightedAttributes,
                                newAttribute,
                            ]
                        })
                        : weightedValue
                )
            }
        });
    };
