import { API } from "aws-amplify";

export async function sendEmail(payload) {
  try {
    await API.post("sesRest", "/send-email", { body: payload });
  } catch (e) {
    console.log("e", e);
  }
}

export async function sendInterviewEmail(payload) {
  try {
    await API.post("sesRest", "/send-interview-email", { body: payload });
  } catch (e) {
    console.log("e", e);
  }
}
