export function validateEmail(str) {
  if (!str) return "Please enter your email.";
  if (str.trim().includes(" ")) return "Email cannot contain spaces.";
  if (!/^[\w.-]+(\+\w+)?@\w+([.-]?\w+)*\.\w{2,3}$/.test(str.trim()))
    return "Email is not valid.";
}

export function isValidEmail(emailStr) {
  const email = emailStr.toLowerCase().trim();
  if (email.trim().includes(" ")) return false;
  if (!/^[\w.-]+(\+\w+)?@\w+([.-]?\w+)*\.\w{2,3}$/.test(email.trim()))
    return false;
  return true;
}

export function validatePassword(str) {
  if (!str) return "Please create a password";
  if (str.includes(" ")) return "Password cannot contain spaces";
  if (str.length < 8) return "Password must be at least 8 characters";
}
