import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { FiCornerUpLeft as BackIcon } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { cloneDeep, set } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import qs from "query-string";
import { sendEmail } from "../../utils/email";

import {
  TextInput,
  Container,
  Button,
  LoadingScreen,
  Checkbox,
  Modal,
} from "../../Components";
import {
  MyLink,
  RoleSelector,
  DeleteModal,
  NotesModal,
  RoleModal,
  Questions,
  UrlModal,
  initQuestions,
  UsersSelector,
  ParticipantAdd,
} from "./index";
import {
  createInterview,
  updateInterview,
  deleteInterview,
  updateRole,
  updateJobRole as mUpdateJobRole,
  updateCompany,
  createSession,
} from "../../graphql/mutations";
import { getInterview, listInterviews } from "../../graphql/queries";
import { getCompanyWithRolesAndUsers } from "../../graphql/custom";
import { GraphQL, getUser, listJobRolesReqByCompId, updateJobRole } from "../../utils/api";
import { sendInterviewEmail } from "../../utils/email";
import getInterviewUrls from "../../utils/getInterviewUrls";
import { stagesV2 as stages } from "../../utils/stages";
import { errorRed } from "../../theme";
import { styles } from "./styles";
import { getCognitoUsername } from "../../utils/auth";
import useGroups from "../../utils/useGroups";
import { validateEmail } from "../../utils/validation";
import { usePageTitle } from "../../navigation/usePageTitle";
import { Auth } from "aws-amplify";

import AWS from 'aws-sdk';
import AppConfig from 'aws-sdk/clients/appconfig';
import { v4 as uuidv4 } from 'uuid';

export default function Interview() {
  usePageTitle("Interview Details");
  const navigate = useNavigate();
  const location = useLocation();
  const [groups, setGroups] = useState(useGroups());

  const [candidateName, setCandidateName] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidateRole, setCandidateRole] = useState("");
  const [interviewers, setInterviewers] = useState({
    discovery: { name: "", email: "" },
    value1: { name: "", email: "" },
    working: { name: "", email: "" },
    value2: { name: "", email: "" },
    accepted: { name: "", email: "" },
    rejected: { name: "", email: "" },
  });
  const [stage, setStage] = useState("discovery");
  const [review, setReview] = useState("");

  const [questions, setQuestions] = useState(initQuestions);

  const [currentTab, setCurrentTab] = useState("discovery");

  const [interviewerLink, setInterviewerLink] = useState("");
  const [intervieweeLink, setIntervieweeLink] = useState("");
  const [participantLink, setParticipantLink] = useState("");
  const [currentUserToRemindForParked, setCurrentUserToRemindForParked] = useState(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [notesModalVisible, setNotesModalVisible] = useState(false);
  const [roleModalVisible, setRoleModalVisible] = useState(false);
  const [urlModalVisible, setUrlModalVisible] = useState(false);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [capacityModalVisible, setCapacityModalVisible] = useState(false);

  const [users, setUsers] = useState({});

  const [urlWaiting, setUrlWaiting] = useState(false);

  const [downloadUrls, setDownloadUrls] = useState([]);

  const [company, setCompany] = useState(null);
  const [companyID, setCompanyId] = useState(null);

  const { id: interviewId, mode, cid } = qs.parse(location.search);

  const [roleMap, setRoleMap] = useState(null);
  const [roleLists, setRoleLists] = useState(null);
  const [sendingEmails, setSendingEmails] = useState(true);

  const [participants, setParticipants] = useState([]);
  const [existingInterviews, setExistingInterviews] = useState([]);

  const [scheduling, setScheduling] = useState({});

  const [waiting, setWaiting] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isRoleManagementEnabled, setIsRoleManagementEnabled] = useState(null);
  const initialAdminCheckDone = useRef(false);

  useEffect(() => {
    if (groups && groups.includes("Admin")) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    const fetchConfig = async () => {
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
      });

      try {
        const user = await Auth.currentAuthenticatedUser();
        const userEmail = user.attributes.email;
        const appConfig = new AppConfig();
        const params = {
          Application: process.env.REACT_APP_APICONFIG_APPID,
          Environment: process.env.REACT_APP_APICONFIG_ENVID,
          Configuration: process.env.REACT_APP_APICONFIG_CONFIGPROFILEID,
          ClientId: uuidv4(),
        };

        try {
          const data = await appConfig.getConfiguration(params).promise();
          const configContent = data.Content.toString('utf-8');
          const config = JSON.parse(configContent);

          if (isAdmin) {
            setIsRoleManagementEnabled(true);
          } else if (config.featureManagement.jobRoleFeature.allowedUsers.length === 0) {
           
            setIsRoleManagementEnabled(true);
          } else if (
            config.featureManagement.jobRoleFeature.enabled &&
            config.featureManagement.jobRoleFeature.allowedUsers.includes(userEmail)
          ) {
            setIsRoleManagementEnabled(true);
          } else {
            setIsRoleManagementEnabled(false);
          }
        } catch (error) {
          console.error('Error fetching configuration:', error);
          setIsRoleManagementEnabled(false);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchConfig();
  }, []);

  // SET THE WORKING SESSION BASED ON CANDIDATE ROLE
  useEffect(() => {
    const setRoleMapping = async () => {
      if (!roleMap || !candidateRole) return;
      const roleData = roleMap[candidateRole];
      if (!roleData) return;

      setQuestions((prevQuestions) => {
        const updatedQuestions = cloneDeep(prevQuestions);
        updatedQuestions.working = roleData.workingSession;
        return updatedQuestions;
      });
    };

    setRoleMapping();
  }, [isRoleManagementEnabled, candidateRole, roleMap, roleLists]);

  useEffect(() => {
    const generateInterviewProfile = async () => {
      if (mode === "create" && groups) {
        (async () => {
          const { values1, values2 } = await getCompanyData();
          const myQuestions = cloneDeep(questions);
          myQuestions.value1 = values1;
          myQuestions.value2 = values2;
          setQuestions(myQuestions);
        })();
      } else if (mode === "edit" && groups) {
        populateInterviewData(interviewId);
      }
    }
    generateInterviewProfile();
  }, [isRoleManagementEnabled, mode, interviewId, groups, userModalVisible]);

  async function getCompanyData() {
    // grab companyID from url as Admin
    if (cid) setCompanyId(cid);

    let userRes;
    let adminUser;
    if (groups && !groups.includes("Admin")) {
      userRes = await getUser(await getCognitoUsername());
      if (!cid) setCompanyId(userRes?.companyID);
    }
    const adminRes = await Auth.currentAuthenticatedUser();
    const adminPayload = adminRes.signInUserSession.idToken.payload;
    if (adminRes && adminPayload["cognito:groups"].includes("Admin"))
      adminUser = adminPayload;
   
    const query = getCompanyWithRolesAndUsers;
    let variables = { id: cid || userRes?.companyID, userStatus: "Active" };
    const res = await GraphQL({ query, variables });

    let company = res.getCompany;

    if (!company.interviewInfo) {
      // in case of a broken or missing interviewInfo object,
      // grab the first admin/owner and make them the default interviewer
      let defaultInterviewers = {};
      for (let i = 0; i < company.users.items.length; i++) {
        let user = company.users.items[i];
        if (["administrator", "owner"].includes(user.role)) {
          for (const stage of Object.keys(stages)) {
            defaultInterviewers[stage] = {
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
            };
          }
          break;
        }
      }
      const query = updateCompany;
      const variables = {
        input: {
          id: cid ? cid : companyID,
          interviewInfo: JSON.stringify({ defaultInterviewers }),
        },
      };
      const res = await GraphQL({ query, variables, authMode: "" });
      company = res.getCompany;
    }

    setCompany(company);
    

    const users = {};
    const interviewers = company?.users?.items?.sort((a, b) => {
      if (a.firstName < b.firstName) return -1;
      return 1;
    });
    interviewers.map((user) => (users[user.id] = user));

    // add CSMs to user obj
    const csmUsers = [
      // Rob, Mike, and Rick's ids
      {
        firstName: "Rick",
        lastName: "Girard",
        email: "rick@intertru.ai",
        id: "756604a0-876d-432f-a8c7-c74a9c751343",
      },
      /*{
        firstName: "Mike",
        lastName: "Monegan",
        email: "mike@intertru.ai",
        id: "410a486b-8dad-4f9e-a51a-4ba35a107fc6",
      },*/
      {
        firstName: "Rob",
        lastName: "Swift",
        email: "rob@intertru.ai",
        id: "4da63b33-be96-40d7-84cd-869209d7d3b6",
      },
    ];
    csmUsers.forEach((csm) => {
      users[csm.id] = csm;
    });

    if (adminUser) {
      let firstName;
      let lastName;
      if (adminUser.name) {
        const [first, ...rest] = adminUser.name.split(" ");
        const last = rest.join(" ");
        firstName = first;
        lastName = last;
      }

      users[adminUser.sub] = {
        firstName,
        lastName,
        email: adminUser.email,
        id: adminUser.sub,
      };
    }
    setUsers(users);

    //TODO change this later on to default to a default interviewer

    const defaults = JSON.parse(company.interviewInfo)?.defaultInterviewers;
    if (mode === "create") setInterviewers(defaults);

    // generate rolemap
    let fetchedRoleLists;
    listJobRolesReqByCompId(company.id).then((response) => {
      fetchedRoleLists = response.listJobRoles.items
      
      const parsedRoles = fetchedRoleLists.map(jobRole => ({
        ...jobRole,
        jobRoleData: JSON.parse(jobRole.jobRoleData)
      }));
  
      const roleData = parsedRoles.map(jobRole => ({
        id: jobRole.id,
        companyID: jobRole.jobRoleData.companyID,
        name: jobRole.jobRoleData.title,
        workingSession: jobRole?.workingSession?.length > 0 ? jobRole.workingSession : ['']
      }));
      let Roles = isRoleManagementEnabled ? roleData : company.roles.items;
      const lookup = {};
      Roles.forEach((r) => {
        lookup[r.id] = r;
      });
      setRoleMap(lookup);
      setRoleLists(roleData)
     });
     
    const updatedQuestions = cloneDeep(questions);
    if (!company.values1) company.values1 = [];
    if (!company.values2) company.values2 = [];
    const values1 = company.values1.map((str) => str);
    const values2 = company.values2.map((str) => str);
    setQuestions(updatedQuestions);

    // return updatedQuestions
    return { values1, values2 };
  }

  async function populateInterviewData(id) {
    const query = getInterview;
    const variables = { id };
    const res = await GraphQL({ query, variables, authMode: "" });
    const { interviewInfo, stage, companyID, scheduling } = res.getInterview;
    // const { listQuestionResponses } = await GraphQL({
    //   query: getQuestionResponsesByInterviewId,
    //   variables: { interviewId: id },
    // });

    // const questionResponses = listQuestionResponses.items;

    // verify questions format
    // const reshapedQuestions = formatQuestionResponses(questionResponses);

    // get values data from the company data
    const { values1, values2, users } = await getCompanyData(companyID);
    const {
      candidateEmail,
      candidateName,
      candidateRole,
      interviewers,
      dateTime,
      participants,
      review,
    } = JSON.parse(interviewInfo);
    const myQuestions = cloneDeep(questions);
    setCandidateEmail(candidateEmail);
    setCandidateName(candidateName);
    setCandidateRole(roleLists ? candidateRole : (candidateRole?.id ?? ''));
    setInterviewers(interviewers);
    if (participants) setParticipants(participants);
    myQuestions.value1 = values1;
    myQuestions.value2 = values2;
    setQuestions(myQuestions);
    setCompanyId(companyID);
    if (scheduling) setScheduling(JSON.parse(scheduling));
    else setScheduling({ [stage]: dateTime });
    if (users?.items) {
      const users = {};
      users.items.map((user) => (users[user.id] = user));
      setUsers(users);
    }
    setReview(review);
    setInterviewerLink(
      `${window.location.origin}/interviewer?id=${interviewId}`
    );
    setParticipantLink(
      `${window.location.origin}/participant?id=${interviewId}`
    );
    setIntervieweeLink(
      `${window.location.origin}/interviewee?id=${interviewId}`
    );
    setStage(stage);
    setCurrentUserToRemindForParked(JSON.parse(interviewInfo).remindUserWhoParked);
  }

  useEffect(() => {
    //get links for audio files
    async function getUrls() {
      setUrlWaiting(true);
      let urls = [];
      if (stage) urls = await getInterviewUrls(interviewId);
      setDownloadUrls(urls);
      setUrlWaiting(false);
    }
    if (mode === "edit") getUrls();
  }, [stage, interviewId, mode]);

  // limit creation of new interviews
  useEffect(() => {
    async function getInterviews() {
      const { listInterviews: res } = await GraphQL({ query: listInterviews });
      const existing = res?.items.map((interview) => {
        const { participants, dateTime } = JSON.parse(interview.interviewInfo);
        return { quantity: 2 + participants?.length, dateTime };
      });
      setExistingInterviews(existing);
    }
    getInterviews();
  }, []);

  let title = "Create Interview";
  if (mode === "edit") title = "Interview Details";

  if (!companyID || !company || !roleMap || !questions) {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <LoadingScreen />
      </div>
    );
  }

  return (
    <Container>
      <DeleteModal
        onClick={onDeleteInterview}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title="Delete Interview"
        description="Are you sure you want to delete this interview?"
      />
      {!!notesModalVisible && (
        <NotesModal
          stage={stage}
          interviewId={interviewId}
          onClose={() => setNotesModalVisible(null)}
          setNotesModalVisible={setNotesModalVisible}
        />
      )}
      <RoleModal
        {...{
          roleModalVisible,
          setRoleModalVisible,
          companyID,
          setCandidateRole,
          getCompanyData,
        }}
      />
      <UrlModal
        {...{
          urlModalVisible,
          setUrlModalVisible,
          urlWaiting,
          downloadUrls,
          stages,
        }}
      />
      <Modal
        style={{ ...styles.roleModal, width: 700 }}
        title="Intertru Capacity Reached"
        isVisible={capacityModalVisible}
        onClose={() => setCapacityModalVisible(false)}
      >
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ marginBottom: 16 }}>
            Intertru is a popular platform with rapidly growing usage.
            Unfortunately, your requested interview time will need to be
            adjusted slightly to avoid an over-subscribed period on our
            interview calendar.
          </div>
          <div style={{ marginBottom: 20 }}>
            Thanks for your cooperation in making sure everyone has an excellent
            interview experience!
          </div>
          <Button filled onClick={() => setCapacityModalVisible(false)}>
            Okay
          </Button>
        </div>
      </Modal>
      {initialAdminCheckDone &&(
        <>
      {renderHeader()}
      {renderInterviewInfo()}
      <Questions
        {...{ currentTab, questions, setQuestions, interviewId, setCurrentTab }}
      />
      {renderFooter()}
      </>
      )}
    </Container>
  );

  function renderHeader() {
    return (
      <div style={styles.daj}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BackIcon
            size={22}
            style={{ marginRight: 20, cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <div style={styles.title}>{title}</div>
        </div>
        {mode === "edit" && (
          <div className="hor-center">
            <Button
              className="red-hover"
              style={{
                border: `2px solid ${errorRed}`,
                color: errorRed,
                marginLeft: 8,
              }}
              onClick={() => setModalVisible(true)}
            >
              Delete Interview
            </Button>
          </div>
        )}
      </div>
    );
  }

  function renderInterviewInfo() {
    if (companyID)
      return (
        <div style={{ marginBottom: 50 }}>
          <div style={styles.subheader}>
            Interview Information {company && `- ${company.company}`}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "50%", borderRight: "1px solid #aaa" }}>
              <div>
                <div style={styles.inputLabel}>Candidate Name</div>
                <TextInput
                  inputStyle={styles.inputStyle}
                  value={candidateName}
                  onChange={setCandidateName}
                />
              </div>
              <div>
                <div style={styles.inputLabel}>Candidate Email</div>
                <TextInput
                  inputStyle={styles.inputStyle}
                  value={candidateEmail}
                  onChange={setCandidateEmail}
                />
              </div>

              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      ...styles.inputLabel,
                      width: "auto",
                      marginRight: 10,
                    }}
                  >
                    Candidate Role
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <RoleSelector
                    {...{
                      company,
                      roleMap,
                      roleLists,
                      candidateRole,
                      setCandidateRole,
                      questions,
                      setQuestions,
                      isRoleManagementEnabled
                    }}
                  />
                  <div style={{ width: 10 }} />
                  <div
                    className="hor-center"
                    style={{ width: 50, marginBottom: 16 }}
                  >
                    <Button
                      filled
                      style={styles.participantAddButton}
                      onClick={() => isRoleManagementEnabled ? navigate(`/admin/jobrole?compID=${companyID}`) : setRoleModalVisible(true)}
                      // onClick={() => setRoleModalVisible(true)}
                    >
                      New
                    </Button>
                  </div>
                </div>
              </div>

              <div>
                <div style={{ ...styles.inputLabel, width: 300 }}>
                  {"Interview Date" +
                    (!!stages[stage] ? ` (${stages[stage]?.label}` : "") +
                    (stages[stage]?.label === "Parked" ? " Until)" : ")")}
                </div>
                <div style={styles.dateInput}>
                  <DatePicker
                    selected={
                      scheduling[stage] ? new Date(scheduling[stage]) : null
                    }
                    onChange={(time) => {
                      const updateScheduling = cloneDeep(scheduling);
                      updateScheduling[stage] = time;
                      setScheduling(updateScheduling);
                    }}
                    {...(stages[stage]?.label !== "Parked") && { showTimeSelect: true }}
                    dateFormat="Pp"
                    timeIntervals={15}
                  />
                </div>
              </div>

              {renderLinks()}
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {renderStageSelector()}
              {!["accepted", "rejected", "parked"].includes(stage) && (
                <UsersSelector
                  {...{
                    interviewers,
                    stage,
                    users,
                    currentTab,
                    setInterviewers,
                    cid,
                    userModalVisible,
                    setUserModalVisible,
                  }}
                />
              )}
              {mode === "edit" && !["parked"].includes(stage) && (
                <MyLink
                  title="Interviewer Link"
                  link={interviewerLink}
                  style={{ marginBottom: 6 }}
                />
              )}
              {!["parked"].includes(stage) && (
              <ParticipantAdd
                {...{
                  users,
                  interviewers,
                  stage,
                  participants,
                  setParticipants,
                  cid,
                  userModalVisible,
                  setUserModalVisible,
                }}
              />
              )}
              {mode === "edit" && !["parked"].includes(stage) && (
                <MyLink
                  title="Participant Link"
                  link={participantLink}
                  style={{ marginTop: 8 }}
                />
              )}
            </div>
          </div>
        </div>
      );
  }

  function renderStageSelector() {
    return (
      <div>
        <div style={styles.inputLabel}>Interview Stage</div>
        <select
          value={stage}
          onChange={(s) => setStage(s.target.value)}
          style={styles.select}
        >
          <option value="discovery">Discovery Call</option>
          {mode === "edit" && (
            <>
              <option value="value1">Value Alignment</option>
              <option value="working">Working Session</option>
              <option value="value2">Final Interview</option>
              <option value="accepted">Accepted</option>
              <option value="parked">Parked</option>
              <option value="rejected">Rejected</option>
            </>
          )}
        </select>
      </div>
    );
  }

  function renderLinks() {
    if (mode !== "edit") return null;

    return (
      <div style={styles.linksWrapper}>
        <MyLink title="Interviewee Link" link={intervieweeLink} />
        <Button
          onClick={() => setNotesModalVisible(interviewId)}
          style={{ marginTop: 16, width: 200 }}
        >
          View Notes
        </Button>
        <Button
          onClick={() => {
            setUrlModalVisible(true);
          }}
          style={{ marginTop: 16, width: 200 }}
        >
          Download Audio
        </Button>
      </div>
    );
  }

  function renderFooter() {
    return (
      <div style={styles.footer}>
        <div className="hor-center">
          <Checkbox
            disabled={
              !scheduling[stage] || ["accepted", "rejected"].includes(stage)
            }
            label="Send Emails"
            checked={sendingEmails}
            onClick={() => setSendingEmails(!sendingEmails)}
            style={
              !!scheduling[stage] || !["accepted", "rejected"].includes(stage)
                ? { cursor: "pointer" }
                : {}
            }
          />
          <div style={{ width: 20 }} />
          {mode === "create" && (
            <Button
              filled
              onClick={onCreate}
              style={styles.createButton}
              waiting={waiting}
            >
              Create Interview
            </Button>
          )}
          {mode === "edit" && (
            <Button
              filled
              onClick={onUpdate}
              style={styles.createButton}
              waiting={waiting}
            >
              Update Interview
            </Button>
          )}
        </div>
      </div>
    );
  }

  async function onCreate() {
    setWaiting(true);
    // validate inputs
    if (
      !(
        (
          candidateName &&
          candidateEmail &&
          candidateRole &&
          interviewers["discovery"].name &&
          interviewers["discovery"].email
        )
        // && dateTime
      )
    ) {
      setWaiting(false);
      return toast.error("Required fields are missing.");
    }
    const email_ = candidateEmail.toLowerCase().trim();

    const emailValidationResult = validateEmail(email_);
    if (emailValidationResult) {
      setWaiting(false);
      return toast.error(emailValidationResult);
    }

    let canCreate = true;

    // check if interview will exceed server capacity
    try {
      const existingQuantity = 2 + participants?.length;
      const requestedEnd = moment(scheduling[stage]).add(45, "m");
      for (const interview of existingInterviews) {
        const { quantity, dateTime: existingStart } = interview;

        const existingEnd = moment(existingStart).add(45, "m");

        // fail if requested start is before existing end AND
        // requested end is after existing start
        if (
          moment(scheduling[stage]) < existingEnd &&
          requestedEnd > moment(existingStart) &&
          quantity + existingQuantity > 7
        ) {
          canCreate = false;
          break;
        }
      }
    } catch (e) {
      setWaiting(false);
    }
    if (!canCreate) {
      setWaiting(false);
      return setCapacityModalVisible(true);
    }

    const userWhoParked = {
      username: "",
      email: ""
    }

    // add interview to db
    const variables = {
      input: {
        questions: JSON.stringify(questions),
        interviewInfo: JSON.stringify({
          candidateName,
          candidateEmail,
          candidateRole: roleMap[candidateRole],
          interviewers,
          participants,
          userWhoParked : userWhoParked
        }),
        scheduling: JSON.stringify(scheduling),
        group: cid ? cid : companyID,
        stage: "discovery",
        companyID: cid ? cid : companyID,
        companyName: company.company,
      },
    };
    const query = createInterview;
    const res = await GraphQL({ query, variables });
    console.log(res);
    const { id } = res.createInterview;

    // create sessions for each stage for the interview
    for (const stage of Object.keys(stages)) {
      const sessionVariables = { input: { id: `${id}-${stage}` } };
      const sessionQuery = createSession;

      try {
        const res = await GraphQL({
          query: sessionQuery,
          variables: sessionVariables,
          authMode: "",
        });
        console.log(res);
      } catch (e) {
        console.error(e);
        setWaiting(false);
      }
    }

    //update company information if necessary

    //create video links and email them
    // await createSignalingChannel(id);

    if (scheduling[stage] && sendingEmails) sendEmails(id);

    await saveWorkingSession();
    await saveValues();

    navigate("/admin/dashboard");
    return toast.success("Interview created successfully.");
  }

  async function onUpdate() {
    setWaiting(true);
    const userWhoParked = {
      username: "",
      email: ""
    }

    if (!(candidateName && candidateEmail && interviewers))
      return toast.error("Required fields are missing.");
    let updatedScheduling = cloneDeep(scheduling);
    delete updatedScheduling.parked;
    const variables = {
      input: {
        id: interviewId,
        questions: JSON.stringify(questions),
        interviewInfo: JSON.stringify({
          candidateName,
          candidateEmail,
          candidateRole: roleMap[candidateRole],
          interviewers,
          dateTime: scheduling[stage],
          participants,
          review,
          remindUserWhoParked: stage === "parked" ? currentUserToRemindForParked : userWhoParked
        }),
        stage,
        scheduling: JSON.stringify(updatedScheduling),
      },
    };
    const query = updateInterview;
    const res = await GraphQL({ query, variables, authMode: "" });

    await saveWorkingSession();
    await saveValues();
    if (
      scheduling[stage] &&
      sendingEmails &&
      !["accepted", "rejected"].includes(stage)
    )
      sendEmails(interviewId);

    setWaiting(false);
    navigate("/admin/dashboard");
    return toast.success("Interview updated successfully.");
  }

  async function onDeleteInterview() {
    setModalVisible(false);
    const variables = { input: { id: interviewId } };
    const query = deleteInterview;
    const res = await GraphQL({ query, variables, authMode: "" });
    console.log(res);
    navigate("/admin/dashboard");
    return toast.success("Interview deleted successfully.");
  }

  function sendEmails(id) {
    const firstName = candidateName.split(" ")[0];
    const interviewerFName = interviewers[stage].name.split(" ")[0];
    const iDate = moment(scheduling[stage]).format("MMMM Do YYYY");
    const interviewShortDate = moment(scheduling[stage]).format("MMMM Do");
    const localTZ = moment.tz.zone(moment.tz.guess()).abbr(new Date());
    const iTime = moment(scheduling[stage]).format("h:mma") + " " + localTZ;
    const iStage = `${stages[stage].label} Interview`;
    const duration = stage === "working" ? 90 : 45;
    const wrapUpDuration = 15;
    const iRole = roleMap[candidateRole].name;

    const intervieweeLink = `${window.location.origin}/interviewee?id=${id}`;
    const interviewerLink = `${window.location.origin}/interviewer?id=${id}`;
    const participantLink = `${window.location.origin}/participant?id=${id}`;

    const teamIntertruEmail = "team@platform.intertru.com";

    // const totalTime = duration + wrapUpDuration;
    const totalTime = duration; // not including wrap up time

    const emailCandidateText = {
      subject: `${interviewers[stage].name} at ${company.company}`,
      body: `Hi ${firstName},
        \n\nYou are confirmed for a ${duration}-minute ${iStage} on ${iDate} at ${iTime} with ${interviewers[stage].name} for the position of ${iRole} at ${company.company}.
        \nPlease reply to this email if you need to reschedule or cancel.
        \nIntertru is an interview platform that you can read more about at https://intertru.ai. To join this ${iStage}, simply click ${intervieweeLink}
        \n\nPlease open this invite attachment to add it to your calendar. Looking forward to chatting with you!\n`,
    };
    const emailInterviewerText = {
      subject: `Intertru with ${candidateName} Interview at ${company.company}`,
      body: `Hi ${interviewerFName},
      \nYou are confirmed for a ${duration}-minute ${iStage} on ${iDate} at ${iTime}.
      \nJoin using your interviewer link at ${interviewerLink}
      \n\nPlease add the attached invite to your calendar, which allows ${duration} minutes for the interview and ${wrapUpDuration} minutes afterward for finalizing your required notes submission.\n`,
    };
    const emailParticipantText = {
      subject: `Intertru with ${candidateName} Interview at ${company.company}`,
      body: `Hello,
      \nYou are confirmed for a ${duration}-minute ${iStage} on ${iDate} at ${iTime}.
      \nJoin using your interviewer link at ${participantLink}
      \n\nPlease add the attached invite to your calendar, which allows ${duration} minutes for the interview and ${wrapUpDuration} minutes afterward for finalizing your required notes submission.\n`,
    };

    function cleanQuestion(input) { // looking forward to removing all this nonsense
      // Remove all characters starting with the first newline
      let result = input.split('\n')[0].trim();
      
      // Remove any square-bracket expressions at the end of the string
      result = result.replace(/\[\d+\]$/, '').trim();
      
      // If the resulting string contains a colon, remove all characters before the colon, the colon itself, and any whitespace following the colon
      if (result.includes(':')) {
          result = result.split(':')[1].trim();
      }
      return result;
    }

    function emailQuestionText (questionArray)  {
      var emailTextValue = "";
      for (let i = 0; i < questionArray.length; i++) {
        const question = questionArray[i];
        const questionName = question.includes(':') ? question.split(':')[0].trim() : "";
        if (!["Wrap-up", "Wrap Up", "Manual Prep Email"].includes(questionName))
          emailTextValue += `${i+1}. ${cleanQuestion(question)}\n\n`;
      }
      return emailTextValue;
    }

    const secondaryEmailCandidateTextValue1 = {
      subject: `Preparation for your ${interviewShortDate} meeting with ${company.company}`,
      body: `Hello ${candidateName},
      \nYou should have just received a meeting request for ${iDate} at ${iTime}.
      \nTo help you prepare, please take a look at the questions below. You'll be asked to respond to each question in detail. 
      \n${emailQuestionText(questions.value1)}
      You may contact your interviewer, ${interviewers[stage].email}, with any questions you have.
      `
    ,}

    const secondaryEmailCandidateTextValue2 = {
      subject: `Preparation for your ${interviewShortDate} meeting with ${company.company}`,
      body: `Hello ${candidateName},
      \nYou should have just received a meeting request for ${iDate} at ${iTime}.
      \nTo help you prepare, please take a look at the questions below. You'll be asked to respond to each question in detail. 
      \n${emailQuestionText(questions.value2)}
      You may contact your interviewer, ${interviewers[stage].email}, with any questions you have.
      `
    ,}

    const candidateEmailPayload = {
      id,
      to: [{ name: candidateName, email: candidateEmail }],
      subject: emailCandidateText.subject,
      text: emailCandidateText.body,
      start: scheduling[stage],
      end: moment(scheduling[stage]).add(totalTime, "minutes"),
      url: intervieweeLink,
      description: `You are confirmed to meet with ${interviewers[stage].name} at ${company.company} using the Intertru Interviewing Platform.
      \nThis video conference works right in your web browser (Chrome, Safari, Firefox, or Edge). Feel free to log in a few minutes early to ensure your camera and microphone are set up and working correctly.`,
      organizer: interviewers[stage].email,
      senderName: company.company,
      stage,
    };

    const secondaryCandidateEmailValue1Payload = {
      id,
      to: [candidateEmail],
      /* cc: [
        { name: interviewers[stage].name, email: interviewers[stage].email },
       ], */
      subject: secondaryEmailCandidateTextValue1.subject,
      text: secondaryEmailCandidateTextValue1.body,
      senderName: teamIntertruEmail,
      stage,
    };

    const secondaryCandidateEmailValue2Payload = {
      id,
      to: [candidateEmail],
      /* cc: [
        { name: interviewers[stage].name, email: interviewers[stage].email },
      ], */
      subject: secondaryEmailCandidateTextValue2.subject,
      text: secondaryEmailCandidateTextValue2.body,
      senderName: teamIntertruEmail,
      stage,
    };

    const interviewerEmailPayload = {
      id,
      to: [
        { name: interviewers[stage].name, email: interviewers[stage].email },
      ],
      subject: `${stages[stage].label} Interview with ${candidateName}`,
      text: emailInterviewerText.body,
      start: scheduling[stage],
      end: moment(scheduling[stage]).add(totalTime, "minutes"),
      url: interviewerLink,
      description: `${iStage}: ${candidateName} for ${roleMap[candidateRole].name}`,
      // organizer: interviewers[stage].email,
      // organizer: interviewers[stage].email,
      senderName: "Intertru",
      stage,
    };

    const participantEmailPayload = {
      id,
      to: participants.map((participant) => ({
        name: `${participant.firstName} ${participant.lastName}`,
        email: participant.email,
      })),
      subject: `${stages[stage].label} Interview with ${candidateName}`,
      text: emailParticipantText.body,
      start: scheduling[stage],
      end: moment(scheduling[stage]).add(totalTime, "minutes"),
      url: participantLink,
      description: `${iStage}: ${candidateName} for ${iRole}`,
      // organizer: interviewers[stage].email,
      senderName: "Intertru",
      stage,
    };

    // send the emails
    sendInterviewEmail(candidateEmailPayload);
    sendInterviewEmail(interviewerEmailPayload);
    if (iStage === "Value Alignment Interview") {
      sendEmail(secondaryCandidateEmailValue1Payload);
    }
    else if (iStage === "Final Interview Interview") {
      sendEmail(secondaryCandidateEmailValue2Payload);
    }
    if (participants?.length > 0) 
      sendInterviewEmail(participantEmailPayload);
    toast.success("Emails sent.");
  }

  async function saveWorkingSession() {
    // SAVE THIS WORKING SESSION TO THE ROLE OBJECT FOR THE SELECTED ROLE
    const query = isRoleManagementEnabled ? mUpdateJobRole : updateRole;
    const variables = {
      input: {
        id: candidateRole,
        workingSession: questions.working,
      },
    };
    const res = await GraphQL({ query, variables, authMode: "" });
    console.log(res);
  }

  async function saveValues() {
    if (cid) setCompanyId(cid);
    const query = updateCompany;
    const variables = {
      input: {
        id: cid ? cid : companyID,
        values1: questions.value1.map((q) => q),
        values2: questions.value2.map((q) => q),
        interviewInfo: JSON.stringify({ defaultInterviewers: interviewers }),
      },
    };
    const res = await GraphQL({ query, variables });
    console.log(res);
  }
}

// export function formatQuestionResponses(questionResponses) {
//   // Initialize an empty object to hold the reshaped questions
//   const reshapedQuestions = {
//     discovery: [],
//     value1: [],
//     working: [],
//     value2: [],
//   };

//   // Iterate over the question responses
//   for (let response of questionResponses) {
//     // Convert the stage using the reverseStageMapping for backwards compatibility
//     let stage = reverseStageMapping[response.stage];

//     // if stage does not exist, add it to the object
//     if (!reshapedQuestions[stage]) {
//       reshapedQuestions[stage] = [];
//     }

//     // Find the correct position to insert the question to keep the array sorted
//     const index = reshapedQuestions[stage].findIndex(
//       (q) => q.order > response.order
//     );
//     if (index === -1) {
//       // If no such position exists, push to the end
//       reshapedQuestions[stage].push({
//         id: response.id,
//         question: response.question,
//         order: response.order,
//         subquestionResponses: response.subquestionResponses,
//       });
//     } else {
//       // Otherwise, splice the question into the correct position
//       reshapedQuestions[stage].splice(index, 0, {
//         id: response.id,
//         question: response.question,
//         order: response.order,
//         subquestionResponses: response.subquestionResponses,
//       });
//     }
//   }
//   //TODO: sort every stage's questions

//   return reshapedQuestions;
// }
