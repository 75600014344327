import { withValue } from "../general/withValue";
import { newGuid } from "../general/newGuid";

export const newQuestion = (text = '') => withValue(newGuid())(
    id => ({
        id,
        title: '',
        text,
    })
);
