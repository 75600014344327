import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Progress } from "@chakra-ui/react";

export default function SoundBar(props) {
  const { initTime, closeInterview } = props;
  const [diff, setDiff] = useState("0000");

  useEffect(() => {
    if (!initTime || closeInterview) return;
    const job = setInterval(() => {
      const start = moment(initTime);
      const end = moment(new Date());
      setDiff(Number(end.diff(start)));
    }, 1000);
    return () => {
      clearInterval(job);
    };
  }, [initTime, closeInterview]);

  const minutesWidth = 65;

  return (
    <div className="hor-center" style={styles.soundBar}>
      <div style={{ width: minutesWidth }}>
        {moment.utc(diff).format("m:ss")}
      </div>
      <div style={{ width: `calc(100% - ${minutesWidth * 2}px)` }}>
        <div style={{ borderRadius: 4, overflow: "hidden" }}>
          <Progress value={diff / 27000} />
        </div>
      </div>
      <div style={{ width: minutesWidth }}>45:00</div>
    </div>
  );
}

const styles = {
  soundBar: {
    zIndex: 3,
    background: "#000000aa",
    borderRadius: 10,
    height: "65%",
    width: "40%",

    textAlign: "center",
    color: "white",
    fontWeight: "bold",
  },
};
