import { uniqBy } from "lodash";
import { isNotUndefined } from "./isNotUndefined";
import { Questions } from "./state/questions/Questions";
import { WeightedValues } from "./state/values/WeightedValues";

export function questionsReferredToFromWeightedValues(wvs: WeightedValues, questionsWhoseIdsMayAppearWithinWeightedValues: Questions) {
  return uniqBy(wvs
    .flatMap(wv => wv.weightedAttributes)
    .flatMap(wa => wa.questionIds)
    .map(qId => questionsWhoseIdsMayAppearWithinWeightedValues.find(q => q.id === qId))
    .filter(isNotUndefined), q => q.id);
}
