import { Stage } from "../../../stages/Stage";
import { tommy } from "../people/tommy";

//TODO: Maybe schedule the meeting but dont have any data yet for it as a test / show the date
export const workingSession: Stage = {
    name: 'working',
    intendedQuestionIds: [],
    meetings: [],
    stageLevelAssessments: [
        {
            notes: "Overall a good candidate",
            personId: tommy.id,
            reason: "He was good enough to keep",
            stageLevelRating: "ThumbsUp"
        }
    ],
    mlScores: []
};
