import { mean } from "lodash";
import { isNotUndefined } from "../isNotUndefined";
import { Attribute } from "../state/attributes/Attribute";
import { ScoredAttribute } from "../state/scores/ScoredAttribute";
import { UnscoredAttribute } from "../state/scores/UnscoredAttribute";

export const scoredAttribute =
  (attributeDef: Attribute) =>
  (attributeUnscored: UnscoredAttribute | undefined): ScoredAttribute => {
    const result =
      attributeUnscored === undefined
        ? {
            attributeId: attributeDef.id,
            questionScores: [],
            score: undefined,
          }
        : {
            ...attributeUnscored,
            score:
              attributeUnscored.questionScores
                .map((qs) => qs.score)
                .filter(isNotUndefined).length > 0
                ? mean(
                    attributeUnscored.questionScores
                      .map((qs) => qs.score)
                      .filter(isNotUndefined),
                  )
                : undefined,
          };
    return result;
  };
