import { cloneDeep } from "lodash";

import { QuestionsTab } from "./index";
import { TextArea, Button } from "../../Components";
import { themeOrange } from "../../theme";
import { styles } from "./styles";

export default function Questions(props) {
  const { currentTab, questions, setQuestions, setCurrentTab } = props;

  const tabs = [
    { label: "Discovery", value: "discovery" },
    { label: "Value Alignment", value: "value1" },
    { label: "Working Session", value: "working" },
    { label: "Final Interview", value: "value2" },
  ];

  function addQuestion() {
    if (currentTab === "discovery") return;
    const updatedQuestions = cloneDeep(questions);
    updatedQuestions[currentTab] = [...questions[currentTab], ""];
    setQuestions(updatedQuestions);
  }

  function deleteQuestion(index) {
    if (currentTab === "discovery") return;
    const updatedQuestions = cloneDeep(questions);
    updatedQuestions[currentTab].splice(index, 1);
    setQuestions(updatedQuestions);
  }

  function changeValue(index, value) {
    if (currentTab === "discovery") return;
    const updatedQuestions = cloneDeep(questions);
    updatedQuestions[currentTab][index] = value;
    setQuestions(updatedQuestions);
  }

  return (
    <div>
      <div style={{ ...styles.subheader, ...styles.daj }}>
        <div>Interview Questions</div>
        {currentTab !== "discovery" && (
          <div>
            <Button onClick={() => addQuestion(currentTab)}>
              Add Question
            </Button>
          </div>
        )}
      </div>
      <div style={styles.tabRow}>
        {tabs.map((tab) => {
          const { label, value } = tab;
          return (
            <QuestionsTab
              key={value}
              selected={currentTab === value}
              onClick={() => setCurrentTab(value)}
              label={label}
            />
          );
        })}
      </div>
      {questions[currentTab].map((question, index) => {
        let allQuestions;
        let mainQuestion;
        let subQuestions;
        if (currentTab !== "working") {
          allQuestions = !!question ? question.split("\n") : [];
          mainQuestion = allQuestions[0];
          subQuestions = allQuestions.slice(1);
        }
        if (currentTab === "discovery")
          return (
            <div key={index} style={{ marginBottom: 16 }}>
              <div style={styles.questionNumber}>Question {index + 1}</div>
              <div style={{ whiteSpace: "pre-line" }}>
                <div style={{ fontWeight: "bold" }}>{mainQuestion}</div>
                {subQuestions.length > 0 &&
                  subQuestions.map((subQuestion, i) => {
                    return <div key={i}>{subQuestion}</div>;
                  })}
              </div>
            </div>
          );
        return (
          <div key={index} style={{ marginBottom: 16 }}>
            <div>
              <div style={styles.daj}>
                {currentTab !== "working" ? (
                  <div style={styles.questionNumber}>Question {index + 1}</div>
                ) : (
                  <div />
                )}
                <div
                  style={{ color: themeOrange, cursor: "pointer" }}
                  onClick={() => deleteQuestion(index)}
                >
                  Remove
                </div>
              </div>
              <TextArea
                inputStyle={styles.areaInputStyle}
                value={question}
                onChange={(value) => changeValue(index, value)}
                rows={currentTab === "working" ? 18 : 8}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
