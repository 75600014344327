import { Box, Text, Tooltip } from "@chakra-ui/react";
import * as React from "react";
import { AiOutlineWarning } from "react-icons/ai";

export const MoreQuestionsIconWithTooltip = 
    () => 
    <Tooltip 
        placement="top" 
        label={
            <Text>
                To score an <i>Attribute</i>, we use the candidate's responses to <i>Related Questions</i>. Therefore, <b>an Attribute must relate to <i>at least</i> 1 Question</b>.
            </Text>
        }>
        <Box><AiOutlineWarning /></Box>
    </Tooltip>;
