import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { iconForRating } from "../iconForRating";
import { ratingForScore } from "../state/scores/ratingForScore";
import _ from "lodash";

export const renderTooltipV2: any = ({ payload, label }: any) => {
  return (
    <div className="tooltip">
      {label}
      {payload.map((assesser: any, i: number, index: number) => {
        const { name, fill, value, payload, dataKey } = assesser;
        return (
          <HStack color={fill} key={`tooltip-${index}`}>
            <Text>{name}</Text>
            <Box>{iconForRating()(ratingForScore(value))}</Box>
            <Box>
              {payload.attributes.map((attribute, index): any => {
                return (
                  <Text key={`attribute${index}`}>
                    {attribute}: {payload.scores[dataKey][index]}
                  </Text>
                );
              })}
            </Box>
          </HStack>
        );
      })}
    </div>
  );
};
