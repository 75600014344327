import React, { CSSProperties } from "react";
import shovel from "../../../images/shovel_orange.png";
import { Subquestion } from "../../../types/Subquestion";
import { themeOrange } from "../../../theme";

type SubquestionText = {
  text: string;
};

type SubquestionModalProps = {
  subQuestions: SubquestionText[];
  subQRs: Subquestion[];
  currentDig: number[];
  currentQIndex: number;
};

export default function SubquestionModal(props: SubquestionModalProps) {
  const {
    subQuestions,
    // subQRs,
    // currentDig,
    // currentQIndex,
  } = props;
  return (
    <div className="fade-in">
      {subQuestions.map((subquestion, i) => {
        const { text } = subquestion;
        return (
          <div key={i} style={styles.digDeeperWrapper}>
            <div style={styles.digDeeperOrangeBlock}>
              <div className="hor-center">
                <div className="hor-center" style={styles.digShovelWrapper}>
                  <img
                    src={shovel}
                    style={{ height: "130%", width: "60%" }}
                    alt="shovel"
                  />
                </div>
                <div
                  style={{ color: "white", fontSize: 14, fontWeight: "bold" }}
                >
                  Dig Deeper:
                </div>
              </div>
            </div>
            <div style={styles.digDeeperQuestion}>
              {text.replace(/^-+\s*/, "")}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const styles: Record<string, CSSProperties> = {
  digDeeperOrangeBlock: {
    borderRadius: "20px 20px 0px 20px",
    height: 28,
    background: themeOrange,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 4,
    paddingRight: 4,
  },
  digShovelWrapper: {
    borderRadius: 50,
    height: 22,
    width: 22,
    minHeight: 22,
    minWidth: 22,
    background: "white",
    marginRight: 8,
    padding: 4,
  },
  digDeeperWrapper: {
    background: "white",
    borderRadius: "20px 20px 0px 20px",
    padding: 8,
    marginBottom: 12,
    cursor: "pointer",
  },
};
