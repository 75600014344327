import { Publisher, StreamManager, Subscriber } from "openvidu-browser";
import React, { CSSProperties, useEffect, useRef } from "react";
import UserVideo from "../../Components/UserVideo";

type ViewersProps = {
  subscribers: Subscriber[];
  publisher: Publisher | null;
  screenShareStream: StreamManager | null;
};

export default function Viewers(props: ViewersProps) {
  const { subscribers, publisher, screenShareStream } = props;

  return (
    <div style={{ ...styles.main, width: screenShareStream ? "15%" : "15%" }}>
      <div style={styles.videos}>
        {screenShareStream && (
          <div
            style={{
              width:
                subscribers.length > 1
                  ? !screenShareStream
                    ? "50%"
                    : "100%"
                  : "100%",
            }}
          >
            {/* {publisher && <UserVideo streamManager={screenShareStream} />} */}
            {publisher && <UserVideo streamManager={publisher} />}
          </div>
        )}
        {subscribers[0] &&
          subscribers.map((subscriber, i) => {
            if (
              subscriber.stream.connection.data.split("|")[0] === "participant"
            )
              return (
                <UserVideo
                  streamManager={subscriber}
                  isNewStyle
                  isIntervieweeView
                  name={subscriber.stream.connection.data.split("|")[1]}
                  // style={{
                  //   width:
                  //     subscribers.length > 1
                  //       ? !screenShareStream
                  //         ? "50%"
                  //         : "100%"
                  //       : "100%",
                  // }}
                />
              );
            return null;
          })}
      </div>
    </div>
  );
}

const sideMargin = 6;

const styles: Record<string, CSSProperties> = {
  main: {
    height: `calc(100% - ${sideMargin * 2}px)`,
    zIndex: 2,
    margin: `${sideMargin}px`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  mainWindow: {
    // background: "#000000b5",
    // borderRadius: 14,
    // padding: 20,
  },
  videos: {
    color: "white",
    width: "100%",
    height: "calc(100% - 64px)",
    position: "relative",
    display: "flex",
    flexWrap: "wrap-reverse",
    flexDirection: "column-reverse",
  },

  outerVideoWrap: {
    height: "95%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  videoWrapper: {
    width: "100%",
    height: "100%",
    borderRadius: 4,
    overflow: "hidden",
  },
  nameLabel: {
    padding: "4px 0px",
    height: "10%",
    color: "white",
    textAlign: "center",
    zIndex: 1,
    fontSize: 14,
  },
};
