import { HStack, Text } from "@chakra-ui/react";
import * as React from "react";
import { AiOutlineArrowUp } from "react-icons/ai";
import { A } from "../../general/A";
import { valueHref } from "../../Values/Detail/valueHref";


export const ParentValueLink = ({ customerId, title, id }) => <A to={valueHref(customerId)(id)}>
    <HStack>
        <AiOutlineArrowUp />
        <Text>Value: {title || 'Untitled'}</Text>
    </HStack>
</A>;
