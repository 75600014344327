import { orderBy } from "lodash";
import { Meetings } from "../state/meetings/Meetings";
import { Rating } from "../state/questions/Rating";
import { isQuestionRatedEvent } from "./isQuestionRatedEvent";

export const personsMostRecentQuestionRating = 
  (qId:String) =>
  (personId:string) =>
  (meetings:Meetings):Rating | undefined =>
  orderBy(
    meetings.flatMap(m => m.meetingEvents).filter(isQuestionRatedEvent).filter(e => e.personId == personId && e.questionId == qId),
    e => e.timestamp,
    "desc",
  )[0]?.rating