import {
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import * as O from "optics-ts/standalone";
import * as React from "react";
import { useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { themeColor } from "../../../../../theme";
import { isEmpty } from "../../general/isEmpty";
import { isEmptyStringOrUndefined } from "../../general/isEmptyStringOrUndefined";
import { jsonContainsText } from "../../general/jsonContainsText";
import { none } from "../../general/none";
import { interviewBuilderInitialState } from "../../interviewBuilderInitialState";
import { newWeightedValue } from "../newWeightedValue";
import { weightedValuesO } from "../weightedValuesO";
import { description } from "./description";
import { EmptyValues } from "./EmptyValues";
import { title } from "./title";
import { titleEquals } from "./titleEquals";
import { ValueCard } from "./ValueCard";
import { ValueDescriptionInput } from "./ValueDescriptionInput";
import { ValueTitleInput } from "./ValueTitleInput";

export const ValuesList = ({ customerId, weightedValues, setState }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState();
  const [isModalVisiable, setIsModalVisible] = useState(false);
  const filteredValues = searchText
    ? weightedValues.filter(jsonContainsText(searchText))
    : weightedValues;
  const [newItem, setNewItem] = useState(newWeightedValue());

  const onNewItemConfirmed = (newItem) => {
    setIsModalVisible(false);
    setNewItem(newWeightedValue());
    setState(O.set(O.compose(weightedValuesO, O.appendTo), newItem));
  };

  return (
    <div style={{ overflowY: "auto", height: "80vh"}}>
      <NewItemModal
        {...{
          isModalVisiable,
          setIsModalVisible,
          newItem,
          setNewItem,
          onNewItemConfirmed,
          weightedValues,
        }}
      />
      <Flex width="100%">
        <Center gap={2}>
          <Heading as="h1" size="lg">
            Your Company Values
          </Heading>
        </Center>
        <Spacer />
        <Center gap={2}>
          <Input
            width={200}
            value={searchText}
            type="text"
            placeholder="Search Values"
            onChange={({ target: { value } }) => setSearchText(value)}
          />
          <Button onClick={() => navigate("./json")}>JSON</Button>
          <Tooltip
            placement="top"
            label={
              <Text>
                Add a <i>Company Value</i>. Candidates will be scored on the
                values you define here.
              </Text>
            }
          >
            <Button
              color="white"
              backgroundColor="rgb(238, 117, 49)"
              onClick={() => setIsModalVisible(true)}
            >
              <HStack gap={1}>
                <AiFillPlusCircle />
                <Text>Value</Text>
              </HStack>
            </Button>
          </Tooltip>
        </Center>
      </Flex>
      {isEmpty(weightedValues) ? (
        <EmptyValues
          title="No Values"
          text="No values yet, please click the add value or load examples button."
        />
      ) : isEmpty(filteredValues) ? (
        <EmptyValues
          title="No Matches Found"
          text="Your search filter yielded no results"
        />
      ) : (
        <VStack maxWidth="100%" gap={4} >
          {filteredValues.map((weightedValue, key) => (
            <ValueCard {...{ customerId, weightedValue, setState, key }} />
          ))}
        </VStack>
      )}
    </div>
  );
};

function NewItemModal({
  isModalVisiable,
  setIsModalVisible,
  newItem,
  setNewItem,
  onNewItemConfirmed,
  weightedValues,
}: any) {
  const t = O.get(title)(newItem);
  const d = O.get(description)(newItem);
  const isValid =
    !isEmptyStringOrUndefined(t) && none(titleEquals(t))(weightedValues);
  return (
    <Modal isOpen={isModalVisiable} onClose={() => setIsModalVisible(false)}>
      <ModalOverlay />
      <ModalContent minWidth="fit-content" height="fit-content" maxW={"80%"}>
        <ModalHeader>New Company Value</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap="1em" width="100%">
            <ValueTitleInput
              title={t}
              onChange={(value) => setNewItem(O.set(title)(value))}
            />
            <ValueDescriptionInput
              description={d}
              onChange={(value) => setNewItem(O.set(description)(value))}
            />
          </VStack>
        </ModalBody>
        <ModalFooter gap={1}>
          <Button onClick={() => setIsModalVisible(false)}>Cancel</Button>
          <Tooltip
            isDisabled={isValid}
            label="Title must be at least 1 character in length, and must be unique"
          >
            <Button
              backgroundColor={themeColor}
              color="white"
              isDisabled={!isValid}
              onClick={() => onNewItemConfirmed(newItem)}
            >
              Create
            </Button>
          </Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
