import { useEffect, useState } from "react";
import { updateInterview } from "../../../graphql/mutations";
import { GraphQL} from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CardOptions(props) {
  const {
    moreModalVisible,
    setMoreModalVisible,
    scroll,
    id,
    setSelectedInterviewId,
    setSelectedInterviewForSchedule,
    setReleased,
    state,
    candidateEmail,
    companyID,
    setWaiting,
    setSelectedInterviewForPark,
  } = props;

  const navigate = useNavigate();

  const inProgressOptions = [
    {
      label: state === "is_scheduled" ? "Reschedule" : "Schedule",
      onClick: (e) => {
        e.stopPropagation();
        setMoreModalVisible(false);
        setSelectedInterviewForSchedule(id);
      },
    },
    {
      label: "View Notes",
      onClick: (e) => {
        e.stopPropagation();
        setMoreModalVisible(false);
        setSelectedInterviewId(id);
      },
    },
    {
      label: "Candidate Analytics",
      onClick: (e) => {
        e.stopPropagation();
        setMoreModalVisible(false);
        const { origin } = window.location;
        window.open(`${origin}/admin/summary/${id}`);
      },
    },
    {
      label: "Edit",
      onClick: (e) => {
        e.stopPropagation();
        setMoreModalVisible(false);
        navigate(`/admin/interview?mode=edit&id=${id}&cid=${companyID}`);
      },
    },
    {
      label: "Email",
      onClick: (e) => {
        e.stopPropagation();
        setMoreModalVisible(false);
        const mailtoLink = `mailto:${candidateEmail}`;
        window.open(mailtoLink);
      },
    },
    {
      label: "Release",
      onClick: async (e) => {
        e.stopPropagation();
        setMoreModalVisible(false);
        setWaiting(true);
        toast.loading("Moving Interview to Released.");
        const query = updateInterview;
        try {
          await GraphQL({
            query,
            variables: { input: { id, stage: "rejected" } },
            authMode: "",
          }).then(() => {
            toast.dismiss();
            toast.success("Interview Moved to Released.");
            setReleased(true);
           // window.location.reload();
          });
        }catch (error) {
          console.error("Error releasing interview", error);
          toast.error("Error releasing interview");
          setWaiting(false);
          return;
        }
      },
    },
    {
      label: "Park For Later",
      onClick: (e) => {
        e.stopPropagation();
        setMoreModalVisible(false);
        setSelectedInterviewForPark(id);
      },
    },
  ];

  const releaseOptions = [
    {
      label: "View Notes",
      onClick: (e) => {
        e.stopPropagation();
        setMoreModalVisible(false);
        setSelectedInterviewId(id);
      },
    },
    {
      label: "Candidate Report",
      onClick: (e) => {
        e.stopPropagation();
        setMoreModalVisible(false);
        const { origin } = window.location;
        window.open(`${origin}/admin/summary/${id}`);
      },
    },
    {
      label: "Edit",
      onClick: (e) => {
        e.stopPropagation();
        setMoreModalVisible(false);
        navigate(`/admin/interview?mode=edit&id=${id}&cid=${companyID}`);
      },
    },
    {
      label: "Email",
      onClick: (e) => {
        e.stopPropagation();
        setMoreModalVisible(false);
        const mailtoLink = `mailto:${candidateEmail}`;
        window.open(mailtoLink);
      },
    },
  ];

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") setMoreModalVisible(false);
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
    
    
  }, [setMoreModalVisible]);
  

  if (moreModalVisible)
    return (
      <>
        <div className="card-options" style={{ marginTop: scroll * -1 + 35 }}>
          { 
           state === "rejected" || state === "parked" ? releaseOptions.map((option, i) => {
            const { label, onClick } = option;
            let optionClass = "card-option";
            if (i !== releaseOptions.length - 1) optionClass += " border-bottom";
            return (
              <p className={optionClass} key={label} onClick={onClick}>
                {label}
              </p>
            );
           }) : inProgressOptions.map((option, i) => {
            const { label, onClick } = option;
            let optionClass = "card-option";
            if (i !== inProgressOptions.length - 1) optionClass += " border-bottom";
            return (
              <p className={optionClass} key={label} onClick={onClick}>
                {label}
              </p>
            );
           })
          }
        </div>
      </>
    );
  }
