import { Box, Heading, Text } from "@chakra-ui/react";
import * as React from "react";

export const EmptyValues = ({title, text}) => 
    <Box marginY="10px">
        <Heading size="lg" color="gray" mb={3}>
            {title}
        </Heading>
        <Text>
            {text}
        </Text>
    </Box>
;
