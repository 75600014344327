const stageMapping = {
  discovery: "discovery",
  value1: "values",
  value2: "final",
  working: "working",
};

const reverseStageMapping = {
  discovery: "discovery",
  values: "value1",
  final: "value2",
  working: "working",
};

export { stageMapping, reverseStageMapping };
