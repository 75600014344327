import { newGuid } from "../../general/newGuid";
import { withValue } from "../../general/withValue";


export const newExample = () => withValue(newGuid())(
    id => ({
        id,
        text: '',
        exampleScore: undefined,
    })
);
