export const stages = {
  discovery: { label: "Discovery Call", order: 0 },
  value1: { label: "Value Alignment", order: 1 },
  working: { label: "Working Session", order: 2 },
  value2: { label: "Final Interview", order: 3 },
  parked : { label: "Parked", order: 4 },
};

export const stagesV2 = {
  discovery: { label: "Discovery", order: 0 },
  value1: { label: "Value Alignment", order: 1 },
  working: { label: "Working Session", order: 2 },
  value2: { label: "Final Interview", order: 3 },
  parked : { label: "Parked", order: 4 },
};
