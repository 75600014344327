import Interviewer from "./Interviewer";

import { AcceptFormModal, DeviceModal } from "../../Components/Modals";
import SoundBar from "./SoundBar";

export default Interviewer;
export { AcceptFormModal, DeviceModal, SoundBar };

// const discoveryNotes =
//   "PAIN:\n\nDESIRE:\nCulture:\nSize:\nRole:\nDomain:\n\nIMPACT:\n\nSKILLS:\n\nCOMMUTE:\n\nINTERVIEWING:\n\nCITIZENSHIP:\n\nSALARY:\n\nAVAILABILITY\n\nMAIN CRITERIA\n1:\n2:\n3:\n4:\n";
