import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import moment from "moment-timezone";

import { CardText, CardOptions, OptionOverlay } from "./index";

export default function Card(props) {
  const {
    interview,
    draggable,
    id,
    index,
    isAdmin = false,
    scroll,
    state,
    setSelectedInterviewId,
    setSelectedInterviewForSchedule,
    setReleased,
    setWaiting,
    stage,
    setSelectedInterviewForPark,
  } = props;
  const navigate = useNavigate();

  const { scheduling } = interview;
  const interviewInfo = JSON.parse(interview.interviewInfo);
  const { candidateRole, candidateName, candidateEmail } = interviewInfo;

  const [moreModalVisible, setMoreModalVisible] = useState(false);

  const interviewLink = `/admin/interview?mode=edit&id=${interview.id}&cid=${interview.companyID}`;

  let time;
  let shortTime;
  const scheduledTime = JSON.parse(scheduling)?.[stage];
  if (scheduling) {
    time = moment(scheduledTime).format("MMM DD, h:mma");
    shortTime = moment(scheduledTime).format("MMM DD");
  } else {
    const dateTime = moment(interviewInfo.dateTime).format("MMM DD, h:mma");
    if (dateTime) {
      time = dateTime;
      shortTime = moment(interviewInfo.dateTime).format("MMM DD");
    }
  }

  const states = {
    is_scheduled: { label: `Sched. ${time}`, color: "green" },
    not_scheduled: { label: "Not Scheduled", color: "yellow" },
    passed: { label: `No Show (${shortTime})`, color: "red" },
    offer_accepted: { label: `Offer Accepted`, color: "green" },
    rejected: { label: "Rejected", color: "red" },
    legacy: { label: "Scheduled", color: "theme-color" },
    parked: { label: "Parked", color: "yellow" },
  };

  let displayTitle = candidateRole?.name;
  if (isAdmin) displayTitle += ` - ${interview.companyName}`;

  let innerContent = (
    <>
      <CardOptions
        {...{
          moreModalVisible,
          setMoreModalVisible,
          navigate,
          interviewLink,
          scroll,
          id,
          setSelectedInterviewId,
          setSelectedInterviewForSchedule,
          setReleased,
          candidateEmail,
          companyID: interview.companyID,
          setWaiting,
          setSelectedInterviewForPark,
          state
        }}
      />
      <CardText
        state={state}
        states={states}
        setMoreModalVisible={setMoreModalVisible}
        id={id}
        displayTitle={displayTitle}
        candidateName={candidateName}
        navigate={navigate}
        interviewLink={interviewLink}
      />
      <OptionOverlay {...{ moreModalVisible, setMoreModalVisible }} />
    </>
  );

  return draggable ? (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          onClick={() => navigate(interviewLink)}
          className="card-wrapper"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {innerContent}
        </div>
      )}
    </Draggable>
  ) : (
    <div className="card-wrapper" onClick={() => navigate(interviewLink)}>
         {innerContent}
    </div>
  );

}


