import { stageLevelRatingFromReviewPassValue } from "./stageLevelRatingFromReviewPassValue";
import { Review, VantasoftStageName } from "./vantasoftState";

export const stageLevelAssessment = (personId: string) => (review: Review) => (stageName: VantasoftStageName) => {
    return ({
    personId,
    notes: (review && review[stageName]?.notes) || "",
    reason: (review && review[stageName]?.reason) || "",
    stageLevelRating: stageLevelRatingFromReviewPassValue(review && review[stageName]?.pass)
});
}