import { ValuesDefintition } from "../values/ValuesDefintition";

//can ask a question and get feedback in one or more meetings, but can only rate the question
//thumbs up or down once.

export const discoveryValuesDef: ValuesDefintition = {
   "questions": [
      {
         "id": "2043",
         "text": "Desire: If you could create your next opportunity from scratch, what would that look like for you? [1]\n(Elaboration) - Explain that to me...\nWhat would the company look like?\nWhat work would you be doing?\nWhat makes this important to you?",
         "title": "Desire"
      },
      {
         "id": "51266",
         "text": "Impact: Tell me about the most significant impact you had in your current role. What have you done that has saved time, generated revenue, or gained recognition from leadership? [3]\n(Elaboration) - Walk me through that...\nBreak down the exact steps you took to solve the problem\nWhat issues came up?\nWhat were the root of the issues?\nHow were you able to deliver?\nWhy was this important to you?\n(Too much we) - I'm hearing you say 'we' a lot. Rather than tell me what your team did, please focus precisely on the work you did.",
         "title": "Impact"
      },
      {
         "id": "21795",
         "text": "Pain: What is happening with your current role that makes you open to hearing about something potentially stronger? [1]\nHow is it affecting you personally?\nWhat else?",
         "title": "Pain"
      },
      {
         "id": "97889",
         "text": "Culture: What type of environment/culture do you do best in? [1]\nWhat is it that makes you thrive in that type of environment?",
         "title": "Culture"
      },
      {
         "id": "7135",
         "text": "Size: What size company do you do best in?\nHow small is too small for you?",
         "title": "Size"
      },
      {
         "id": "11079",
         "text": "Role: What type of role would you be looking to play?\n",
         "title": "Role"
      },
      {
         "id": "23549",
         "text": "Domain: What industries or specializations are you interested in?\nWhy do these areas interest you?",
         "title": "Domain"
      },
      {
         "id": "47116",
         "title": "Strengths",
         "text": "Skills: What are your core Strengths? [1]"
      },
      {
         "id": "21553",
         "title": "Skills",
         "text": "Skills: What are your top skills? [1]"
      },
      {
         "id": "34649",
         "title": "Interviewing",
         "text": "Interviewing: To properly pace out the process, where are you in the interview process?"
      },
      {
         "id": "90337",
         "title": "Salary",
         "text": "Salary: Expectations $ What are your minimum salary requirements?\n"
      },
      {
         "id": "59659",
         "text": "Authorization: What is your current work authorization status?",
         "title": "Authorization",
         "libraryId": "73499"
      },
      {
         "id": "53739",
         "title": "Offer Acceptance Criteria",
         "text": "Offer Acceptance Criteria: What are 3 or 4 “Main Criteria” that you would need to see in a company for you to accept an offer with them?\n"
      },
      {
         "id": "3811",
         "title": "Availability",
         "text": "Availability: What is your availability to interview this week? (dates/times)"
      },
      {
         "id": "85026",
         "title": "Commute",
         "text": "Commute: Where are you willing to commute daily? (understand limits)\n"
      },
      {
         "id": "56603",
         "title": "Wrap Up",
         "text": "Wrap Up: What questions do you have for me? [2]\n"
      }
   ],
   "weightedValues": [
      {
        "description": "This contains the temporarily hard-coded attribute for the HireOS(tm) PAIN Question",
        "weight": "25",
        "weightedAttributes": [
          {
              "examples": [],
              "description": "The desire to transition from a dissatisfactory work or job situation to some new work position, likely at a different company.  The reason for the current situation being undesirable or dissatisfactory must be clear, credible, and not just for compensation reasons.  Good reasons for experiencing dissatisfaction are boredom, lack of new challenges, lack of learning or advancement opportunities, lack of good teamwork or camaraderie, or inability to change the direction of their career.  Self-awareness and forward-thinking should be at the core of experiencing pain with the current situation.",
              "weight": "100",
              "id": "35559",
              "questionIds": [
                "21795"
              ],
              "title": "Readiness for Change"
          }
        ],
        "id": "42755",
        "title": "Pain"
      },
      {
        "description": "This contains the temporarily hard-coded attribute for the HireOS(tm) DESIRE Question",
        "weight": "25",
        "weightedAttributes": [
          {
              "examples": [],
              "description": "Looking for new opportunities to move forward towards short-term or long-term professional goals a person has set for themselves.  The determination to make sacrifices and take risks to realize these goals may stem from a larger vision for what they wish to achieve such as changing an industry, making a social impact, leaving a lasting legacy, or changing career direction.",
              "weight": "100",
              "id": "18880",
              "questionIds": [
                "2043",
                "97889",
                "7135",
                "11079",
                "23549"
              ],
              "title": "Ambition"
          }
        ],
        "id": "10472",
        "title": "Desire"
      },
      {
         "description": "This contains the temporarily hard-coded attribute for the HireOS(tm) DESIRE Question",
         "weight": "25",
         "weightedAttributes": [
            {
               "examples": [],
               "description": "A positive impact on a company requires demonstrating an overall capability for high performance in a work setting.  This requires a blend of leadership skills, interpersonal abilities, and technical competencies.  A high performer may exhibit visionary thinking, problem-solving skills, resilience, adaptability, strong communication, initiative, and a results-oriented focus.",
               "weight": "100",
               "id": "98903",
               "questionIds": [
                  "51266"
               ],
               "title": "High performer"
            }
         ],
         "id": "29550",
         "title": "Impact"
      },
      {
         "description": "This contains the temporarily hard-coded attribute for the HireOS(tm) DESIRE Question",
         "weight": "25",
         "weightedAttributes": [
            {
               "examples": [],
               "description": "Builders are most excited in job roles where they can start something from scratch.  They are here to change the world by creating new things.  Proactive and driven to fix problems whenever they see them, builders are best hired by start-ups.  Builders are often the first employees in a company, on a team, or performing a function.",
               "weight": "10",
               "id": "44183",
               "questionIds": [
                  "2043"
               ],
               "title": "Builder"
            },
            {
               "examples": [],
               "description": "Improvers thrive in stable, growing companies where resources like money and staff time allow them to make incremental investments into existing products, systems, services, frameworks, or business relationships to create incremental business value for the company and professional satisfaction for themselves.  Improvers work on what is already there and producing revenue and usually can measure the results of their efforts in tangible ways.",
               "weight": "0",
               "id": "30384",
               "questionIds": [
                  "2043"
               ],
               "title": "Improver"
            },
            {
               "examples": [],
               "description": "Maintainers are employees who are mostly here for the paycheck.  They want to be left to do their job how they see fit.  They do not strive to improve things or build anything new.  Maintainers are useful in executing systems and protocols established by others, and so they contribute the most at larger companies",
               "weight": "0",
               "id": "79613",
               "questionIds": [
                  "2043"
               ],
               "title": "Maintainer"
            },
            {
               "examples": [],
               "description": "Alignment with the right skills",
               "weight": "10",
               "id": "96809",
               "questionIds": [
                  "47116",
                  "21553"
               ],
               "title": "Skills Match",
               "attributeLibraryId": "63947"
            },
            {
               "examples": [],
               "description": "We are able to hire the candidate in our timeframe",
               "weight": "10",
               "id": "35181",
               "questionIds": [
                  "34649",
                  "3811"
               ],
               "title": "Appropriate to Interview",
               "attributeLibraryId": "94949"
            },
            {
               "examples": [],
               "description": "We can pay them what they feel they deserve",
               "weight": "10",
               "id": "24061",
               "questionIds": [
                  "90337"
               ],
               "title": "Salary Match",
               "attributeLibraryId": "3899"
            },
            {
               "examples": [
                  {
                     "exampleScore": 8,
                     "text": "example text"
                  },
                  {
                     "exampleScore": 3,
                     "text": "example text 2"
                  }
               ],
               "attributeLibraryId": "71300",
               "description": "The candidate has work authorization",
               "weight": "10",
               "id": "19446",
               "questionIds": [
                  "59659"
               ],
               "title": "Work Authorization Match"
            },
            {
               "examples": [],
               "description": "Offer Acceptance Criteria are achievable in this role",
               "weight": "10",
               "id": "10709",
               "questionIds": [
                  "53739"
               ],
               "title": "Hiring Criteria Match",
               "attributeLibraryId": "74963"
            },
            {
               "examples": [],
               "description": "Candidate is willing to work in required location",
               "weight": "10",
               "id": "99100",
               "questionIds": [
                  "85026"
               ],
               "title": "Work Location Match",
               "attributeLibraryId": "60795"
            },
            {
               "examples": [],
               "description": "Inquisitive about things that matter regarding the company and/or role",
               "weight": "10",
               "id": "11145",
               "questionIds": [
                  "56603"
               ],
               "title": "Asks Good Questions",
               "attributeLibraryId": "96557"
            }
         ],
         "id": "51080",
         "title": "Profile Match"
      },      
   ]
};
