import React from "react";
import { useEffect } from 'react'


export function ToolTipModal(props) {

    const {headerText, bodyText, styleTop, styleLeft, buttonRef, modalRef, showModalTip, modalType} = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (buttonRef.current && !buttonRef.current.contains(event.target) &&
          modalRef.current && !modalRef.current.contains(event.target)) {
            showModalTip(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const styles = {
        mainContainer : {
            borderRadius: 24,
            background: "var(--White-White, #FFF)",
            /* Shadow 2 */
            boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.40)",
            display: "flex",
            padding: "8px 8px 24px 8px",
            flexDirection: "column",
            alignItems: "center",
            gap: 16,
            position : "absolute",
            top: styleTop,
            left: styleLeft,
            width: 402
        },
        header : {
            display: "flex",
            width: 386,
            height : 40,
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: 200,
            background: "linear-gradient(90deg, #F60 0%, #F59E0B 100%)",
            padding : 8
        },
        headerText : {
            color: "var(--White-White, #FFF)",
    
            /* Body Medium/SemiBold */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 600,
            marginLeft: 8
        },
        tipModal: {
            position: "fixed",
            top: modalType === "companyValuesModalTip" ? styleTop - 6 : modalType === "industry" ? styleTop + 193 : styleTop + 277,
            left: modalType === "clientType" ? styleLeft + 107 : styleLeft + 105,
            zIndex: 999,
            width: 12,
            height: 7,
        },
    }

    function renderHeader() {
        return (
            <div style={styles.header}>
                <div style={{display : "flex"}}>
                    <div className="modal-info-icon-black"></div>
                    <div style={styles.headerText}>{headerText}</div>
                </div>
                <button className="modal-close-icon"
                    onClick={() => showModalTip(false)}
                />
            </div>
        )
    }

    function renderBody() {
        return (
            <div style={{paddingLeft: 16, paddingRight : 16}}>
                <div>{bodyText}</div>
            </div>
        )
    }

    return(
        <div style={styles.mainContainer} ref={modalRef}>
            {modalType === "companyValuesModalTip" && (
                    <div className="modal-tip-upward" style={styles.tipModal}></div>
            )}
            {modalType === "industry" && (
                <div className="modal-tip" style={styles.tipModal}></div>
            )}
            {modalType === "clientType" && (
                <div className="modal-tip" style={styles.tipModal}></div>
            )}
            {renderHeader()}
            {renderBody()}
        </div>
    )
}

