import { Center, Flex, Heading, Input, Spacer } from "@chakra-ui/react";
import * as React from "react";
import { ValueCard } from "../List/ValueCard";
import { Breadcrumbs } from "../../general/Breadcrumbs";
import { breadcrumbsForValue } from "./breadcrumbsForValue";
import { AllValuesLink } from "./AllValuesLink";

export const ValueDetailsPage = 
    ({ weightedValue, setState, customerId }) => 
    <div style={{height: "80vh", overflowY: "auto"}}>
        <Breadcrumbs {...{breadcrumbs: breadcrumbsForValue(customerId)(weightedValue) }} mb={5} />
        <Flex width="100%">
            <Center gap={2}>
                <Heading as='h1' size='lg'>Value Details: {weightedValue.title || 'Untitled'}</Heading>
            </Center>
            <Spacer />
            <Center gap={2}>
                <AllValuesLink {...{customerId}} />
            </Center>
        </Flex>
        <ValueCard showActionButtons={false} {...{customerId, weightedValue, setState}} maxHeight="80vh" overflowY="auto" />
    </div>
