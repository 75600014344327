import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn } from "../utils/auth";

import { LoginContainer, Button, TextInput } from "../Components";
import { Auth } from "aws-amplify";
import { usePageTitle } from "../navigation/usePageTitle";

import eliminatingText from "../images/eliminating_text.png";
import intertruLogo from "../images/intertru.png";
import AWS from 'aws-sdk';

export default function Login() {
  const navigate = useNavigate();
  usePageTitle("Login");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [waiting, setWaiting] = useState(false);

  return (
    <div style={{ height: "100%", margin: "-8px", minWidth: 1280, minHeight: 800, width: "100%", position: "absolute"}} className="background-login">
        <LoginContainer className="hor-center" style={{height: "100%"}}>
          <div className="box-shadow" style={styles.loginContainer}>
            <div className="hor-center" style={{ width: "100%" }}>
              <div style={styles.halfContainer}>
                <div className="hor-center">
                  <h1 className="hor-left header-signin" style={{ 
                    width: "400px", 
                    marginBottom: 40, }}>
                    Sign In
                  </h1>
                </div>
                {renderForm()}
              </div>
            </div>
            
          </div>
          <div className="background-login-planet" style={styles.planetContainer}>
           <div className="intertru-signin-logo">
            <img
              src={intertruLogo}
              style={{ height: "110%", width: "90%" }}
              />
           </div>
           <div className="eliminating-signin-text-image">
            <img
              src={eliminatingText}
              style={{ height: "90%", width: "75%" }}
              alt="eliminating text"
              />
           </div>
          </div> 
        </LoginContainer>
      </div>
  );

  function renderForm() {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="hor-center">
          <div style={{ width: "50%" }}>
            <div className="hor-center">
              <div>
                <div style={styles.label}>
                  <div style={styles.inputLabel}>Email</div>
                  <TextInput
                    inputStyle={styles.inputStyle}
                    value={email}
                    onChange={setEmail}
                  />
                </div>
                <div style={styles.label}>
                  <div style={styles.inputLabel}>Password</div>
                  <TextInput
                    type="password"
                    inputStyle={styles.inputStyle}
                    value={password}
                    onChange={setPassword}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hor-center" >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              filled
              type="submit"
              title="Sign In"
              onClick={onLogin}
              waiting={waiting}
              style={{ width: 400 }}
            />
          </div>
        </div>
        {/* <div className="hor-center" style={{ marginTop: 32 }}>
          <div style={{ display: "flex", flexDirection: "column", width: "400px" }}>
            Forgot Password?
          </div>
        </div> */}
      </form>
    );
  }

  async function onLogin() {
    try {
      setWaiting(true);
      await signIn(email, password);
      const authRes = await Auth.currentAuthenticatedUser();

      const groups =
        authRes.signInUserSession.accessToken.payload["cognito:groups"];

      const isAdmin = groups ? groups.includes("Admin") : false;
      if (isAdmin) return navigate("/admin/dashboard");
      navigate("/admin/dashboard");
    } catch (e) {
      setWaiting(false);
      console.log(e);
    }
  }
}

const styles = {
  infoCard: {
    width: 1000,
    marginTop: 100,
    marginBottom: 20,
    borderRadius: 6,
    height: 600,
    padding: 30,
    background: "white",
  },
  label: {
    marginBottom: 24,
    width: "400px",
  },
  inputLabel: {
    // marginRight: 16,
    fontWeight: "bold",
    width: 400,
    maxWidth: 400,
    textAlign: "left",
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontStyle: "normal", 
  },
  inputStyle: {
    height: 40,
    border: "1px solid var(--Stroke, #DFE4EA)",
    borderRadius: 4,
    width: 400,
    marginTop: 8,
    marginBottom: 20,
  },

  activeInputStyle: {
    border: "1px solid var(--Orange-Orange, #F27430)",
    background: "var(--White-White, #FFF)",
    height: 40,
    borderRadius: 4,
    width: 400,
    marginTop: 10,
    marginBottom: 20,
  },

  halfContainer: {
    width: '100%', // Adjust as needed
    padding: '10px',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 100,
    marginBottom: 20,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    height: 600,
    padding: 30,
    background: "white",
    maxWidth: "90%",
  },
  loginContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    height: 600,
    padding: 30,
    background: "white",
    maxWidth: "90%",
  },

  planetContainer: {
    justifyContent: 'space-between',
    width: '100%',
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    height: 600,
    padding: 30,
    maxWidth: "90%",
  },

  backgroundImageProp: {
    backgroundImage: `url(../src/images/signin_background.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
};
