import { breadcrumbsForValues } from "../breadcrumbsForValues";
import { valueHref } from "./valueHref";


export const breadcrumbsForValue = 
    (customerId) => 
    ({ title, id }) => 
    [
        ...breadcrumbsForValues(customerId),
        { label: `Value - ${title || 'Untitled'}`, href: valueHref(customerId)(id) }
    ];
