import { uniqBy } from "lodash";
import { Interviewer } from "../attendees/Interviewer";
import { Participant } from "../attendees/Participant";
import { People } from "../attendees/People";
import { discoveryValuesDef } from "../exampleData/discoveryValuesDef";
import { finalStage } from "../exampleData/scenario1/final/finalStage";
import { workingSession } from "../exampleData/scenario1/workingSession/workingSession";
import { ThumbsDown } from "../questions/ThumbsDown";
import { ThumbsSideways } from "../questions/ThumbsSideways";
import { ThumbsUp } from "../questions/ThumbsUp";
import { Stages } from "../stages/Stages";
import { StateForChart } from "../StateForChart";
import { ValuesDefintition } from "../values/ValuesDefintition";
import { shortestFriendlyName } from "./shortestFriendlyName";
import { stageLevelAssessment } from "./stageLevelAssessment";
import {
  InterviewerEntry,
  InterviewRecord,
  VantasoftStageName,
} from "./vantasoftState";
import { getFirstLine } from "../../get-first-line";

export const stateForChartFromInterviewRecord =
  (candidateId: string) =>
  (valuesDefinition: ValuesDefintition) =>
  (interviewRecord: InterviewRecord): StateForChart => {
    const { interviewInfo, scheduling } = interviewRecord;
    const { interviewers, participants, review, dateTime } = interviewInfo;
    const interviewerArray = Object.values(interviewers);

    const personFromInterviewerEntry = (interviewerEntry: InterviewerEntry) => {
      const otherInterviewerNames = interviewerArray
        .filter((i) => i.id !== interviewerEntry.id)
        .map((i) => i.name);

      return {
        friendlyName: shortestFriendlyName(interviewerEntry.name)(
          otherInterviewerNames,
        ),
        id: interviewerEntry.id,
      };
    };

    console.log('valuesDefinition', valuesDefinition)
    const interviewerPeople: People = uniqBy(
      interviewerArray.map(personFromInterviewerEntry),
      (p) => p.id,
    );

    const participantPeople: People = uniqBy(
      Object.values(participants).map(personFromInterviewerEntry),
      (p) => p.friendlyName,
    );

    const people: People = uniqBy(
      [...interviewerPeople, ...participantPeople],
      (p) => p.friendlyName,
    );

    const intendedAttendees = [
      ...participantPeople.map(
        (p) => ({ personId: p.id, type: "Participant" }) as Participant,
      ),
      ...interviewerPeople.map(
        (p) => ({ personId: p.id, type: "Interviewer" }) as Interviewer,
      ),
    ]; //TODO: This would be different for different stages but the current data structure does not allow that

    const questionIdFromText = (
      questionText: string,
      valuesDefinition: ValuesDefintition,
    ) => {
      //match question text by comparing first line 
      const questionFirstLine = getFirstLine(questionText)
      const result = valuesDefinition.questions.find((q) => {        
        const valueQuestionFirstLine = getFirstLine(q.text)
        return  valueQuestionFirstLine === questionFirstLine
      })?.id;
      
      if (!result) {
        console.error(`Could not find question in values definition with matching text: "${questionText}"`)
      }
      return result;
    };

    const personIdForStage = (stageName: VantasoftStageName) => {
      const result = interviewers[stageName]?.id;
      if (!result)
        throw new Error(
          `Could not find lead interviewer for stage ${stageName} when attempting to assign person to meeting events`,
        );
      return result;
    };
    //TODO: DRY these up so they only vary by stageName
    //TODO: Re-enable discovery when we are ready (get values working first)
    console.log('response items', interviewRecord.questionResponses.items)
    const stages: Stages = [
      {
        name: "discovery",
        intendedQuestionIds: discoveryValuesDef.questions.map((q) => q.id),
        mlScores: [],
        meetings: [
          {
            intendedAttendees: intendedAttendees.filter(
              (a) =>
                interviewers.discovery?.id === a.personId ||
                participants.discovery?.id === a.personId,
            ),
            meetingEvents:
              interviewRecord.questionResponses.items
                .filter((i) => i.stage == "discovery")
                .map((i) => {
                  return {
                    personId: personIdForStage("discovery"),
                    timestamp: i.timestamp, //TODO: May need to map this
                    type: "QuestionRatedEvent",
                    questionId: questionIdFromText(
                      i.question,
                      discoveryValuesDef,
                    ),
                    rating:
                      i.rating == "negative"
                        ? ThumbsDown
                        : i.rating == "neutral"
                          ? ThumbsSideways
                          : ThumbsUp,
                    // rating: ratingFromAcceptBoolean(i.rating),
                  };
                }) || [],
            scheduledEndDateTime:
              (scheduling && scheduling["discovery"]) || dateTime,
            scheduledStartDateTime:
              (scheduling && scheduling["discovery"]) || dateTime,
          },
        ],
        stageLevelAssessments: [
          stageLevelAssessment(interviewers["discovery"]?.id!)(review)(
            "discovery",
          ),
        ],
      },
      {
        name: "value1",
        intendedQuestionIds: valuesDefinition.questions.map((q) => q.id),
        mlScores: [],
        meetings: [
          {
            intendedAttendees: intendedAttendees.filter(
              (a) =>
                interviewers.value1?.id === a.personId ||
                participants.value1?.id === a.personId,
            ),
            meetingEvents:
              interviewRecord.questionResponses.items
                .filter((i) => i.stage === "values")
                .map((i) => ({
                  personId: personIdForStage("value1"),
                  timestamp: i.timestamp, //TODO: May need to map this
                  type: "QuestionRatedEvent",
                  questionId: questionIdFromText(i.question, valuesDefinition),
                  rating:
                    i.rating === "negative"
                      ? ThumbsDown
                      : i.rating === "neutral"
                        ? ThumbsSideways
                        : ThumbsUp,
                })) || [],
            scheduledEndDateTime:
              (scheduling && scheduling["value1"]) || dateTime,
            scheduledStartDateTime:
              (scheduling && scheduling["value1"]) || dateTime,
          },
        ],
        stageLevelAssessments: [
          stageLevelAssessment(interviewers["value1"]?.id!)(review)("value1"),
        ],
      },
      workingSession,
      finalStage,
    ];

    const stateForChart = {
      candidateId,
      valuesDefinition: {
        questions: [
          ...discoveryValuesDef.questions,
          ...valuesDefinition.questions,
        ],
        weightedValues: [
          ...discoveryValuesDef.weightedValues,
          ...valuesDefinition.weightedValues,
        ],
      },
      people,
      stages,
    };

    // console.log({interviewRecord, stateForChart})

    return stateForChart;
  };
