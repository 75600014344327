import { useEffect, useState } from "react";
import CreditCardInput from "react-credit-card-input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Container, LoadingScreen, Button } from "../Components";
import {
  createPaymentMethod,
  createSubscription,
  getCompany,
  getPrices,
} from "../utils/api";
import { companyIdFromLocalStorage } from "./companyIdFromLocalStorage";

export default function Subscribe() {
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [company, setCompany] = useState(null);
  const [plan, setPlan] = useState(null);

  const [waiting, setWaiting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getCompanyData() {
      const company = await getCompany(companyIdFromLocalStorage());
      setCompany(company);
    }
    getCompanyData();
  }, []);

  useEffect(() => {
    async function getPlanData() {
      const companyPlanId = JSON.parse(company?.plan).id;
      const plans = await getPrices();
      for (const plan of plans) {
        if (plan?.id === companyPlanId) {
          setPlan(plan);
          break;
        }
      }
    }
    if (company?.plan) getPlanData();
  }, [company]);

  if (!(company && plan)) return <LoadingScreen />;

  return (
    <Container>
      <div style={styles.header}>Checkout</div>
      <div style={styles.card}>
        <div style={styles.paymentForm}>
          <div style={styles.subHeader}>Payment Details</div>
          <div style={styles.row}>
            <div style={styles.label}>Company</div>
            <div style={styles.input}>{company.company}</div>
          </div>
          <div style={styles.row}>
            <div style={styles.label}>Card</div>
            <CreditCardInput
              fieldClassName="credit-card-input"
              cardNumberInputProps={{
                value: cardNumber,
                onChange: (e) => setCardNumber(e.target.value),
              }}
              cardExpiryInputProps={{
                value: expiry,
                onChange: (e) => setExpiry(e.target.value),
              }}
              cardCVCInputProps={{
                value: cvc,
                onChange: (e) => setCvc(e.target.value),
              }}
            />
          </div>
        </div>
        <div style={{ padding: 40, width: 360 }}>
          <div style={styles.subHeader}>Intertru {plan?.name}</div>
          <div style={{ fontSize: 18, marginBottom: 16 }}>
            Billed now: ${plan?.unit_amount / 100}
          </div>
          <div style={{ marginBottom: 20, lineHeight: 1.8 }}>
            You'll be charged ${plan?.unit_amount / 100} monthly until you
            cancel your subscription.
          </div>
          <Button
            filled
            style={{ width: "100%" }}
            onClick={onSubmit}
            waiting={waiting}
          >
            Buy Now
          </Button>
        </div>
      </div>
    </Container>
  );

  async function onSubmit() {
    if (!cardNumber) return toast.error("Credit card number is required.");
    if (!expiry) return toast.error("Credit card expiration date is required.");
    if (!cvc) return toast.error("Credit card CVC is required.");
    const [exp_month, exp_year] = expiry.split(" / ");
    const { stripeCustomerId } = company;

    setWaiting(true);

    try {
      // attach the payment method
      const pmId = await createPaymentMethod({
        stripeCustomerId,
        number: cardNumber,
        exp_month,
        exp_year,
        cvc,
      });
      console.log(`Created payment method ${pmId}`);

      // create the subscription
      const subId = await createSubscription({
        description: company?.company,
        stripeCustomerId,
        plan,
      });
      console.log(`Created subscription ${subId}`);

      toast.success("Thank you for subscribing to Intertru!");
      navigate("/admin/dashboard");
    } catch (e) {
      toast.error(e.response.data);
      setWaiting(false);
    }

    setWaiting(false);
  }
}

const styles = {
  header: {
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 32,
  },
  subHeader: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 32,
  },
  card: {
    display: "flex",
    borderRadius: 8,
    border: "2px solid #ddd",
    boxShadow: "0 0px 0px 0 rgba(60,66,87,.12), 0 3px 6px 0 rgba(0, 0, 0, .05)",
  },
  paymentForm: {
    padding: 40,
    borderRight: "2px solid #ddd",
    flexGrow: 1,
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
  label: {
    width: 120,
    minWidth: 120,
    color: "#555",
  },
  input: {
    background: "#f3f3f3",
    borderRadius: 3,
    height: 36,
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    maxWidth: 400,
  },
};
