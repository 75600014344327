import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

export default function useGroups() {
  const [groups, setGroups] = useState<string[]>([]);

  useEffect(() => {
    checkAuth();
  }, []);

  async function checkAuth() {
    try {
      const d = await Auth.currentSession();
      const cogGroups = d.accessToken.payload["cognito:groups"];
      setGroups(cogGroups);
    } catch (e) {
      console.log("error", e);
      setGroups([]);
    }
  }
  return groups;
}
