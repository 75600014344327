import { useState, useEffect } from "react";
import AudioPlayer from "../Interview/AudioPlayer";
import { MdPlayArrow, MdPause } from "react-icons/md";

import "./audio.css";
import Loader from "../../Components/Loader";

export default function Audio(props) {
  const {
    audioRef,
    audioURLs,
    elapsed,
    setElapsed,
    selectedStage,
    audioSrc,
    setAudioSrc,
    setPaused,
    showTicks = false,
    data,
  } = props;

  const [duration, setDuration] = useState(null);

  useEffect(() => {
    setPaused(true);
    setElapsed(0);
  }, [selectedStage, setElapsed, setPaused]);

  useEffect(() => {
    if (audioRef.current && audioURLs[selectedStage]) {
      setAudioSrc(audioRef.current.audio.current);
    }
  }, [audioRef, selectedStage, audioURLs, setAudioSrc]);

  const toggleAudio = () => {
    if (audioSrc.paused) {
      audioSrc.play();
      setPaused(false);
    } else {
      audioSrc.pause();
      setPaused(true);
    }
  };

  if (audioSrc)
    audioSrc.onloadedmetadata = function () {
      setDuration(audioSrc.duration);
    };

  const PlayerIcon = audioRef?.current?.isPlaying() ? MdPause : MdPlayArrow;

  if (audioURLs)
    return (
      <div className="bg-white">
        {
          audioURLs.length === 0 ? (
            <div style={{ marginLeft: 10 }}>
              <Loader />
            </div>
          ) : audioURLs[selectedStage] ? (
            <div className="horizontal">
              {!!audioSrc && (
                <div className="audio-toggle" onClick={toggleAudio}>
                  <PlayerIcon color={"#414D55"} />
                </div>
              )}
              <AudioPlayer
                currentTab={selectedStage}
                {...{ audioRef, elapsed }}
                downloadUrls={audioURLs}
                onPlay={() => setElapsed(audioSrc.currentTime)}
                onPause={() => setElapsed(audioSrc.currentTime)}
              />
              {showTicks && <Timestamps data={data} duration={duration} />}
            </div>
          ) : (
            ""
          )
          // (
          //   <div style={{ paddingVertical: 10 }}>Audio file unavailable.</div>
          // )
        }
      </div>
    );
}

function Timestamps(props) {
  const { data, duration } = props;
  let timestamps = [];
  data.forEach(
    (assessment) =>
      (timestamps = timestamps.concat(
        assessment.assessment.value[0].evidence.map((text) => text.start)
      ))
  );

  const hi = document.getElementsByClassName("rhap_progress-container");
  console.log(duration);
  return <div className="ticks-wrapper"></div>;
}
