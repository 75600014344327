import React, { CSSProperties, useEffect, useState } from "react";
import { stagesV2 } from "../../../utils/stages";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import "../index.css";
import { LoadingScreen } from "../../../Components/Loader";
import { GraphQL } from "../../../utils/api";
import Dialog from "./Dialog";
import {
  getQuestionResponseWithSubquestions,
  getQuestionResponsesByInterviewIdAndStageAndName,
} from "../../../graphql/custom";
import Footer from "./Footer";
import {
  createQuestionResponse,
  createSubquestionResponse,
  updateQuestionResponse,
  updateSession,
  updateSubquestionResponse,
} from "../../../graphql/mutations";
import { stageMapping } from "../../../utils/stageMapping";
import { cloneDeep } from "lodash";
import { Interview, Stage } from "../../../types/Interview";
import { Subquestion } from "../../../types/Subquestion";
import { Question } from "../../../types/Question";
import { getSession } from "../../../graphql/queries";

type NotesAreaProps = {
  interview: Interview;
  stage: Stage;
  handleLeaveCall: () => Promise<void>;
  initTime: number | null;
  isParticipant: boolean;
  currentDig: number[];
  setCurrentDig: (dig: any) => void;
  name: string;
  isWeFlagged: number;
  isElaborateFlagged: number;
  setIsElaborateFlagged: (time: number) => void;
};

export default function NotesArea(props: NotesAreaProps) {
  const {
    interview,
    stage,
    initTime,
    isParticipant,
    currentDig,
    setCurrentDig,
    isWeFlagged,
    isElaborateFlagged,
    setIsElaborateFlagged,
  } = props;
  const { candidateName, candidateRole } = interview?.interviewInfo;

  const [currentQIndex, setCurrentQIndex] = useState<number>(0);

  const [subQRs, setSubQRs] = useState<Subquestion[]>([]);

  const [isAIObservationVisible, setIsAIObservationVisible] = useState<
    string | null
  >(null);
  const [isMoreNotesNeeded, setIsMoreNotesNeeded] = useState<string | null>(
    null
  );

  const [isSubQRModalVisible, setIsSubQRModalVisible] =
    useState<boolean>(false);

  const [fadeIn, setFadeIn] = useState<boolean>(true);

  const [questionIDs, setQuestionIDs] = useState<string[]>([]);
  const [closedArray, setClosedArray] = useState<boolean[]>([]);

  const [standardSubQs, setStandardSubQs] = useState<Subquestion[]>([]);
  const [autoSubQs, setAutoSubQs] = useState<Subquestion[]>([]);

  const [initialRenderTime, setInitialRenderTime] = useState<number | null>(
    null
  );

  const [requiredSubQRs, setRequiredSubQRs] = useState<number>(0);

  const questions: string[] = (interview?.questions[stage ?? ""] ?? []).map(
    (question: any) => (typeof question === "string" ? question : question.text)
  );
  const isLastQuestion = currentQIndex === questions.length - 1;
  const currentQuestion = questions[currentQIndex] || "";

  const [session, setSession] = useState();

  const questionCategory =
    currentQuestion && currentQuestion?.includes(":")
      ? currentQuestion?.split(":")[0]
      : "";

  const [currentQ, setCurrentQ] = useState<Question>({
    notes: "",
    stage,
    interviewId: interview.id,
    interviewerName: interview.interviewInfo.interviewers[stage],
    question: questions[currentQIndex],
    order: currentQIndex,
  });

  // increment dig under certain timed condition:
  // - after a set interval staying on this question
  useEffect(() => {
    // only run this if the questionResponse doesn't exist
    // or if not already elaborate flagged
    // and only run for lead interviewers
    // and only run for lead interviewers
    let timer: ReturnType<typeof setTimeout> | null = null;
    setIsElaborateFlagged(0);

    if (
      !questionIDs[currentQIndex] &&
      initialRenderTime !== null &&
      !isParticipant
    ) {
      const currentTime = Date.now();
      const elapsed = currentTime - initialRenderTime;
      const delay = 30000;

      if (elapsed < delay) {
        const timeoutDelay = delay - elapsed;
        timer = setTimeout(async () => {
          // incrementDig();
          setIsElaborateFlagged(Date.now());

          let updatedSubQR;
          if (standardSubQs[currentDig[currentQIndex]]) {
            const elaborationSubQ = autoSubQs.find((subQ) =>
              subQ.question.match(/^\s*\(\s*elaboration\s*\)\s*/i)
            );

            if (elaborationSubQ) {
              updatedSubQR = cloneDeep(elaborationSubQ);
              updatedSubQR.displayTime = Date.now();

              const getRes = await GraphQL({
                query: getSession,
                variables: { id: `${interview?.id}-${stage}` },
                authMode: "",
              });
              setSession(getRes.getSession);

              const subquestionsOrder = [
                ...getRes.getSession.subquestionsOrder,
              ];
              const subquestionObject = JSON.parse(
                subquestionsOrder[currentQIndex]
              );

              subquestionObject.order.push(updatedSubQR);
              subquestionsOrder[currentQIndex] =
                JSON.stringify(subquestionObject);

              const query = updateSession;
              const variables = {
                input: {
                  id: `${interview?.id}-${stage}`,
                  questions: JSON.stringify(currentDig),
                  subquestionsOrder: subquestionsOrder,
                },
              };
              const res = await GraphQL({ query, variables, authMode: "" });
              console.log(res);
              setSession(res.updateSession);
            }
          }
        }, timeoutDelay);
      }
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [initialRenderTime, currentQIndex, questionIDs, setIsElaborateFlagged]);

  // start a timer for this question if there is no
  // QuestionResponse in the database for it yet
  useEffect(() => {
    // Check if the questionID does exists at the current index
    if (!questionIDs[currentQIndex]) setInitialRenderTime(Date.now());
  }, [questionIDs, currentQIndex, initialRenderTime]);

  useEffect(() => {
    const allSubquestions = currentQuestion.split("\n").slice(1);
    const mainQuestion = currentQuestion.split("\n")[0];
    const bracketedNumberMatch = mainQuestion.match(/\[\d+\]$/);
    if (bracketedNumberMatch) {
      let requiredSubQrs = parseInt(bracketedNumberMatch[0].slice(1, -1), 10);
      if (allSubquestions.length < requiredSubQrs)
        setRequiredSubQRs(allSubquestions.length);
      else setRequiredSubQRs(requiredSubQrs);
    } else setRequiredSubQRs(0);

    const standardSubquestions: Subquestion[] = allSubquestions
      .filter(
        (subQ: string) => !!subQ && !subQ.startsWith("(") && subQ.trim() !== ""
      )
      .map((subQ, index) => ({
        question: subQ,
        order: index,
        notes: "",
        hidden: false,
        displayTime: 0,
      }));

    const autoSubQs: string[] = allSubquestions.filter((subQ: string) =>
      subQ.startsWith("(")
    );
    // if (stage !== "discovery" || currentQIndex !== questions.length - 1) {
    setStandardSubQs(standardSubquestions);
    // }
    const mappedAutoSubQs: Subquestion[] = autoSubQs.map((subQ, index) => ({
      question: subQ,
      order: index,
      notes: "",
      hidden: false,
      displayTime: 0,
    }));
    setAutoSubQs(mappedAutoSubQs);
  }, [currentQIndex, currentQuestion, questions.length, stage]);

  const qrId = questionIDs[currentQIndex];

  const [reviewerName, setReviewerName] = useState<string>("");

  // set reviewer name field
  useEffect(() => {
    const name = isParticipant
      ? props?.name
      : stage && interview?.interviewInfo?.interviewers[stage]?.name;
    setReviewerName(name);
  }, [stage, props.name, isParticipant, interview?.interviewInfo.interviewers]);

  // pre-load question response ID array
  useEffect(() => {
    async function getQRIDsByInterviewIdAndStage(stage: string) {
      let nextToken = null;
      let qrs: Question[] = [];
      do {
        const query = getQuestionResponsesByInterviewIdAndStageAndName;
        const variables = {
          interviewId: interview?.id,
          stage,
          interviewerName: reviewerName,
          limit: 1000,
          nextToken,
        };
        const { listQuestionResponses } = await GraphQL({
          query,
          variables,
          authMode: "",
        });
        const qrRes = listQuestionResponses.items;
        qrs = qrs.concat(qrRes);
        nextToken = listQuestionResponses.nextToken;
      } while (nextToken);
      const sortedQrs = qrs
        .sort((a, b) => (a.order || 0) - (b.order || 0))
        .map((qr) => qr.id || "");

      // Fill the rest of the array with empty strings up to questions.length
      const filledQrs = Array(questions.length)
        .fill("")
        .map((_, index) => sortedQrs[index] || "");

      setQuestionIDs(filledQrs);
    }

    // if (questions?.length) setQuestionIDs(new Array(questions.length).fill(""));
    if (interview?.id && stage && reviewerName)
      getQRIDsByInterviewIdAndStage(stageMapping[stage]);
  }, [interview?.id, questions.length, currentQIndex, stage, reviewerName]);

  // populate with existing QR and subQRs, if possible
  useEffect(() => {
    async function getQuestionResponseById() {
      if (questionIDs && qrId) {
        const { getQuestionResponse: res } = await GraphQL({
          query: getQuestionResponseWithSubquestions,
          variables: { id: qrId },
          authMode: "",
        });
        setCurrentQ(res);
        setSubQRs(
          res.subquestionResponses.items.sort((a, b) => a.order - b.order)
        );
        if (!isParticipant) {
          setCurrentDig((prevDig: number[]) => {
            const updatedDig = [...prevDig];
            updatedDig[currentQIndex] = res.subquestionResponses.items.length;
            return updatedDig;
          });
        }
      }
    }
    getQuestionResponseById();
  }, [currentQIndex, isParticipant, qrId, questionIDs, setCurrentDig]);

  // fill with blanks if participant with missing subquestionTexts
  useEffect(() => {
    if (currentDig && isParticipant) {
      const subQRsLength = subQRs.filter((subQR) => !!subQR)?.length;
      if (subQRsLength < currentDig[currentQIndex]) {
        const currentDigValue = currentDig[currentQIndex];
        const subQuestionsToAdd = currentDigValue - subQRsLength;
        const updatedWithBlanks: Subquestion[] = [...subQRs];
        for (let i = 0; i < subQuestionsToAdd; i++)
          updatedWithBlanks.push(standardSubQs[i + subQRsLength]);
        setSubQRs([...updatedWithBlanks]);
      }
    }
  }, [currentDig, isParticipant, standardSubQs]);

  // reset moreNotesNeeded popup when question changes
  useEffect(() => {
    if (currentQ && currentQ?.notes) setIsMoreNotesNeeded(null);
  }, [currentQ, currentQ.notes]);

  // handle behavior for when clicking on dig deeper shovel
  const onClickShovel = () => {
    if (isSubQRModalVisible) setIsSubQRModalVisible(false);
    if (!currentQ.notes) return setIsMoreNotesNeeded("shovel");
    // @zufahmai this is where I need your help in animating the shovel icon to confirm the click
    // rendering takes far too long and a second click needs to be disallowed until the first one is processed
    if (
      standardSubQs?.length &&
      currentDig[currentQIndex] < standardSubQs?.length
    ) {
      // check if every subqr either has notes, or was skipped
      if (subQRs.every((subqr, i) => subqr.notes.trim() || closedArray[i])) {
        incrementDig();  // To-Do increment visible counter
      } else
        return setIsAIObservationVisible(
          "Please enter notes on the current follow-up question. Use the 'x' to request a different suggestion."
        );
    }
    // else {
    //   // unhide the first hidden subquestion, if user has dug deeper but closed all
    //   const firstHidden = closedArray.findIndex((value) => value === true);
    //   if (firstHidden !== -1) {
    //     setClosedArray((prev) =>
    //       prev.map((value, index) => (index === firstHidden ? false : value))
    //     );
    //   }
    // }
    setIsAIObservationVisible(null);
  };

  useEffect(() => {
    const updateSessionData = async () => {
      const getVariables = {
        id: `${interview.id}-${stage}`,
      };
      const getRes = await GraphQL({
        query: getSession,
        variables: getVariables,
        authMode: "",
      });
      console.log("getRes", getRes);
      setSession(getRes.getSession);

      let updatedSubquestionsOrder = cloneDeep(
        getRes.getSession.subquestionsOrder
      );
      if (!getRes.getSession.subquestionsOrder)
        updatedSubquestionsOrder = questions.map(
          (question: string, i: number) =>
            JSON.stringify({
              questionText: question.split("\n")[0],
              questionNum: i + 1,
              order: [],
            })
        );

      const query = updateSession;
      const variables = {
        input: {
          id: `${interview.id}-${stage}`,
          questions: JSON.stringify(currentDig),
          subquestionsOrder: updatedSubquestionsOrder,
        },
      };

      try {
        const res = await GraphQL({ query, variables, authMode: "" });
        console.log(res);
        setSession(res.updateSession);
      } catch (error) {
        console.error("Failed to update session", error);
      }
    };

    if (!isParticipant && currentDig?.length > 0) {
      updateSessionData();
    }
  }, [currentDig, interview, stage, isParticipant]);

  // add a blank subquestion for participants
  // when the dig level changes
  const handleParticipantState = () => {
    if (isParticipant) {
      subQRs.push(standardSubQs[currentDig[currentQIndex]]);
    }
  };

  const incrementDig = async () => {
    let updatedSubQR;
    if (standardSubQs[currentDig[currentQIndex]]) {
      updatedSubQR = cloneDeep(standardSubQs[currentDig[currentQIndex]]);
      updatedSubQR.displayTime = Date.now();
      subQRs.push(updatedSubQR);

      const getRes = await GraphQL({
        query: getSession,
        variables: { id: `${interview?.id}-${stage}` },
        authMode: "",
      });
      setSession(getRes.getSession);

      const subquestionsOrder = [...getRes.getSession.subquestionsOrder]; // Clone the array to avoid direct mutation
      const subquestionObject = JSON.parse(subquestionsOrder[currentQIndex]);

      subquestionObject.order.push(updatedSubQR); // Modify the object
      subquestionsOrder[currentQIndex] = JSON.stringify(subquestionObject);

      const query = updateSession;
      const variables = {
        input: {
          id: `${interview?.id}-${stage}`,
          questions: JSON.stringify(currentDig),
          subquestionsOrder: subquestionsOrder,
        },
      };
      const res = await GraphQL({ query, variables, authMode: "" });
      console.log(res);
      setSession(res.updateSession);
    }
    const updatedCurrentDig = [...currentDig];
    updatedCurrentDig[currentQIndex] += 1;
    setCurrentDig(updatedCurrentDig);
    handleParticipantState();
  };

  // when the question changes, update qrs/subqrs
  // and reset inputs
  const onQuestionChange = async (payload: {
    notes?: string;
    rating?: string;
    subQRs: Subquestion[];
  }) => {
    setIsSubQRModalVisible(false);
    const { notes, rating, subQRs } = payload;
    // update QR and subQRs on db if ready
    const qrInput = {
      id: currentQ.id,
      interviewId: currentQ.interviewId,
      stage: currentQ.stage,
      rating,
      timestamp: currentQ.timestamp, // don't change timestamp even if notes are being changed
      interviewerName: isParticipant ? props.name : currentQ.interviewerName,
      question: currentQ.question,
      notes,
      order: currentQ.order,
    };
    let subQrInputs: Promise<any>[] = [];
    const subQRsWithNotes = subQRs?.filter(
      (subQR: Subquestion) => subQR?.notes.trim() !== ""
    );
    if (subQRsWithNotes.length > 0) {
      subQrInputs = cloneDeep(subQRsWithNotes)?.map(
        async (subQR: Subquestion, i: number) =>
          !!subQR.id
            ? GraphQL({
                query: updateSubquestionResponse,
                variables: {
                  input: {
                    id: subQR.id,
                    questionResponseId: subQR.questionResponseId,
                    question: subQR.question,
                    notes: subQRsWithNotes[i].notes,
                    order: subQR.order,
                    displayTime: subQR.displayTime,
                  },
                },
                authMode: "",
              })
            : GraphQL({
                query: createSubquestionResponse,
                variables: {
                  input: {
                    questionResponseId: currentQ.id,
                    question: subQRsWithNotes[i].question,
                    notes: subQRsWithNotes[i].notes,
                    order: subQRsWithNotes[i].order,
                    displayTime: subQRsWithNotes[i].displayTime,
                  },
                },
                authMode: "",
              })
      );
    }
    if (!isLastQuestion) {
      setCurrentQ({
        notes: "",
        stage,
        interviewId: interview.id,
        interviewerName: interview.interviewInfo.interviewers[stage].name,
        question: questions[currentQIndex + 1],
        order: currentQIndex + 1,
      });
      setSubQRs([]);
    }

    if (currentQ.id) {
      // update question response
      await GraphQL({
        query: updateQuestionResponse,
        variables: { input: qrInput },
        authMode: "",
      });
    } else if (currentQ.notes) {
      await GraphQL({
        query: createQuestionResponse,
        variables: { input: qrInput },
        authMode: "",
      });
    }

    // update all subquestionTexts
    if (subQrInputs.length > 0) await Promise.all(subQrInputs);
  };

  const mainQuestionCategory = questionCategory
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  if (!interview)
    return (
      <div style={styles.main}>
        <div style={styles.mainWindow}>
          <div className="hor-center" style={{ height: "100%" }}>
            <LoadingScreen color="#fff" width={50} height={50} />
          </div>
        </div>
      </div>
    );

  const hasNextQuestion = (currentQIndex + 1 < questions.length);
  const canAdvanceToNextQuestion = hasNextQuestion && questionIDs[currentQIndex];

  return (
    <div style={styles.main}>
      <div style={styles.mainWindow}>
        <div className="hor-center" style={styles.candidateName}>
          {candidateName}
        </div>
        <div className="hor-center" style={styles.stageAndTitleWrapper}>
          {stagesV2[stage]?.label} Interview for {candidateRole?.name}
        </div>
        <div
          className={`dynamic-area notes-fade-${fadeIn ? "in" : "out"}`}
          style={styles.scrollableNotes}
        >
          <div className="hor-center" style={styles.questionLabel}>
            <FiChevronLeft
              size={34}
              color={currentQIndex === 0 ? "#777" : "#fff"}
              style={{
                cursor: currentQIndex > 0 ? "pointer" : "default",
                marginRight: 4,
              }}
              onClick={() => {
                if (currentQIndex > 0) {
                  onQuestionChange({
                    rating: currentQ.rating,
                    notes: currentQ.notes,
                    subQRs,
                  });
                  setCurrentQIndex(currentQIndex - 1);
                }
              }}
            />
            Question {currentQIndex + 1} of {questions.length}
            {mainQuestionCategory ? `: ${mainQuestionCategory}` : ""}
            <FiChevronRight
              size={34}
              color={
                canAdvanceToNextQuestion ? "#fff" : "#777" 
              }
              style={{
                cursor: canAdvanceToNextQuestion ? "pointer" : "default",
                marginLeft: 4,
              }}
              onClick={() => {
                if (canAdvanceToNextQuestion) {
                  onQuestionChange({
                    rating: currentQ.rating,
                    notes: currentQ.notes,
                    subQRs,
                  });
                  setCurrentQIndex(currentQIndex + 1);
                }
              }}
            />
          </div>
          <Dialog
            questions={questions}
            stage={stage}
            questionResponse={currentQ}
            setQuestionResponse={setCurrentQ}
            subQRs={subQRs}
            autoSubQs={autoSubQs}
            setAutoSubQs={setAutoSubQs}
            setSubQRs={setSubQRs}
            closedArray={closedArray}
            setClosedArray={setClosedArray}
            currentQIndex={currentQIndex}
            currentDig={currentDig}
            isAIObservationVisible={isAIObservationVisible}
            setIsAIObservationVisible={setIsAIObservationVisible}
            isSubQRModalVisible={isSubQRModalVisible}
            // setIsSubQRModalVisible={setIsSubQRModalVisible}
            isMoreNotesNeeded={isMoreNotesNeeded}
            setIsMoreNotesNeeded={setIsMoreNotesNeeded}
            incrementDig={incrementDig}
            isParticipant={isParticipant}
            isWeFlagged={isWeFlagged}
            isElaborateFlagged={isElaborateFlagged}
            id={interview.id}
          />
        </div>
      </div>
      <Footer
        interview={interview}
        stage={stage}
        questions={questions}
        isLastQuestion={isLastQuestion}
        setFadeIn={setFadeIn}
        currentQuestion={currentQuestion}
        currentQIndex={currentQIndex}
        setCurrentQIndex={setCurrentQIndex}
        currentDig={currentDig}
        questionResponse={currentQ}
        setQuestionResponse={setCurrentQ}
        subQRs={subQRs}
        setSubQRs={setSubQRs}
        autoSubQs={autoSubQs}
        setAutoSubQs={setAutoSubQs}
        setIsAIObservationVisible={setIsAIObservationVisible}
        setIsMoreNotesNeeded={setIsMoreNotesNeeded}
        questionIDs={questionIDs}
        setQuestionIDs={setQuestionIDs}
        onQuestionChange={onQuestionChange}
        initTime={initTime}
        incrementDig={incrementDig}
        onClickShovel={onClickShovel}
        closedArray={closedArray}
        setClosedArray={setClosedArray}
        isParticipant={isParticipant}
        isSubQRModalVisible={isSubQRModalVisible}
        name={props.name}
        requiredSubQRs={requiredSubQRs}
      />
    </div>
  );
}

const sideMargin = 6;

const styles: Record<string, CSSProperties> = {
  main: {
    width: "25%",
    height: `calc(100% - ${sideMargin * 2}px)`,
    zIndex: 2,
    margin: `${sideMargin}px`,
    position: "relative",
  },
  mainWindow: {
    background: "#000000b5",
    padding: 20,
    color: "white",
    height: "100%",
    width: "100%",
    borderRadius: 14,
    position: "relative",
  },
  candidateName: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
  },
  questionLabel: {
    width: "100%",
    fontSize: 16,
    paddingBottom: 10,
    marginBottom: 14,
    alignItems: "center",
    justifyContent: "space-between",
  },
  scrollableNotes: {
    height: "72.5%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  textAreaInput: {
    width: "100%",
    maxWidth: "none",
    background: "#000000bb",
    outline: "none",
    padding: 12,
    borderRadius: 6,
  },
  bottomNotesRatingSection: {
    height: "15%",
    zIndex: 10,
    position: "absolute",
    bottom: 0, // padding of parent
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
  },
  digDeeperShovelButton: {
    height: "40%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingBottom: 12,
    cursor: "pointer",
  },
  stageAndTitleWrapper: {
    fontSize: 14,
    textAlign: "center",
    paddingBottom: 16,
    borderBottom: "1px solid white",
    marginBottom: 20,
  },
  shovelImage: {
    height: 50,
    padding: 10,
    marginLeft: -4,
    marginRight: 4,
  },
};
