import { Stage } from "../../../stages/Stage";
import { tommy } from "../people/tommy";

export const finalStage: Stage = {
    name: 'value2',
    intendedQuestionIds: [],
    meetings: [],
    stageLevelAssessments: [
        {
            notes: "Overall a good candidate",
            personId: tommy.id,
            reason: "He was good enough to keep",
            stageLevelRating: "ThumbsUp"
        }
    ],
    mlScores: [],
};
