import React from "react";
import { useMediaQuery } from "react-responsive";

export default function Container(props) {
  const {
    width = 1000,
    ignorePadding = false,
    className = "",
    style = {},
  } = props;

  // sm breakpoint
  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });

  let padding = isMobile ? 20 : 40;
  if (ignorePadding) padding = 0;
  const maxWidth = width + padding * 2;
  const stylePlus = {
    margin: "auto",
    maxWidth,
    padding,
    height: "100%",
    overflowY: "auto",
    ...props.style,
  };
  return (
    <div className={className} style={stylePlus}>
      {props.children}
    </div>
  );
}
