import React from "react";
import ReactLoading from "react-loading";

const themeColor = "#EE7531";

export default function Button(props) {
  let colorTheme = themeColor;
  if (props.themeColor) colorTheme = props.themeColor;

  const style = { ...styles.button, border: `2px solid ${colorTheme}` };

  if (props.filled) {
    style.backgroundColor = colorTheme;
    style.color = "white";
  } else {
    style.backgroundColor = "white";
    style.color = colorTheme;
  }

  if (props.waiting) {
    style.cursor = "auto";
    // style.opacity = 0.8;
  }

  if (props.marginless) style.margin = 0;
  const hasIcon = props.icon ? true : false;

  if (props.hide) return null;
  let className = "hover-opacity";
  if (props.className) className = `hover-opacity ${props.className}`;
  let buttonType = "button";
  if (props.type) buttonType = props.type;

  if (props.disabled) {
    style.opacity = 0.6;
    style.cursor = "auto";
  }
  return (
    <button
      className={className}
      onClick={onClick}
      style={{ ...style, ...props.style }}
      disabled={props.disabled}
      type={buttonType}
    >
      {renderContent()}
    </button>
  );

  function renderContent() {
    if (props.waiting)
      return (
        <div style={{ height: 32, alignItems: "center", marginTop: -5 }}>
          <Loader color={props.themeColor} />
        </div>
      );

    if (props.children) return props.children;

    return (
      <div style={{ display: "flex" }}>
        {hasIcon && <div style={styles.buttonIcon}>{props.icon}</div>}
        {props.title}
      </div>
    );
  }

  function onClick(evt) {
    if (evt) evt.preventDefault();
    if (props.waiting) return;
    if (props.disabled) return;
    if (props.onClick) props.onClick();
  }
}

function Loader(props) {
  return (
    <div style={{ position: "relative" }}>
      <ReactLoading type="cylon" color="white" width={36} />
    </div>
  );
}

const styles = {
  button: {
    minHeight: 38,
    padding: "0 16px",
    minWidth: 100,
    fontSize: 16,
    color: "#444",
    borderRadius: 8,
    userSelect: "none",
    // boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    cursor: "pointer",
  },
  buttonIcon: {
    marginRight: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    width: 20,
    height: 20,
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    background: themeColor,
    borderRadius: 10,
    userSelect: "none",
    // boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
  },
  buttonWrapper: {
    overflow: "hidden",
    borderRadius: 6,
    background: "white",
    display: "inline-flex",
  },
  configIcon: {
    marginRight: 4,
    fontSize: 16,
    bottom: 2,
    position: "relative",
  },
  config: {
    display: "inline",
    whiteSpace: "nowrap",
    zIndex: 10,
  },
  ctaButton: {
    minWidth: 200,
    minHeight: 50,
    height: 50,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.8,
    userSelect: "none",
    border: "none",
    borderRadius: 6,
    color: "white",
    padding: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
