import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import * as React from "react";
import { styles } from "../../../styles";
import { ScoredExampleTR } from "./ScoredExampleTR";
import { TextWithInfoTooltip } from "../../general/TextWithInfoTooltip";


export const ScoredExampleTable = ({ valueId, attributeId, examples, setState }) => <TableContainer>
    <Table variant="striped">
        <Thead style={styles.tableHeader}>
            <Tr className="header-tr">
                <Th className="header-th">
                    <TextWithInfoTooltip content="Text" tooltipLabel="Text of an example response a candidate might give" />
                </Th>
                <Th className="header-th">
                    <TextWithInfoTooltip content="Score" tooltipLabel="How you would score the attribute based on this example response" />
                </Th>
                <Th className="header-th" isNumeric>Actions</Th>
            </Tr>
        </Thead>
        <Tbody>
            {examples.map(
                (example, key) => <ScoredExampleTR {...{ example, valueId, attributeId, setState, key }} />
            )}
        </Tbody>
    </Table>
</TableContainer>;
