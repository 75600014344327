import { Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import * as React from "react";
import { RelatedAttributes } from "./RelatedAttributes";
import { onAddSharedQuestionClicked } from "./onAddSharedQuestionClicked";

export const ExistingQuestionTR = ({ attributeTitle, setIsOpen, valueId, attributeId, weightedValues, existingQuestion: { id, title, text }, setState }) => {
    return <Tooltip label={<Text>Associate this question with the <i>{attributeTitle || 'Untitled'}</i> attribute</Text>}>
        <Tr className="custom-tr-striped" onClick={onAddSharedQuestionClicked(valueId)(attributeId)(id)(setIsOpen)(setState)}>
            <Td>
                {title}
            </Td>
            <Td width="60%">
                {text}
            </Td>
            <Td>
                <RelatedAttributes enableLinks={false} {...{questionId: id, weightedValues, attributeId}} />
            </Td>
        </Tr>
    </Tooltip>
};
