export default function OptionOverlay(props) {
  const { moreModalVisible, setMoreModalVisible } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {moreModalVisible && (
        <div
          className="option-overlay"
          onClick={(e) => {
            e.stopPropagation();
            setMoreModalVisible(false);
          }}
        />
      )}
    </div>
  );
}
