import { Text } from "@chakra-ui/react";
import React, { MouseEventHandler, useState } from "react";
import { PolarAngleAxis, RadarChart, Tooltip } from "recharts";
import { themeOrange } from "../../../theme";
import { People } from "../state/attendees/People";
import { IntertruDimensionScores } from "../state/scores/IntertruDimensionScores";
import { Stage } from "../state/stages/Stage";
import { stageFriendlyName } from "../state/stages/stageFriendlyName";
import { ValuesDefintition } from "../state/values/ValuesDefintition";
import { colors } from "./colors";
import { intendedAndOrActualAttendeePersonIds } from "./intendedAndOrActualAttendeePersonIds";
import { onReloadClick } from "./onReloadClick";
import { radarChartDimensionsFromState } from "./radarChartDimensionsFromState";
import { radarLine } from "./radarLine";
import { renderTooltipV2 } from "./renderTooltipV2";
import { SelectableBox } from "./SelectableBox";
import { SetInterviewAnalyticsState } from "./SetInterviewAnalyticsState";
import { stageDisplayDateString } from "./stageDisplayDateString";
import { valuesDefinitionRelatedToStage } from "./valuesDefinitionRelatedToStage";
import { valuesRelatedToStage } from "./valuesRelatedToStage";
import { EmptyChartSVG } from "./EmptyChartSVG";
import { personNameById } from "./personNameById";
import classNames from "classnames";
import { HandRightIcon } from "../../../Components/common/icons/HandRightIcon";
import { Tooltip as ChakraTooltip } from "@chakra-ui/react";
import { SimpleTooltip } from "../../../Components/SimpleToolTip";
import { ThumbsUp } from "../state/questions/ThumbsUp";
import { ThumbsDown } from "../state/questions/ThumbsDown"
import { personById } from "./personById";
import { FrontHandIcon } from "../../../Components/common/icons/FrontHandIcon";
import { EllipseIcon } from "../../../Components/common/icons/EllipseIcon";
import { WarningIcon } from "../../../Components/common/icons/WarningIcon";
import { isQuestionRatedEvent } from "./isQuestionRatedEvent";
type StageChartV2Props = {
  candidateId: string;
  people: People;
  stage: Stage;
  mlScores: IntertruDimensionScores;
  valuesDefinition: ValuesDefintition;
  isSelected: boolean;
  onClick: MouseEventHandler;
  setStateForChart: SetInterviewAnalyticsState;
};

export const StageChartV2 = ({
  candidateId,
  isSelected,
  onClick,
  stage,
  people,
  valuesDefinition,
  mlScores,
  setStateForChart
}: StageChartV2Props) => {
  
  const { name, meetings, stageLevelAssessments } = stage;
  const personIds = intendedAndOrActualAttendeePersonIds(meetings);
  const [isReloading, setIsReloading] = useState<any>(false);
  const onReloadClickHandler = onReloadClick(candidateId)(stage)(
    valuesDefinitionRelatedToStage(valuesDefinition)(stage),
  )(setIsReloading)(setStateForChart);

  const valuesRelatedToThisStage =
    valuesRelatedToStage(valuesDefinition)(stage);
  // console.log({ valuesRelatedToThisStage });

  const chartDimensions = radarChartDimensionsFromState(
    valuesRelatedToThisStage,
    meetings,
    mlScores,
  );
  const isDisabled = chartDimensions.length === 0;
  const noInterviewDataTooltipHeaderText = 'Update Interview Questions'
  const noInterviewDataTooltipContent = `One or more dimensions of this chart are blank because company values do not match the Values of interview questions.${'\n'}
Contact your Intertru Customer Success Manager so that we can help you ensure the interview questions are reflected in the Values definitions.`  
  // if any question is missing from the user values definition
  const isMissingUserValuesDefinition = stage.name === 'value1' && stage.meetings.flatMap((meeting) => meeting.meetingEvents).filter(isQuestionRatedEvent).some((meetingEvent) => meetingEvent.questionId == null);
  return (
    <SelectableBox
      isSelected={isSelected}
      onClick={(e) => (isDisabled ? undefined : onClick(e))}
      isDisabled={isDisabled}
    >
      <div className="flex flex-col justify-center items-center w-full">        
        <div className="flex justify-between items-center w-full"> {/* Adjusted line */}
          <Text className="text-center w-full"> {/* Adjusted line */}
            <strong>{stageFriendlyName(name)}</strong>
          </Text>
          {isMissingUserValuesDefinition  && <ChakraTooltip label={<SimpleTooltip headerText={noInterviewDataTooltipHeaderText} content={noInterviewDataTooltipContent} />}>                    
              <div>
              <WarningIcon />              
              </div>
          </ChakraTooltip>
        }
        </div>
        <Text align="right">{stageDisplayDateString(meetings)}</Text>
      </div>
      {chartDimensions.length > 0 ? (
        <RadarChart
          outerRadius={90}
          innerRadius={10}
          width={isSelected ? 550 : 200}
          height={250}
          data={chartDimensions}
          className="radar-chart"
        >
          <Tooltip content={renderTooltipV2} />
          {/* <PolarGrid gridType="circle" fill="white" stroke="white" /> */}
          {isSelected ? <PolarAngleAxis dataKey="dimensionName" /> : null}
          {personIds.map((personId, i) =>
            radarLine({
              name: personNameById(people)(personId),
              color: colors[i],
              key: personId,
            }),
          )}
          {radarLine({
            name: "Intertru",
            color: themeOrange,
            key: "Intertru",
          })}          
        </RadarChart>
      ) : (
        <>
          <div className="w-[200px] h-[250px] flex justify-center items-center">
            <EmptyChartSVG />
          </div>
          <div className="absolute bg-black opacity-10 w-full h-full top-0 left-0" />
        </>
      )}
      {chartDimensions?.length > 0 &&
              <div className="col-span-10 col-start-2">
          {intendedAndOrActualAttendeePersonIds(meetings).map(
            (pId: any) => {
                const assessment = stageLevelAssessments.find(a => a.personId === pId)
                if (!assessment) {
                    console.error('Could not find assessment for person', pId, name)
                    return null
                }
                const rating = assessment.stageLevelRating
                const person = personById(people)(pId)
                const reason = assessment.reason
                const reasonTitle =  rating === ThumbsUp ? 'Why?' : 'Why not?'
                const interviewerName =  person?.friendlyName ?? 'Interviewer';
                return (
                  <ChakraTooltip label={<SimpleTooltip headerText={reasonTitle} content={reason} />}>                    
                    <div className={classNames("border border-solid rounded-lg py-1 px-2 gap-2 flex items-center", 
                      {
                        "border-status-proceed": rating === ThumbsUp,
                        "border-status-not-proceed": rating === ThumbsDown,
                      }
                    )}>
                      {isSelected && (<>
                        <EllipseIcon className={rating === ThumbsUp ? 'text-status-proceed' : 'text-status-not-proceed'}/>
                        <div className={classNames("self-stretch text-lg font-semibold font-['Montserrat'] leading-relaxed text-status-proceed", 
                        {
                          "text-status-proceed": rating === ThumbsUp,
                          "text-status-not-proceed": rating === ThumbsDown,
                        })}>
                        {interviewerName}
                      </div>
                      <div className={classNames("self-stretch text-lg font-semibold font-['Montserrat'] leading-relaxed text-status-proceed", 
                        {
                          "text-status-proceed": rating === ThumbsUp,
                          "text-status-not-proceed": rating === ThumbsDown,
                        })}>
                        |
                      </div>
                      </>) }

                      {rating === ThumbsUp ? <HandRightIcon /> : <FrontHandIcon />}
                    </div>      
                    </ChakraTooltip>
                );
            },
          )}
        </div>
       }
    </SelectableBox>
  );
};
