export const privacyContent = (
  <>
    <h1 dummy="padding-left: 169pt;text-indent: 0pt;line-height: 29pt;text-align: center;">
      Intertru Privacy Policy
    </h1>
    <p dummy="text-indent: 0pt;text-align: left;">
      <br />
    </p>
    <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 167%;text-align: left;">
      Effective September 14, 2022 [See also Terms of Service]
    </p>
    <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      This privacy policy (“<b>Policy</b>”) informs you of our practices when
      handling your Personal Information through the Services (both as defined
      below). In this Policy, “<b>Intertru, Inc.</b>”, “<b>we</b>” or “<b>us</b>
      ” refers to Intertru, Inc., a company registered in Delaware with its
      registered address located at 4590 Macarthur Boulevard, Newport Beach, CA,
      92660. We are the data controller under the applicable privacy laws.
    </p>
    <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      Where we have an Intertru Business or enterprise service agreement in
      place with an enterprise customer who is asking you to use our Services
      (for example your employer), we obtain and process your Personal
      Information on behalf of and at the instructions of that customer. In that
      context, such enterprise customers are the data controllers and their
      privacy policies will apply to the processing of your Information. We
      encourage you to read their privacy policies.
    </p>
    <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      For the purpose of this Policy, “<b>Personal Information</b>” means any
      information relating to an identified or identifiable individual. This
      includes Personal Information you provide or generate when you use: (a)
      Our <b>Intertru, Inc. </b>app (the “<b>App</b>”); and (b),
      https://intertru.ai and any other dedicated <b>Intertru, Inc. </b>websites
      (the “<b>Website</b>”) (collectively, the “<b>Services</b>”). When you use
      the Services, you accept and understand that we collect, process, use, and
      store your Personal Information as described in this Policy.
    </p>
    <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      If you are a California resident, our Privacy Notice for California
      Residents includes additional information about your rights and how we
      collect, use, and share information.
    </p>
    <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      If you do not agree with this Policy, you must not use any of the
      Services. If you change your mind in the future you must stop using the
      Services and you may exercise your rights concerning your Personal
      Information as set out in this Policy.
    </h2>
    <ol id="l1">
      <li>
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          1. INFORMATION WE COLLECT
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We will collect and use the following Personal Information about you:
        </p>
        <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Information you provide to us
        </h2>
        <ul id="l2">
          <li data-list-text="●">
            <h2 dummy="padding-top: 9pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Registration Information
              <span dummy="p">
                . When you create an account on our Services, you will be asked
                to provide your name, email, and a password, you may voluntarily
                add a profile picture. For Pro or Business plans which are paid
                Services, our payment processing partner Stripe, Inc. may also
                collect your name, billing address, and payment information.
                Payment information is not shared with us and is maintained by
                Stripe.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              App Information
              <span dummy="p">
                . When you use the Services, you may provide us with your audio
                and video recordings (“
              </span>
              The Recordings
              <span dummy="p">
                ”) and any text, images or videos that you upload or provide to
                us in the context of the Services.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: justify;">
              Communication Information
              <span dummy="p">
                . When you contact us, you provide us with your phone number,
                email, and any other information you choose to provide over such
                communication, including information about your query.
              </span>
            </h2>
            <h2 dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
              Information you provide us about others
            </h2>
          </li>
          <li data-list-text="●">
            <p dummy="padding-top: 9pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              If you choose to collaborate on a task with your co-workers or
              friends, or refer us to them, you provide us with the email
              address and contact information of your co-workers or friends.
            </p>
          </li>
          <li data-list-text="●">
            <p dummy="padding-top: 8pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              If you provide an Audio Recording or Video Recording, this may
              contain the Personal Information of third parties. Before you do
              so, please make sure you have the necessary permissions from your
              co-workers, friends or other third parties before sharing Personal
              Information or referring them to us.
            </p>
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
              Information we automatically collect or is generated about you
              when use the Services
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 9pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Usage Information
              <span dummy="p">
                : When you use the Services, you generate information pertaining
                to your use, including timestamps, such as access, record,
                share, edit and delete events, app use information, interactions
                with our team, and transaction records.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Device Information
              <span dummy="p">: We assign a unique user identifier (“</span>
              UUID
              <span dummy="p">
                ”) to each mobile device that accesses the Services. When you
                use our Services, you provide information such as your IP
                address, UUIDs, device IDs, web beacons and other device
                information (such as carrier type, whether you access our
                Service from a desktop or mobile platform, device model, brand,
                web browser and operating system).
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Cookies:{" "}
              <span dummy="p">
                We use Cookies and other similar technologies (“
              </span>
              Cookies
              <span dummy="p">
                ”) to enhance your experience when using the Service. For more
                information about our Cookies policy, see HOW WE USE COOKIES AND
                SIMILAR TECHNOLOGIES below.
              </span>
            </h2>
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
              Information received from third parties.
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 9pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Information we receive from third party platforms
              <span dummy="p">
                : When you connect third party platforms, apps or providers
                (such as Google Calendar, iCal or other calendar programs,
                Google Contacts, Teams or Zoom) to our Services, or when you
                register through a third party account (such as Google or
                Microsoft), we receive Personal Information that includes your
                username, profile picture, email address, time, location,
                calendar information, contact information from such third
                parties and any information you choose to upload to such third
                party platforms (“
              </span>
              Platform Information<span dummy="p">”).</span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Information from platforms our Services relies on
              <span dummy="p">
                : We receive transaction information from our payment processor
                Stripe.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 8pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Other third parties.{" "}
              <span dummy="p">
                We may receive additional information about you, such as
                demographic or interest attributes from third parties such as
                data or marketing partners and combine it with other information
                we have about you.
              </span>
            </h2>
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              We also collect, and use aggregated data such as statistical or
              demographic data for our purposes. Aggregated data may be derived
              from your Personal Information but is not Personal Information as
              this data will not directly or indirectly reveal your identity.
              However, if we combine or connect aggregated data with your
              Personal Information so that it can directly or indirectly
              identify you, we will treat the combined data as Personal
              Information which will be used in accordance with this Policy.
            </p>
          </li>
        </ul>
      </li>
      <li data-list-text="2.">
        <h2 dummy="padding-top: 2pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          2. HOW WE USE YOUR PERSONAL INFORMATION
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We use your Personal Information to:
        </p>
        <ul id="l3">
          <li data-list-text="●">
            <h2 dummy="padding-top: 9pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Set up your account.{" "}
              <span dummy="p">
                We use your registration information, device information and
                information received from third parties (such as your username,
                email address) in order to set up an account for you to use our
                Services. We do so in accordance with our contractual and
                precontractual obligations to you in order to provide you with
                an account to use the Services.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Provide you with the Services
              <span dummy="p">
                . We use your audio and video recordings, usage information and
                platform information in order to provide you with the Services.
                In addition, we use your communication information to facilitate
                support (e.g. retrieval of a forgotten password). We do so in
                accordance with our contractual obligations to you in order to
                provide you with the Services.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Improve and monitor the Services
              <span dummy="p">
                . We use information we automatically collect or generate about
                you when you use the Services, as well as non-personal
                information about your device such as device manufacturer, model
                and operating system, and the amount of free space on your
                device, to analyze the use of and improve our Services. We train
                our proprietary artificial intelligence technology on
                aggregated, de-identified audio recordings and video recordings.
                Only with your explicit permission will we manually review
                certain audio and/or video recordings to further refine our
                model training data.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Communicate with you
              <span dummy="p">
                . If you contact us, we will use your contact information to
                communicate with you and, if applicable, your usage information
                to support your use of the Services.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Send you newsletters about product news or updates that may be of
              interest to you.{" "}
              <span dummy="p">
                We will send you emails with news or updates pertaining to our
                Services. When doing so, we process your email address, name and
                may process your usage information. Your consent can be
                withdrawn at any time by following the unsubscribe mechanism at
                the bottom of each communication.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Prevent fraud, defend Intertru, Inc. against legal claims or
              disputes, enforce our terms and to comply with our legal
              obligations.{" "}
              <span dummy="p">
                It is in our legitimate interest to protect our interests by (1)
                monitoring the use of the Services to detect fraud or any other
                user behavior which prejudices the integrity of our Services,
                (2) taking steps to remedy aforementioned fraud and behavior,
                (3) defending ourselves against legal claims or disputes, and
                (4) enforcing our terms and policies. When doing so, we will
                process the Personal Information relevant in such a case,
                including information you provide us, information we
                automatically collect about you, and information which is
                provided to us by third parties.
              </span>
            </h2>
          </li>
        </ul>
      </li>
      <li data-list-text="3.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          3. HOW WE USE COOKIES AND SIMILAR TECHNOLOGIES
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          We and our third party partners use Cookies, pixel tags, and similar
          technologies to collect information about your browsing activities and
          to distinguish you from other users of our Services in order to aid
          your experience and measure and improve our advertising effectiveness.
        </p>
        <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Cookies are small files of letters and numbers that we store on your
          browser or on your device. They contain information that is
          transferred to your device.
        </p>
        <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
          We use Cookies to collect information about your browsing activities
          and to distinguish you from other users of our Services in order to
          aid your experience.
        </p>
        <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We use the following types of Cookies and similar technologies:
        </p>
        <ul id="l4">
          <li data-list-text="●">
            <h2 dummy="padding-top: 9pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Strictly necessary Cookies:{" "}
              <span dummy="p">
                Some Cookies are strictly necessary to make our Services
                available to you; for example, to provide login functionality,
                user authentication and security. We cannot provide you with the
                Services without this type of Cookie.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Functional Cookies
              <span dummy="p">
                : These are used to recognize you when you return to our
                Website. This enables us to personalize our content for you and
                remember your preferences (for example, your choice of
                language).
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Analytical, performance, or advertising Cookies:{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                dummy="s2"
                target="_blank"
                rel="noreferrer"
              >
                We also use Cookies and similar technologies for analytics
                purposes in order to operate, maintain, and improve our Services
                and measure and improve our advertising effectiveness. We use
                third party analytics providers, including Google Analytics and
                Amplitude, to help us understand how users engage with us. We
                also use third party advertising partners, including Facebook,
                to deliver ads to you on other sites. Google Analytics uses
                first-party Cookies to track user interactions which helps show
                how users use our Service and Website. This information is used
                to compile reports and to help us improve our Service and
                Website. Such reports disclose Website trends without
                identifying individual visitors. You can opt out of Google
                Analytics by going to{" "}
              </a>
              <span dummy=" color: #0462C1; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt;">
                https://tools.google.com/dlpage/gaoptout
              </span>
              <span dummy="s1"> </span>
              <span dummy="p">or via Google’s Ads settings.</span>
            </h2>
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              You can block Cookies by setting your internet browser to block
              some or all or Cookies. However, if you use your browser settings
              to block all Cookies (including essential Cookies) you may not be
              able to use our Services.
            </p>
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
              Except for essential Cookies, all Cookies will expire after a
              maximum of two years.
            </p>
          </li>
        </ul>
      </li>
      <li data-list-text="4.">
        <h2 dummy="padding-top: 9pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          4. WITH WHOM WE SHARE YOUR PERSONAL INFORMATION
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We share your Personal Information with selected third parties,
          including:
        </p>
        <ul id="l5">
          <li data-list-text="●">
            <h2 dummy="padding-top: 9pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Other users{" "}
              <span dummy="p">
                who see your Personal Information (such as your username and
                email) and any other information you choose to share with them
                through the Services.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 8pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">
              Vendors and service providers we rely on for the provision of the
              Services<span dummy="p">, for example:</span>
            </h2>
            <ul id="l6">
              <li data-list-text="•">
                <h2 dummy="padding-top: 9pt;padding-left: 12pt;text-indent: -8pt;text-align: left;">
                  Cloud service providers{" "}
                  <span dummy="p">
                    who we rely on for compute and data storage.
                  </span>
                </h2>
              </li>
              <li data-list-text="•">
                <h2 dummy="padding-top: 9pt;padding-left: 12pt;text-indent: -8pt;text-align: left;">
                  Platform support providers{" "}
                  <span dummy="p">
                    who help us manage and monitor the Services.
                  </span>
                </h2>
              </li>
              <li data-list-text="•">
                <h2 dummy="padding-top: 9pt;padding-left: 12pt;text-indent: -8pt;text-align: left;">
                  Mobile advertising tracking providers{" "}
                  <span dummy="p">
                    who help us measure our advertising effectiveness.
                  </span>
                </h2>
              </li>
              <li data-list-text="•">
                <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  Analytics providers{" "}
                  <span dummy="p">
                    who provide analytics, segmentation and mobile measurement
                    services and help us understand our user base.
                  </span>
                </h2>
              </li>
              <li data-list-text="•">
                <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  Advertising Partners
                  <span dummy="p">
                    : We work with third party advertising partners to show you
                    ads that we think may interest you.
                  </span>
                </h2>
                <h2 dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  Providers of integrated third-party programs, apps or
                  platforms
                  <span dummy="p">
                    , such as Google Calendar and Apple iCal. When you connect
                    third party platforms to our Services, you authorize us to
                    share designated information and data created and/or
                    uploaded by you to our servers with these third-party
                    programs on your behalf.
                  </span>
                </h2>
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  We are not responsible for the content, privacy and security
                  practices and policies of such third parties or App providers.
                  We encourage you to learn about their privacy and security
                  policies before integrating such platforms.
                </p>
                <ul id="l7">
                  <li data-list-text="●">
                    <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
                      Payment processors
                      <span dummy="p">
                        , such as Stripe. These payment processors are
                        responsible for the processing of your Personal
                        Information, and may use your Personal Information for
                        their own purposes in accordance with their privacy
                        policies. More information is available
                      </span>
                    </h2>
                    <p dummy="padding-left: 41pt;text-indent: 0pt;line-height: 13pt;text-align: left;">
                      <a
                        href="https://stripe.com/gb/privacy"
                        dummy="s2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        here:{" "}
                      </a>
                      <span dummy=" color: #0462C1; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt;">
                        https://stripe.com/gb/privacy
                      </span>
                      .
                    </p>
                  </li>
                  <li data-list-text="●">
                    <h2 dummy="padding-top: 9pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
                      Law enforcement agencies, public authorities or other
                      judicial bodies and organizations.{" "}
                      <span dummy="p">
                        We disclose Personal Information if we are legally
                        required to do so, or if we have a good faith belief
                        that such use is reasonably necessary to comply with a
                        legal obligation, process or request; enforce our terms
                        of service and other agreements, policies, and
                        standards, including investigation of any potential
                        violation thereof; detect, prevent or otherwise address
                        security, fraud or technical issues; or protect the
                        rights, property or safety of us, our users, a third
                        party or the public as required or permitted by law
                        (including exchanging information with other companies
                        and organizations for the purposes of fraud protection).
                      </span>
                    </h2>
                  </li>
                  <li data-list-text="●">
                    <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
                      Change of corporate ownership.{" "}
                      <span dummy="p">
                        If we are involved in a merger, acquisition, bankruptcy,
                        reorganization, partnership, asset sale or other
                        transaction, we may disclose your Personal Information
                        as part of that transaction.
                      </span>
                    </h2>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li data-list-text="5.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          5. HOW LONG WE STORE YOUR INFORMATION
        </h2>
        <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Intertru, Inc.{" "}
          <span dummy="p">
            stores all Personal Information for as long as necessary to fulfill
            the purposes set out in this Policy, or for as long as we are
            required to do so by law or in order to comply with a regulatory
            obligation. When deleting Personal Information, we will take
            measures to render such Personal Information irrecoverable or
            irreproducible, and the electronic files which contain Personal
            Information will be permanently deleted.
          </span>
        </h2>
      </li>
      <li data-list-text="6.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          6. YOUR RIGHTS
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          In certain circumstances you have the following rights in relation to
          your Personal Information that we hold.
        </p>
        <ul id="l8">
          <li data-list-text="●">
            <h2 dummy="padding-top: 8pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Access
              <span dummy="p">
                . You have the right to access the Personal Information we hold
                about you, and to receive an explanation of how we use it and
                who we share it with.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 8pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Correction
              <span dummy="p">
                . You have the right to correct any Personal Information we hold
                about you that is inaccurate or incomplete.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">
              Erasure
              <span dummy="p">
                . You have the right to request for your Personal Information to
                be erased or deleted.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 2pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Object to processing
              <span dummy="p">
                . You have the right to object to our processing of your
                Personal Information where we are relying on a legitimate
                interest or if we are processing your Personal Information for
                direct marketing purposes.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Restrict processing
              <span dummy="p">
                . You have a right in certain circumstances to stop us from
                processing your Personal Information other than for storage
                purposes.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 8pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Portability
              <span dummy="p">
                . You have the right to receive, in a structured, commonly used
                and machine-readable format, Personal Information that you have
                provided to us if we process it on the basis of our contract
                with you, or with your consent, or to request that we transfer
                such Personal Information to a third party.
              </span>
            </h2>
          </li>
          <li data-list-text="●">
            <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
              Withdraw consent
              <span dummy="p">
                . You have the right to withdraw any consent you previously
                applied to us. We will apply your preferences going forward, and
                this will not affect the lawfulness of processing before your
                consent was given.
              </span>
            </h2>
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              <a
                href="mailto:support@intertru.ai"
                dummy="s2"
                target="_blank"
                rel="noreferrer"
              >
                Please note that, prior to any response to the exercise of such
                rights, we will require you to verify your identity. In
                addition, we may require additional information (for example,
                why you believe the information we hold about you is inaccurate
                or incomplete) and may have valid legal reasons to refuse your
                request. We will inform you if that is the case. For more
                information on how to exercise your rights, or to exercise your
                rights, please email support@intertru.ai
              </a>
            </p>
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              If you are a California resident, California law affords you
              certain rights regarding our collection and use of your personal
              information. To learn more about your California privacy rights,
              please visit our Privacy Notice for California Residents.
            </p>
          </li>
        </ul>
      </li>
      <li data-list-text="7.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          7. PRIVACY SHIELD
        </h2>
        <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Intertru, Inc.{" "}
          <span dummy="p">
            complies with the EU-U.S. Privacy Shield and the Swiss-U.S. Privacy
            Shield Frameworks (“
          </span>
          Privacy Shield
          <span dummy="p">
            ”) as set forth by the U.S. Department of Commerce regarding the
            collection, use, and retention of personal information transferred
            from the European Union, United Kingdom and Switzerland to the
            United States in reliance on Privacy Shield. Intertru, Inc
          </span>
          .{" "}
          <a
            href="https://www.privacyshield.gov/"
            dummy="s2"
            target="_blank"
            rel="noreferrer"
          >
            has certified to the Department of Commerce that it adheres to the
            Privacy Shield Principles with respect to such information. If there
            is any conflict between the terms in this privacy policy and the
            Privacy Shield Principles, the Privacy Shield Principles shall
            govern. To learn more about the Privacy Shield program, and to view
            our certification, please visit{" "}
          </a>
          <span dummy=" color: #0462C1; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt;">
            https://www.privacyshield.gov/
          </span>
          <span dummy="p">.</span>
        </h2>
        <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          For more information on how we comply with the Privacy Shield
          Principles, please see{" "}
          <b>APPENDIX: Intertru, Inc. Privacy Shield Notice</b>.
        </p>
        <p dummy="text-indent: 0pt;text-align: left;">
          <br />
        </p>
      </li>
      <li data-list-text="8.">
        <h2 dummy="padding-left: 15pt;text-indent: -11pt;text-align: left;">
          8. CHILDREN
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          The Service and Website are not targeted at children, and we do not
          knowingly collect Personal Information from children under the age of
          13. If you learn that a child has provided us with Personal
          Information in violation of this Policy, please contact us as
          indicated below.
        </p>
      </li>
      <li data-list-text="9.">
        <h2 dummy="padding-top: 2pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          9. CONTACT &amp; COMPLAINTS
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          <a
            href="mailto:support@intertru.ai"
            dummy="s2"
            target="_blank"
            rel="noreferrer"
          >
            For inquiries or complaints regarding this Policy, please first
            contact us at{" "}
          </a>
          support@intertru.ai and we will endeavor to deal with your complaint
          as soon as possible. This is without prejudice to your right to launch
          a claim with a data protection authority.
        </p>
        <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          <a
            href="https://forms.dataprotection.ie/contact"
            dummy="s2"
            target="_blank"
            rel="noreferrer"
          >
            If you are based in the EEA or the UK, you may also make a complaint
            to either the Irish Data Protection Commission (on +353 578 684 800
            or via{" "}
          </a>
          <span dummy=" color: #0462C1; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt;">
            https://forms.dataprotection.ie/contact
          </span>
          ) or the UK’s ICO (on
        </p>
        <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          <a
            href="https://ico.org.uk/make-a-complaint/"
            dummy="s2"
            target="_blank"
            rel="noreferrer"
          >
            +44 303 123 1113 or via{" "}
          </a>
          <span dummy=" color: #0462C1; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt;">
            https://ico.org.uk/make-a-complaint/
          </span>
          ), or to the supervisory authority where you are located.
        </p>
      </li>
      <li data-list-text="10.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          10. DATA SECURITY
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          We use certain physical, managerial, and technical safeguards that are
          designed to improve the integrity and security of Personal Information
          that we collect and maintain. However, the transfer of Personal
          Information through the internet will carry its own inherent risks and
          we do not guarantee the security of your data transmitted through the
          internet. You make any such transfer at your own risk.
        </p>
        <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          The Website and Service may provide features or links to websites and
          services provided by third parties. Any information you provide on
          Apps, third-party websites or services is provided directly to the
          operators of such websites or services and is subject to their
          policies governing privacy and security, even if accessed via our
          Website or in connection with our Service.
        </p>
      </li>
      <li data-list-text="11.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          11. CROSS-BORDER DATA TRANSFERS
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          To facilitate our global operations, Intertru, Inc. may transfer,
          store and process your operations with our partners and service
          providers based outside of the country in which you are based. Laws in
          those countries may differ from the laws applicable to your country of
          residence. Where we transfer, store and process your Personal
          Information outside of the EEA or the UK we will ensure that the
          appropriate safeguards are in place to ensure an adequate level of
          protection such as through acceding to the Standard Contractual
          Clauses. Further details regarding the relevant safeguards can be
          obtained from us on request.
        </p>
      </li>
      <li data-list-text="12.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          12. CHANGES
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Where required, we will update this Policy from time to time. When we
          do so, we will make it available on this page and indicate the date of
          the latest revision. Please check this page frequently to see any
          updates or changes to this Policy.
        </p>
      </li>
      <li data-list-text="13.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          13. ABOUT US
        </h2>
      </li>
    </ol>
    <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      <a
        href="mailto:support@intertru.ai"
        dummy="s2"
        target="_blank"
        rel="noreferrer"
      >
        If you have any questions, comments or concerns about our Privacy
        Policy, you may contact us by email at{" "}
      </a>
      support@intertru.ai attn: Privacy Officer or by mail to:
    </p>
    <p dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Intertru, Inc.
    </p>
    <p dummy="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Attn: Privacy Officer
    </p>
    <p dummy="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      4590 Macarthur Boulevard Newport Beach, CA, 92660
    </p>
  </>
);

export const termsContent = (
  <>
    <h1 dummy="padding-left: 155pt;text-indent: 0pt;line-height: 29pt;text-align: center;">
      Terms of Service
    </h1>
    <p dummy="text-indent: 0pt;text-align: left;">
      <br />
    </p>
    <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 167%;text-align: left;">
      Effective September 14, 2022 [See also Privacy Policy]
    </p>
    <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      Welcome, and thank you for your interest in Intertru, Inc.. (“
      <b>Intertru</b>,” “<b>we</b>,” or “<b>us</b>”) and our Intertru Voice and
      Video Interviewing tool, along with our website at Intertru, Inc. along
      with our related networks, applications, mobile applications, and other
      services provided by us (collectively, the “<b>Service</b>
      ”). These Terms of Service are a legally binding contract between you and
      Intertru, Inc. regarding your use of the Service. As used herein, “
      <b>you</b>” or “<b>your</b>” means any entity, university, organization,
      or company accessing or using the Service (“
      <b>Organization</b>”) as well as any individual end user accessing and
      using the Service, as applicable and hereby agreeing to these Terms.
    </p>
    <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      PLEASE READ THE FOLLOWING TERMS CAREFULLY.
    </h2>
    <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      BY CLICKING <span dummy="p">“</span>I ACCEPT
      <span dummy="p">,” </span>OR BY DOWNLOADING, INSTALLING, OR OTHERWISE
      ACCESSING OR USING THE SERVICE
      <span dummy="p">
        , YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO
        YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS
        AND CONDITIONS, INCLUDING INTERTRU, INC. PRIVACY POLICY (TOGETHER, THESE
        “
      </span>
      TERMS
      <span dummy="p">
        ”). IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN YOU DO
        NOT HAVE OUR PERMISSION TO USE THE SERVICE. YOUR USE OF THE SERVICE, AND
        INTERTRU, INC. PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT
        BY INTERTRU, INC AND BY YOU TO BE BOUND BY THESE TERMS.
      </span>
    </h2>
    <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      ARBITRATION NOTICE.{" "}
      <span dummy="p">
        Except for certain kinds of disputes described in Section 19, you agree
        that disputes arising under these Terms will be resolved by binding,
        individual arbitration, and BY ACCEPTING THESE TERMS, YOU AND INTERTRU,
        INC ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN
        ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR
      </span>
    </h2>
    <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      RIGHT TO GO TO COURT to assert or defend your rights under this contract
      (except for matters that may be taken to small claims court). Your rights
      will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury. (See
      Section 19.)
    </p>
    <ol id="l1">
      <li data-list-text="1.">
        <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Intertru, Inc. Service Overview.{" "}
          <span dummy="p">
            The Service is a cognitive intelligence platform to ensure
            interviewing consistency and accountability. You may choose whether
            to use the free version of the Service (“
          </span>
          Free Service
          <span dummy="p">
            ”) or the subscription-based paid version of the Service for which
            you may be required to pay fees (the “
          </span>
          Paid Service<span dummy="p">”).</span>
        </h2>
      </li>
      <li data-list-text="2.">
        <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Eligibility.{" "}
          <span dummy="p">
            You must be at least 13 years old to use the Service. By agreeing to
            these Terms, you represent and warrant to us that: (a) you are at
            least 13 years old; (b) you have not previously been suspended or
            removed from the Service; and (c) your registration and your use of
            the Service is in compliance with any and all applicable laws and
            regulations. If you are an Organization, the individual accepting
            these Terms on your behalf represents and warrants that they have
            authority to bind you to these Terms and you agree to be bound by
            these Terms.
          </span>
        </h2>
      </li>
      <li data-list-text="3.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Accounts and Registration.
        </h2>
        <ol id="l2">
          <li data-list-text="3.1">
            <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              General
              <span dummy="p">
                . To access most features of the Service, you must register for
                an account. When you register for an account, you may be
                required to provide us with some information about yourself,
                such as your
              </span>
            </h2>
            <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              name, email address, phone number, or other contact information.
              If you wish to designate individual users to access and use the
              Service under your account (such users, “<b>Authorized Users</b>
              <a
                href="mailto:support@intertru.ai"
                dummy="a"
                target="_blank"
                rel="noreferrer"
              >
                ”), you may also be required to provide us with some information
                about such Authorized Users. You agree that the information you
                provide to us is accurate and that you will keep it accurate and
                up-to-date at all times, and that you have all necessary rights
                and approvals to provide such information. When you register,
                you will be asked to provide a password. You are responsible for
                maintaining the confidentiality of your account and password,
                and you accept responsibility for all activities that occur
                under your account. If you believe that your account is no
                longer secure, then you must immediately notify us at{" "}
              </a>
              <a
                href="mailto:support@intertru.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@intertru.ai.
              </a>
            </p>
          </li>
          <li data-list-text="3.2">
            <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
              Authorized Users<span dummy="p">.</span>
            </h2>
            <ol id="l3">
              <li data-list-text="a.">
                <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  You are responsible for: (i) identifying and authenticating
                  all of your Authorized Users (ii) approving access by your
                  Authorized Users to the Service and designating appropriate
                  access controls in connection with your account, (iii)
                  controlling against unauthorized access by your Authorized
                  Users, and
                </p>
                <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  (iv) all activities that occur under your Authorized Users’
                  usernames, passwords or accounts as a result of your
                  Authorized Users’ access and use of the Service.
                </p>
              </li>
              <li data-list-text="b.">
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  If you received your credentials to access and use the Service
                  (including certain features thereof) from your Organization or
                  otherwise are accessing the Service under your Organization’s
                  account, you acknowledge and agree that your access to the
                  Service as provided by your Organization is subject to the
                  agreement between Intertru, Inc. and your Organization with
                  respect to the Service. You also acknowledge that your
                  Organization may terminate the access to the Service that they
                  provide to you at any time. Intertru, Inc. will have no
                  liability to you in connection with any such removal or
                  termination.
                </p>
              </li>
              <li data-list-text="c.">
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  If the domain of the email address associated with your
                  account is owned by an Organization and was assigned to you as
                  an employee, contractor or member of the Organization (such as
                  yourname@youremployer.com), you acknowledge and agree that we
                  may identify your account to the Organization, migrate your
                  account to the Organization’s account, and/or restrict or
                  terminate access to your account. Intertru, Inc. will have no
                  liability to you in connection with any such disclosure,
                  restriction, or termination.
                </p>
              </li>
              <li data-list-text="d.">
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  If you join an Intertru Business account, you must use it in
                  compliance with your organization’s terms and policies. Please
                  note that Intertru Business accounts are subject to your
                  Organization’s control. Your administrators may be able to
                  access, disclose, restrict, or remove information in or from
                  your Intertru Business account. They may also be able to
                  restrict or terminate your access to an Intertru Business
                  account. If you convert an existing Intertru Individuals
                  account into part of an Intertru Business account, your
                  administrators may prevent you from later disassociating your
                  account from the Intertru Business account.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li data-list-text="4.">
        <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          General Payment Terms.{" "}
          <span dummy="p">
            Certain features of the Service may require you to pay fees. Before
            you pay any fees, you will have the opportunity to review and accept
            the fees that you will be charged. All fees are in U.S. Dollars and
            are non-refundable.
          </span>
        </h2>
        <ol id="l4">
          <li data-list-text="4.1">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Price.{" "}
              <span dummy="p">
                Intertru, Inc. reserves the right to determine pricing for the
                Service. Intertru, Inc. will make reasonable efforts to keep
                pricing information published on the website up to date. We
                encourage you to check our website periodically for current
                pricing information. Intertru, Inc. may change the fees for
              </span>
            </h2>
            <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              any feature of the Service, including additional fees or charges,
              if Intertru, Inc. gives you advance notice of changes before they
              apply. Intertru, Inc., at its sole discretion, may make
              promotional offers with different features and different pricing
              to any of Intertru, Inc. customers. These promotional offers,
              unless made to you, will not apply to your offer or these Terms.
            </p>
          </li>
          <li data-list-text="4.2">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Authorization.{" "}
              <span dummy="p">
                You authorize Intertru, Inc. and its third party payment
                processors to charge all sums for the orders that you make and
                any level of Service you select as described in these Terms or
                published by Intertru, Inc., including all applicable taxes, to
                the payment method specified in your account. If you pay any
                fees with a credit card, Intertru, Inc. or its third party
                payment processors may seek
              </span>
            </h2>
            <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              pre-authorization of your credit card account prior to your
              purchase to verify that the credit card is valid and has the
              necessary funds or credit available to cover your purchase. At its
              discretion Intertru may allow an organization to purchase the
              Service by issuing an invoice for payment that must be settled
              within 30 days of issuance.
            </p>
          </li>
          <li data-list-text="4.3">
            <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
              Subscription Service.
            </h2>
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              a. <b>General. </b>The Service may include automatically recurring
              payments for periodic charges (“
              <b>Subscription Service</b>”). If you activate a Subscription
              Service, you authorize Intertru, Inc. or its third party payment
              processors or Apple or Google if you subscribed directly through
              the mobile application to periodically charge, on a going-forward
              basis and until cancellation of either the recurring payments or
              your account, all accrued sums on or before the payment due date
              for the accrued sums, including all accrued sums for your
              Authorized Users. The “<b>Subscription Billing Date</b>” is the
              date when you purchase your first subscription to the Service. For
              more information on the “<b>Subscription Fee</b>”, please
            </p>
            <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              see https://intertru.ai/pricing. Your account will be charged
              automatically on the Subscription Billing Date all applicable fees
              and taxes for the next subscription period, which may be one month
              or one year depending on the selection you make on your account
              (such period, the “<b>Initial Subscription Period</b>”). At the
              end of the Initial Subscription Period, your subscription will
              automatically renew for a period equal to the Initial Subscription
              Period (such renewal period, the “
              <b>Renewal Subscription Period</b>
              <a
                href="mailto:support@intertru.ai"
                dummy="a"
                target="_blank"
                rel="noreferrer"
              >
                ”), unless you cancel your subscription or we terminate it. If
                you may add additional users to Teams accounts throughout the
                Subscription Period you will be charged a pro-rata fee for the
                time remaining to the renewal period. If you disable users to
                Teams or do not allocate users to a purchased Team user seat you
                will earn a corresponding pro-rata credit for the unused portion
                of the Subscription Period that may be applied to your fee for
                the subsequent Renewal Subscription Period. You must cancel your
                subscription before it renews in order to avoid billing of the
                next periodic Subscription Fee to your account. Intertru, Inc.
                or its third party payment processor will bill the periodic
                Subscription Fee to the payment method you provide to us during
                registration (or to a different payment method if you change
                your payment information). You may cancel the Subscription
                Service in the settings page for your account at Intertru, Inc.
                if you signed up directly via our website, or through settings
                in the Apple App Store, or Google Play Store if you subscribed
                directly in the mobile application or contacting us at:{" "}
              </a>
              <a
                href="mailto:support@intertru.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@intertru.ai.
              </a>
            </p>
          </li>
          <li data-list-text="4.4">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Delinquent Accounts.{" "}
              <span dummy="p">
                Intertru, Inc. may suspend or terminate access to the Service,
                including fee-based portions of the Service, for any account for
                which any sums are due but unpaid. Without
              </span>
            </h2>
            <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              limiting the generality of the foregoing, if you have been
              provided access to the Service (including certain features
              thereof) via your Organization and your Organization has not paid
              all sums due, we may suspend or terminate your access to the
              Service. In addition to the amount due for the Service, a
            </p>
            <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              delinquent account may be charged with fees or charges that are
              incidental to any chargeback or collection of any the unpaid
              amount, including collection fees.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="5.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Licenses
        </h2>
        <ol id="l5">
          <li data-list-text="5.1">
            <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Limited License.{" "}
              <span dummy="p">
                Subject to your complete and ongoing compliance with these
                Terms, Intertru, Inc. grants you, solely for your personal,
                non-commercial use, a limited, non-exclusive, non-transferable,
                non-sublicensable, revocable license to: (a) install and use one
                object code copy of any mobile application associated with the
                Service obtained from a legitimate marketplace on a mobile
                device that you own or control; and (b) access and use the
                Service.
              </span>
            </h2>
          </li>
          <li data-list-text="5.2">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              License Restrictions.{" "}
              <span dummy="p">
                Except and solely to the extent such a restriction is
                impermissible under applicable law, you may not: (a) reproduce,
                distribute, publicly display, or publicly perform the Service;
              </span>
            </h2>
            <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              (b) make modifications to the Service; (c) interfere with or
              circumvent any feature of the Service, including any security or
              access control mechanism; (d) access or use the Service in
              violation of any usage restrictions or other limitations
              associated with the level of Service you (or your Organization)
              have selected to access and purchased, if applicable. If you are
              prohibited under applicable law from using the Service, you may
              not use it.
            </p>
          </li>
          <li data-list-text="5.3">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Feedback
              <span dummy="p">
                . If you choose to provide input and suggestions regarding
                problems with or proposed modifications or improvements to the
                Service (“
              </span>
              Feedback
              <span dummy="p">
                ”), then you hereby grant Intertru, Inc. an unrestricted,
                perpetual, irrevocable, non-exclusive, fully-paid, royalty-free
                right to exploit the Feedback in any manner and for any purpose,
                including to improve the Service and create other products and
                services.
              </span>
            </h2>
          </li>
        </ol>
      </li>
      <li data-list-text="6.">
        <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Ownership; Proprietary Rights.{" "}
          <span dummy="p">
            The Service is owned and operated by Intertru, Inc. The visual
            interfaces, graphics, design, compilation, information, data,
            computer code (including source code or object code), products,
            software, services, and all other elements of the Service (“
          </span>
          Materials
          <span dummy="p">
            ”) provided by Intertru, Inc. are protected by intellectual property
            and other laws. All Materials included in the Service are the
            property of Intertru, Inc. or its third party licensors. Except as
            expressly authorized by Intertru, Inc., you may not make use of the
            Materials. Intertru, Inc. reserves all rights to the Materials not
            granted expressly in these Terms.
          </span>
        </h2>
      </li>
      <li data-list-text="7.">
        <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Government Rights.{" "}
          <span dummy="p">
            Intertru, Inc. provides the Service, including any related software,
            data, and technology, for ultimate government end use solely in
            accordance with the following: The government hereby agrees that the
            Service qualify as “commercial” computer software. Government
            technical data and software rights related to the Service include
            only those rights customarily provided to the public as defined in
            these Terms. This customary commercial license is provided in
            accordance with FAR 12.211 (Technical Data) and FAR 12.212
            (Software) and, for Department of Defense transactions, DFAR
          </span>
        </h2>
        <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          252.227-7015 (Technical Data – Commercial Items) and DFAR 227.7202-3
          (Rights in Commercial Computer Software or Computer Software
          Documentation). If a government agency has a need for rights not
          granted under these Terms, it must negotiate with Intertru, Inc. to
          determine if there are acceptable terms for granting those rights, and
          a mutually acceptable written addendum specifically granting those
          rights must be included in any applicable agreement.
        </p>
      </li>
      <li data-list-text="8.">
        <h2 dummy="padding-top: 2pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Third Party Terms
        </h2>
        <ol id="l6">
          <li data-list-text="8.1">
            <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Third Party Services and Linked Websites
              <span dummy="p">
                . Intertru, Inc. may provide tools through the Service that
                enable you to export information, including User Content, to
                third party services, including through features that may allow
                you to link your account on Intertru, Inc. with an account on
                the third party service, such as Twitter or Facebook, or through
                our implementation of third party buttons (such as “like” or
                “share” buttons). By using one of these tools, you agree that
                Intertru, Inc. may transfer that information to the applicable
                third party service. Third party services are not under
                Intertru, Inc. control, and, to the fullest extent permitted by
                law, Intertru, Inc. is not responsible for any third party
                service’s use of your exported information. The Service may also
                contain links to third party websites. Linked websites are not
                under Intertru, Inc. control, and Intertru, Inc. is not
                responsible for their content.
              </span>
            </h2>
          </li>
        </ol>
      </li>
      <li data-list-text="9.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          User Content
        </h2>
        <ol id="l7">
          <li data-list-text="9.1">
            <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              User Content Generally
              <span dummy="p">
                . Certain features of the Service may permit users to upload
                content to the Service (including by syncing your account with
                Third Party Accounts as further described in Section 9.1),
                including voice recordings, video recordings, audio recordings,
                data, text, photographs, and other types of works (“
              </span>
              User Content
              <span dummy="p">
                ”), and to otherwise publish User Content on the Service. To the
                extent you desire to use the Service in connection with
                materials or information located on your Zoom, Teams, Dropbox,
                or other third-party accounts (collectively, “
              </span>
              Third Party Accounts
              <span dummy="p">
                ”), you hereby grant Intertru, Inc. permission to access the
                Third Party Accounts in connection with Intertru, Inc. provision
                of the Service. As between you and Intertru, Inc., you retain
                any copyright and other proprietary rights that you may hold in
                the User Content that you post to the Service.
              </span>
            </h2>
          </li>
          <li data-list-text="9.2">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Voice and Video Recordings
              <span dummy="p">
                . The Service may provide a feature that allows you to record
                individual conversations and/or upload recorded conversations.
                The laws regarding the notice and notification requirements of
                such recorded conversations vary by location. You acknowledge
                and agree that you are solely responsible for providing any
                notices to, and obtaining consent from, individuals in
                connection with any recordings as required under applicable law.
              </span>
            </h2>
          </li>
          <li data-list-text="9.3">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Limited License Grant to Intertru, Inc.{" "}
              <span dummy="p">
                Customer retains all ownership rights to the User Content
                processed using the service. You grant Intertru, Inc. a
                worldwide, non-exclusive, royalty-free, fully paid right and
                license (with the right to sublicense) to host, store, transfer,
                display, perform, reproduce, modify, export, process, transform,
                and distribute your User Content, in whole or in part, in any
                media formats and through any media channels now known or
                hereafter developed in a manner that is under your control.
                Customer may delete User Content from their account where it may
                be stored in a separate trash folder provided with the service
                for a specified period unless they choose to delete it from the
                trash folder. Once it has been permanently deleted from the user
                account either by direct action by the user or on expiration of
                the specified retention period in the trash folder, no record of
                the User Content is retained and the User Content cannot be
                recreated by the service.
              </span>
            </h2>
          </li>
          <li data-list-text="9.4">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              License Grant to Users
              <span dummy="p">
                . By providing User Content to or via the Service to other users
                of the Service, you grant those users a non-exclusive license to
                access, use, modify, and distribute that User Content as
                permitted by these Terms and the functionality of the Service.
              </span>
            </h2>
          </li>
          <li data-list-text="9.5">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              Access to User Content and Results
              <span dummy="p">
                . Intertru, Inc. may permit you to share certain User Content or
                the results of processing User Content with other users of the
                Service, share User Content or the results
              </span>
            </h2>
            <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              of processing User Content outside of the Service, or even make
              certain User Content or the results of processing User Content
              public for all (even non-Service users) to view. You acknowledge
              and agree that, although Intertru, Inc. may provide certain
              features intended to allow you to restrict access of some User
              Content you create from others, Intertru, Inc. does not guarantee
              that such User Content or any results of processing User Content
              will never be accessible by others. To the fullest extent
              permitted by law, Intertru, Inc. is not responsible for the use of
              any User Content or results of processing User Content by users or
              non-users of the Service or any third parties.
            </p>
          </li>
          <li data-list-text="9.6">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              User Content Representations and Warranties
              <span dummy="p">
                . Intertru, Inc. disclaims any and all liability in connection
                with User Content. You are solely responsible for your User
                Content and the consequences of providing User Content via the
                Service. By providing User Content via the Service, you affirm,
                represent, and warrant that:
              </span>
            </h2>
            <ol id="l8">
              <li data-list-text="a.">
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  you are the creator and owner of the User Content, or have the
                  necessary licenses, rights, consents, and permissions to
                  authorize Intertru, Inc. and users of the Service to use and
                  distribute your User Content as necessary to exercise the
                  licenses granted by you in this Section, in the manner
                  contemplated by Intertru, Inc., the Service, and these Terms;
                </p>
              </li>
              <li data-list-text="b.">
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  your User Content, and the use of your User Content as
                  contemplated by these Terms, does not and will not: (i)
                  infringe, violate, or misappropriate any third party right,
                  including any copyright, trademark, patent, trade secret,
                  moral right, privacy right, right of publicity, or any other
                  intellectual property or proprietary right; (ii) slander,
                  defame, libel, or invade the right of privacy, publicity or
                  other property rights of any other person; or (iii) cause
                  Intertru, Inc. to violate any law or regulation; and
                </p>
              </li>
              <li data-list-text="c.">
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  your User Content could not be deemed by a reasonable person
                  to be objectionable, profane, indecent, pornographic,
                  harassing, threatening, embarrassing, hateful, or otherwise
                  inappropriate.
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="9.7">
            <h2 dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              User Content Disclaimer
              <span dummy="p">
                . We are under no obligation to edit or control User Content
                that you or other users post or publish, and will not be in any
                way responsible or liable for User Content. Intertru, Inc. may,
                however, at any time and without prior notice, screen, remove,
                edit, or block any User Content that in our sole judgment
                violates these Terms or is otherwise objectionable. You
                understand that when using the Service you will be exposed to
                User Content from a variety of sources and acknowledge that User
                Content may be inaccurate, offensive, indecent, or
                objectionable. You agree to waive, and do waive, any legal or
                equitable right or remedy you have or may have against Intertru,
                Inc. with respect to User Content. If notified by a user or
                content owner that User Content allegedly does not conform to
                these Terms, we may investigate the allegation and determine in
                our sole discretion whether to remove the User Content, which we
                reserve the right to do at any time and without notice. For
                clarity, Intertru, Inc. does not permit copyright-infringing
                activities on the Service.
              </span>
            </h2>
          </li>
          <li data-list-text="9.8">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Monitoring Content
              <span dummy="p">
                . Intertru, Inc. does not control and does not have any
                obligation to monitor: (a) User Content; (b) any content made
                available by third parties; or (c) the use of the Service by its
                users. Intertru, Inc. is not responsible for the use of any User
                Content by users or any third parties. You acknowledge and agree
                that Intertru, Inc. reserves the right to, and may from time to
                time, monitor any and all information transmitted or received
                through the Service for operational and other purposes. If at
                any time Intertru, Inc. chooses to monitor the content,
                Intertru, Inc. still assumes no responsibility or liability for
                content or any loss or damage incurred as a result of the use of
                content.
              </span>
            </h2>
          </li>
          <li data-list-text="9.9">
            <h2 dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Machine Learning
              <span dummy="p">
                . Intertru, Inc. shall have the right to collect and analyze
                data and other information relating to the provision, use and
                performance of various aspects of the Service and related
                systems and technologies (“
              </span>
              Usage Data
              <span dummy="p">
                ”). The Service may be implemented using machine learning
                systems with features and implementations designed to generate
                statistics, calibrate data models, and improve algorithms in the
                course of processing User Content and Usage Data (“
              </span>
              Machine Learning
              <span dummy="p">
                ”). Nothing in these Terms prohibits Company from using such
                Machine Learning for testing, tuning, optimizing, validating, or
                otherwise enhancing the analytics, models, or algorithms
                underlying the Service. Nothing in these Terms gives you any
                rights in or to any part of the Service or the Machine Learning
                generated by Company or the Machine Learning generated in the
                course of providing the Service.
              </span>
            </h2>
          </li>
        </ol>
      </li>
      <li data-list-text="10.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Communications.
        </h2>
        <ol id="l9">
          <li data-list-text="10.1">
            <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Text Messaging.{" "}
              <span dummy="p">
                Intertru, Inc. and those acting on our behalf may send you text
                (SMS) messages at the phone number you provide us. These
                messages may be used for two factor authentication.. Standard
                data and message rates may apply whenever you send or receive
                such messages, as specified by your carrier and Intertru, Inc.
                is not responsible for these charges.
              </span>
            </h2>
          </li>
          <li data-list-text="10.2">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Push Notifications.{" "}
              <span dummy="p">
                When you install our app on your mobile device, you agree to
                receive push notifications, which are messages an app sends you
                on your mobile device when the app is not on. You can turn off
                notifications by visiting your mobile device’s “settings” page.
              </span>
            </h2>
          </li>
          <li data-list-text="10.3">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Email.{" "}
              <span dummy="p">
                We may send you emails in the course of delivering the service
                to notify when User Content has been processed, shared with you
                or for other operational purposes. We may also send emails
                concerning our products and services, as well as those of third
                parties. You may opt out of promotional emails by following the
                unsubscribe instructions in the promotional email itself.
              </span>
            </h2>
          </li>
        </ol>
      </li>
      <li data-list-text="11.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Prohibited Conduct.{" "}
          <span dummy="p">BY USING THE SERVICE YOU AGREE NOT TO:</span>
        </h2>
        <ol id="l10">
          <li data-list-text="a.">
            <p dummy="padding-top: 9pt;padding-left: 15pt;text-indent: -10pt;text-align: left;">
              use the Service for any illegal purpose or in violation of any
              local, state, national, or international law;
            </p>
          </li>
          <li data-list-text="b.">
            <p dummy="padding-top: 9pt;padding-left: 16pt;text-indent: -11pt;text-align: left;">
              use the Service or any portion thereof for the direct or indirect
              benefit of any third parties;
            </p>
          </li>
          <li data-list-text="c.">
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              use the Service in connection with any direct or indirect
              commercial purposes, including in connection with any paid
              transcription workflow or as a value-added component of a
              commercial product or service;
            </p>
          </li>
          <li data-list-text="d.">
            <p dummy="padding-top: 8pt;padding-left: 16pt;text-indent: -11pt;text-align: left;">
              harass, threaten, demean, embarrass, or otherwise harm any other
              user of the Service;
            </p>
          </li>
          <li data-list-text="e.">
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              violate, or encourage others to violate, any right of a third
              party (including by act or omission), including by infringing or
              misappropriating any third party intellectual property or
              proprietary right;
            </p>
          </li>
          <li data-list-text="f.">
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              interfere with security-related features of the Service, including
              by: (i) disabling or circumventing features that prevent or limit
              use or copying of any content; or (ii) reverse engineering or
              otherwise attempting to discover the source code of any portion of
              the Service except to the extent that the activity is expressly
              permitted by applicable law;
            </p>
          </li>
          <li data-list-text="g.">
            <p dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              interfere with the operation of the Service or any user’s
              enjoyment of the Service, including by: (i) uploading or otherwise
              disseminating any virus, adware, spyware, worm, or other malicious
              code; (ii) making any unsolicited offer or advertisement to
              another user of the Service; (iii) collecting personal
            </p>
            <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              information about another user or third party without consent; or
              (iv) interfering with or disrupting any network, equipment, or
              server connected to or used to provide the Service;
            </p>
          </li>
          <li data-list-text="h.">
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              perform any fraudulent activity including impersonating any person
              or entity, claiming a false affiliation, accessing any other
              Service account without permission, or falsifying your age or date
              of birth;
            </p>
          </li>
          <li data-list-text="i.">
            <p dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              sell or otherwise transfer the access granted under these Terms or
              any Materials (as defined in Section 6) or any right or ability to
              view, access, or use any Materials; or
            </p>
          </li>
          <li data-list-text="j.">
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              attempt to do any of the acts described in this Section 11 or
              assist or permit any person in engaging in any of the acts
              described in this Section 11.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="12.">
        <h2 dummy="padding-top: 8pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Digital Millennium Copyright Act
        </h2>
        <ol id="l11">
          <li data-list-text="12.1">
            <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              DMCA Notification
              <span dummy="p">
                . We comply with the provisions of the Digital Millennium
                Copyright Act applicable to Internet service providers (17
                U.S.C. §512, as amended). If you have an intellectual property
                rights-related complaint about material posted on the Service,
                you may contact our Designated Agent at the following address:
              </span>
            </h2>
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
              Intertru, Inc.
            </p>
            <p dummy="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              ATTN: Legal Department (Copyright Notification) 590 Macarthur
              Boulevard
            </p>
            <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              <a
                href="mailto:support@intertru.ai"
                dummy="a"
                target="_blank"
                rel="noreferrer"
              >
                Newport Beach, CA, 92660 Email:{" "}
              </a>
              <a
                href="mailto:support@intertru.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@intertru.ai
              </a>
            </p>
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Any notice alleging that materials hosted by or distributed
              through the Service infringe intellectual property rights must
              include the following information:
            </p>
            <ol id="l12">
              <li data-list-text="a.">
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  an electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright or other right
                  being infringed;
                </p>
              </li>
              <li data-list-text="b.">
                <p dummy="padding-top: 8pt;padding-left: 16pt;text-indent: -11pt;text-align: left;">
                  a description of the copyrighted work or other intellectual
                  property that you claim has been infringed;
                </p>
              </li>
              <li data-list-text="c.">
                <p dummy="padding-top: 9pt;padding-left: 14pt;text-indent: -9pt;text-align: left;">
                  a description of the material that you claim is infringing and
                  where it is located on the Service;
                </p>
              </li>
              <li data-list-text="d.">
                <p dummy="padding-top: 9pt;padding-left: 16pt;text-indent: -11pt;text-align: left;">
                  your address, telephone number, and email address;
                </p>
              </li>
              <li data-list-text="e.">
                <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  a statement by you that you have a good faith belief that the
                  use of the materials on the Service of which you are
                  complaining is not authorized by the copyright owner, its
                  agent, or the law; and
                </p>
              </li>
              <li data-list-text="f.">
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                  a statement by you that the above information in your notice
                  is accurate and that, under penalty of perjury, you are the
                  copyright or intellectual property owner or authorized to act
                  on the copyright or intellectual property owner’s behalf.
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="12.2">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Repeat Infringers.{" "}
              <span dummy="p">
                Intertru, Inc. will promptly terminate the accounts of users
                that are determined by Intertru, Inc. to be repeat infringers.
              </span>
            </h2>
          </li>
        </ol>
      </li>
      <li data-list-text="13.">
        <h2 dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Modification of these Terms.{" "}
          <span dummy="p">
            We reserve the right to change these Terms on a going-forward basis
            at any time. Please check these Terms periodically for changes. If a
            change to these Terms materially modifies your rights or
            obligations, we may require that you accept the modified Terms in
            order to continue to use the Service. Material modifications are
            effective upon your acceptance of the modified
          </span>
        </h2>
        <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Terms. Immaterial modifications are effective upon publication. Except
          as expressly permitted in this Section 13, these Terms may be amended
          only by a written agreement signed by authorized representatives of
          the parties to these Terms. Disputes arising under these Terms will be
          resolved in accordance with the version of these Terms that was in
          effect at the time the dispute arose.
        </p>
      </li>
      <li data-list-text="14.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Term, Termination and Modification of the Service
        </h2>
        <ol id="l13">
          <li data-list-text="14.1">
            <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Term.{" "}
              <span dummy="p">
                These Terms are effective beginning when you accept the Terms or
                first download, install, access, or use the Service, and ending
                when terminated as described in Section 14.2.
              </span>
            </h2>
          </li>
          <li data-list-text="14.2">
            <h2 dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Termination
              <a
                href="mailto:support@intertru.ai"
                dummy="a"
                target="_blank"
                rel="noreferrer"
              >
                . If you violate any provision of these Terms, your
                authorization to access the Service and these Terms
                automatically terminate. In addition, Intertru, Inc. may, at its
                sole discretion, terminate these Terms or your account on the
                Service, or suspend or terminate your access to the Service, at
                any time for any reason or no reason, with or without notice.
                You may terminate your account and these Terms at any time by
                contacting customer service at{" "}
              </a>
              <a
                href="mailto:support@intertru.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@intertru.ai.
              </a>
            </h2>
          </li>
          <li data-list-text="14.3">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Effect of Termination.{" "}
              <span dummy="p">
                Upon termination of these Terms: (a) your license rights will
                terminate and you must immediately cease all use of the Service;
                (b) you will no longer be authorized to access your account or
                the Service; (c) you must pay Intertru, Inc. any unpaid amount
                that was due prior to termination; and (d) all payment
                obligations accrued prior to termination and Sections [5.3, 6,
                7, 10, 14.3, 16, 16, 17, 18, 19, and 20] will survive.
              </span>
            </h2>
          </li>
          <li data-list-text="14.4">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Modification of the Service.{" "}
              <span dummy="p">
                Intertru, Inc. reserves the right to modify or discontinue the
                Service at any time (including by limiting or discontinuing
                certain features of the Service), temporarily or permanently,
                without notice to you. Intertru, Inc. will have no liability for
                any change to the Service or any suspension or termination of
                your access to or use of the Service.
              </span>
            </h2>
          </li>
        </ol>
      </li>
      <li data-list-text="15.">
        <h2 dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Indemnity.{" "}
          <span dummy="p">
            To the fullest extent permitted by law, you are responsible for your
            use of the Service, and you will defend and indemnify Intertru, Inc.
            and its officers, directors, employees, consultants, affiliates,
            subsidiaries and agents (together, the “
          </span>
          Intertru, Inc. Entities
          <span dummy="p">
            ”) from and against every claim brought by a third party, and any
            related liability, damage, loss, and expense, including reasonable
            attorneys’ fees and costs, arising out of or connected with: (a)
            your unauthorized use of, or misuse of, the Service; (b) your
            violation of any portion of these Terms, any representation,
            warranty, or agreement referenced in these Terms, or any applicable
            law or regulation; (c) your violation of any third party right,
            including any intellectual property right or publicity,
            confidentiality, other property, or privacy right; (d) the nature of
            content of Data processed by the Service; or (e) any dispute or
            issue between you and any third party. We reserve the right, at our
            own expense, to assume the exclusive defense and control of any
            matter otherwise subject to indemnification by you (without limiting
            your indemnification obligations with respect to that matter), and
            in that case, you agree to cooperate with our defense of those
            claims.
          </span>
        </h2>
      </li>
      <li data-list-text="16.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Disclaimers; No Warranties
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          THE SERVICE AND ALL MATERIALS AND CONTENT AND TRANSCRIPTIONS AVAILABLE
          THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE”
          BASIS. INTERTRU, INC. DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS AND
          CONTENT AND TRANSCRIPTIONS AVAILABLE THROUGH THE SERVICE, INCLUDING:
          (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE, QUIET
        </p>
        <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF
          COURSE OF DEALING, USAGE, OR TRADE. INTERTRU, INC. DOES NOT WARRANT
          THAT THE SERVICE OR ANY PORTION OF THE SERVICE (OR YOUR ACCESS
          THERETO), OR ANY DATA, MATERIALS OR CONTENT OFFERED THROUGH OR
          PROCESSED BY THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF
          ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND INTERTRU, INC. DOES
          NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED. INTERTRU, INC
          IS NOT RESPONSIBLE FOR THE FAILURE TO STORE OR MAINTAIN ANY USER DATA,
          CONTENT OR TRANSCRIPTIONS, USER COMMUNCIATIONS, ACCOUNT INFORMATION,
          OR PERSONAL SETTINGS. INTERTRU, INC. MAKES NO WARRANTY ABOUT THE
          COMPLETENESS OR ACCURACY OF THE TRANSCRIPTION.
        </p>
        <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM THE SERVICE OR INTERTRU, INC. ENTITIES OR ANY MATERIALS OR
          CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY
          REGARDING ANY OF THE INTERTRU, INC. ENTITIES OR THE SERVICE THAT IS
          NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY
          DAMAGE THAT MAY RESULT FROM THE SERVICE AND YOUR DEALING WITH ANY
          OTHER SERVICE USER. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION
          OF THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT
          RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER
          SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY
          LOSS OF, USE OR DISCLOSURE OF DATA, INCLUDING USER CONTENT.
        </p>
        <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION APPLY TO
          THE FULLEST EXTENT
        </p>
        <p dummy="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          PERMITTED BY LAW. Intertru, Inc. does not disclaim any warranty or
          other right that Intertru, Inc. is prohibited from disclaiming under
          applicable law.
        </p>
      </li>
      <li data-list-text="17.">
        <h2 dummy="padding-top: 8pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Limitation of Liability
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE INTERTRU,
          INC. ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF
          PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR
          RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR
          USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER
          BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR
          ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY INTERTRU, INC. ENTITY
          HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
        </p>
        <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          EXCEPT AS PROVIDED IN SECTION 19.5 AND TO THE FULLEST EXTENT PERMITTED
          BY LAW, THE AGGREGATE LIABILITY OF THE INTERTRU, INC. ENTITIES TO YOU
          FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY
          INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE
          TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE
          GREATER OF:
        </p>
        <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          (A) THE AMOUNT YOU HAVE PAID TO INTERTRU, INC. FOR ACCESS TO AND USE
          OF THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE
          GIVING RISE TO CLAIM; OR (B) $100.
        </p>
        <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF
          LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS
          INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER
          THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF
          THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE
          AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE
          LIMITATIONS IN THIS SECTION 17 WILL APPLY EVEN IF ANY LIMITED REMEDY
          FAILS OF ITS ESSENTIAL PURPOSE.
        </p>
      </li>
      <li data-list-text="18.">
        <h2 dummy="padding-top: 2pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Privacy and Information Security
        </h2>
        <ol id="l14">
          <li data-list-text="18.1">
            <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Privacy Policy
              <span dummy="p">
                . You acknowledge and agree that except as described in these
                Terms, any User Content, data, recordings, and information you
                enter into or upload to the Service or that we collect in
                connection with the Service (“
              </span>
              Data
              <span dummy="p">
                ”) will be processed as described in the Intertru, Inc. Privacy
                Policy. Please read the Privacy Policy carefully.
              </span>
            </h2>
          </li>
          <li data-list-text="18.2">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Data Processing
              <span dummy="p">
                . To the extent Data falls within the scope of the General Data
                Protection Regulation or the United Kingdom General Data
                Protection Regulation, the terms of the Data Processing
                Attachment in{" "}
              </span>
              Appendix 1 <span dummy="p">of these Terms (“</span>DPA
              <span dummy="p">
                ”) apply to the processing of any Customer Personal Data (as
                defined in the DPA). To the extent Data falls within the scope
                of the California Consumer Privacy Act of 2018, the terms of the
                California Data Processing Attachment in Appendix 6 of these
                Terms (“CCPA DPA”) apply to the processing of any Customer
                Personal Information (as defined in the CCPA DPA).
              </span>
            </h2>
          </li>
          <li data-list-text="18.3">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Data
              <span dummy="p">
                . As between you and Intertru, Inc., Data that you enter into or
                upload to the Service is and will remain owned by you. You
                hereby grant Intertru, Inc. the right to collect, process,
                transmit, store, use, and disclose Data to provide the Service
                and as otherwise set forth in these Terms and the Intertru, Inc.
                Privacy Policy.
              </span>
            </h2>
          </li>
          <li data-list-text="18.4">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Use of Aggregated Data
              <span dummy="p">
                . You acknowledge and agree that Intertru, Inc. may collect,
                create, process, transmit, store, use, and disclose aggregated
                and/or deidentified data derived from Data or use of the
                Services (“
              </span>
              Aggregated Data
              <span dummy="p">
                ”) for its business purposes, including for machine learning and
                training, industry analysis, benchmarking, and analytics. All
                Aggregated Data will be in an aggregated and/or deidentified
                form only and will not identify you. Nothing in these Terms
                gives you any rights in or to any part of the Service or
                Aggregated Data.
              </span>
            </h2>
          </li>
          <li data-list-text="18.5">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Compliance
              <span dummy="p">
                . You are solely responsible (a) for Data as entered into,
                supplied, accessed, or used by you and (b) for complying with
                any privacy and data protection laws and regulations applicable
                to Data or your use of the Service. You represent and warrant
                that you have obtained and will maintain all rights, consents,
                and authorizations required to grant Intertru, Inc. the rights
                and licenses set forth in Section 18 and to enable Intertru,
                Inc. to exercise its rights under the same without violation or
                infringement of the rights of any third party.
              </span>
            </h2>
          </li>
          <li data-list-text="18.6">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Information Security
              <span dummy="p">
                . Intertru, Inc. will employ commercially reasonable security
                measures that are designed to protect Data in its possession or
                control against unlawful or unauthorized access, use,
                alteration, or disclosure.
              </span>
            </h2>
          </li>
        </ol>
      </li>
      <li data-list-text="19.">
        <h2 dummy="padding-top: 8pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Dispute Resolution and Arbitration
        </h2>
        <ol id="l15">
          <li data-list-text="19.1">
            <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Generally
              <span dummy="p">
                . In the interest of resolving disputes between you and
                Intertru, Inc. in the most expedient and cost effective manner,
                and except as described in Section 19.2 and 19.3, you and
                Intertru, Inc. agree that every dispute arising in connection
                with these Terms will be resolved by binding arbitration.
                Arbitration is less formal than a lawsuit in court. Arbitration
                uses a neutral arbitrator instead of a judge or jury, may allow
                for more limited discovery than in court, and can be subject to
                very limited review by courts. Arbitrators can award the same
                damages and relief that a court can award. This agreement to
                arbitrate disputes includes all claims arising out of or
                relating to any aspect of these Terms, whether based in
                contract, tort, statute, fraud, misrepresentation, or any other
                legal theory, and regardless of whether a claim arises during or
                after the termination of these Terms. YOU UNDERSTAND AND AGREE
              </span>
            </h2>
            <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              THAT, BY ENTERING INTO THESE TERMS, YOU AND INTERTRU, INC. ARE
              EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A
              CLASS ACTION.
            </p>
          </li>
          <li data-list-text="19.2">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Exceptions
              <span dummy="p">
                . Despite the provisions of Section 19.1, nothing in these Terms
                will be deemed to waive, preclude, or otherwise limit the right
                of either party to: (a) bring an individual action in small
                claims court; (b) pursue an enforcement action through the
                applicable federal, state, or local agency if that action is
                available; (c) seek injunctive relief in a court of law in aid
                of arbitration; or (d) to file suit in a court of law to address
                an intellectual property infringement claim.
              </span>
            </h2>
          </li>
          <li data-list-text="19.3">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Opt-Out
              <span dummy="p">
                . If you do not wish to resolve disputes by binding arbitration,
                you may opt out of the provisions of this Section 19 within 30
                days after the date that you agree to these Terms by sending a
                letter to Intertru, Inc., Attention: Legal
                Department-Arbitration Opt-Out, 4590 Macarthur Boulevard Newport
                Beach, CA, 92660 that specifies: your full legal name, the email
                address associated with your account on the Service, and a
                statement that you wish to opt out of arbitration (“
              </span>
              Opt-Out Notice
              <span dummy="p">
                ”). Once Intertru, Inc. receives your Opt-Out Notice, this
                Section 19 will be void and any action arising out of these
                Terms will be resolved as set forth in Section 20.2. The
                remaining provisions of these Terms will not be affected by your
                Opt-Out Notice.
              </span>
            </h2>
          </li>
          <li data-list-text="19.4">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Arbitrator
              <span dummy="p">
                . Any arbitration between you and Intertru, Inc. will be settled
                under the Federal Arbitration Act and administered by the
                American Arbitration Association (“
              </span>
              AAA
              <span dummy="p">
                ”) under its Consumer Arbitration Rules (collectively, “
              </span>
              AAA Rules
              <a
                href="http://www.adr.org/"
                dummy="a"
                target="_blank"
                rel="noreferrer"
              >
                ”) as modified by these Terms. The AAA Rules and filing forms
                are available online at{" "}
              </a>
              <span dummy=" color: #0462C1; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt;">
                www.adr.org
              </span>
              <span dummy="p">
                , by calling the AAA at 1-800-778-7879, or by contacting
                Intertru, Inc. The arbitrator has exclusive authority to resolve
                any dispute relating to the interpretation, applicability, or
                enforceability of this binding arbitration agreement.
              </span>
            </h2>
          </li>
          <li data-list-text="19.5">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Notice of Arbitration; Process
              <span dummy="p">
                . A party who intends to seek arbitration must first send a
                written notice of the dispute to the other party by certified
                U.S. Mail or by Federal Express (signature required) or, only if
                that other party has not provided a current physical address,
                then by electronic mail (“
              </span>
              Notice of Arbitration
              <span dummy="p">
                ”). Intertru, Inc. address for Notice is: Intertru, Inc., 4590
                Macarthur Boulevard
              </span>
            </h2>
            <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Newport Beach, CA, 92660. The Notice of Arbitration must: (a)
              describe the nature and basis of the claim or dispute; and (b) set
              forth the specific relief sought (“
              <b>Demand</b>”). The parties will make good faith efforts to
              resolve the claim directly, but if the parties do not reach an
              agreement to do so within 30 days after the Notice of Arbitration
              is received, you or Intertru, Inc. may commence an arbitration
              proceeding. All arbitration proceedings between the parties will
              be confidential unless otherwise agreed by the parties in writing.
              During the arbitration, the amount of any settlement offer made by
              you or Intertru, Inc. must not be disclosed to the arbitrator
              until after the arbitrator makes a final decision and award, if
              any. If the arbitrator awards you an amount higher than the last
              written settlement amount offered by Intertru, Inc. in settlement
              of the dispute prior to the award, Intertru, Inc. will pay to you
              the higher of:
            </p>
            <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">
              (i) the amount awarded by the arbitrator; or (ii) $10,000.
            </p>
          </li>
          <li data-list-text="19.6">
            <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Fees
              <span dummy="p">
                . If you commence arbitration in accordance with these Terms,
                Intertru, Inc. will reimburse you for your payment of the filing
                fee, unless your claim is for more than $10,000, in which case
                the payment of any fees will be decided by the AAA Rules. Any
                arbitration hearing will take place at a location to be agreed
                upon in Orange County, California, but if the claim is for
                $10,000 or less, you may choose whether the arbitration will be
                conducted: (a) solely on the basis of documents submitted to the
                arbitrator; (b) through a non-appearance based telephone
                hearing; or (c) by an in-person hearing as established by the
                AAA Rules in the county (or parish) of your billing address. If
                the arbitrator finds that either the
              </span>
            </h2>
            <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              substance of your claim or the relief sought in the Demand is
              frivolous or brought for an improper purpose (as measured by the
              standards set forth in Federal Rule of Civil Procedure 11(b), then
              the payment of all fees will be governed by the AAA Rules. In that
              case, you agree to reimburse Intertru, Inc. for all monies
              previously disbursed by it that are otherwise your obligation to
              pay under the AAA Rules. Regardless of the manner in which the
              arbitration is conducted, the arbitrator must issue a reasoned
              written decision sufficient to explain the essential findings and
              conclusions on which the decision and award, if any, are based.
              The arbitrator may make rulings and resolve disputes as to the
              payment and reimbursement of fees or expenses at any time during
              the proceeding and upon request from either party made within 14
              days of the arbitrator’s ruling on the merits.
            </p>
          </li>
          <li data-list-text="19.7">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              No Class Actions
              <span dummy="p">
                . YOU AND INTERTRU, INC. AGREE THAT EACH MAY BRING CLAIMS
                AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND
                NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
              </span>
            </h2>
            <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both
              you and Intertru, Inc. agree otherwise, the arbitrator may not
              consolidate more than one person’s claims, and may not otherwise
              preside over any form of a representative or class proceeding.
            </p>
          </li>
          <li data-list-text="19.8">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Modifications to this Arbitration Provision
              <span dummy="p">
                . If Intertru, Inc. makes any future change to this arbitration
                provision, other than a change to Intertru, Inc. address for
                Notice of Arbitration, you may reject the change by sending us
                written notice within 30 days of the change to Intertru, Inc.
                address for Notice of Arbitration, in which case your account
                with Intertru, Inc. will be immediately terminated and this
                arbitration provision, as in effect immediately prior to the
                changes you rejected will survive.
              </span>
            </h2>
          </li>
          <li data-list-text="19.9">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              Enforceability
              <span dummy="p">
                . If Section 19.7 or the entirety of this Section 19 is found to
                be unenforceable, or if Intertru, Inc. receives an Opt-Out
                Notice from you, then the entirety of this Section 19 will be
                null and void and, in that case, exclusive jurisdiction and
                venue described in Section 20.2 will govern any action arising
                out of or related to these Terms.
              </span>
            </h2>
          </li>
        </ol>
      </li>
      <li data-list-text="20.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: justify;">
          Miscellaneous
        </h2>
        <ol id="l16">
          <li data-list-text="20.1">
            <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              General Terms
              <span dummy="p">
                . These Terms, together with the Privacy Policy and any other
                agreements expressly incorporated by reference into these Terms,
                are the entire and exclusive understanding and agreement between
                you and Intertru, Inc. regarding your use of the Service. You
                may not assign or transfer these Terms or your rights under
                these Terms, in whole or in part, by operation of law or
                otherwise, without our prior written consent. We may assign
                these Terms at any time without notice or consent. The failure
                to require performance of any provision will not affect our
                right to require performance at any other time after that, nor
                will a waiver by us of any breach or default of these Terms, or
                any provision of these Terms, be a waiver of any subsequent
                breach or default or a waiver of the provision itself. Use of
                section headers in these Terms is for convenience only and will
                not have any impact on the interpretation of any provision.
                Throughout these Terms the use of the word “including” means
                “including but not limited to”. If any part of these Terms is
                held to be invalid or unenforceable, the unenforceable part will
                be given effect to the greatest extent possible, and the
                remaining parts will remain in full force and effect.
              </span>
            </h2>
          </li>
          <li data-list-text="20.2">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              Governing Law
              <span dummy="p">
                . These Terms are governed by the laws of the State of
                California without regard to conflict of law principles. You and
                Intertru, Inc. submit to the personal and exclusive jurisdiction
                of the state courts and federal courts located within Orange
                County, California for resolution of any lawsuit or court
                proceeding permitted under these Terms. We operate the Service
                from our offices in California,
              </span>
            </h2>
            <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              and we make no representation that Materials included in the
              Service are appropriate or available for use in other locations.
            </p>
          </li>
          <li data-list-text="20.3">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Additional Terms
              <span dummy="p">
                . Your use of the Service is subject to all additional terms,
                policies, rules, or guidelines applicable to the Service or
                certain features of the Service that we may post on or link to
                from the Service (the “
              </span>
              Additional Terms
              <span dummy="p">
                ”). All Additional Terms are incorporated by this reference
                into, and made a part of, these Terms.
              </span>
            </h2>
          </li>
          <li data-list-text="20.4">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Consent to Electronic Communications
              <span dummy="p">
                . By using the Service, you consent to receiving certain
                electronic communications from us as further described in our
                Privacy Policy. Please read our Privacy Policy to learn more
                about our electronic communications practices. You agree that
                any notices, agreements, disclosures, or other communications
                that we send to you electronically will satisfy any legal
                communication requirements, including that those communications
                be in writing.
              </span>
            </h2>
          </li>
          <li data-list-text="20.5">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Contact Information
              <a
                href="mailto:support@intertru.ai"
                dummy="a"
                target="_blank"
                rel="noreferrer"
              >
                . The Service is offered by Intertru, Inc., located at , 4590
                Macarthur Boulevard Newport Beach, CA, 92660. You may contact us
                by sending correspondence to that address or by emailing us at{" "}
              </a>
              <a
                href="mailto:support@intertru.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@intertru.ai.
              </a>
            </h2>
          </li>
          <li data-list-text="20.6">
            <h2 dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Notice to California Residents.{" "}
              <span dummy="p">
                If you are a California resident, under California Civil Code
                Section 1789.3, you may contact the Complaint Assistance Unit of
                the Division of Consumer Services of the California Department
                of Consumer Affairs in writing at 1625 N. Market Blvd., Suite
                S-202, Sacramento, California 95834, or by telephone at (800)
                952-5210 in order to resolve a complaint regarding the Service
                or to receive further information regarding use of the Service.
              </span>
            </h2>
          </li>
          <li data-list-text="20.7">
            <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              No Support
              <span dummy="p">
                . We are under no obligation to provide support for the Service.
                In instances where we may offer support, the support will be
                subject to published policies.
              </span>
            </h2>
          </li>
        </ol>
      </li>
      <li data-list-text="21.">
        <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          Notice Regarding Apple.{" "}
          <span dummy="p">
            This Section 21 only applies to the extent you are using our mobile
            application on an iOS device. You acknowledge that these Terms are
            between you and Intertru, Inc. only not with Apple Inc. (“
          </span>
          Apple
          <span dummy="p">
            ”), and Apple is not responsible for the Service or the content
            thereof. Apple has no obligation to furnish any maintenance and
            support services with respect to the Service. If the Service fails
            to conform to any applicable warranty, you may notify Apple and
            Apple will refund any applicable purchase price for the mobile
            application to you; and, to the maximum extent permitted by
            applicable law, Apple has no other warranty obligation with respect
            to the Service. Apple is not responsible for addressing any claims
            by you or any third party relating to the Service or your possession
            and/or use of the Service, including: (a) product liability claims;
            (b) any claim that the Service fails to conform to any applicable
            legal or regulatory requirement; or (c) claims arising under
            consumer protection or similar legislation. Apple is not responsible
            for the investigation, defense, settlement and discharge of any
            third party claim that the Service and/or your possession and use of
            the Service infringe a third party’s intellectual property rights.
            You agree to comply with any applicable third party terms when using
            the Service. Apple and Apple’s subsidiaries are third party
            beneficiaries of these Terms, and upon your acceptance of these
            Terms, Apple will have the right (and will be deemed to have
            accepted the right) to enforce these Terms against you as a third
            party beneficiary of these Terms. You hereby represent and warrant
            that: (i) you are not located in a country that is subject to a U.S.
            Government embargo, or that has been designated by the U.S.
            Government as a “terrorist supporting” country; and
          </span>
        </h2>
      </li>
    </ol>
    <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">
      (ii) you are not listed on any U.S. Government list of prohibited or
      restricted parties.
    </p>
    <h2 dummy="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      APPENDIX 1
    </h2>
    <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Data Processing Attachment
    </h2>
    <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      This Data Processing Attachment (“<b>DPA</b>”) is incorporated into and
      made part of the Terms of Service (“<b>Terms</b>”) between you (“
      <b>Customer</b>”) and Intertru, Inc. (“
      <b>Company</b>”). Unless otherwise defined in this DPA, capitalized terms
      will have the meaning given to them in the Terms. This DPA prevails over
      any conflicting term of the Terms, but does not otherwise modify the
      Terms.
    </p>
    <ol id="l17">
      <li data-list-text="1.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Definitions
        </h2>
        <ol id="l18">
          <li data-list-text="1.1">
            <p dummy="padding-top: 9pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
              In this DPA:
            </p>
            <ol id="l19">
              <li data-list-text="a.">
                <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  ““<b>Controller,</b>” “<b>Data Subject,</b>” “
                  <b>Personal Data,</b>” “<b>Personal Data Breach,</b>” “
                  <b>Processing,</b>” “<b>Processor,</b>” and “
                  <b>Supervisory Authority,</b>” have the meaning given to them
                  in the GDPR.
                </p>
              </li>
              <li data-list-text="b.">
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  “<b>Customer Personal Data</b>” means any Data that
                  constitutes Personal Data, the Processing of which is subject
                  to Data Protection Law, for which Customer or Customer’s
                  customers are the Controller, and which is Processed by
                  Company to provide the Service;
                </p>
              </li>
              <li data-list-text="c.">
                <p dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  “<b>Data Protection Law</b>” means Data Protection Directive
                  95/46/EC, General Data Protection Regulation (EU) 2016/679 (“
                  <b>GDPR</b>”), the United Kingdom General Data Protection
                  Regulation, and e-Privacy Directive 2002/58/EC (as amended by
                  Directive 2009/136/EC), and their national implementations in
                  the European Economic Area (“<b>EEA</b>
                  ”), Switzerland and the United Kingdom, each as applicable,
                  and as may be amended or replaced from time to time;
                </p>
              </li>
              <li data-list-text="d.">
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  “<b>Data Subject Rights</b>” means Data Subjects’ rights to
                  information, access, rectification, erasure, restriction,
                  portability, objection, and not to be subject to automated
                  individual decision-making in accordance with Data Protection
                  Law;
                </p>
              </li>
              <li data-list-text="e.">
                <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  “<b>International Data Transfer</b>” means any transfer of
                  Customer Personal Data from the EEA, Switzerland or the United
                  Kingdom to an international organization or to a country
                  outside of the EEA, Switzerland and the United Kingdom;
                </p>
              </li>
              <li data-list-text="f.">
                <p dummy="padding-top: 7pt;padding-left: 12pt;text-indent: -7pt;text-align: left;">
                  “<b>Subprocessor</b>” means a Processor engaged by Company to
                  Process Customer Personal Data; and
                </p>
              </li>
              <li data-list-text="g.">
                <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  “Standard Contractual Clauses”{" "}
                  <span dummy="p">
                    means the clauses annexed to EU Commission Implementing
                    Decision EU 2021/914 of June 4, 2021 on standard contractual
                    clauses for the transfer of personal data to third countries
                    pursuant to Regulation (EU) 2016/679 of the European
                    parliament and of the Council;
                  </span>
                </h2>
              </li>
              <li data-list-text="h.">
                <h2 dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                  “UK Standard Contractual Clauses”{" "}
                  <span dummy="p">
                    means the clauses annexed to EU Commission Decision
                    2010/87/EU of 5 February 2010 on standard contractual
                    clauses for the transfer of personal data to processors
                    established in third countries under Directive 95/46/EC of
                    the European Parliament and of the Council (OJ L 39,
                    12.2.2010, p. 5-18).
                  </span>
                </h2>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li data-list-text="2.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Scope and applicability
        </h2>
        <ol id="l20">
          <li data-list-text="2.1">
            <p dummy="padding-top: 9pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
              This DPA applies to Processing of Customer Personal Data by
              Company to provide the Service.
            </p>
          </li>
          <li data-list-text="2.2">
            <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              The subject matter, nature and purpose of the Processing, the
              types of Customer Personal Data and categories of Data Subjects
              are set out in <b>Appendix 5</b>.
            </p>
          </li>
          <li data-list-text="2.3">
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Customer is a Controller and appoints Company as a Processor on
              behalf of Customer in relation to the purposes set out in{" "}
              <b>Appendix 5</b>. Customer is responsible for compliance with the
              requirements of Data Protection Law applicable to Controllers.
            </p>
          </li>
          <li data-list-text="2.4">
            <p dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              If Customer is a Processor on behalf of other Controller(s), then
              Customer is the single point of contact for Company; must obtain
              all necessary authorizations from such other Controller(s);
              undertakes to issue all instructions and exercise all rights on
              behalf of such other Controller(s); and is responsible for
              compliance with the requirements of Data Protection Law applicable
              to Processors.
            </p>
          </li>
          <li data-list-text="2.5">
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Customer acknowledges that Company may Process Personal Data
              relating to the operation, support, or use of the Service for its
              own business purposes, such as billing, account management, data
              analysis, benchmarking, technical support, product development,
              and compliance with law. Company is the Controller for such
              Processing and will Process such data in accordance with Data
              Protection Law. Such Processing shall not be subject to the terms
              of this DPA.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="3.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Instructions
        </h2>
        <ol id="l21">
          <li data-list-text="3.1">
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Company will Process Customer Personal Data to provide the Service
              and in accordance with Customer’s documented instructions.
            </p>
          </li>
          <li data-list-text="3.2">
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              The Controller’s instructions are documented in this DPA, the
              Terms, and any applicable statement of work.
            </p>
          </li>
          <li data-list-text="3.3">
            <p dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Customer may reasonably issue additional instructions as necessary
              to comply with Data Protection Law. Company may charge a
              reasonable fee to comply with any additional instructions.
            </p>
          </li>
          <li data-list-text="3.4">
            <p dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Unless prohibited by applicable law, Company will inform Customer
              if Company is subject to a legal obligation that requires Company
              to Process Customer Personal Data in contravention of Customer’s
              documented instructions.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="4.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Personnel
        </h2>
        <ol id="l22">
          <li data-list-text="4.1">
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Company will ensure that all personnel authorized to Process
              Customer Personal Data are subject to an obligation of
              confidentiality.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="5.">
        <h2 dummy="padding-top: 8pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Security and Personal Data Breaches
        </h2>
        <ol id="l23">
          <li data-list-text="5.1">
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Taking into account the state-of-the-art, the costs of
              implementation and the nature, scope, context and purposes of
              Processing as well as the risk of varying likelihood and severity
              for the rights and freedoms of natural persons, Company will
              implement appropriate technical and organizational measures to
              ensure a level of security appropriate to the risk, including the
              measures listed in <b>Appendix 3</b>.
            </p>
          </li>
          <li data-list-text="5.2">
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Customer acknowledges that the security measures in{" "}
              <b>Appendix 3 </b>are appropriate in relation to the risks
              associated with Customer’s intended Processing, and will notify
              Company prior to any intended Processing for which Company’s
              security measures may not be appropriate.
            </p>
          </li>
          <li data-list-text="5.3">
            <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Company will notify Customer without undue delay after becoming
              aware of a Personal Data Breach involving Customer Personal Data.
              If Company’s notification is delayed, it will be accompanied by
              reasons for the delay.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="6.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Subprocessing
        </h2>
        <ol id="l24">
          <li data-list-text="6.1">
            <p dummy="padding-top: 9pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
              Customer hereby authorizes Company to engage Subprocessors.
            </p>
          </li>
          <li data-list-text="6.2">
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Company will enter into a written agreement with Subprocessors
              which imposes the same obligations as required by Data Protection
              Law.
            </p>
          </li>
          <li data-list-text="6.3">
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Customer may object to the addition of a Subprocessor based on
              reasonable grounds relating to a potential or actual violation of
              Data Protection Law by providing written notice detailing the
              grounds of such objection within thirty (30) days following
              Company’s notification of the intended change. Customer and
              Company will work together in good faith to address Customer’s
              objection. If Company chooses to retain the Subprocessor, Company
              will inform Customer at least thirty (30) days before authorizing
              the Subprocessor to Process Customer Personal Data, and Customer
              may immediately discontinue using the relevant part of the
              Service, and may terminate the relevant part of the Service within
              thirty (30) days.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="7.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Assistance
        </h2>
        <ol id="l25">
          <li data-list-text="7.1">
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Taking into account the nature of the Processing, and the
              information available to Company, Company will assist Customer,
              including, as appropriate, by implementing technical and
              organizational measures, with the fulfillment of Customer’s own
              obligations under Data Protection Law to: comply with requests to
              exercise Data Subject Rights; conduct data protection impact
              assessments, and prior consultations with Supervisory Authorities;
              and notify a Personal Data Breach.
            </p>
          </li>
          <li data-list-text="7.2">
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Company will maintain records of Processing of Customer Personal
              Data in accordance with Data Protection Law.
            </p>
          </li>
          <li data-list-text="7.3">
            <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Company may charge a reasonable fee for assistance under this{" "}
              <b>Section 7</b>. If Company is at fault, Company and Customer
              shall each bear their own costs related to assistance.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="8.">
        <h2 dummy="padding-top: 8pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Audit
        </h2>
        <ol id="l26">
          <li data-list-text="8.1">
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Upon reasonable request, Company must make available to Customer
              all information necessary to demonstrate compliance with the
              obligations of this DPA and allow for and contribute to audits,
              including inspections, as mandated by a Supervisory Authority or
              reasonably requested no more than once a year by Customer and
              performed by an independent auditor as agreed upon by Customer and
              Company. The foregoing shall only extend to those documents and
              facilities relevant and material to the processing of Customer
              Personal Data, and shall be conducted during normal business hours
              and in a manner that causes minimal disruption.
            </p>
          </li>
          <li data-list-text="8.2">
            <p dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
              Company will inform Customer if Company believes that Customer’s
              instruction under <b>Section</b>
            </p>
            <h2 dummy="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              8.1{" "}
              <span dummy="p">
                infringes Data Protection Law. Company may suspend the audit or
                inspection, or withhold requested information until Company has
                modified or confirmed the lawfulness of the instructions in
                writing.
              </span>
            </h2>
          </li>
          <li data-list-text="8.3">
            <p dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
              Company and Customer each bear their own costs related to an
              audit.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="9.">
        <h2 dummy="padding-top: 2pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          International Data Transfers
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          9.1 Customer hereby authorizes Company to perform International Data
          Transfers to any country deemed adequate by the EU Commission; on the
          basis of appropriate safeguards in accordance with Data Protection Law
          or pursuant to the Standard Contractual Clauses and UK Standard
          Contractual Clauses referred to in Section 9.2 and 9.3 respectively.
        </p>
        <ol id="l27">
          <ol id="l28">
            <li data-list-text="9.2.">
              <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                By signing this DPA, Customer and Company hereby agree to
                conclude the provisions of module two (controller to processor)
                of the Standard Contractual Clauses, which are hereby
                incorporated into this DPA and completed as follows:
              </p>
              <ul id="l29">
                <li data-list-text="●">
                  <p dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">
                    The optional Clause 7 is kept;
                  </p>
                </li>
                <li data-list-text="●">
                  <p dummy="padding-top: 9pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
                    In Clause 9, Option 1 is struck and Option 2 is kept, and
                    Customer may object to the addition of a Subprocessor within
                    (30) days.
                  </p>
                </li>
                <li data-list-text="●">
                  <p dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">
                    In Clause 11, the optional language is struck;
                  </p>
                </li>
                <li data-list-text="●">
                  <p dummy="padding-top: 9pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
                    In Clauses 17 and 18, the governing law and the competent
                    courts are those of the country in which Customer is
                    established.
                  </p>
                </li>
                <li data-list-text="●">
                  <p dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">
                    Appendixes 2, 3 and 4 to these terms of service
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="9.3.">
              <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                By signing this DPA, Customer and Company conclude the UK
                Standard Contractual Clauses, which are hereby incorporated into
                this DPA and completed as follows: the “data exporter” is
                Customer; the “data importer” is Company; the governing law in
                Clause 9 and Clause 11.3 of the UK Standard Contractual Clauses
                is the law of the country in which Customer is established;
                Appendix 1 and Appendix 2 to the UK Standard Contractual
                Clauses, are Appendix 5 and 3 respectively; and the optional
                indemnification clause is struck.
              </p>
            </li>
            <li data-list-text="9.4.">
              <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
                If the Standard Contractual Clauses or the UK Standard
                Contractual Clauses are amended, updated, or invalidated,
                Customer and Company will work together in good faith to
                reasonably resolve such
              </p>
            </li>
          </ol>
        </ol>
        <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">
          non-compliance.
        </p>
      </li>
      <li data-list-text="10.">
        <h2 dummy="padding-top: 9pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Notifications
        </h2>
        <ol id="l30">
          <li data-list-text="10.1">
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              <a
                href="mailto:support@intertru.ai"
                dummy="a"
                target="_blank"
                rel="noreferrer"
              >
                Customer will send all notifications, requests and instructions
                under this DPA to Company’s legal department via email to{" "}
              </a>
              support@intertru.ai. Company will send all notifications under
              this DPA to Customer’s contact email address.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="11.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Liability
        </h2>
        <ol id="l31">
          <li data-list-text="11.1">
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              To the extent permitted by applicable law, where Company has paid
              damages or fines, Company is entitled to claim back from Customer
              that part of the compensation, damages or fines, corresponding to
              Customer’s part of responsibility for the damages or fines.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="12.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Termination and return or deletion
        </h2>
        <ol id="l32">
          <li data-list-text="12.1">
            <p dummy="padding-top: 9pt;padding-left: 26pt;text-indent: -22pt;text-align: left;">
              This DPA is terminated upon the termination of the Terms.
            </p>
          </li>
          <li data-list-text="12.2">
            <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Customer may request return of Customer Personal Data up to ninety
              (90) days after termination of the Terms. Unless required or
              permitted by applicable law, Company will delete all remaining
              copies of Customer Personal Data within one hundred eighty (180)
              days after returning Customer Personal Data to Customer.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="13.">
        <h2 dummy="padding-top: 7pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Modification of this DPA
        </h2>
        <ol id="l33">
          <li data-list-text="13.1">
            <p dummy="padding-top: 9pt;padding-left: 26pt;text-indent: -22pt;text-align: left;">
              This DPA may only be modified by a written amendment signed by
              both Company and Customer.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="14.">
        <h2 dummy="padding-top: 9pt;padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Invalidity and severability
        </h2>
        <ol id="l34">
          <li data-list-text="14.1">
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              If any provision of this DPA is found by any court or
              administrative body of competent jurisdiction to be invalid or
              unenforceable, then the invalidity or unenforceability of such
              provision does not affect any other provision of this DPA and all
              provisions not affected by such invalidity or unenforceability
              will remain in full force and effect.
            </p>
          </li>
        </ol>
      </li>
    </ol>
    <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      APPENDIX 2
    </h2>
    <ol id="l35">
      <li data-list-text="A.">
        <h2 dummy="padding-top: 9pt;padding-left: 17pt;text-indent: -12pt;text-align: left;">
          List of Patries
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          <b>Data exporter(s): </b>[
          <i>
            Identity and contact details of the data exporter(s) and, where
            applicable, of its/their data protection officer and/or
            representative in the European Union
          </i>
          ]
        </p>
        <ol id="l36">
          <li data-list-text="1.">
            <p dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Name: As included in the Terms Address: As included in the Terms
            </p>
            <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">
              Contact person’s name, position and contact details: As included
              in the Terms
            </p>
            <p dummy="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Activities relevant to the data transferred under these Clauses:
              As included in the Terms Signature and date: As included in the
              Terms
            </p>
            <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">
              Role: Controller
            </p>
            <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              <b>Data importer(s): </b>[
              <i>
                Identity and contact details of the data importer(s), including
                any contact person with responsibility for data protection
              </i>
              ]
            </p>
          </li>
          <li data-list-text="2.">
            <p dummy="padding-top: 8pt;padding-left: 15pt;text-indent: -10pt;text-align: left;">
              Name: Intertru, Inc.
            </p>
            <p dummy="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
              Address: As included in the Terms
            </p>
            <p dummy="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
              Contact person’s name, position and contact details: As included
              in the Terms
            </p>
            <p dummy="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
              Activities relevant to the data transferred under these Clauses:
              As included in the Terms Signature and date: As included in the
              Terms
            </p>
            <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">
              Role: Processor
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="B.">
        <h2>DESCRIPTION OF TRANSFER</h2>
        <p>Categories of data subjects whose personal data is transferred</p>
        <p>
          1 Employees, contractors, or students of Organizations who use the
          Service
        </p>
        <p>
          2 Other meeting participants where the Service is used by one of the
          above data subjects
        </p>
        <p>Categories of personal data transferred</p>
        <p>
          1 Registration information including email address and volunteered
          first and last name
        </p>
        <p>
          2 Audio and Video recordings stored by the user that may include
          personal data
        </p>
        <p>
          Sensitive data transferred (if applicable) and applied restrictions or
          safeguards that fully take into consideration the nature of the data
          and the risks involved, such as for instance strict purpose
          limitation, access restrictions (including access only for staff
          having followed specialized training), keeping a record of access to
          the data, restrictions for onward transfers or additional security
          measures.
        </p>
        <p>
          1 The Service may Process video and voiceprints to recognize users and
          automatically tag their name within the recordings.
        </p>
        <p>
          The frequency of the transfer (e.g. whether the data is transferred on
          a one-off or continuous basis).
        </p>
        <p>Continuous</p>
        <p>Nature of the processing</p>
        <p>Processing operations as detailed in the Terms</p>
        <p>Purpose(s) of the data transfer and further processing</p>
        <p>
          1 The Service creates legible notes from recorded voice conversations
          using proprietary technologies for automated speech recognition (ASR),
          keyword extraction and voice to text synchronization. Data may be
          ingested directly via recording using a mobile or web application,
          upload of audio <span>and video </span>files or synchronization with
          other applications including cloud storage or online video
          conferencing that store or capture recorded audio. Audio and video are
          processed in cloud infrastructure and delivered to the Intertru
          application where it may be consumed or downloaded in a variety of
          formats.
        </p>
        <p>
          The period for which the personal data will be retained, or, if that
          is not possible, the criteria used to determine that period
        </p>
        <p>As detailed in the Terms.</p>
      </li>
      <li data-list-text="C.">
        <h2>COMPETENT SUPERVISORY AUTHORITY</h2>
      </li>
    </ol>
    <p>As detailed in Appendix 1.</p>
    <h2>APPENDIX 3</h2>
    <h2>Security Measures</h2>
    <p>
      The Service is provisioned using a cloud-based platform and we employ best
      practices regarding security services. A summary of security measures
      includes:
    </p>
    <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Physical Access Controls
    </p>
    <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      All User Content is processed and stored in leading commercial cloud
      services infrastructure in the United States. We work with cloud services
      that comply with security standards regarding managing physical access.
    </p>
    <p dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      System Access Controls
    </p>
    <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      We maintain separate production and development/staging environments.
      Access to production environments is limited to system administrators and
      all access is logged.
    </p>
    <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Data Access Controls
    </p>
    <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      Only selected support personnel may access User Content directly with the
      users explicit permission. Any requests to access User Content is approved
      by the system administrator. All requests are logged.
    </p>
    <p dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Transmission Controls
    </p>
    <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      All User Content is encrypted in transit and at rest. We use HTTPS for all
      communication. We use the industry standard encryption algorithms for
      stored User Content and passwords.
    </p>
    <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Input Controls
    </p>
    <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
      User is authenticated by password or using Google, Microsoft, or Apple
      login. Two factor authentication may be enabled by account administrator
      for Teams accounts. We use web cookies to validate signed in users.
    </p>
    <p dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Data Backups
    </p>
    <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 167%;text-align: left;">
      We have daily backup of databases and all User Content is stored in
      persistent storage. Data Segregation
    </p>
    <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      All User Content is associated with their user ID and stored in a manner
      that there is no way for users to access another’s content.
    </p>
    <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">
      APPENDIX 5
    </h2>
    <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Description of the processing
    </h2>
    <ol id="l37">
      <li data-list-text="1.">
        <h2 dummy="padding-top: 9pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Data Subjects
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          The Customer Personal Data Processed concern the following categories
          of Data Subjects (please specify):
        </p>
        <p dummy="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">
          # CATEGORY
        </p>
        <ol id="l38">
          <li data-list-text="1">
            <p dummy="padding-top: 9pt;padding-left: 15pt;text-indent: -10pt;text-align: left;">
              Employees, contractors, or students of Organizations who use the
              Service
            </p>
          </li>
          <li data-list-text="2">
            <p dummy="padding-top: 9pt;padding-left: 13pt;text-indent: -8pt;text-align: left;">
              Other meeting participants where the Service is used by one of the
              above data subjects
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="2.">
        <h2 dummy="padding-top: 9pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Categories of Customer Personal Data
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 167%;text-align: left;">
          The Customer Personal Data Processed concern the following categories
          of data (please specify): # CATEGORY
        </p>
        <ol id="l39">
          <li data-list-text="1">
            <p dummy="padding-left: 15pt;text-indent: -10pt;text-align: left;">
              Registration information including email address and volunteered
              first and last name
            </p>
          </li>
          <li data-list-text="2">
            <p dummy="padding-top: 9pt;padding-left: 15pt;text-indent: -10pt;text-align: left;">
              Audio and video recordings stored by the user that may include
              personal data
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="3.">
        <h2 dummy="padding-top: 9pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Sensitive data
        </h2>
        <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 167%;text-align: left;">
          The Customer Personal Data Processed concern the following special
          categories of data (please specify): # CATEGORY
        </p>
        <p dummy="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
          1 The Service may Process video and voiceprints to recognize users and
          automatically tag their name within the transcript.
        </p>
      </li>
      <li data-list-text="4.">
        <h2 dummy="padding-top: 7pt;padding-left: 15pt;text-indent: -11pt;text-align: left;">
          Processing operations
        </h2>
      </li>
    </ol>
    <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 167%;text-align: left;">
      The Customer Personal Data will be subject to the following basic
      Processing activities (please specify): # CATEGORY
    </p>
    <p dummy="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      1 The Service creates legible notes from recorded voice conversations
      using proprietary technologies for automated speech recognition (ASR),
      keyword extraction and voice to text synchronization. Data may be ingested
      directly via recording using a mobile or web application, upload of audio
      and video files or synchronization with other applications including cloud
      storage or online video conferencing that store or capture recorded audio.
      Audio and video are processed in cloud infrastructure and delivered to the
      Intertru application where it may be consumed or downloaded in a variety
      of formats.
    </p>
    <h2 dummy="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      APPENDIX 6
    </h2>
    <h2 dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      California Data Processing Attachment
    </h2>
    <p dummy="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
      This California Data Processing Attachment (“<b>CCPA DPA</b>”) is
      incorporated into and made part of the Terms of Service (“
      <b>Terms</b>”) between you (“<b>Customer</b>”) and Intertru, Inc. (“
      <b>Company</b>”) if you are an Organization. Unless otherwise defined in
      this CCPA DPA, capitalized terms will have the meaning given to them in
      the Terms. In the event of any conflict between the terms of the Terms and
      this CCPA DPA, this CCPA DPA shall prevail to the extent of such conflict.
    </p>
    <ol id="l40">
      <li data-list-text="1.">
        <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
          Definitions<span dummy="p">. “</span>Business Purpose
          <span dummy="p">,” “</span>Consumer<span dummy="p">,” “</span>
          Personal Information<span dummy="p">,” “</span>Selling
          <span dummy="p">,” and “</span>Service Provider
          <span dummy="p">
            ” shall have the respective meanings given to them in the California
            Consumer Privacy Act of 2018 (California Civil Code §§ 1798.100 to
            1798.199) and its implementing regulations, as amended or superseded
            from time to time (“
          </span>
          CCPA<span dummy="p">”).</span>
        </h2>
      </li>
      <li data-list-text="2.">
        <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
          Scope
          <span dummy="p">
            . The terms of this CCPA DPA shall apply only to Company’s
            collection, retention, use, disclosure, and sale of Personal
            Information governed by the CCPA that Customer provides to Company
            as a Service Provider, or Company collects from or on behalf of
            Customer as a Service Provider, in connection with the Services or
            to perform a Business Purpose (“
          </span>
          Customer Personal Information<span dummy="p">”).</span>
        </h2>
      </li>
      <li data-list-text="3.">
        <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
          Role
          <span dummy="p">
            . The parties acknowledge and agree that Customer appoints Company
            as a Service Provider to process Customer Personal Information on
            behalf of Customer.
          </span>
        </h2>
      </li>
      <li data-list-text="4.">
        <h2 dummy="padding-top: 8pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
          Restrictions on Processing
          <span dummy="p">
            . Company is prohibited from (i) retaining, using, or disclosing
            Customer Personal Information for any purpose other than for the
            specific purpose of performing the Services or as otherwise
            permitted by the CCPA or the Terms and (ii) further collecting,
            Selling, or using the Customer Personal Information, except as
            necessary to perform the Services.
          </span>
        </h2>
      </li>
      <li data-list-text="5.">
        <h2 dummy="padding-top: 7pt;padding-left: 41pt;text-indent: -18pt;line-height: 108%;text-align: left;">
          Assistance
          <span dummy="p">
            . Company will provide reasonable assistance to Customer for the
            fulfillment of Customer’s obligations to respond to CCPA data-rights
            requests of Consumers.
          </span>
        </h2>
      </li>
    </ol>
  </>
);
