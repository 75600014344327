import { useState, useEffect } from "react";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";

import { errorRed } from "../../theme";
import { styles } from "./styles";
import { Button, Modal } from "../../Components";
import { User } from "../Users";

export default function ParticipantAdd(props) {
  const {
    users,
    interviewers,
    stage,
    participants,
    setParticipants,
    cid,
    userModalVisible,
    setUserModalVisible,
  } = props;

  const [availableParticipants, setAvailableParticipants] = useState([]);

  // workable users
  useEffect(() => {
    const participantIds = participants.map((p) => p.id);
    let newUsers = cloneDeep(users);
    for (const userId of Object.keys(newUsers)) {
      if (interviewers[stage]?.id === userId || participantIds.includes(userId))
        newUsers[userId].canParticipate = false;
      else newUsers[userId].canParticipate = true;
    }
    setAvailableParticipants(newUsers);
  }, [participants, interviewers, stage, users]);

  return (
    <>
      <div>
        <Modal
          style={{ width: "auto", minWidth: "auto", maxHeight: "80%" }}
          title="Add User"
          isVisible={userModalVisible}
          onClose={() => setUserModalVisible(false)}
        >
          <div
            style={{
              maxHeight: "calc(80vh - 76px)",
              overflowY: "auto",
              padding: "0 20px",
            }}
          >
            <User mini cid={cid} setUserModalVisible={setUserModalVisible} />
          </div>
        </Modal>
        <div style={styles.inputLabel}>Other Interviewers</div>
        <div className="hor-center">
          <select
            value=""
            onChange={(e) => {
              addParticipant(availableParticipants[e.target.value]);
            }}
            style={{ ...styles.select, maxWidth: 350 - 60 }}
          >
            <option hidden value="">
              Add Participants..
            </option>
            {Object.keys(availableParticipants).map((partId) => {
              const { id, firstName, lastName, canParticipate } =
                availableParticipants[partId];
              if (canParticipate)
                return (
                  <option key={id} value={id}>
                    {firstName} {lastName}
                  </option>
                );
              else return null;
            })}
          </select>
          <div style={{ width: 10 }} />
          <div className="hor-center" style={{ width: 50, marginBottom: 16 }}>
            <Button
              filled
              style={styles.participantAddButton}
              onClick={() => setUserModalVisible(true)}
            >
              New
            </Button>
          </div>
        </div>
      </div>
      <div>
        {participants && (
          <>
            {participants.length === 0 && (
              <div style={{ width: 350, color: "#999", fontStyle: "italic" }}>
                No Participants
              </div>
            )}
            {participants.length > 0 &&
              participants.map((participant, i) => {
                const { firstName, lastName, email } = participant;
                return (
                  <div
                    className="hor-center"
                    style={{
                      width: 350,
                      padding: "2px 0px",
                      borderTop: `1px solid ${i === 0 ? "white" : "#ddd"}`,
                      justifyContent: "space-between",
                    }}
                    key={i}
                  >
                    {firstName} {lastName} ({email})
                    <div
                      style={styles.removeParticipantButton}
                      onClick={() => removeParticipant(i)}
                    >
                      <FiX color={errorRed} />
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </>
  );

  function addParticipant(participant) {
    if (participants.length === 3)
      return toast.error("Cannot add any more participants.");
    const updatedParticipants = cloneDeep(participants);
    updatedParticipants.push(participant);
    setParticipants(updatedParticipants);
  }

  function removeParticipant(index) {
    const updatedParticipants = cloneDeep(participants);
    updatedParticipants.splice(index, 1);
    setParticipants(updatedParticipants);
  }
}
