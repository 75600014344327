import React from 'react'
//import { getRolesByCompanyId } from "../../utils/api";
import { useEffect, useState } from 'react'
import { usePageTitle } from "../../navigation/usePageTitle";
import { useNavigate } from "react-router-dom";
import { RoleTableList, LoadingScreen } from "../../Components";
import { listJobRolesReqByCompId, listJobRolesReq } from "../../utils/api"
import { getUser } from "../../utils/api";
import { getCognitoUsername } from "../../utils/auth";
import { AddNewJobRoleCompanySelection, StatusModal } from './Modals';
import { render } from '@testing-library/react';

const JobRoles = () => {
    usePageTitle("Roles Management")
    const navigate = useNavigate();

    const [rolesList, setRolesList] = useState([]);
    const [searchValue, setSearchValue] = useState();

    const [filterQuery, setFilterQuery] = useState('');
    const [filteredRoles, setFilteredRoles] = useState([]);

    const [showCompanySelection, setShowCompanySelection] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const [showStatusMessage, setShowStatusMessage] = useState(false);
    const [headerText, setHeaderText] = useState("");
    const [contentText, setContentText] = useState("");

    const [renderComplete, setRenderComplete] = useState(false);

    useEffect(() => {
        async function getJobRoles() {
            const userId = await getCognitoUsername();
            await getUser(userId).then((userRes) => {
                const companyId = userRes?.companyID;
                
                if (!companyId) {
                    setIsAdmin(true);
                    try {
                        listJobRolesReq().then((response) => {
                            const sortedRoles = response.listJobRoles.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                            setRolesList(sortedRoles);
                            setRenderComplete(true);
                        });
                    }
                    catch (e) {
                        console.log("error getting roles: ", e);
                        setRenderComplete(true);
                    }
                }
                else {
                    try {
                        listJobRolesReqByCompId(companyId).then((response) => {
                            const sortedRoles = response.listJobRoles.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                            setRolesList(sortedRoles);
                            setRenderComplete(true);
                        });
                    }
                    catch (e) {
                        console.log("error getting roles: ", e);
                        setRenderComplete(true);
                    }
                }
            });
        }
        getJobRoles();
    }, []);

    useEffect(() => {
        if (showStatusMessage) {
          const timer = setTimeout(() => {
            setShowStatusMessage(false);
          }, 3000); 
          return () => clearTimeout(timer);
        }
      }, [showStatusMessage]);

    useEffect(() => {
        const parsedRoles = rolesList.map(jobRole => ({
          ...jobRole,
          jobRoleData: JSON.parse(jobRole.jobRoleData)
        }));
    
        setFilteredRoles(
    
          parsedRoles.filter(jobRole =>
            
            jobRole.jobRoleData.title?.toLowerCase().includes(filterQuery.toLowerCase()) ||
            jobRole.jobRoleData.requiredSkills?.some(skill =>
              skill.name?.toLowerCase().includes(filterQuery.toLowerCase())
            ) ||
            jobRole.jobRoleData.optionalSkills?.some(skill =>
              skill.name?.toLowerCase().includes(filterQuery.toLowerCase())
            )
            || jobRole.status?.toLowerCase().includes(filterQuery.toLowerCase())
            
          )
        );
      }, [filterQuery, rolesList]);
   

    const handleSearchValueChange = (event) => {
        setFilterQuery(event.target.value);
    }

    function addNewRoleButtonClicked() {
        if (isAdmin) {
            setShowCompanySelection(true);
        }
        else {
            navigate("/admin/jobrole");
        }
     }

     const cancelCompanySelection = () => {
        setShowCompanySelection(false);
        //console.log("cancelCompanySelection");
      };

     const renderHeaderRoles = () => {
        return (
                <div style={styles.roleHeader}>
                    <div style={{justifyContent: "flex-start", display: "flex", marginTop: 16, alignItems: "center"}}>
                        <h1 style={styles.headerText}>Roles</h1>
                        <div style={styles.personIconHolder}>
                            <div style={styles.personIcon} className="person-icon"></div>
                        </div>
                        <div style={styles.roleCounter}>
                            <div style={{color: "var(--Green-Green, #22AD5C)", 
                            fontFamily: "Montserrat", 
                            fontStyle: "normal", 
                            fontWeight: "700", 
                            lineHeight: "26px", 
                            fontSize: "18px"}}>{rolesList.length}</div>
                            <div style={{color: "--Secondary-Text, #6B7280)", 
                            fontFamily: "Montserrat", 
                            fontStyle: "normal", 
                            fontWeight: "400", 
                            lineHeight: "24px", 
                            fontSize: "16px",}}>Total Roles</div>
                        </div>
                        <div style={styles.divider}></div>
                        <div style={styles.searchHolder}>
                            <div style={styles.searchInputBox}>
                                <input placeholder='Search' 
                                    style={{outline: "none", height: 32}}
                                    onChange={handleSearchValueChange}
                                >
                                </input>
                                <div className='search-icon'></div>
                            </div>
                        </div>
                    </div>
                    <div style={{alignItems: "center",  display: "flex"}}>
                        <button style={styles.addNewRoleButton}
                            onClick={addNewRoleButtonClicked}>
                            <div className='add-icon'></div>   
                            <div style={styles.addNewRollButtonText}>New Role</div>
                        </button>
                    </div>
                </div>
        )
    }
    
    const renderRolesList = () => {
        return (
                <div className='container' style={contentStyles.contentBodyWrapper}>
                     {showStatusMessage && (
                    <StatusModal
                        onClose={() => setShowStatusMessage(false)}
                        showModal = {setShowStatusMessage}
                        headerText = {headerText}
                        statusText = {contentText}
                        status="success"
                    />
                )}
                    <div style={contentStyles.roleListWrapper}>
                        {!!renderComplete ? (
                            <div>
                                {rolesList.length <= 0 ? (
                                <div style={{ width: "100%", height: "100%", zIndex: 999 }}>
                                Click on 'New Role' to begin
                            </div>
                            ): (
                           <RoleTableList 
                               jobRoleList={filteredRoles}
                               isAdmin={isAdmin} 
                               setRoleList={setRolesList}
                               setShowStatusMessage={setShowStatusMessage}
                               setHeaderText={setHeaderText}
                               setContentText={setContentText}
                               setRenderComplete={setRenderComplete}
                           />
                             )}
                            </div>
                        ) : (
                            <LoadingScreen/>
                        )}
                    </div>
                </div>

        )
    }

    return (
       <>
            {showCompanySelection && (
                <AddNewJobRoleCompanySelection
                    onClose={cancelCompanySelection}
                    modalVisible = {showCompanySelection}
                />
            )}
            <div>
             {renderHeaderRoles()}
            </div>
            {renderRolesList()}
</>
    )
}

const contentStyles = {

    container: {
        minWidth: 1280, 
        maxWidth: 1728,
        width: "100%",
        filter: "drop-shadow(0px 1px 3px rgba(166, 175, 195, 0.40))",
    },
    
    contentBodyWrapper: {
        backgroundColor: "#F3F4F6",
        borderRadius: 24,
        display : "flex",
        minWidth: 1280, 
        maxWidth: "100vw",
        width: "100%",
        position: "relative",
        padding : 8,
        maxHeight: "calc(100vh - 186px)",
        filter: "drop-shadow(0px 1px 3px rgba(166, 175, 195, 0.40))",
    },
    roleListWrapper: {
        padding: 16,
        backgroundColor: "#FFF",
        width: "100%",
        maxWidth: "100vw",
        borderRadius: 24,
    }
}

const styles = {
    containerBody: {
        backgroundColor: "rgb(243, 244, 246)",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        display: "flex",     
    },
    roleBodyWrapper: {
        backgroundColor : "#F3F4F6",
        display : "flex",
    },
    roleHeader : {
        backgroundColor: "#FFF",
        height: 114,
        display: "flex",
        justifyContent: "space-between",
    },
    headerText: {
        color: "var(--Primary-Text, #4B5563)",
        textAlign: "left",
        alignItems: "center",
        /* Heading 4 */
        fontFamily: "Montserrat",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 700,
        height: "38px" /* 126.667% */,
        marginLeft: 24,
        marginBottom: 32,
        wdith: "86px",
        display: "flex",

    },
    personIconHolder : {
        width: "48px",
        height: "48px",
        flexShrink: 0,
        borderRadius: "8px",
        background:" var(--Green-Green-light-6, #DAF8E6)",
        marginLeft: 48,
        marginBottom: 32,
    },
    personIcon : {
        width: "32px",
        height: "32px",
        flexShrink: 0,
        margin: 8,
    },
    roleCounter : {
        marginLeft: 16,
        width: "87px",
        height: "48px",
        marginBottom: 32,
    },
    divider : {
        background: "var(--Stroke, #DFE4EA)",
        width: "1px",
        height: "48px",
        marginLeft: 24,
        marginBottom: 32,
    },
    searchHolder : {
        width: 232,
        marginLeft: 24,
        marginBottom: 32,
    },
    searchInputBox :{
        display: "flex",
        height: 40,
        width: 232,
        padding: "8px 16px",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch",
        borderRadius: 8,
        border: "1px solid var(--Stroke, #DFE4EA)",
        background: "var(--White-White, #FFF)"
    },
    addNewRoleButton : {
        position: "relative",
        display: "inline-flex", 
        padding: "8px 16px",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "8px",
        borderRadius: "50px",
        background: "var(--Primary, #F60)",
        width: 142,
        height: 40,
        marginRight: 32,
        marginBottom: 24,
    },
    addNewRollButtonText : {
        color: "var(--White-White, #FFF)",
        textAlign: "center",

        /* Body Medium/Medium */
        fontFamily: "Montserrat",
        fontFize: "16px",
        fontFtyle: "normal",
        fontWeight: 500,
        lineHeight: "24px" /* 150% */
    },

    dropDownAction : {
        position: 'absolute', 
        backgroundColor: 'white', 
        zIndex: 1, listStyleType: 'none', 
        padding: 8, 
        margin: 0, 
        height: "auto", 
        maxHeight: 312,
        overflow: 'scroll',
        border: "1px solid var(--Stroke, #DFE4EA)",
        borderRadius: 8,
        width : 355
    },
}

export default JobRoles;