import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
} from "@chakra-ui/react";
import { LoadingScreen } from "../../Components";
import { roles } from "../../utils/roles";
import { getCompany } from "../../utils/api";
import { styles } from "./styles";

export default function UsersTable(props) {
  const {
    users,
    style,
    showCompanyName = false,
    permission = null,
    showArchived,
  } = props;
  const navigate = useNavigate();

  const [listUsers, setListUsers] = useState(null);

  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    setWaiting(true);
    async function formatUserData() {
      const userData = users?.map(
        async ({
          firstName,
          lastName,
          email,
          role,
          lastLoggedIn,
          id,
          companyID,
          status,
        }) => ({
          name: `${firstName} ${lastName}`,
          email,
          role,
          lastLoggedIn,
          id,
          companyName: (await getCompany(companyID))?.company,
          status,
        })
      );
      const listUsers = await Promise.all(userData);
      setListUsers(sortUsers(listUsers));
      setWaiting(false);
    }
    if (users) {
      formatUserData();
    }
  }, [users, showArchived]);

  if (!listUsers || waiting)
    return (
      <div style={{ ...styles.tableWrapper, height: "70vh" }}>
        <LoadingScreen />
      </div>
    );

  return (
    <div style={styles.tableWrapper}>
      {users && (
        <TableContainer style={{ ...styles.tableContainer, ...style }}>
          <Table size="md" variant="simple">
            <Thead style={styles.tableHeader}>
              <Tr className="header-tr">
                <Th className="header-th">Name</Th>
                <Th className="header-th">Email</Th>
                {showCompanyName && <Th className="header-th">Customer</Th>}
                <Th className="header-th">Permission Level</Th>
                {showArchived && <Th className="header-th">Status</Th>}
                <Th className="header-th">Last Activity</Th>
              </Tr>
            </Thead>
            <Tbody>
              {listUsers?.map((user, i) => {
                const {
                  name,
                  email,
                  role,
                  lastLoggedIn,
                  id,
                  companyName,
                  status,
                } = user;
                let linkStyle = {};
                if (permission !== "interviewer")
                  linkStyle = { ...styles.link };
                const statusColor = { Active: "#2ecc40", Archived: "#d93c33" };

                return (
                  <Tr className="custom-tr" key={i}>
                    <Td>
                      <div
                        style={linkStyle}
                        onClick={() => {
                          if (permission !== "interviewer")
                            navigate(`/admin/user?uid=${id}`);
                        }}
                      >
                        {name}
                      </div>
                    </Td>
                    <Td>
                      <div
                        style={styles.link}
                        onClick={() =>
                          (window.location.href = `mailto:${email}`)
                        }
                      >
                        {email}
                      </div>
                    </Td>
                    {showCompanyName && <Td>{companyName}</Td>}
                    <Td>{roles[role]}</Td>
                    {showArchived && (
                      <Td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              ...styles.statusCircle,
                              background: statusColor[status],
                            }}
                          />
                          {status}
                        </div>
                      </Td>
                    )}
                    <Td>
                      <LastActivity lastLoggedIn={lastLoggedIn} />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

function LastActivity(props) {
  const { lastLoggedIn } = props;
  if (!lastLoggedIn) return "Never";
  if (moment().isSame(lastLoggedIn, "day")) return "Today";
  else return moment(lastLoggedIn).format("l");
}

function sortUsers(users) {
  let res = [];
  const obj = {};
  users.forEach((user) => {
    if (!obj[user.companyName]) obj[user.companyName] = [];
    obj[user.companyName].push(user);
  });
  const cNames = Object.keys(obj).sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;
    return 1;
  });
  cNames.forEach((company) => {
    const ordered = obj[company].sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      return 1;
    });
    res = res.concat(ordered);
  });
  return res;
}
