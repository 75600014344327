import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import {
  Button,
  Modal,
  TextInput,
  TextArea,
  LoadingScreen,
} from "../../Components";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { QuestionsTab } from "../Interview/index";
import { errorRed, themeOrange } from "../../theme";
import { cloneDeep, max, set } from "lodash";

import { createRole, updateInterview } from "../../graphql/mutations";
import { listReviews } from "../../graphql/queries";
import { getInterviewWithAllResponses as getInterview } from "../../graphql/custom";

import { toast } from "react-toastify";
import { GraphQL } from "../../utils/api";
import { stages } from "../../utils/stages";
import { getInterviewUrlsForNotesModal } from "../../utils/getInterviewUrls";
import createParticipantReview from "../../utils/createParticipantReview";
import Audio from "../Summary/Audio";
import { stageMapping } from "../../utils/stageMapping";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { MdThumbDown, MdThumbUp } from "react-icons/md";
import { Auth } from "aws-amplify";


export function DeleteModal(props) {
  const { onClick, modalVisible, setModalVisible, title, description } = props;

  return (
    <Modal
      title={title || ""}
      isVisible={modalVisible}
      onClose={() => setModalVisible(false)}
    >
      <div style={{ padding: 20 }}>
        <div className="hide-scroll">
          <div>{description || ""}</div>
          <div style={{ marginTop: 20, textDecoration: "underline" }} />
        </div>
        <Button title="Yes" filled onClick={onClick} />
        <Button
          title="No"
          onClick={() => setModalVisible(false)}
          style={{ marginLeft: 8 }}
        />
      </div>
    </Modal>
  );
}

export function ParkForLaterModal(props) {
  const { interviewId, modalVisible } = props;

  const [parkForLaterModalVisible, setParkForLaterModalVisible] =
    useState(false);

  const [interviewData, setInterviewData] = useState(null);

  const [candidateName, setCandidateName] = useState("");

  const [currentStage, setCurrentStage] = useState("");

  const [currentScheduling, setCurrentScheduling] = useState({});

  const [parkedDate, setParkedDate] = useState(); // default to today

  const [interviewInformation, setInterviewInformation] = useState({});

  const [currentUser, setCurrentUser] = useState();


  const parkStyle = {
    datePicker: {
      outline: "none",
      height: 40,
      border: "none",
      background: "var(--White-White, #FFF)",
      width: "100%",
    },
  };

  useEffect(() => {
    async function getCurrentUser() {
      const user = await Auth.currentAuthenticatedUser();
      setCurrentUser(user);
      console.log(user);
    }
    getCurrentUser();
  }, []);

  useEffect(() => {
    async function getInterviewData() {
      setInterviewData(null);
      const query = getInterview;
      const variables = { id: interviewId };
      let sched;
      let pDate;
      try {
        await GraphQL({ query, variables, authMode: "" }).then(
          (res) => {
            setInterviewData(res);
            const { interviewInfo, stage, scheduling } = res.getInterview;
            const { candidateName } = JSON.parse(interviewInfo);
            setInterviewInformation(JSON.parse(interviewInfo));
            setCandidateName(candidateName);
            setCurrentStage(stage);
            sched = JSON.parse(scheduling);
            setCurrentScheduling(sched);
            let pDateSched = sched[stage];
            console.log(pDateSched);
            if (pDateSched !== null && pDateSched !== undefined) {
              pDate = new Date(sched[stage]);
              setParkedDate(pDate);
            }
            setParkForLaterModalVisible(modalVisible);  
            
          } 
        );
      } catch (error) {
        toast.error("Error fetching interview data.");
      }
    }
    if (!!interviewId) getInterviewData();
  }, [interviewId]);

  useEffect(() => {
  }, [currentScheduling, parkedDate, interviewInformation]);

  async function submitInterviewForPark() {

    const userWhoParked = {
      username: currentUser.username,
      email: currentUser.attributes.email
    }
    let interviewInfo = cloneDeep(interviewInformation);
    interviewInfo.remindUserWhoParked = userWhoParked;
    setParkForLaterModalVisible(false);
    setCurrentStage("parked");
    const variables = {
      input: {
        id: interviewId,
        stage: currentStage,
        interviewInfo: JSON.stringify(interviewInfo),
        scheduling: JSON.stringify(currentScheduling),
      },
    };
    const query = updateInterview;
    try {
      await GraphQL({ query, variables, authMode: "" }).then((res) => {
        setParkForLaterModalVisible(false);
        props.onClose();
        toast.success("Interview moved to Parked");
      });
    } catch (error) {
      setParkForLaterModalVisible(true);
      console.error("Error updating interview:", error);
      toast.error("Error updating interview.");
    }
  }
  const datePickerRef = useRef(null);
  const handleShowDatePicker = () => {
    datePickerRef.current.setOpen(true);
  }

  function validateTimeIfFuture(selectedDateTime) {
    setCurrentStage("parked");
    const currentDate = new Date();
    if (selectedDateTime < currentDate) {
      toast.error("Please select a future date");
    } else {
      const updateScheduling = cloneDeep(currentScheduling);
      selectedDateTime.setHours(8, 0, 0, 0);
      updateScheduling[currentStage] = selectedDateTime.toISOString();
      setCurrentScheduling(updateScheduling);
      setParkedDate(selectedDateTime);
    }
  }

  return !interviewData ? (
    <LoadingScreen
      style={{ position: "absolute", height: "100%", minHeight: 300 }}
    />
  ) : (
    <div>
      <Modal
        style={{ ...styles.parkModal, overflowY: "hidden", padding: 0}}
        title={[`Park ${candidateName} For Later`]}
        isVisible={parkForLaterModalVisible}
        onClose={() => {
          props.onClose();
          setParkForLaterModalVisible(false);
        }}
      >
      <div style={{padding: 16}}>
          <div className={'fullInputStyle'}>
              <DatePicker className="custom-datepicker"
                  ref={datePickerRef}
                  placeholderText="Select a date"
                  selected={
                    parkedDate
                      ? parkedDate
                      : (() => {
                        const today = new Date();
                        const threeMonthsFromToday = new Date(today);
                        threeMonthsFromToday.setMonth(today.getMonth() + 3);

                        if (threeMonthsFromToday.getDate() !== today.getDate()) {
                          threeMonthsFromToday.setDate(0); // Sets to the last day of the previous month
                        }
                        return threeMonthsFromToday;
                      })()
                  }
                  onChange={(time) => {
                    validateTimeIfFuture(time);
                  }}
                  dateFormat="EEEE, yyyy-MM-dd"
                />
            <button className="date-picker-icon" onClick={handleShowDatePicker}/>
          </div>
          <div style={{ marginTop: 32, marginBottom: 24 }}>
              This interview will be marked released and you will be reminded via email on this date.
          </div>
          <div style={{justifyContent: "space-between", display: "flex", width: 376, bottom: "16px", position: "absolute"}}>
            <Button
              title="OK"
              filled
              onClick={submitInterviewForPark}
              style={{ width: "48%"}}
            />
            <Button
              title="Cancel"
              onClick={() => {
                props.onClose();
                setParkForLaterModalVisible(false);
              }}
              style={{ width: "48%", bottom: 16}}
            />
          </div>
          
        </div>
      </Modal>
    </div>
  );
}

export function ScheduleModal(props) {
  const { modalVisible, interviewId, mode = "edit" } = props;

  const editMode = mode === "edit";

  const [interviewData, setInterviewData] = useState();

  const [scheduling, setScheduling] = useState({});

  const [stage, setStage] = useState("");

  const [formalStage, setFormalStage] = useState("");

  const [scheduleModalVisible, setScheduleModalVisible] = useState(false);

  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    async function getInterviewData() {
      try {
        const query = getInterview;
        const variables = { id: interviewId };
        const res = await GraphQL({ query, variables, authMode: "" });

        setScheduleModalVisible(modalVisible);
        setInterviewData(res);
        if (res) {
          const { interviewInfo, stage, scheduling } = res.getInterview;
          setScheduling(scheduling);
          setStage(stage);
          const { dateTime } = JSON.parse(interviewInfo);
          if (scheduling) setScheduling(JSON.parse(scheduling));
          else setScheduling({ [stage]: dateTime });
        }
      } catch (error) {
        console.error("Error fetching interview data:", error);
      }
    }

    if (!!interviewId) getInterviewData();
  }, [interviewId, editMode]);

  useEffect(() => {
    setStageProperName(stage);
  });

  const setStageProperName = (stage) => {
    if (stage === "discovery") setFormalStage("Discovery Interview");
    if (stage === "value1") setFormalStage("Value Working Session");
    if (stage === "working") setFormalStage("Working Session");
    if (stage === "value2") setFormalStage("Final Interview");
    if (stage === "offer") setFormalStage("Accepted");
  };

  return !interviewData ? (
    <LoadingScreen
      style={{ position: "absolute", height: "100%", minHeight: 300 }}
    />
  ) : (
    <Modal
      className="notes-modal"
      style={{ ...styles.scheduleModal, overflowY: "hidden" }}
      title="Schedule Interview"
      isVisible={scheduleModalVisible}
      onClose={() => {
        props.onClose();
        setScheduleModalVisible(false);
      }}
    >
      <div className="hide-scroll">
        <div
          style={{ ...styles.scheduleInputLabel, width: 380, marginLeft: 24 }}
        >
          {`Interview Date ` + formalStage}
        </div>
        <div style={styles.scheduleDateInput}>
          <DatePicker
            selected={scheduling[stage] ? new Date(scheduling[stage]) : null}
            onChange={(time) => {
              const updateScheduling = cloneDeep(scheduling);
              updateScheduling[stage] = time;
              setScheduling(updateScheduling);
            }}
            showTimeSelect
            dateFormat="Pp"
            timeIntervals={15}
          />
        </div>
        <Button
          filled
          onClick={onUpdateSchedule}
          style={styles.scheduleButton}
          waiting={waiting}
        >
          Update Schedule
        </Button>
      </div>
    </Modal>
  );

  async function onUpdateSchedule() {
    setWaiting(true);
    const { interviewInfo, stage } = interviewData.getInterview;
    const {
      candidateName,
      candidateEmail,
      candidateRole,
      interviewers,
      participants,
      review,
    } = JSON.parse(interviewInfo);
    let formattedScheduleDate = scheduling[stage].toISOString();
    let jsonSchedule = JSON.stringify({ [stage]: formattedScheduleDate });
    const variables = {
      input: {
        id: interviewId,
        interviewInfo: JSON.stringify({
          candidateName,
          candidateEmail,
          candidateRole,
          interviewers,
          dateTime: jsonSchedule,
          participants,
          review,
        }),
        stage,
        scheduling: jsonSchedule,
      },
    };
    const query = updateInterview;
    const res = await GraphQL({ query, variables, authMode: "" });
    setScheduleModalVisible(false);
    setWaiting(false);
    toast.success("Interview updated successfully.");
    return setTimeout(() => {
      window.location.reload();
    }, 3000);
  }
}

export function NotesModal(props) {
  const { stage, interviewId, interviewers, mode = "edit" } = props;

  const editMode = mode === "edit";

  const [interviewData, setInterviewData] = useState(null);

  const [reviews, setReviews] = useState([]);
  const [currentTab, setCurrentTab] = useState("");

  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [added, setAdded] = useState(false);

  const audioRef = useRef();
  const [audioSrc, setAudioSrc] = useState(null);

  const [elapsed, setElapsed] = useState(0);

  const [author, setAuthor] = useState(null);

  const [myInterviewers, setMyInterviewers] = useState(interviewers || null);
  const [noteUrls, setNoteUrls] = useState([]);

  const [review, setReview] = useState(null);

  const [paused, setPaused] = useState(true);

  useEffect(() => {
    // grab interview data for dashboard viewing
    async function getInterviewData() {
      // setWaiting(true);
      setInterviewData(null);
      const query = getInterview;
      const variables = { id: interviewId };
      const res = await GraphQL({ query, variables, authMode: "" });
      setInterviewData(res);
      // setWaiting(false);
    }
    if (!!interviewId) getInterviewData();
  }, [interviewId, editMode]);

  useEffect(() => {
    // get urls for audio files
    async function getUrls() {
      setNoteUrls([]);
      let urls = [];
      if (!!interviewId)
        urls = await getInterviewUrlsForNotesModal(interviewId);
      setNoteUrls(urls);
    }
    getUrls();
  }, [currentTab, interviewId]);

  useEffect(() => {
    // grab review data from interview
    setReview(null);
    setMyInterviewers(null);
    if (interviewData) {
      const interviewInfo = JSON.parse(
        interviewData.getInterview.interviewInfo
      );
      setReview(interviewInfo.review);
      setMyInterviewers(interviewInfo.interviewers);
    }
  }, [interviewData, setReview]);

  useEffect(() => {
    if (audioRef.current) setAudioSrc(audioRef.current.audio.current);
  }, [audioRef]);

  useEffect(() => {
    // make the current notes tab default to the latest stage
    if (review) {
      let latestStage = "";
      for (const stage of Object.keys(stages).sort(
        (a, b) => stages[a].order - stages[b].order
      )) {
        if (Object.keys(review).includes(stage)) latestStage = stage;
      }
      setCurrentTab(latestStage);
    } else setCurrentTab("discovery");
  }, [review, stage]);

  useEffect(() => {
    // get participant reviews for the interview
    async function getReviews() {
      const query = listReviews;
      const res = await GraphQL({ query, authMode: "" });
      setReviews(
        res.listReviews.items.filter(
          (review) => review.interviewId === interviewId
        )
      );
    }
    setReviews([]);
    getReviews();
  }, [interviewId, added]);

  const questionResponses =
    interviewData?.getInterview.questionResponses.items.sort((a, b) => {
      const interviewerNameA = a.interviewerName;
      const interviewerNameB = b.interviewerName;
      const myInterviewerName = myInterviewers?.[stage]?.name;
      if (
        interviewerNameA === myInterviewerName &&
        interviewerNameB !== myInterviewerName
      )
        return -1;
      else if (
        interviewerNameB === myInterviewerName &&
        interviewerNameA !== myInterviewerName
      )
        return 1;
      else return 0;
    });

  const currentStageHasQRs =
    questionResponses?.filter((qr) => qr.stage === stageMapping[currentTab])
      .length > 0;

  return (
    <Modal
      className="notes-modal"
      style={{ ...styles.notesModal, overflowY: "hidden" }}
      title="Interview Notes"
      titleButton={
        !!(editMode && review && review[currentTab]) ? (
          <>
            <Button
              onClick={() => {
                setAuthor("participant");
                setReviewModalVisible(true);
              }}
              style={{ width: 250 }}
            >
              Add Participant Review
            </Button>
            <Button
              filled
              onClick={async () => {
                const updatedInterviewInfo =
                  cloneDeep(interviewData).getInterview.interviewInfo;
                let updated = JSON.parse(updatedInterviewInfo);
                updated.review = review;
                const input = JSON.stringify(updated);

                const variables = {
                  input: {
                    id: interviewId,
                    interviewInfo: input,
                  },
                };
                const query = updateInterview;
                await GraphQL({ query, variables, authMode: "" });
                toast.success("Notes updated.", { autoClose: 3000 });
                window.location.reload();
              }}
              style={{ marginLeft: 10 }}
            >
              Save
            </Button>
          </>
        ) : null
      }
      isVisible={true}
      onClose={() => {
        props.onClose();
        setElapsed(0);
        setNoteUrls([]);
        if (audioSrc) audioSrc.pause();
      }}
      loading={(!editMode && !interviewData) || !noteUrls}
      stickyFooter={
        <Audio
          audioURLs={noteUrls}
          elapsed={elapsed}
          setElapsed={setElapsed}
          selectedStage={currentTab}
          background={"#fff"}
          {...{ paused, setPaused, audioRef, audioSrc, setAudioSrc }}
        />
      }
    >
      {(!editMode && !interviewData) || !noteUrls ? (
        <LoadingScreen style={{ height: "100%", minHeight: 300 }} />
      ) : (
        <div style={{ height: "100%", padding: "0px 20px" }}>
          <NewReviewModal
            {...{
              reviewModalVisible,
              setReviewModalVisible,
              currentTab,
              interviewId,
              setAdded,
              author,
              review,
              setReview,
            }}
          />
          <div style={styles.tabRow}>
            {Object.keys(stages).map((tab) => {
              return (
                <QuestionsTab
                  key={tab}
                  selected={currentTab === tab}
                  onClick={() => setCurrentTab(tab)}
                  label={stages[tab].label}
                />
              );
            })}
          </div>
          {review ? (
            <div key={stage} className="hide-scroll">
              {review[currentTab] ? (
                <div
                  key={stage}
                  style={{
                    ...styles.stageNotes,
                    background: `${themeOrange}32`,
                  }}
                >
                  <div style={styles.noteHeader}>
                    <p>
                      Interviewer:{" "}
                      {!!myInterviewers && myInterviewers[currentTab].name}
                    </p>
                  </div>
                  <TextInput
                    label="Accepted"
                    disabled={!editMode}
                    inputStyle={{ ...styles.inputStyle, width: "100%" }}
                    value={review[currentTab].pass}
                    onChange={(u) => changeNotes(currentTab, "pass", u)}
                  />
                  <TextArea
                    label="Reason"
                    disabled={!editMode}
                    inputStyle={{ ...styles.areaInputStyle, marginBottom: 8 }}
                    value={review[currentTab].reason}
                    onChange={(u) => changeNotes(currentTab, "reason", u)}
                    rows={2}
                  />
                  {/* check if now using new questionReponse reviews */}
                  {!!interviewData?.getInterview?.interviewInfo &&
                    JSON.parse(interviewData?.getInterview?.interviewInfo)
                      .review[currentTab]?.notes && (
                      <TextArea
                        label="Notes"
                        disabled={!editMode}
                        inputStyle={{
                          ...styles.areaInputStyle,
                          marginBottom: 10,
                        }}
                        value={review[currentTab].notes}
                        onChange={(u) => changeNotes(currentTab, "notes", u)}
                        rows={12}
                      />
                    )}
                  {review[currentTab].timestamps &&
                    review[currentTab].timestamps.length > 0 &&
                    renderTimestamps()}
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ marginBottom: 12 }}>No notes to display.</p>
                  {editMode && (
                    <Button
                      style={{ width: 270 }}
                      onClick={() => {
                        setAuthor("interviewer");
                        setReviewModalVisible(true);
                      }}
                    >
                      Add Interviewer Review
                    </Button>
                  )}
                </div>
              )}
              {renderParticipantReviews()}
            </div>
          ) : (
            <div style={{ marginBottom: 12 }}>No review submitted.</div>
          )}
          {myInterviewers && currentStageHasQRs && (
            <>
              <div style={{ fontWeight: "bold", marginBottom: 6 }}>Notes</div>
              <div style={{ height: "100%", width: "100%" }}>
                {questionResponses
                  .filter((qr) => qr.stage === stageMapping[currentTab])
                  .sort((a, b) => a.order - b.order)
                  .map((qr) => (
                    <QuestionResponseDisplay
                      key={qr.id}
                      questionResponse={qr}
                      setElapsed={setElapsed}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );

  function renderTimestamps() {
    return (
      <div style={{ marginBottom: 12 }}>
        <div style={styles.inputLabel}>Timestamps</div>
        {review[currentTab].timestamps.map((timestamp, i) => {
          const { timeStamp, question, accept } = timestamp;
          let icon = accept ? (
            <MdThumbUp color="#16A000" />
          ) : (
            <MdThumbDown color={errorRed} />
          );
          let t = timeStamp.split(":");
          let timeInSeconds = +t[0] * 60 * 60 + +t[1] * 60 + +t[2];
          return (
            <div style={{ display: "flex", justifyContent: "left" }} key={i}>
              <div style={{ margin: "4px 6px 0px 0px" }}>{icon}</div>
              <div style={styles.timestamp}>
                <p
                  style={styles.timestampLink}
                  onClick={() => {
                    setElapsed(timeInSeconds);
                  }}
                >
                  {timeStamp}
                </p>
                {` ${question}`}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  function renderParticipantReviews() {
    return reviews.map((pReview) => {
      const { stage } = JSON.parse(pReview.notes);
      if (stage === currentTab)
        return <ParticipantReview key={pReview.id} pReview={pReview} />;
      return null;
    });
  }

  function changeNotes(stage, field, value) {
    const updatedReview = cloneDeep(review);
    updatedReview[stage][field] = value;
    setReview(updatedReview);
  }
}

function QuestionResponseDisplay(props) {
  const { questionResponse, setElapsed } = props;
  const {
    id,
    order,
    question,
    rating,
    timestamp,
    interviewerName,
    notes,
    subquestionResponses,
  } = questionResponse;

  let ratings = {
    positive: <FaThumbsUp color="#16A000" />,
    negative: <FaThumbsDown color={errorRed} />,
    neutral: (
      <FaThumbsDown color="#e1c200" style={{ transform: "rotate(-90deg)" }} />
    ),
  };
  let t;
  let timeInSeconds;
  if (timestamp) {
    t = timestamp?.split(":");
    timeInSeconds = +t[0] * 60 * 60 + +t[1] * 60 + +t[2];
  }

  const regularSubquestions = subquestionResponses.items.filter(
    (subQR) => !/^\(.*\)/.test(subQR.question)
  );
  const prefixedSubquestions = subquestionResponses.items.filter((subQR) =>
    /^\(.*\)/.test(subQR.question)
  );
  // Sort both arrays by order
  regularSubquestions.sort((a, b) => a.order - b.order);
  prefixedSubquestions.sort((a, b) => a.order - b.order);

  return (
    <div
      key={id}
      style={{
        marginBottom: 12,
        borderLeft: `2px solid ${themeOrange}`,
        paddingLeft: 6,
      }}
    >
      <div
        style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
      >
        Q{order + 1} -{" "}
        {!!timestamp && timestamp !== "N/A" && (
          <p
            style={styles.timestampLink}
            onClick={() => setElapsed(timeInSeconds)}
          >
            {timestamp}
          </p>
        )}
        <div style={{ fontSize: 18, margin: "0px 6px 0px 6px" }}>
          {ratings[rating]}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <div style={{ fontWeight: "bold", marginBottom: 4 }}>{question}</div>
      </div>
      <div style={{ borderRadius: 8, padding: 6, background: "#eee" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          Interviewer: <p style={{ marginLeft: 6 }}> {interviewerName}</p>
        </div>
        Notes:{" "}
        <span style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
          {notes}
        </span>
        <div>
          {/* Render regular subquestions first */}
          {regularSubquestions.map((subQR, i) => (
            <div key={subQR.id}>
              <span>
                {i + 1}. {subQR.question} -{" "}
              </span>
              <span style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
                {subQR.notes}
              </span>
            </div>
          ))}
          {/* Render prefixed subquestions after */}
          {prefixedSubquestions.map((subQR, i) => (
            <div key={subQR.id}>
              <span>{subQR.question} - </span>
              <span style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
                {subQR.notes}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function NewReviewModal(props) {
  const {
    reviewModalVisible,
    setReviewModalVisible,
    currentTab,
    interviewId,
    setAdded,
    author,
    review,
    setReview,
  } = props;

  const [pass, setPass] = useState(null);
  const [reason, setReason] = useState("");
  const [notes, setNotes] = useState("");

  //participants only
  const [name, setName] = useState("");

  let title = `Add ${
    author === "participant" ? "Participant" : "Interviewer"
  } Review`;

  return (
    <Modal
      style={styles.pReviewModal}
      title={title}
      isVisible={reviewModalVisible}
      onClose={() => {
        setReviewModalVisible(false);
      }}
    >
      <div style={{ padding: 20 }}>
        <div style={{ marginBottom: 20 }}>
          <div style={{ marginRight: 20 }}>
            {author === "participant" && (
              <>
                <div style={styles.inputLabel}>Participant Name</div>

                <TextInput
                  inputStyle={styles.inputStyle}
                  value={name}
                  onChange={setName}
                />
              </>
            )}
            <div
              className="hor-center"
              style={{ justifyContent: "flex-start" }}
            >
              <div style={styles.pInputLabel}>
                Should this candidate move on to the next stage?
              </div>
              <div style={{ marginBottom: 10 }}>
                <RadioGroup onChange={setPass} value={pass}>
                  <Stack direction="row">
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Stack>
                </RadioGroup>
              </div>
            </div>
          </div>
          <div style={styles.pInputLabel}>Why or why not?</div>
          <TextArea
            inputStyle={styles.areaInputStyle}
            value={reason}
            onChange={setReason}
          />
          <div style={styles.inputLabel}>Interview Notes</div>
          <TextArea
            inputStyle={styles.areaInputStyle}
            value={notes}
            onChange={setNotes}
            rows={8}
          />
        </div>
        <Button title="Add" filled onClick={onSubmitForm} />
      </div>
    </Modal>
  );

  async function onSubmitForm() {
    if (!pass || !reason || (author === "participant" && !name))
      return toast.error("All fields are required.");
    if (author === "participant") {
      try {
        createParticipantReview(
          { stage: currentTab, pass, reason, notes },
          interviewId,
          name,
          true //notesMode
        );
        toast.success("Participant review added.");
        setAdded(true);
        setReviewModalVisible(false);
      } catch (e) {
        console.log(e);
      }
    } else if (author === "interviewer") {
      let updatedReview = cloneDeep(review);
      updatedReview[currentTab] = {
        pass,
        reason,
        notes,
      };
      setReview(updatedReview);
      setReviewModalVisible(false);
      toast.success("Review added.");
    }
  }
}

function ParticipantReview(props) {
  const { id, author, notes: myNotes } = props.pReview;

  const [pNotes, setPNotes] = useState(null);

  useEffect(() => {
    setPNotes(JSON.parse(myNotes));
  }, [myNotes]);

  if (pNotes)
    return (
      <div
        key={id}
        style={{ ...styles.stageNotes, background: `${themeOrange}32` }}
      >
        <div style={styles.noteHeader}>Participant: {author}</div>
        <div style={{ ...styles.inputLabel, marginBottom: 14 }}>
          Accepted: {pNotes.pass}
        </div>
        <div
          style={{ ...styles.inputLabel, minWidth: "100%", marginBottom: 14 }}
        >
          Reason: {pNotes.reason}
        </div>
        <div style={{ ...styles.inputLabel, fontWeight: "bold" }}>Notes</div>
        <div style={{ whiteSpace: "pre-line" }}>{pNotes.notes}</div>
      </div>
    );
}

export function RoleModal(props) {
  const {
    roleModalVisible,
    setRoleModalVisible,
    companyID,
    setCandidateRole,
    getCompanyData,
  } = props;

  const [roleName, setRoleName] = useState("");

  return (
    <Modal
      style={styles.roleModal}
      title="Add a Role"
      isVisible={roleModalVisible}
      onClose={() => setRoleModalVisible(false)}
    >
      <div style={{ padding: 20 }}>
        <div style={styles.inputLabel}>Role Name</div>
        <TextInput
          inputStyle={styles.inputStyle}
          value={roleName}
          onChange={setRoleName}
        />
        <div style={{ marginTop: 20 }}>
          <Button title="Add" filled onClick={onCreateRole} />
        </div>
      </div>
    </Modal>
  );

  async function onCreateRole() {
    if (!roleName) return toast.error("Name is required.");
    const query = createRole;
    const variables = {
      input: {
        name: roleName,
        workingSession: [],
        group: companyID,
        companyID,
      },
    };
    const res = await GraphQL({ query, variables, authMode: "" });
    toast.success("Role created successfully.");
    const roleId = res.createRole.id;
    setCandidateRole(roleId);
    setRoleModalVisible(false);
    getCompanyData();
  }
}

export function UrlModal(props) {
  const {
    urlModalVisible,
    setUrlModalVisible,
    urlWaiting,
    downloadUrls = [],
    stages,
  } = props;

  return (
    <Modal
      style={{ width: "50%" }}
      title="Download Audio"
      isVisible={urlModalVisible}
      onClose={() => setUrlModalVisible(false)}
    >
      {renderUrlModal()}
    </Modal>
  );

  function renderUrlModal() {
    if (!urlWaiting && downloadUrls.length === 0)
      return (
        <div style={{ padding: 20 }}>No interview audio files recorded.</div>
      );
    return (
      <div style={{ padding: 20 }}>
        <div className="hide-scroll">
          {urlWaiting && <LoadingScreen height={50} width={50} />}
          {!urlWaiting &&
            Object.keys(downloadUrls)
              .sort((a, b) => stages[b].order - stages[a].order)
              .map((url, i) => {
                if (downloadUrls[url])
                  return (
                    <div key={url}>
                      <a
                        href={downloadUrls[url]}
                        download
                        style={{
                          ...styles.downloadButton,
                          marginTop: i === 0 ? 0 : 16,
                        }}
                      >
                        {stages[url].label} Audio
                      </a>
                    </div>
                  );
                return null;
              })}
        </div>
      </div>
    );
  }
}

export function CompaniesModal(props) {
  const { companies, navigate } = props;
  if (companies)
    return (
      <div>
        {companies.length === 0 && (
          <div style={{ padding: "0px 20px 20px" }}>
            No companies created yet.
          </div>
        )}
        {companies.length > 0 && (
          <>
            <div style={{ padding: "0px 20px", marginBottom: 30 }}>
              Choose a customer to create an interview for:
            </div>
            <div
              style={{
                maxHeight: "calc(80vh - 133px)",
                width: "100%",
                overflowY: "auto",
              }}
            >
              {companies
                .sort((a, b) => {
                  if (a.company.toLowerCase() < b.company.toLowerCase())
                    return -1;
                  else return 1;
                })
                .map((company) => {
                  return (
                    <div key={company.id} style={{ padding: "0px 20px" }}>
                      <Button
                        filled
                        style={{ width: "100%", marginBottom: 12 }}
                        title={company.company}
                        onClick={() => {
                          navigate(
                            `/admin/interview?mode=create&cid=${company.id}`
                          );
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
    );
}

const styles = {
  notesModal: {
    minWidth: "70%",
    height: "85%",
    borderRadius: 0,
  },
  scheduleModal: {
    minWidth: "30%",
    height: "40%",
    borderRadius: 10,
    scroll: "hidden",
  },
  parkModal: {
    minWidth: 420,
    height: 352,
    borderRadius: 10,
    scroll: "hidden",
    maxWidth: 420,
    width: 420,
    paddingBottom: 0,
  },
  datePicker: {
    outline: "none",
    height: 40,
    border: "none",
    background: "var(--White-White, #FFF)",
    width: "100%",
},
  dateInput: {
    border: "1px solid #555",
    padding: 8,
    marginTop: 8,
    marginLeft: 24,
    borderRadius: 4,
    maxWidth: 190,
    height: 40,
    minWidth: 180,
  },
  scheduleDateInput: {
    border: "1px solid #555",
    padding: 8,
    marginTop: 8,
    marginLeft: 24,
    borderRadius: 4,
    maxWidth: 300,
    height: 40,
    minWidth: 180,
  },

  reconsiderDateInput: {
    border: "1px solid #555",
    padding: 8,
    borderRadius: 4,
    maxWidth: 368,
    height: 40,
    minWidth: 180,
  },

  scheduleButton: {
    height: 44,
    minWidth: 180,
    width: 300,
    marginTop: 24,
    marginLeft: 24,
  },
  stageNotes: {
    borderRadius: 8,
    padding: 12,
    marginBottom: 12,
  },
  noteHeader: {
    fontWeight: "bold",
    marginBottom: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputStyle: {
    width: 350,
    height: 40,
    border: "1px solid #555",
    borderRadius: 4,
  },
  inputLabel: {
    width: 180,
    maxWidth: 180,
  },
  scheduleInputLabel: {
    width: 180,
    maxWidth: 380,
    marginBottom: 16,
  },
  areaInputStyle: {
    padding: 5,
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #555",
    borderRadius: 4,
    whiteSpace: "pre-line",
  },
  roleModal: {
    minWidth: "40%",
  },
  downloadButton: {
    border: `2px solid ${themeOrange}`,
    borderRadius: 8,
    display: "inline-flex",
    width: 400,
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    minHeight: 38,
    padding: "0 16px",
    color: themeOrange,
  },
  tabRow: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    marginBottom: 20,
    width: "100%",
  },
  timestamp: {
    margin: "2px 0px",
    fontSize: 16,
    display: "flex",
    justifyContent: "center",
  },
  pInputLabel: {
    width: 500,
    marginBottom: 12,
  },
  pReviewModal: {
    minWidth: 700,
    width: 700,
    overflowY: "hidden",
  },
  timestampLink: {
    color: "#3355dd",
    textDecoration: "underline",
    cursor: "pointer",
    margin: "0px 6px",
    letterSpacing: 0.5,
  },

  datePicker: {
    outline: "none",
    height: 40,
    border: "none",
    background: "var(--White-White, #FFF)",
    width: "100%",
  },
};
