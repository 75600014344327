import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { getCompany, createJobRole, updateJobRoleStatus } from "../utils/api";
import { useNavigate } from "react-router-dom";
import { color } from '@chakra-ui/react';

const RoleTableList = ({ jobRoleList, isAdmin, setRoleList, setShowStatusMessage, setHeaderText, setContentText, setRenderComplete }) => {

  const [jobRoles, setJobRoles] = useState([]);

  const [showActionList, setShowActionList] = useState(null);

  const [actionList, setActionList] = useState(['Edit', 'Duplicate', 'Mark Hired and Close', 'Archived'])

  const [updateTable, setUpdateTable] = useState(false);

  const dropdownRef = useRef(null);
  const [position, setPosition] = useState({ top: '100%', bottom: 'auto' });

  const navigate = useNavigate();

 const fetchJobRoles = async () => {
    const jobRolesWithCompany = await Promise.all(jobRoleList.map(async (jobRole) => {
      const company = await getCompany(jobRole.companyID);
      const hManagerDataItems = company.users.items;
      const hManager = hManagerDataItems.find(user => user.id === jobRole.jobRoleData.hiringManager);
      
      const dateObject = new Date(jobRole.createdAt);
      const month = dateObject.toLocaleString('default', { month: 'short' });
      const day = dateObject.getDate();
      const year = dateObject.getFullYear();
      const formattedDate = `${month} ${day}, ${year}`;

      return {
        ...jobRole,
        company,
        hiringManager: hManager,
        formattedDate,
      };
    }));
    const sortedRolesWithCompany = jobRolesWithCompany.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setJobRoles(sortedRolesWithCompany);
    setUpdateTable(false);
    setRenderComplete(true);
  };

  useEffect(() => {
    fetchJobRoles();
  }, [jobRoleList, updateTable]);

  useEffect(() => {
    if (showActionList && dropdownRef.current) {
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        if (dropdownRect.bottom > viewportHeight) {
            setPosition({ top: 'auto', bottom: '100%' });
        } else {
            setPosition({ top: '100%', bottom: 'auto' });
        }
    }
}, [showActionList]);

  const handleButtonClick = (jobRoleId) => {
    setShowActionList(showActionList === jobRoleId ? null : jobRoleId);
  };

  const handleAction = (action, selectedJobRole) => {
    if (action === 'Edit') {
      navigate(`/admin/jobrole?compID=${selectedJobRole.company.id}`, { state: { currentJobRole: selectedJobRole, mode : 'edit'}})
    } else if (action === 'Duplicate') {
       handleDuplicateRole(selectedJobRole);
    } else if (action === 'Mark Hired and Close') {
       handleMarkAsClosedAndHired(selectedJobRole);
    }
    else if (action === 'Archived') {

    }
  }
  const handleDuplicateRole = async (jobRole) => {
    try {
      setShowActionList(false);
      const params = {
          companyID: jobRole.companyID,
          jobRoleData: jobRole.jobRoleData,
          workingSession: jobRole.workingSession ? jobRole.workingSession : []
      };
      console.log(params);
      await createJobRole(params).then((res) => {
          if(res) {
            setUpdateTable(true);
            setRoleList((prevRoleList) => [...prevRoleList, res.createJobRole]);
            setShowStatusMessage(true);
            setHeaderText('Successful!');
            setContentText(`${jobRole.jobRoleData.title} Duplicated Successfully!`);
          }
      });
    } catch (error) {
        console.error('Job Creation Failed:', error);
    }
  }

  const handleMarkAsClosedAndHired = async (jobRole) => {
    try {
      setShowActionList(false);
      const params = {
          id: jobRole.id,
          status: 'Hired'
      };
      await updateJobRoleStatus(params).then((res) => {
          if(res) {
            setUpdateTable(true);
            setRoleList((prevRoleList) =>
              prevRoleList.map((role) =>
                  role.id === res.id
                      ? { ...role, ...res }
                      : role
              )
            );
            setShowStatusMessage(true);
            setHeaderText('Successful!');
            setContentText(`Congratulations for Hiring ${jobRole.jobRoleData.title}!`);
          }
      });
    } catch (error) {
        console.error('Job Creation Failed:', error);
    }
  }
 
  const styles = {
      listTable : {
        display: "flex",
        flexDirection: "column"
      },
     
      listHeader: {
        display: "flex",
        color: "var(--Primary-Text, #374151)",
        justifyContent: "space-between",
        /* Body Medium/SemiBold */
        fontFamily: "Montserrat",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 600
      },
      listContent: {
        borderTop: "1px solid var(--Stroke, #DFE4EA)",
        height: "100%",
        overflow: "scroll", 
        maxHeight: "calc(100vh - 248px)",
        scrollBehavior: "smooth"
      },
      listRowContent: {
        display: "flex", 
        fontFamily: "Montserrat",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        justifyContent: "space-between",
        
      },
      titleHeaderStyle : {
        flex: 1,
        padding: '8px',
        textAlign: 'left', 
      },
      titleColumnStyle : {
        flex: 1,
        padding: '8px',
        textAlign: 'left', 
        textDecoration: 'underline',
        color: 'var(--Primary, navy)',
      },
      columnStyle : {
        flex: 0.75,
        padding: '8px',
        textAlign: 'left', 
      }, 
      actionColumnStyle: {
        flex: 0.2,
        padding: '8px',
        textAlign: 'right', 
        display: 'flex',
        justifyContent: 'flex-end',
        position : 'relative',
        marginRight: 8
      },
      dropDownAction: {
        position: 'absolute',
        top: 'auto',
        right: 5,
        backgroundColor: '#f9f9f9',
        borderRadius: 16,
        background: "var(--White-White, #FFF)",
        boxShadow: "0px 1px 3px 0px rgba(166, 175, 195, 0.40)",
        zIndex: 1,
        listStyleType: 'none',
        padding: "16px 0px",
        margin: '0',
        width: 204,
        height : 142,
        bottom: "100%",
      },
      listItemStyle: {
        padding: '8px 16px',
        cursor: 'pointer',
        backgroundColor: 'white',
        display : 'flex'
      },
      listItemHoverStyle: {
        backgroundColor: 'var(--Cyan-Cyan-light-3, #D0F0FD)',
        color: "var(--Primary, #F60)",
      }, 
  };
  return !jobRoleList.lenght < 0  ? (
    <div>Click on 'New Role' button to begin </div>
  ) 
  : (
    <div style={styles.listTable}>
      <div style={styles.listHeader}>
        <div style={styles.titleHeaderStyle}>Title</div>
        {isAdmin && (
            <div style={styles.columnStyle}>
              <div>Company</div>
              <div style={{fontSize: 9}}>(Super Admins Only)</div>
            </div>
          )
        }
        <div style={styles.columnStyle}>Hiring Manager</div>
        <div style={styles.columnStyle}>Created </div>
        <div style={styles.columnStyle}>Status</div>
        <div style={styles.actionColumnStyle}>Actions</div>
      </div>
      
      <div style={styles.listContent}>
          {jobRoles.map((jobRole) => (
            <div key={jobRole.id} style={styles.listRowContent}>
              <button 
                style={styles.titleColumnStyle}
                onClick={() => navigate(`/admin/jobrole?compID=${jobRole.company.id}`, { state: { currentJobRole: jobRole, mode : 'edit'}})}
              >{jobRole.jobRoleData.title}</button>
              {isAdmin && (
                <div style={styles.columnStyle}>{jobRole.company.company}</div>
              )}
              <div style={styles.columnStyle}>
                {jobRole.hiringManager
                  ? `${jobRole.hiringManager.firstName} ${jobRole.hiringManager.lastName}`
                  : '-'}
              </div>
              <div style={styles.columnStyle}>{jobRole.formattedDate}</div>
              <div style={styles.columnStyle}>{jobRole.status}</div>
              <div style={styles.actionColumnStyle}>
                <button
                  className='more-horiz'
                  onClick={() => handleButtonClick(jobRole.id)}
                >
                </button>
                {showActionList === jobRole.id && (
                  <ul 
                    ref={dropdownRef}
                    style={{...styles.dropDownAction, ...position}}
                  >
                    {actionList.map((action) => (
                      <li
                        key={action.toLowerCase().trim()}
                        style={styles.listItemStyle}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor =
                            styles.listItemHoverStyle.backgroundColor)
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = 'white')
                        }
                        onClick={() => handleAction(action, jobRole)}
                      >
                        {action}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default RoleTableList;