import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import "./index.scss";
import "./tailwind.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { toast } from "react-toastify";

const { REACT_APP_ENV } = process.env;

// Sentry.init({
//    dsn: "https://82354d0ed83a4566577415ab14c810da@o4506713428066304.ingest.us.sentry.io/4506713435930624",
//    integrations: [
//      Sentry.browserTracingIntegration(),
//      Sentry.replayIntegration({
//        maskAllText: false,
//        blockAllMedia: false,
//      }),
//    ],
// // Performance Monitoring
// tracesSampleRate: 1.0, //  Capture 100% of the transactions
// // Set 'tracePropagationTargets' to control which URLs distributed tracing should be enabled
// tracePropagationTargets: [/^https:\/\/app\.intertru\.ai/],
// // Session Replay
// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <div id="main-container">
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </div>

  // </React.StrictMode>
);
if (!["staging", "dev"].includes(REACT_APP_ENV)) {
  // for development only
  window.addEventListener("error", (e) => toast.error(e.message));
  window.addEventListener("unhandledrejection", (e) =>
    toast.error(e.reason.message)
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
