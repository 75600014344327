import React from "react";
import ReactLoading from "react-loading";
import { themeColor } from "../theme";

export default function Loader(props) {
  const { display, height = "auto" } = props;
  return (
    <span style={{ position: "relative" }}>
      <ReactLoading
        className={`loader ${display ? "" : "hide"}`}
        type="cylon"
        color={themeColor}
        width={40}
        height={height}
      />
    </span>
  );
}

export function Spokes(props) {
  const { color = themeColor } = props;
  return (
    <div style={{ ...styles.loader, ...props.style }}>
      <ReactLoading type="spokes" color={color} />
    </div>
  );
}

export function LoadingScreen(props) {
  const { color = themeColor, height = 100, width = 100 } = props;
  return (
    <div style={{ ...styles.loadingScreen, ...props.style }}>
      <ReactLoading type="spin" color={color} height={height} width={width} />
    </div>
  );
}

const styles = {
  loader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 200,
  },
  loadingScreen: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100% - 68px)",
  },
};
