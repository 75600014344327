import { Th, Thead, Tr } from "@chakra-ui/react";
import * as React from "react";
import { styles } from "../../styles";
import { TextWithInfoTooltip } from "../general/TextWithInfoTooltip";

export const QuestionTableHeaders = ({showActions = false}) => <Thead style={styles.tableHeader}>
    <Tr className="header-tr">
        <Th className="header-th">
            <TextWithInfoTooltip content="Title" tooltipLabel="Short Name or Title for this Question" />
        </Th>
        <Th className="header-th">
            <TextWithInfoTooltip content="Text" tooltipLabel="Question Text" />
        </Th>
        <Th className="header-th">
            <TextWithInfoTooltip content="Also Relates To" tooltipLabel="Other attributes that are also related to this question" />
        </Th>
        {showActions && <Th className="header-th" isNumeric>Actions</Th>}
    </Tr>
</Thead>;
