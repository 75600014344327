import { Button, Center, Container, Flex, HStack, Heading, Spacer, Text, Tooltip } from "@chakra-ui/react";
import * as React from "react";
import { AiFillPlusCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { isEmpty } from "../general/isEmpty";
import { EmptyQuestions } from "./EmptyQuestions";
import { HelpForAttributeQuestion } from "./HelpForAttributeQuestion";
import { MoreQuestionsIconWithTooltip } from "./MoreQuestionsIconWithTooltip";
import { QuestionModal } from "./QuestionModal";
import { QuestionTable } from "./QuestionTable";

export const QuestionTableWithAddButtons = ({ customerId, suggestions, setSuggestions, weightedValues, valueId, attributeTitle, attributeId, questions, setState, existingQuestions }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    return <Container maxWidth="100%">
        <Flex width="100%" mb={2}>
            <Center gap={2}>
                <HStack>
                    <Tooltip placement="top" label={<HelpForAttributeQuestion {...{ attributeTitle }} />}>
                        <HStack>
                            <Heading size="md">
                                Questions
                            </Heading>
                            <AiOutlineInfoCircle />
                        </HStack>
                    </Tooltip>
                    {questions.length < 1 && <MoreQuestionsIconWithTooltip />}
                </HStack>
            </Center>
            <Spacer />
            <Tooltip placement="top" label={<Text>Associate a new or existing question with this attribute</Text>}>
                <Button onClick={() => setIsOpen(true)}>
                {/* <Button onClick={onAddQuestionClick(setState)(valueId)(attributeId)}> */}
                    <HStack gap={1}>
                        <AiFillPlusCircle />
                        <Text>Question</Text>
                    </HStack>
                </Button>
            </Tooltip>
        </Flex>
        {/* NOTE: We have multiple modals in the vdom */}
        <QuestionModal {...{ existingQuestions, suggestions, setSuggestions, questions, weightedValues, attributeId, attributeTitle, setState, valueId, isOpen, setIsOpen, existingQuestions }} />
        {isEmpty(questions)
            ? <EmptyQuestions />
            : <QuestionTable {...{ customerId, weightedValues, valueId, attributeId, questions, setState }} />}
    </Container>;
};
