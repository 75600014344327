
export const onAttributeFieldChange = (valueId) => (attributeId) => (fieldName) => (setState) => (e) => setState(
    state => ({
        ...state,
        weightedValues: state.weightedValues.map(
            weightedValue => weightedValue.id == valueId
                ? ({
                    ...weightedValue,
                    weightedAttributes: weightedValue.weightedAttributes.map(
                        weightedAttribute => weightedAttribute.id == attributeId
                            ? ({
                                ...weightedAttribute,
                                [fieldName]: e.target.value,
                            })
                            : weightedAttribute
                    )
                })
                : weightedValue
        )
    })
);
