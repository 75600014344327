import * as React from "react"
import { Route, Routes } from "react-router-dom"
import { Error404 } from "../Error404"
import { ValueRoutes } from "./Detail/ValueRoutes"
import { JsonValuesView } from "./JSON/JsonValuesView"
import { ValuesPage } from "./List/ValuesPage"

export const ValuesRoutes = ({customerId, state, setState}) => {
    const { weightedValues, questions } = state
    return  <>
        <Routes>
            <Route path="/" element={<ValuesPage {...{customerId, weightedValues, setState}} />} />
            <Route path=":valueId/*" element={<ValueRoutes {...{customerId, weightedValues, questions, setState}} />} />
            <Route path="/json" element={<JsonValuesView {...{ customerId, state, setState}} />} />
            <Route element={<Error404 />} />
        </Routes>
    </>
}