import React from "react";

export interface ThumbNotApprovedIconProps {
  className?: string;
}

export const ThumbNotApprovedIcon = ({
  className,
}: ThumbNotApprovedIconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="thumb_down_alt_flipped">
        <path
          id="Vector"
          d="M9 2H18C18.83 2 19.54 2.5 19.84 3.22L22.86 10.27C22.95 10.5 23 10.74 23 11V13C23 14.1 22.1 15 21 15H14.69L15.64 19.57L15.67 19.89C15.67 20.3 15.5 20.68 15.23 20.95L14.17 22L7.59 15.41C7.22 15.05 7 14.55 7 14V4C7 2.9 7.9 2 9 2ZM9 14L13.34 18.34L12.23 13H21V11L18 4H9V14ZM5 2H1V14H5V2Z"
        />
      </g>
    </svg>
  );
};
