import { HStack, Text, VStack } from "@chakra-ui/react";
import * as React from "react";
import { allAttributesWithQuestionId } from "../Attributes/Detail/allAttributesWithQuestionId";
import { RelatedAttributeLink } from "./RelatedAttributeLink";
import { fullyQualifiedAttributeName } from "./fullyQualifiedAttributeName";
import { valueContainingAttribute } from "../Attributes/Detail/valueContainingAttribute";

export const RelatedAttributes = ({ customerId, questionId, weightedValues, attributeId, enableLinks = true }) => {
    const linkedAttributes = allAttributesWithQuestionId(weightedValues)(questionId).filter(a => a.id != attributeId);
    return <VStack>
        {linkedAttributes.length == 0
            ? <Text>None</Text>
            : linkedAttributes.map(
                attribute => {
                    const value = valueContainingAttribute(weightedValues)(attribute);
                    return enableLinks
                        ? <RelatedAttributeLink key={attribute.id} {...{ customerId, value, weightedValues, attribute }} />
                        : <Text key={attribute.id}>{fullyQualifiedAttributeName(value)(attribute)}</Text>
                }
            )}
    </VStack>;
};
