import { Button, Input, Td, Textarea, Tooltip, Tr } from "@chakra-ui/react";
import * as React from "react";
import { AiFillDelete } from "react-icons/ai";
import { onQuestionDelete } from "./onQuestionDelete";
import { onQuestionFieldChange } from "./onQuestionFieldChange";
import { RelatedAttributes } from "./RelatedAttributes";

export const QuestionTR = ({ customerId, weightedValues, valueId, attributeId, question: { id, title, text }, setState }) => {
    return <Tr>
        <Td>
            <Input
                placeholder="Title (Short Name)"
                type="text"
                title="title"
                minWidth="5em"
                onChange={onQuestionFieldChange(id)('title')(setState)}
                value={title} />
        </Td>
        <Td width="60%">
            <Textarea
                placeholder="Your Question Here?"
                value={text}
                onChange={onQuestionFieldChange(id)('text')(setState)} />
        </Td>
        <Td>
            <RelatedAttributes {...{customerId, questionId: id, weightedValues, attributeId}} />
        </Td>
        <Td>
            <Tooltip label="Delete Question">
                <Button onClick={onQuestionDelete(valueId)(attributeId)(id)(setState)}><AiFillDelete /></Button>
            </Tooltip>
        </Td>
    </Tr>;
};
