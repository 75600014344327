import React, { forwardRef } from "react";
import classNames from "classnames";
import { WarningIcon } from "../../Components/common/icons/WarningIcon";

interface RightSideIndicatorProps {
  className?: string;
}

const RightSideIndicator = ({ className }: RightSideIndicatorProps) => (
  <div className={className}>
    <div className="absolute right-0 top-1/2 transform -translate-y-1/2 w-[40px] h-[2px] bg-white" />
    <div className="absolute right-[-2.5] top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-white rounded-full" />
  </div>
);

export interface InterventionOverlayProps {
  item: any;
  onDismiss: () => void;
}

export const InterventionOverlay = forwardRef(
  ({ item, onDismiss }: InterventionOverlayProps, forwardedRef) => {
    return (
      <div
        ref={forwardedRef}
        className={classNames("w-[445px] rounded-[20px] bg-white absolute p-3")}
      >
        {/* <RightSideIndicator className="absolute top-1/2 right-0 transform translate-x-[40px] -translate-y-1/2" /> */}
        <div className="bg-danger w-full rounded-[20px] text-white relative flex items-center p-1">
          <WarningIcon />
          <span className="ml-2 text-white text-base font-semibold font-['Montserrat'] leading-normal">
            Illegal Question: {item.category}
            
          </span>
        </div>
        <div className="w-full p-3">
          <span className="text-black text-base font-bold font-['Montserrat'] leading-normal">
           Instruct candidate: “DON’T ANSWER THAT!”
            <br />
          </span>
          <span className="text-black text-base font-normal font-['Montserrat'] leading-normal">
            <br />
            Please return to the interview question.
          </span>
        </div>
        <div className="w-full flex justify-end">
          <button
            className="w-20 h-10 px-7 py-2.5 rounded-full border border-black flex-col justify-center items-center gap-2.5 inline-flex text-center text-black text-base font-normal font-['Montserrat'] leading-tight"
            onClick={onDismiss}
          >
            OK
          </button>
        </div>
      </div>
    );
  },
);

export default InterventionOverlay;
