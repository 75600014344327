import React from "react";
import { LabelValue } from "../../Components/common/LabelValue";
import { PillButton } from "../../Components/common/PillButton";

export interface TopSectionProps {
  interviewData: {
    candidateName: string;
    candidateRole: { name: string };
    candidateEmail: string;
  };
}

export const TopSection = ({ interviewData }: TopSectionProps) => {
  console.log("interviewData: ", interviewData);
  return (
    <div className="flex w-full justify-between items-center bg-white px-8 py-4 rounded-t-[24px]">
      <div className="flex space-x-24">
        <LabelValue label="Candidate" value={interviewData?.candidateName} />
        <LabelValue label="Role" value={interviewData?.candidateRole?.name} />
      </div>
      <div className="space-x-2">
        <PillButton href={`mailto:%20?subject=Candidate%20Analytics:%20${interviewData?.candidateName}&body=Please%20check%20out%20the%20Intertru%20analysis%20on%20${interviewData?.candidateName}: `+ window.location.href} target="_blank">
          Share Analysis
        </PillButton>
        <PillButton href={`mailto:${interviewData?.candidateEmail}`} target="_blank">
          Email Candidate
        </PillButton>
      </div>
    </div>
  );
};
