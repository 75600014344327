import React from 'react'
import { getCompany } from "../../utils/api";
import { useEffect, useState, useRef } from 'react'
import { usePageTitle } from "../../navigation/usePageTitle";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import mammoth from 'mammoth';
import { pdfjs } from 'react-pdf';
import { htmlToText } from 'html-to-text';
import { JobDescLoadingModal, 
    StatusModal, 
    SkillsModal, 
    HireOSRoleProfileModal,
    JobDescriptionConfirmationModal,
    AddNewManagerModal
 } from './Modals';

import { extractJobRoleData } from './JobRoleDataExtractor';

import { Auth } from "aws-amplify";
import { createJobRole, updateJobRole } from '../../utils/api';
import useGroups from "../../utils/useGroups";

import { 
    CITIZENSHIPSANDVISA,
    HIREOSPROFILE,
    WORKLIFEBALANCE,
    ROLETYPE,
    ROLEIMPACT,
    WOKRLOCATION
 } from './DropdownDataCollections';
import { TipModal } from '../../Components';

import { getCognitoUsername } from "../../utils/auth";
import { getUser } from "../../utils/api";
import qs from "query-string";
import { over } from 'lodash';


export default function JobRole() {
    const location = useLocation();
    const { compID } = qs.parse(location.search)

    const { currentJobRole, mode } = location.state || {};

    usePageTitle(mode === 'edit' ? "Edit Job Role" : "Add New Job Role")
    const navigate = useNavigate();
    const groups = useGroups();
    const currentDate = new Date(currentJobRole?.createdAt).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });

    const [jobRoleData, setJobRoleData] = useState();

    const [isNeedNByDateFocused, setIsNeedByDateFocused] = useState(false);
    const [isHiringManagerFocused, setIsHiringManagerFocused] = useState(false);
    const [isCitizenshipFocused, setIsCitizenshipFocused] = useState(false);
    const [isWorkLocationFocused, setIsWorkLocationFocused] = useState(false);
    const [isHireOSRoleProfileFocused, setIsHireOSRoleProfileFocused] = useState(false);
    const [isWorkLifeBalanceFocused, setIsWorkLifeBalanceFocused] = useState(false);
    const [isRoleTypeFocused, setIsRoleTypeFocused] = useState(false);
    const [isRoleImpactFocused, setIsRoleImpactFocused] = useState(false);

    const [title, setTitle] = useState(currentJobRole?.jobRoleData.title);
    const [needByDate, setNeedByDate] = useState();
    const [minSalary, setMinSalary] = useState(currentJobRole?.jobRoleData.minSalary);
    const [maxAnnualSalary, setMaxAnnualSalary] = useState(currentJobRole?.jobRoleData.maxAnnualSalary);
    const [officeLocation, setOfficeLocation] = useState(currentJobRole?.jobRoleData.officeLocation);
    const [cId, setCId] = useState();

    const [hiringManagers, setHiringManagers] = useState();
    const [selectedHiringManager, setSelectedHiringManager] = useState(currentJobRole?.hiringManager?.id);
    const [selectedCitizenship, setSelectedCitizenship] = useState(currentJobRole?.jobRoleData.workAuthorizationRequirement);
    const [selectedHireOSRoleProfile, setSelectedHireOSRoleProfile] = useState(currentJobRole?.jobRoleData.roleProfile? currentJobRole?.jobRoleData.roleProfile : "builder");
    const [selectedWorkLocation, setSelectedWorkLocation] = useState(currentJobRole?.jobRoleData.workLocation)
    const [selectedWorkLifeBalance, setSelectedWorkLifeBalance] = useState(currentJobRole?.jobRoleData.workLifeBalance? currentJobRole?.jobRoleData.workLifeBalance : "workcentric");
    const [selectedRoleType, setSelectedRoleType] = useState(currentJobRole?.jobRoleData.roleType? currentJobRole?.jobRoleData.roleType : "manager");
    const [selectedRoleImpact, setSelectedRoleImpact] = useState(currentJobRole?.jobRoleData.roleImpact? currentJobRole?.jobRoleData.roleImpact : "veryhigh");

    const [showHireOSToolTipModal, setShowHireOSToolTipModal] = useState(false);
    const [showWorkLifeBalanceToolTipModal, setShowWorkLifeBalanceToolTipModal] = useState(false);
    const [showRoleTypeToolTipModal, setShowRoleTypeToolTipModal] = useState(false);
    const [showRoleImpactToolTipModal, setShowRoleImpactToolTipModal] = useState(false);

    const [showHireOSModal, setShowHireOSModal] = useState(false)

    const [showAddRequiredSkillsModal, setShowAddRequiredSkillsModal] = useState(false);

    const [finalSelectedSkills, setFinalSelectedSkills] = useState(currentJobRole?.jobRoleData.maxAnnualSalary? currentJobRole?.jobRoleData.requiredSkills : []);
    const [finalOptionalSkills, setFinalOptionalSkills] = useState(currentJobRole?.jobRoleData.optionalSkills ? currentJobRole?.jobRoleData.optionalSkills: []);

    const [htmlContent, setHtmlContent] = useState('');
    const [jobDescriptionLabel, setJobDescriptionLabel] = useState("Job Description (Optional)");


    const [progressStatus, setProgressStatus] = useState(null);
    const [showUploadJobDescriptionStatus, setShowUploadJobDescriptionStatus] = useState(false);
    const [showUploadJobDescriptionFail, setShowUploadJobDescriptionFail] = useState(false);
    const [showJobDescriptionDeleteConfirmation, setShowJobDescriptionDeleteConfirmation] = useState(false);

    const [showStatus, setShowStatus] = useState(false); 
    const [addedSkill, setAddedSkill] = useState(false);
    const [addedJobRole, setAddedJobRole] = useState(false);
    const [updatedJobRole, setUpdatedJobRole] = useState(false);
    const [addedHiringManager, setAddedHiringManager] = useState(false);
    const [addedHiringManagerName, setAddedHiringManagerName] = useState();

    const [showAddManagerModal, setShowAddManagerModal] = useState(false);

    const [jobRole, setJobRole] = useState()

    const [enableSaveButton, setEnableSaveButton] = useState(false)

    const [textJobDescriptionContent, setTextContent] = useState(currentJobRole?.jobRoleData.jobDescription? currentJobRole?.jobRoleData.jobDescription : htmlToText(htmlContent, {
        wordwrap: 130
      }));

    const handleChangeJobDescription = (event) => {
        setTextContent(event.target.value);
    };

    const [styleTop, setStyleTop] = useState()
    const [styleLeft, setStyleLeft] = useState()

    const buttonRef = useRef(null);
    const hireOSProfileRef = useRef(null);

    const workLifeBtnRef = useRef(null);
    const workLifeModalRef = useRef(null);

    const roleTypeBtnRef = useRef(null);
    const roleTypeModalRef = useRef(null);

    const roleImpacteBtnRef = useRef(null);
    const roleImpacteModalRef = useRef(null);

    const [showJobDescProgress, setShowJobDescProgress] = useState(false);

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const getJobRoleData = async (text) => {
        try {
            extractJobRoleData(text).then((data) => {
                layoutJobRoleData(data)
            })
        } catch (error) {
            setProgressStatus("error");
            setShowJobDescProgress(false);
            setShowUploadJobDescriptionFail(true);
        }
    
    }
    useEffect(() => {
    },[finalOptionalSkills, finalSelectedSkills])

    useEffect(() => {
        if (currentJobRole) {
            let needByD = currentJobRole.jobRoleData.needByDate
            if(needByD) {
                let nbd = new Date(needByD)
                setNeedByDate(nbd)
            }
            else {
                let nbd = new Date()
                setNeedByDate(nbd)
            }
        }
        const checkUser = async () => {
            try {
                let authUser = await Auth.currentAuthenticatedUser();
                if (authUser) {
                    setIsAuthenticated(true);
                }
            } catch {
                setIsAuthenticated(false);
            }
        };
        checkUser();
    }, []);
    useEffect(() => {
    }, [showJobDescProgress, jobRole, title, maxAnnualSalary, minSalary, finalOptionalSkills, finalSelectedSkills])

    function layoutJobRoleData (jobRoleData) {
        const { jobTitle, minimumAnnualSalary, maximumAnnualSalary, requiredSkills, desiredSkills, 
            jobWorkLocationType, officeLocation, roleType, workAuthorizationRequirement } = jobRoleData;
        try {
            setTitle(jobTitle);
            setMinSalary(minimumAnnualSalary);
            setMaxAnnualSalary(maximumAnnualSalary);
            setOfficeLocation(officeLocation);
            setSelectedWorkLocation(jobWorkLocationType.toLowerCase());
            setSelectedRoleType(roleType);
            setSelectedCitizenship(workAuthorizationRequirement.replace(/\s+/g, '').toLowerCase());

            requiredSkills.forEach((skill) => {
                setFinalSelectedSkills((prev) => [...prev, {name: skill}]);
            })
            desiredSkills.forEach((skill) => {
                setFinalOptionalSkills((prev) => [...prev, {name: skill}]);
            })
            setShowJobDescProgress(false);
            setProgressStatus("success");
            setShowUploadJobDescriptionStatus(true);
            const timer = setTimeout(() => {
                setShowUploadJobDescriptionStatus(false);
                }, 3000);
            return () => clearTimeout(timer);
        }
        catch (error) {
            setProgressStatus("error");
            setShowJobDescProgress(false);
            // setShowUploadJobDescriptionFail(true);
        }
        
    }

    // useEffect(() => {
    //     if (showUploadJobDescriptionStatus) {
    //       const timer = setTimeout(() => {
    //         setShowUploadJobDescriptionStatus(false);
    //       }, 3000); // Set the timeout for 5 seconds
    
    //       // Cleanup function to clear the timeout if the component unmounts or before re-running the effect
    //       return () => clearTimeout(timer);
    //     }
    //   }, [showUploadJobDescriptionStatus]);

    //   useEffect(() => {
    //     if (showUploadJobDescriptionFail) {
    //       const timer = setTimeout(() => {
    //         setShowUploadJobDescriptionFail(false);
    //       }, 3000); 
         
    //       return () => clearTimeout(timer);
    //     }
    //   }, [showUploadJobDescriptionFail]);

      useEffect(() => {
        if (showStatus) {
          const timer = setTimeout(() => {
            setShowStatus(false);
            setProgressStatus(null);
            setAddedJobRole(false);
            setAddedSkill(false);
            setAddedHiringManager(false);
          }, 3000); 
          return () => clearTimeout(timer);
        }
      }, [showStatus, addedJobRole, addedSkill, addedHiringManager, hiringManagers, addedHiringManagerName]);

    const handleCloseJobDecsriptionModal = () => {
        setTextContent(null);
        setJobRoleData(null);
        setShowJobDescProgress(false);
    }

    const handleDeleteJobDescriptionConfirmation = () => {
        setTextContent(null)
        setShowJobDescriptionDeleteConfirmation(false)
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                var lsCompanyId;
                if (compID) {
                    lsCompanyId = compID
                }
               
                const userId = await getCognitoUsername();
                await getUser(userId).then(async (user) => {
                    if(!lsCompanyId) {
                        lsCompanyId = user?.companyID;
                    }
                    setCId(lsCompanyId);
                    const res = await getCompany(lsCompanyId);
                    let company = res;
                    var currentHiringManagers = [];
                    company.users.items.forEach((item) => {
                        if (item.role === "owner" || item.role === "admin") {
                            currentHiringManagers.push(item);
                        }
                    });
                    setHiringManagers(currentHiringManagers);
                })
                
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    }, [selectedHiringManager]); 

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (buttonRef.current && !buttonRef.current.contains(event.target) &&
                hireOSProfileRef.current && !hireOSProfileRef.current.contains(event.target)) {
            setShowHireOSToolTipModal(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setStyleTop(null)
        setStyleLeft(null)
        if (showHireOSToolTipModal && buttonRef.current && hireOSProfileRef.current) {
       
          const btnRect = buttonRef.current.getBoundingClientRect();

          const modalTop = btnRect.top - hireOSProfileRef.current.offsetHeight - 205;
          const modalLeft = btnRect.left + hireOSProfileRef.current.width / 2;

          setStyleTop(modalTop)
          setStyleLeft(modalLeft)
        }
    }, [showHireOSToolTipModal]);

    useEffect(() => {
        setStyleTop(null)
        setStyleLeft(null)
        if (showWorkLifeBalanceToolTipModal && workLifeBtnRef.current && workLifeModalRef.current) {
            const btnRect = workLifeBtnRef.current.getBoundingClientRect();

            const modalTop = btnRect.top - workLifeModalRef.current.offsetHeight - 205;
            const modalLeft = btnRect.left - workLifeModalRef.current.width;

            setStyleTop(modalTop)
            setStyleLeft(modalLeft)
          }
    }, [showWorkLifeBalanceToolTipModal]);

    useEffect(() => {
        setStyleTop(null)
        setStyleLeft(null)
        if (showRoleTypeToolTipModal && roleTypeBtnRef.current && roleTypeModalRef.current) {
     
            const btnRect = roleTypeBtnRef.current.getBoundingClientRect();

            const modalTop = btnRect.top - roleTypeModalRef.current.offsetHeight - 205;
            const modalLeft = btnRect.left + roleTypeModalRef.current.width / 2;

            setStyleTop(modalTop)
            setStyleLeft(modalLeft)
          }
    }, [showRoleTypeToolTipModal]);

    useEffect(() => {
        setStyleTop(null)
        setStyleLeft(null)
        if (showRoleImpactToolTipModal && roleImpacteBtnRef.current && roleImpacteModalRef.current) {

            const btnRect = roleImpacteBtnRef.current.getBoundingClientRect();

            const modalTop = btnRect.top - roleImpacteModalRef.current.offsetHeight - 205;
            const modalLeft = btnRect.left + roleImpacteModalRef.current.width / 2;

            setStyleTop(modalTop)
            setStyleLeft(modalLeft)
          }
    }, [showRoleImpactToolTipModal]);


    const handleTitleChange = (event) => {
        setTitle(event.target.value);
        setEnableSaveButton(true)
    };
    const handleMinSalaryChange = (event) => {
        
        const value = event.target.value;
        const numericValue = value.replace(/[^\d.]/g, '');
        const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        }).format(numericValue);

        setMinSalary(formattedValue.toString());
        setEnableSaveButton(true)
    }
    const handleMaxSalaryChange = (event) => {
        const value = event.target.value;
        const numericValue = value.replace(/[^\d.]/g, '');
        const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        }).format(numericValue)

        setMaxAnnualSalary(formattedValue.toString());
        setEnableSaveButton(true)
    }
    const handleOfficeLocationChange = (event) => {
        setOfficeLocation(event.target.value);
        setEnableSaveButton(true)
    }

    function handleHiringManagerSelection(selectedValue)  {
        
        let hiringManager = selectedValue;
        if (hiringManager === "addnew") {
            setShowAddManagerModal(true);
        }
        else {
            setSelectedHiringManager(hiringManager);
        }
        setEnableSaveButton(true)
    }

    const handleHireOSInfoButtonClicked = () => {
        setShowHireOSToolTipModal(true);
        
    }

    const handleWorkLifeInfoButtonClicked = () => {
        setShowWorkLifeBalanceToolTipModal(true);
    }

    const handleRoleTypeInfoButtonClicked = () => {
        setShowRoleTypeToolTipModal(true);
    }

    const handleRoleImpactInfoButtonClicked = () => {
        setShowRoleImpactToolTipModal(true);
    }

    const fileInputRef = useRef(null);

    const handleJobDescriptionUploadButtonClicked = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
        setEnableSaveButton(true)
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          setShowJobDescProgress(true);
          const reader = new FileReader();
    
          if (file.type === 'application/pdf') {
            reader.onload = async () => {
              const pdfData = new Uint8Array(reader.result);
              const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
              let html = '<div style="width: 100%">';
              for (let i = 1; i <= pdf.numPages; i++) {
                const page = await pdf.getPage(i);
                const textContent = await page.getTextContent();
                textContent.items.forEach((item) => {
                  html += `<p>${item.str}</p>`;
                });
              }
              html += '</div>';
              setHtmlContent(html);
              setJobDescriptionLabel("Job Description");
              setTextContent(htmlToText(html, {
                wordwrap: 130
                }));
                getJobRoleData(textJobDescriptionContent);
            };
            reader.readAsArrayBuffer(file);
          } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            reader.onload = () => {
              mammoth.convertToHtml({ arrayBuffer: reader.result })
                .then(result => {
                  setHtmlContent(result.value);
                  setTextContent(htmlToText(result.value, {
                    wordwrap: 130
                    }));
                    getJobRoleData(textJobDescriptionContent);
                })
                .catch(err => console.error(err));
            };
            reader.readAsArrayBuffer(file);
          } else if (file.type === 'text/plain' || file.type === 'application/rtf') {
            reader.onload = () => {
              setHtmlContent(`<pre>${reader.result}</pre>`);
              setTextContent(htmlToText(`<pre>${reader.result}</pre>`, {
                wordwrap: 130
                }));
                getJobRoleData(textJobDescriptionContent);
            };
            reader.readAsText(file);
          } else {
            alert('Unsupported file type');
          }
        }
    };

    const handleSelectHireOSRoleProfile = (s) => {
        setSelectedHireOSRoleProfile(s.target.value)
        setEnableSaveButton(true)
    };
    const handleSelectRoleType = (s) => {
        setSelectedRoleType(s.target.value)
        setEnableSaveButton(true)
    };

    const handleSelectRoleImpact = (s) => {
        setSelectedRoleImpact(s.target.value)
        setEnableSaveButton(true)
    };
    const handleSelectWorkLifeBalance = (s) => {
        setSelectedWorkLifeBalance(s.target.value)
        setEnableSaveButton(true)
    };
    const handleSelectWorkLocation = (s) => {
        setSelectedWorkLocation(s.target.value)
        setEnableSaveButton(true)
    };

    const handleAddSkillsButtonClicked = () => {
        setShowAddRequiredSkillsModal(true);
    };

    const handleStatusNote = () => {
        setProgressStatus("success");
        setShowStatus(true);
        setAddedSkill(true);
    }

    const handleRequiredSkillsClose = () => {
        setShowAddRequiredSkillsModal(false)
        setEnableSaveButton(true)
    }
    const datePickerRef = useRef(null);
    const handleShowDatePicker = () => {
        datePickerRef.current.setOpen(true);
    }

    const handleSaveButtonClicked = async () => {
        setEnableSaveButton(false)
        try {
            let jData = prepareRolePayload();
            if(jData) {
                const params = {
                    companyID: cId,
                    jobRoleData: jData
                };
                await createJobRole(params).then((res) => {
                    if(res) {
                        setJobRole(res.createJobRole)
                        setShowStatus(true)
                        setAddedJobRole(true)
                        setProgressStatus("success")
                        navigate(-1)
                    }
                });
            }
            
        } catch (error) {
            console.error('Job Creation Failed:', error);
            setEnableSaveButton(true)
        }
    }

    const handleEditButtonClicked = async () => {
        try {
            let jData = prepareRolePayload();
            if(jData) {
                const params = {
                    id: currentJobRole.id,
                    jobRoleData: jData,
                    status: 'Open'
                };
                await updateJobRole(params).then((res) => {
                    if(res) {
                        setJobRole(res.updateJobRole)
                        setShowStatus(true)
                        setUpdatedJobRole(true)
                        setProgressStatus("success")
                        setEnableSaveButton(false)
                    }
                });
            }
        } catch (error) {
            console.error('Job Role Updating Failed:', error);
        }
    }

    const handleCancelButtonClicked = () => {
        navigate(-1)
    }

    const handleManagerSuccess = (manager) => {
        setSelectedHiringManager(manager.id)
        setShowAddManagerModal(false)
        if (manager) {
            setShowStatus(true)
            setAddedHiringManager(true)
            setAddedHiringManagerName(manager.firstName + " " + manager.lastName)
        }
    }

    const prepareRolePayload = () => {
        const rolePayload = {
            title: title,
            companyID: currentJobRole?.jobRoleData.companyID ? currentJobRole?.jobRoleData.companyID : cId,
            needByDate: needByDate,
            minSalary: minSalary,
            maxAnnualSalary: maxAnnualSalary,
            officeLocation: officeLocation,
            workLocation: selectedWorkLocation,
            roleType: selectedRoleType,
            workLifeBalance: selectedWorkLifeBalance,
            workAuthorizationRequirement: selectedCitizenship,
            hiringManager: selectedHiringManager,
            roleProfile: selectedHireOSRoleProfile,
            requiredSkills: finalSelectedSkills,
            optionalSkills: finalOptionalSkills,
            jobDescription: textJobDescriptionContent,
            roleImpact: selectedRoleImpact,
        }
        setJobRoleData(rolePayload);
        return rolePayload;
    }

    const contentStyles = {

        container: {
            minWidth: 1280, 
            maxWidth: 1728,
            width: "100%",
            padding : 8,
            filter: "drop-shadow(0px 1px 3px rgba(166, 175, 195, 0.40))",
        },
        
        contentBodyWrapper: {
            backgroundColor: "#F3F4F6",
            borderRadius: 24,
            display : "flex",
            minWidth: 1280, 
            maxWidth: "100vw",
            width: "100%",
            position: "relative",
            padding : 8,
            maxHeight: "calc(100vh - 186px)",
            filter: "drop-shadow(0px 1px 3px rgba(166, 175, 195, 0.40))",
        },
        roleFormDetails: {
            padding: 16,
            backgroundColor: "#FFF",
            width: "45%",
            maxWidth: 1728,
            borderRadius: 24,
            overflow: "scroll",
            scrollbarWidth: "none",
        },
        roleJobDescription: {
            padding: 16,
            backgroundColor: "#FFF",
            width: "55%",
            height: "auto",
            maxWidth: 1728,
            borderRadius: 24,
            marginRight: 8,
        },
        jobDesriptionText : {
            color: "var(--Primary-Text, #4B5563)",

            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500,
            marginBottom: 8,
            justifyContent: "flex-start",
        },
        jobDescriptionDeleteButton : {
            display: "inline-flex",
            padding: "4px 8px",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            borderRadius: 50,
            border: "1px solid var(--Stroke, #DFE4EA)",
            background: "var(--White-White, #FFF)",
            marginBottom : 8
        },
        uploadContainer: {
            borderRadius: 8,
            border: "1px dashed var(--Stroke, #DFE4EA)",
            background: "var(--Gray-Gray-2, #F3F4F6)",
            padding: "152px 111.646px 160px 104px",
            alignItems: "center",
            flex: "1 0 0",
            alignSelf: "stretch",
            height: "calc(-254px + 100vh)",
            overflow: "scroll",
            justifyContent: "center",
            display: "flex",
        },
        uploadText :{
            color: "var(--Secondary-Text, #6B7280)",
            textAlign: "center",
            marginTop: 16,
            /* Body Medium/SemiBold */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 600
        },
        acceptedText :{
            color: "var(--Secondary-Text, #6B7280)",
            textAlign: "center",
            /* Body Medium/SemiBold */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400
        },
        uploadButton :{
            borderRadius: 50,
            border: "1px solid var(--Stroke, #DFE4EA)",
            background: "var(--White-White, #FFF)",
            display: "flex",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            width: 91,
            height: 40
        },
        addButton :{
            borderRadius: 50,
            border: "1px solid var(--Stroke, #DFE4EA)",
            background: "var(--White-White, #FFF)",
            display: "flex",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            width: 111,
            height: 40
        },
        jobDescriptionContainer: {
            overflow: "scroll",
            height: "calc(100vh - 250px)",
            maxHeight: "calc(100vh - 250px)",
            padding: 16,
            display: "flex",
            alignItems: "flex-start",
            gap: 8,
            alignSelf: "stretch",
            borderRadius: 8,
            border: "1px solid var(--Stroke, #DFE4EA)",
            background: "var(--White-White, #FFF)",
        },
    }

    const styles = {
        containerBody: {
           
            height: "100%",
            width: "100%",
            overflow: "hidden"
        },
        roleBodyWrapper: {
            backgroundColor : "#FFF",
            display : "flex",
        },
        roleHeader : {
            backgroundColor: "#FFF",
            height: 114,
            display: "flex",
            justifyContent: "space-between",
            padding: 24
        },
        headerText: {
            color: "var(--Primary-Text, #4B5563)",

            /* Heading 5 */
            fontFamily: "Montserrat",
            fontSize: 28,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: 40 /* 142.857% */
        },
        
        formLabelText : {
            color: "var(--Primary-Text, #4B5563)",

            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500,
            marginBottom: 8
        },
        formLabelTextSkills : {
            color: "var(--Primary-Text, #374151)",

            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 600,
            marginBottom: 8
        },
        smallTextLabel :{
            color: "var(--Secondary-Text, #6B7280)",

            /* Body Small/Regular */
            fontFamily: "Montserrat",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 400,
            marginTop: 4,
            marginBottom: 16
        },

        datePicker: {
            outline: "none",
            height: 40,
            border: "none",
            background: "var(--White-White, #FFF)",
            width: "100%",
        },
        modalTip: {
            width: 12, 
            height :7,
            position: "absolute",
            transform: "translateX(-60%)",
            top: 378,
            zIndex: 999,
            left: "16%",
            display: "flex",
            gap: 16
        },

        fullInputStyle : {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "90%",        
            background: "var(--White-White, #FFF)",
            outline: "none",
            height: 38,
        },

        formLabelText : {
            color: "var(--Primary-Text, #6B7280)",
    
            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            marginBottom: 8,
            marginLeft: 16
        },

        dropDownSkills : {
            position: 'absolute', 
            backgroundColor: 'white', 
            zIndex: 1, listStyleType: 'none', 
            padding: 8, 
            margin: 0, 
            height: "auto", 
            maxHeight: 312,
            overflow: 'scroll',
            border: "1px solid var(--Stroke, #DFE4EA)",
            borderRadius: 8,
            width : 355
        },
        listItemStyle : {
            padding: 8,
            cursor: 'pointer'
        },
    
        listItemHoverStyle : {
            backgroundColor: '#f0f0f0'
        },

        cancelButton : {
            borderRadius: 50,
            border: "1px solid var(--Stroke, #DFE4EA)",
            background: "var(--White-White, #FFF)",
            display: "flex",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            color: "var(--Primary-Text, #374151)",
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500,
            marginLeft: 16,
        },

        saveButton: {
            borderRadius: 50,
            background: enableSaveButton ? "var(--Primary, #F60)" : "var(--Stroke, #DFE4EA)",
            display: "flex",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            cursor: enableSaveButton ? "pointer" : "not-allowed",
            color: enableSaveButton ? "white" :" var(--Disabled, #9CA3AF)",
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500,
            marginLeft: 16,
            widht: 102,
            height: 40
        },
        editButton: {
            borderRadius: 50,
            background: enableSaveButton ? "var(--Primary, #F60)" : "var(--Stroke, #DFE4EA)",
            display: "flex",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            cursor: enableSaveButton ? "pointer" : "not-allowed",
            color: enableSaveButton ? "white" :" var(--Disabled, #9CA3AF)",
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500,
            marginLeft: 16,
            widht: 102,
            height: 40
        }
    }

    
    const renderHeaderRoles = () => {
        return (
            <div style={styles.roleHeader}>
                    <div style={{justifyContent: "flex-start", display: "flex", marginTop: 16, alignItems: "center"}}>
                        <div>
                            <button
                                onClick={() => navigate(-1)}
                            >Roles
                        </button>
                        </div>
                        <div className="forward-icon"></div>
                        <div style={styles.headerText}>{mode === "edit" ? "Edit Role" : "New Role"}</div>
                    </div>
                    <div style={{alignItems: "center",  display: "flex", justifyContent: "space-between"}}>
                            <div>
                                {mode === 'edit' ? (
                                    <div>
                                        {enableSaveButton ? (
                                            <div style={styles.editButton}>
                                            <div className="save-icon-active"></div> 
                                            <button onClick={() => handleEditButtonClicked()}>Save</button>
                                        </div>
                                        ): (
                                            <div style={styles.editButton}>
                                                <div className="save-icon-disable"></div> 
                                                <button onClick={() => ('')}>Save</button>
                                            </div>
                                        )}
                                    </div>
                                    
                                ): (
                                    <div> 
                                        {enableSaveButton ? (
                                            <div style={styles.saveButton}>
                                                <div className="save-icon-active"></div> 
                                                <button onClick={() => handleSaveButtonClicked()}>Save</button>
                                            </div>
                                        ) : (
                                            <div style={styles.saveButton}>
                                                <div className="save-icon-disable"></div> 
                                                <button onClick={() => navigate("/admin/jobroles")}>Save</button>
                                            </div>
                                        )}
                                    </div>
                                )
                                }
                                
                                
                            </div>
                            <button style={styles.cancelButton} onClick={() => handleCancelButtonClicked()}>Cancel</button>
                    </div>
                </div>
        )
    }
    
    const renderNewJobRoleForm = () => {
        return (
            <div style={contentStyles.roleFormDetails}>
                <div>
                    <div style={styles.formLabelText}>Title</div>
                    <input className='fullInputStyle'
                        type="text"
                        value={title}
                        onChange={handleTitleChange}
                    />
                    {mode === 'edit' && <div style={styles.smallTextLabel}>Role Created : {currentDate}</div>}
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: 16}}>
                        <div style={{width: "49%"}}>
                            <div style={styles.formLabelText}>Need by Date</div>
                            <div className={isNeedNByDateFocused ? 'fullInputStyle focused' : 'fullInputStyle'}>
                                <DatePicker style={styles.datePicker}
                                    selected={needByDate ? needByDate : new Date()}
                                    ref={datePickerRef}
                                    onChange={(date) => setNeedByDate(date)}
                                    dateFormat="MMM dd, yyyy"
                                    onFocus={() => setIsNeedByDateFocused(true)}
                                    onBlur={() => setIsNeedByDateFocused(false)}
                                />
                            <button className="date-picker-icon" onClick={handleShowDatePicker}>
                               
                            </button>
                            </div>
                        </div>
                        <div style={{width: "49%"}}>
                            <div style={styles.formLabelText}>Hiring Manager</div>
                            <div className={isHiringManagerFocused? 'fullInputStyle focused':'fullInputStyle'}>
                                <select style={{width:"100%", height: 35, outline: "none"}}
                                    value={selectedHiringManager}
                                    onChange={(s) => handleHiringManagerSelection(s.target.value)}
                                    onFocus={() => setIsHiringManagerFocused(true)}
                                    onBlur={() => setIsHiringManagerFocused(false)}
                                >   
                                    <option value="">Select</option>
                                    <option value="addnew">Add New</option>
                                    {hiringManagers && hiringManagers?.map((item) => (
                                        <option key={item.id} value={item.id}>{item.firstName + " " + item.lastName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>   
                    </div>
                    <div style={{height: 16}}></div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{width: "49%"}}>
                            <div style={styles.formLabelText}>Min Annual Salary (Opt.)</div>
                            <input className='fullInputStyle'
                                placeholder='$0'
                                value={minSalary}
                                onChange={handleMinSalaryChange}
                            />
                        </div>
                        
                        <div style={{width: "49%"}}>
                            <div style={styles.formLabelText}>Max Annual Salary (Opt.)</div>
                            <input className='fullInputStyle'
                                placeholder='$0'
                                value={maxAnnualSalary}
                                onChange={handleMaxSalaryChange}
                            />
                        </div>
                    </div>
                    <div style={{height: 16}}></div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{width: "49%"}}>
                            <div style={styles.formLabelText}>Citizenship and Visa</div>
                            <div className={isCitizenshipFocused ? 'fullInputStyle focused' : 'fullInputStyle'}>
                                <select style={{width:"100%", height: 35, outline: "none"}}
                                    value={selectedCitizenship}
                                    onChange={(s) => setSelectedCitizenship(s.target.value)}
                                    onFocus={() => setIsCitizenshipFocused(true)}
                                    onBlur={() => setIsCitizenshipFocused(false)}
                                >
                                    <option value="">Select</option>
                                    {CITIZENSHIPSANDVISA.map((item) => (
                                        <option key={item.value} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div style={{width: "49%"}}>
                            <div style={styles.formLabelText}>Work Location</div>
                            <div className={isWorkLocationFocused ? 'fullInputStyle focused' : 'fullInputStyle'}>
                                <select style={{width:"100%", height: 35, outline: "none"}}
                                    value={selectedWorkLocation}
                                    onChange={(s) => handleSelectWorkLocation(s)}
                                    onFocus={() => setIsWorkLocationFocused(true)}
                                    onBlur={() => setIsWorkLocationFocused(false)}
                                >
                                    <option value="">Select</option>
                                    {WOKRLOCATION.map((item) => (
                                        <option key={item.value} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>   
                    </div>
                    {(selectedWorkLocation === "hybrid" || selectedWorkLocation === "office") && (
                        <div>
                            <div style={{ height: 16 }}></div>
                            <div style={styles.formLabelText}>Office Location</div>
                            <input 
                                value={officeLocation}
                                className='fullInputStyle'
                                onChange={handleOfficeLocationChange} />
                        </div>
                    )}
                    
                    <div style={{height: 16}}></div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{width: "49%"}}>
                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <div style={styles.formLabelText}>HireOS™ Role Profile</div>
                                <button 
                                    className="help-icon" 
                                    ref={buttonRef}
                                    onClick={() => handleHireOSInfoButtonClicked()}
                                    id="hireos-info-btn"
                                ></button>
                                {showHireOSToolTipModal && (
                                    <div>
                                        <TipModal
                                            headerText="HireOS™ Role Profile"
                                            content="HireOS™, Intertru's methodology for hiring the best-aligned individuals, asks hiring managers to 
                                            explicitly state whether the ideal person in this role is building something new, 
                                            improving something that exists, or maintaining things as they are."
                                            contentLinks={[ {url: "", text: "Learn the different profiles here."}
                                            ]}
                                            showModalTip={setShowHireOSToolTipModal}
                                            buttonRef={buttonRef}
                                            modalRef={hireOSProfileRef}
                                            modal={"hireos"}
                                            top={styleTop}
                                            left={styleLeft}
                                            showHireOSRole={setShowHireOSModal}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={isHireOSRoleProfileFocused ? 'fullInputStyle focused' : 'fullInputStyle'}>
                                <select style={{width:"100%", height: 35, outline: "none"}}
                                    value={selectedHireOSRoleProfile}
                                    onChange={(s) => handleSelectHireOSRoleProfile(s)}
                                    onFocus={() => setIsHireOSRoleProfileFocused(true)}
                                    onBlur={() => setIsHireOSRoleProfileFocused(false)}
                                >
                                    {HIREOSPROFILE.map((item) => (
                                        <option key={item.value} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        
                        <div style={{width: "49%"}}>
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <div style={styles.formLabelText}>Work/Life Balance</div>
                                <button className="help-icon"
                                    onClick={() => handleWorkLifeInfoButtonClicked()}
                                    ref={workLifeBtnRef}
                                ></button>
                        {showWorkLifeBalanceToolTipModal && (
                        <div>
                            <TipModal
                                headerText="Work/Life Balance"
                                content={
                                    <>
                                        If this role allows the employee to dedicate more than 12 hours to their life outside of work on most workdays, select <strong>Life-Centric</strong>.<br/><br/>
                                        If this role allows the employee to dedicate more than 12 hours to their work on most workdays, select <strong>Work-Centric</strong>.
                                    </>
                                    }
                                contentLinks={[]}
                                showModalTip={setShowWorkLifeBalanceToolTipModal}
                                buttonRef={workLifeBtnRef}
                                modalRef={workLifeModalRef}
                                modal={"worklife"}
                                top={styleTop}
                                left={styleLeft}
                            />
                        </div>
                        )}
                            </div>
                            <div className={isWorkLifeBalanceFocused ? 'fullInputStyle focused' : 'fullInputStyle'}>
                                <select style={{width:"100%", height: 35, outline: "none"}}
                                    value={selectedWorkLifeBalance}
                                    onChange={(s) => handleSelectWorkLifeBalance(s)}
                                    onFocus={() => setIsWorkLifeBalanceFocused(true)}
                                    onBlur={() => setIsWorkLifeBalanceFocused(false)}
                                >
                                    {WORKLIFEBALANCE.map((item) => (
                                        <option key={item.value} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>   
                    </div>
                    
                    <div style={{height: 16}}></div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{width: "49%"}}>
                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <div style={styles.formLabelText}>Role Type</div>
                                <button className="help-icon"
                                    onClick={() => handleRoleTypeInfoButtonClicked()}
                                    ref={roleTypeBtnRef}
                                ></button>
                                {showRoleTypeToolTipModal && (
                                    <div>
                                        <TipModal
                                            headerText="Role Type"
                                            content={
                                                <>
                                                If this Role is intended to focus on personnel management responsibilities (e.g., writing performance reviews), choose <strong>Manager</strong>.<br/><br/>
                                                If no personnel management responsibilities are expected for this Role, choose <strong>Individual Contributor</strong>.<br/><br/>
                                                If this Role is intended to focus on technical responsibilities (e.g., writing code), choose <strong>Technical</strong>.<br/><br/>
                                                </>
                                            }
                                            
                                            contentLinks={[
                                            ]}
                                            showModalTip={setShowRoleTypeToolTipModal}
                                            buttonRef={roleTypeBtnRef}
                                            modalRef={roleTypeModalRef}
                                            modal={"roletype"}
                                            top={styleTop}
                                            left={styleLeft}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={isRoleTypeFocused ? 'fullInputStyle focused' : 'fullInputStyle'}>
                                <select style={{width:"100%", height: 35, outline: "none"}}
                                    value={selectedRoleType}
                                    onChange={(s) => handleSelectRoleType(s)}
                                    onFocus={() => setIsRoleTypeFocused(true)}
                                    onBlur={() => setIsRoleTypeFocused(false)}
                                >
                                    {ROLETYPE.map((item) => (
                                        <option key={item.value} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div style={{width: "49%"}}>
                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <div style={styles.formLabelText}>Role Impact</div>
                                <button className="help-icon"
                                    onClick={() => handleRoleImpactInfoButtonClicked()}
                                    ref={roleImpacteBtnRef}
                                >
                                </button>
                                {showRoleImpactToolTipModal && (
                                    <div>
                                        <TipModal
                                            headerText="Role Impact"
                                            content={
                                                <>
                                                Not all roles are designed to be highly visible and crucial to the company's success. However, if this role, such as COO, is deemed highly significant, select <strong>Very High</strong>.<br/><br/>
                                                If this role is deemed to be of average importance, select <strong>Medium</strong>.<br/><br/>
                                                If this role is deemed to be of low importance, select <strong>Low</strong>.
                                                </>
                                            }
                                            
                                            contentLinks={[
                                            ]}
                                            showModalTip={setShowRoleImpactToolTipModal}
                                            buttonRef={roleImpacteBtnRef}
                                            modalRef={roleImpacteModalRef}
                                            modal={"roleimpact"}
                                            top={styleTop}
                                            left={styleLeft}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={isRoleImpactFocused ? 'fullInputStyle focused' : 'fullInputStyle'}>
                                <select style={{width:"100%", height: 35, outline: "none"}}
                                    value={selectedRoleImpact}
                                    onChange={(s) => handleSelectRoleImpact(s)}
                                    onFocus={() => setIsRoleImpactFocused(true)}
                                    onBlur={() => setIsRoleImpactFocused(false)}
                                >
                                    {ROLEIMPACT.map((item) => (
                                        <option key={item.value} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div> 
                    </div>
                    <div style={{height: 16}}></div>
                    <div style={{height: 1, flexShrink: 0, alignSelf: "stretch", background: "var(--Stroke, #DFE4EA)"}}></div>
                    <div style={{display: "flex", justifyContent:"space-between", marginTop: 16}}>
                        {finalSelectedSkills.length > 0 || finalOptionalSkills.length > 0 ? (
                            <div>
                                {finalSelectedSkills.length > 0 && (
                                    <div>
                                        <div style={styles.formLabelTextSkills}>Required Skills</div>
                                        {finalSelectedSkills.map((item, index) => (
                                            <div key={index} style={{marginBottom : 16, position: "relative"}}>
                                            <div style={styles.formLabelText}>{`   ${index + 1}. ${item.name}`}</div>
                                        </div>
                                        ))}
                                    </div>
                                )} 
                                {finalOptionalSkills.length > 0 && (
                                    <div>
                                        <div style={styles.formLabelTextSkills}>Desired Skills</div>
                                        {finalOptionalSkills.map((item, index) => (
                                            <div key={index} style={{marginBottom : 16, position: "relative"}}>
                                            <div style={styles.formLabelText}>{`   ${index + 1}. ${item.name}`}</div>
                                        </div>
                                        ))}
                                    </div>
                                )}   
                            </div>
                            
                        ) : (
                            <div style={styles.formLabelTextSkills}>Required/Desired Skills</div>
                        )}
                        
                        <button 
                            style={contentStyles.addButton}
                            onClick={() => handleAddSkillsButtonClicked()}
                        >{finalSelectedSkills.length > 0 || finalOptionalSkills.length > 0 ? "Edit Skills" : "Add Skills"}</button>

                        {showStatus && addedSkill && ( 
                            <StatusModal
                                onClose={() => showStatus(false)}
                                status={progressStatus}
                                headerText="Skillfull!"
                                statusText={`${finalSelectedSkills.length + finalOptionalSkills.length} role skills have been added.`}
                            />
                        )}
                        {showStatus && addedJobRole && ( 
                            <StatusModal
                                onClose={() => showStatus(false)}
                                status={progressStatus}
                                headerText="Job Role Successfully Created"
                                statusText={``}
                            />
                        )}
                        {showStatus && updatedJobRole && ( 
                            <StatusModal
                                onClose={() => showStatus(false)}
                                status={progressStatus}
                                headerText="Job Role Successfully Updated"
                                statusText={``}
                            />
                        )}
                        {showStatus && addedHiringManager && ( 
                            <StatusModal
                                onClose={() => showStatus(false)}
                                status={'success'}
                                headerText="Success"
                                statusText={addedHiringManagerName + " has been added as the hiring manager."}
                            />
                        )}
                    </div>
                </div>
            </div>
    
        )
    }
    

    const renderJobDescriptionContainer = () => {
        return (
            <div style={contentStyles.roleJobDescription}>
                <div style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
                    <div style={contentStyles.jobDesriptionText}>{jobDescriptionLabel}</div>
                        {showJobDescProgress && (
                            <JobDescLoadingModal
                                showModal={showJobDescProgress}
                                cancelProgress={handleCloseJobDecsriptionModal}
                                onClose={() => setShowJobDescProgress(false)}
                            />
                        )}
                         {showUploadJobDescriptionStatus && ( 
                            <StatusModal
                                onClose={() => setShowUploadJobDescriptionStatus(false)}
                                status={progressStatus}
                                statusText="Feel free to edit the job desciption uploaded"
                                headerText="Job Description Upload Success"
                            />
                        )}
                        {showUploadJobDescriptionFail && ( 
                            <StatusModal
                                onClose={() => setShowUploadJobDescriptionFail(false)}
                                status={progressStatus}
                                headerText="Error Parsing Job Description"
                                statusText="You may continue editing the job description and form"
                            />
                        )}
                    {textJobDescriptionContent && (
                        <div style={contentStyles.jobDescriptionDeleteButton}>
                            <button className="delete-icon-button"
                                onClick={() => setShowJobDescriptionDeleteConfirmation(true)}
                            ></button>
                        </div>
                    )}
                    </div>
                       
                     {textJobDescriptionContent && (
                        <div style={contentStyles.jobDescriptionContainer}>
                            <textarea style={{ width: '100%', height: '100%', boxSizing: 'border-box', outline: "none"}}
                                value={textJobDescriptionContent}
                                onChange={(e) => handleChangeJobDescription(e)}
                            ></textarea>
                        </div>
                    )}
                    {!textJobDescriptionContent && (
                        <div style={contentStyles.uploadContainer}>
                            <div>
                                <div style={{display: "flex", justifyContent : "center", width: "100%"}}>
                                    <div className="note-add-icon"></div>
                                </div>
                                <div style={{justifyContent : "center", width: "100%"}}>
                                    <div style={contentStyles.uploadText}>Upload a job description for this role.</div>
                                    <div style={contentStyles.acceptedText}>Accepted file types: DOC/DOCX, PDF, and TXT.</div>
                                    <div style={{width: "100%", justifyContent : "center", display: "flex", marginTop: 16}}>
                                        <input
                                            type="file"
                                            accept=".pdf,.doc,.docx,.txt,.rtf"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                        />
                                        <button 
                                            style={contentStyles.uploadButton}
                                            onClick={() => handleJobDescriptionUploadButtonClicked()}
                                        >Upload</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    
            </div>
            
        )
    }
    
    return (
        <div style={styles.containerBody}>
            <SkillsModal
                title="Add Skills"
                mode="view"
                modalType="required"
                modalVisible={showAddRequiredSkillsModal}
                currentRequiredSkills={finalSelectedSkills}
                currentOptionalSkills={finalOptionalSkills}
                setFinalOptionalSkills={setFinalOptionalSkills}
                setFinalSelectedSkills={setFinalSelectedSkills}
                onClose={() => handleRequiredSkillsClose()}
                handleStatusNote={handleStatusNote}
            />
            {showHireOSModal && (
            <HireOSRoleProfileModal
                onClose={() => setShowHireOSModal(false)}
                modalVisible={showHireOSModal}
            />
            )}
            {showJobDescriptionDeleteConfirmation && (
                <JobDescriptionConfirmationModal
                    onClose={() => setShowJobDescriptionDeleteConfirmation(false)}
                    modalVisible = {showJobDescriptionDeleteConfirmation}
                    deleteJobDesctiptionAction={() => handleDeleteJobDescriptionConfirmation()}
                />
            )}
            {showAddManagerModal && (
                <AddNewManagerModal
                onClose={() => setShowAddManagerModal(false)}
                modalVisible = {showAddManagerModal}
                companyID={cId}
                setSelectedHiringManager={setSelectedHiringManager}
                success={handleManagerSuccess}
            />
            )}
            
            <div>
                {renderHeaderRoles()}

                    <div className='container' style={contentStyles.contentBodyWrapper}>
                        {renderJobDescriptionContainer()}
                        {renderNewJobRoleForm()}
                    </div>
                </div>
            </div>
    )
}

