import React from "react";
import {useRef, useEffect, useState} from "react";

import "./orgprofile.css";
import { ToolTipModal }  from "./Modals";
import { TOTALEMPLOYEES } from "./OrgDataCollections";
import { border } from "@chakra-ui/react";


export default function OrganizationProfile() {

    const companyValuesAndBehaviorBtnRef = useRef(null);
    const companyValuesModalTipRef = useRef(null);
    const [showCompanyValuesModalTip, setShowCOmpanyValuesModalTip] = useState(false);

    const industryBtnRef = useRef(null);
    const industryModalTipRef = useRef(null);
    const [showIndustryModalTip, setShowIndustryModalTip] = useState(false);

    const clientTypeBtnRef = useRef(null);
    const clientTypeModalTipRef = useRef(null);
    const [showClientTypeModalTip, setShowClientTypeModalTip] = useState(false);

    const [styleTop, setStyleTop] = useState(null);
    const [styleLeft, setStyleLeft] = useState(null);

    const [totalEmployees, setTotalEmployees] = useState(null);
    const [totalEmployeeOptionModalVisible, setTotalEmployeeOptionModalVisible] = useState(false);
    const [totalEmployeesSelectionFocused, setTotalEmployeesSelectionFocused] = useState(false);

    const [hoveredOption, setHoveredOption] = useState(null);

    useEffect(() => {
        setStyleTop(null)
        setStyleLeft(null)
        if (showCompanyValuesModalTip && companyValuesAndBehaviorBtnRef.current && companyValuesModalTipRef.current) {
     
            const btnRect = companyValuesAndBehaviorBtnRef.current.getBoundingClientRect();
            const modalTop = btnRect.top - companyValuesModalTipRef.current?.offsetHeight + 262;
            const modalLeft = btnRect.left - 100;

            setStyleTop(modalTop)
            setStyleLeft(modalLeft)
          }
    }, [showCompanyValuesModalTip]);

    useEffect(() => {
        setStyleTop(null)
        setStyleLeft(null)
        if (showIndustryModalTip && industryBtnRef.current && industryModalTipRef.current) {
     
            const btnRect = industryBtnRef.current.getBoundingClientRect();
            const modalTop = btnRect.top - industryModalTipRef.current?.offsetHeight - 4;
            const modalLeft = btnRect.left - 100;

            setStyleTop(modalTop)
            setStyleLeft(modalLeft)
          }
    }, [showIndustryModalTip]);

    useEffect(() => {
        setStyleTop(null)
        setStyleLeft(null)
        if (showClientTypeModalTip && clientTypeBtnRef.current && clientTypeModalTipRef.current) {
     
            const btnRect = clientTypeBtnRef.current.getBoundingClientRect();
            const modalTop = btnRect.top - clientTypeModalTipRef.current?.offsetHeight - 4;
            const modalLeft = btnRect.left - 100;

            setStyleTop(modalTop)
            setStyleLeft(modalLeft)
          }
    }, [showClientTypeModalTip]);


    const handleShowModal = () => {
        setShowCOmpanyValuesModalTip(true);
    };

    const totalEmployeesOption = TOTALEMPLOYEES.map((item) => ({
        label: item.label,
        value: item.value,
        onClick: () => {
            setTotalEmployeeOptionModalVisible(false);
            setTotalEmployees(item.value);
        },
    }));
        

    function renderHeader() {
        return (
            <div className="headerContainer">
                <div className="headerText">Organization Profile</div>
                <div className="headerButtonContainer">
                    <button className="publishButton">
                        <div className="publish-icon"></div>
                        <div>Publish</div>
                    </button>
                    <button className="regularButton">Save</button>
                </div>
            </div>
        );
    }

    function renderOnboardingContainer() { 
        return (
            <div className="bodyStandardContainer">
                <div className="wariningContainer">
                    <div className="warning-icon"></div>
                </div>
                <div className="warningTextContainer">
                    <div className="warningHeaderText">Complete Your Organization Profile</div>
                    <div className="standardText">4 Steps Remaining Company Values & Behaviors, Industry/Industries, Client Type(s) & Publish Profile</div>
                </div>
            </div>
        )
    }

    function renderCompany() {
        return(
            <div className="bodyStandardContainer" style={styles.bodyStandardContainer}>
            <div className="subContainers">
                <div className="mainText">Company Name</div>
                <div className="fullInputStyle">Assure Bank</div>
            </div>
            <div className="subContainers">
                <div className="mainText">Total Employees</div>
                <div className={totalEmployeesSelectionFocused ? 'fullInputStyle focused' : 'fullInputStyle'} style={{paddingLeft : 8, display: "flex"}}>
                    <button style={{width: "100%", height: 40, textAlign: "left", outline : "none"}}
                        onClick={() => setTotalEmployeeOptionModalVisible(!totalEmployeeOptionModalVisible)}
                        onFocus={() => setTotalEmployeesSelectionFocused(true)}
                        onBlur={() => setTotalEmployeesSelectionFocused(false)}
                    >{totalEmployees}</button>
                    <div className="downward-icon-selection"></div>
                 </div>
                 {totalEmployeeOptionModalVisible && (
                    <div style={styles.optionsModal}>
                        {totalEmployeesOption.map((option, i) => {
                            let optionStyle = { ...styles.totalEmployeesOption };
                            if (hoveredOption === option.value) {
                                optionStyle = { ...optionStyle, ...styles.hoveredOption };
                              }
                            return (
                                <p 
                                    key={option.value} 
                                    style={optionStyle} 
                                    onClick={option.onClick}
                                    onMouseEnter={() => setHoveredOption(option.value)}
                                    onMouseLeave={() => setHoveredOption(null)}
                                >
                                    {option.label}
                                </p>
                            );
                        })}
                    </div>
                )}

             </div>
        </div>
        )
    }

    function renderAddNewValue() {
        return (
            <div style={styles.addValueContainer}>
                <div className="add-value-moderator"></div>
                <button className="regularButton">Add New</button>
            </div>
        )
    }

    function renderCompanyData() {
        return (
            <div className="bodyStandardContainerNoMargin" style={styles.bodyStandardContainer}>
                <div style={styles.midContainers}>
                    <div style={styles.subContainers}>
                        <div className="mainContainerText">Industry/Industries</div>
                        <button className="help-icon"
                            onMouseEnter={() => setShowIndustryModalTip(true)}
                            ref={industryBtnRef}
                        />
                    </div>
                    <div className="standardText" style={styles.marginSpacing}>Add Industry</div>
                    <div style={styles.subContainers}>
                        <div className="fullInputStyle"></div>
                        <div className="regularButton" style={styles.buttonSpace}>Select</div>
                    </div>
                </div>
                <div className="divider"></div>
                <div style={styles.midContainers}>
                    <div style={styles.subContainers}>
                        <div className="mainContainerText">Client Type(s)</div>
                        <button className="help-icon" 
                            onMouseEnter={() => setShowClientTypeModalTip(true)}
                            ref={clientTypeBtnRef}
                        />
                    </div>
                    <div className="standardText" style={styles.marginSpacing}>Add Client Type</div>
                    <div style={styles.subContainers}>
                        <div className="fullInputStyle"></div>
                        <div className="regularButton" style={styles.buttonSpace}>Select</div>
                    </div>
                </div>
            </div>
        )
    }

    function renderCompanyValuesAndBehaviors() {
        return (
            <div className="bodyNonFlexContainer">
                <div style={styles.subContainers}>
                    <div className="mainContainerText">Company Values & Behaviors</div>
                    <button className="help-icon"
                         onMouseEnter={() => setShowCOmpanyValuesModalTip(true)}
                        ref={companyValuesAndBehaviorBtnRef}
                    ></button>
                    
                </div>
                <div style={styles.valuesListContainers}>
                    {renderAddNewValue()}
                </div>
            </div>
        )
    }

    function renderBodyMainContainer() {
        return (
            <div className="mainBodyContainer">
                {renderOnboardingContainer()}
                {renderCompany()}
                {renderCompanyValuesAndBehaviors()}
                {renderCompanyData()}
            </div>
        )
    }

    return (
        <div>
            {renderHeader()}
            {renderBodyMainContainer()}
            {showCompanyValuesModalTip && 
                <ToolTipModal 
                    headerText="Company Values & Behaviors"
                    bodyText={
                        <>
                        Describing your organizational values and their associated behaviors 
                        enables Intertru to prepare <strong>interview questions and extract evidence </strong>
                        to accurately measure candidate alignment with your values and behavioral norms.
                        <br/><br/>
                        Learn more here.
                    </>}
                    modalRef={companyValuesModalTipRef} 
                    buttonRef={companyValuesAndBehaviorBtnRef}
                    styleTop={styleTop}
                    styleLeft={styleLeft}
                    showModalTip={setShowCOmpanyValuesModalTip}
                    modalType="companyValuesModalTip"
                />
            }
            {showIndustryModalTip && 
                <ToolTipModal 
                    headerText="Industry/Industries"
                    bodyText={
                        <>
                       Specifying your organization’s industry allows Intertru to tailor <strong>interview questions and measure alignment. </strong> 
                       This ensures that candidates are evaluated on relevant behaviors and cultural fit for your unique company.
                    </>}
                    modalRef={industryModalTipRef} 
                    buttonRef={industryBtnRef}
                    styleTop={styleTop}
                    styleLeft={styleLeft}
                    showModalTip={setShowIndustryModalTip}
                    modalType="industry"
                />
            }
            {showClientTypeModalTip && 
                <ToolTipModal 
                    headerText="Client Type(s)"
                    bodyText={
                        <>
                          Describing the types of client your organization serves helps Intertru create <strong>behavioral interview questions and value-alignment 
                          assessments that are customized to your client interactions and relationships.</strong>
                          <br/><br/>
                          This enhances the evaluation process to ensure candidates align with the specific needs and values of your client base.
                        </>
                    }
                    modalRef={clientTypeModalTipRef} 
                    buttonRef={clientTypeBtnRef}
                    styleTop={styleTop}
                    styleLeft={styleLeft}
                    showModalTip={setShowClientTypeModalTip}
                    modalType="clientType"
                />
            }
        </div>
    );
}

const styles = {
    bodyStandardContainer: {
        padding: 16,
        justifyContent: "space-between",
    },
    subContainers: {
        display: "flex",
        alignItems: "center",
    },
    midContainers: {
        width: "49%"
    },
    valuesListContainers: {
        display: "flex",
        alignItems: "center",
        marginTop : 16,
    },
    addValueContainer: {
        borderRadius: "8px",
        border: "1px dashed var(--Stroke, #DFE4EA)",
        background: "var(--Gray-Gray-2, #F3F4F6)",
        alignSelf: "stretch",
        height: 415,
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 8,
        width: 230,
    },
    buttonSpace: {
        marginLeft: 8,
    },
    marginSpacing: {
        marginTop: 8,
        marginBottom: 8,
    },
    totalEmployeesSelect: {
        width: 200,
        height: 36,
        fontSize: 16,
        padding: 8,
        outline : "none"
    },

    totalEmployeesOption: {
        zIndex: 20,
        fontSize: 16,
        textAlign: "left",
        fontWeight: "400",
        color: "var(--Secondary-Text, #6B7280)",
        padding: "8px",
        cursor: "pointer",
    },
    optionsModal: {
        position: "absolute",
        width: 118,
        background: "#FFF",
        zIndex: 5,
        borderRadius: 16,
        padding: 16,
        background: "var(--White-White, #FFF)",
        
        /* Shadow 1 */
        boxShadow: "0px 1px 3px 0px rgba(166, 175, 195, 0.40)"
      },
      selectionOverlay: {
        height: "100vh",
        width: "100vw",
        position: "absolute",
        left: 0,
        top: 0,
      },
      hoveredOption: {
        backgroundColor: 'var(--Cyan-Cyan-light-3, #D0F0FD)',
        borderRadius: 8,
      },
}
