import { toast } from "react-toastify"

export const onAddSharedQuestionClicked = 
    (valueId) =>
    (attributeId) =>
    (questionId) =>
    (setIsOpen) =>
    (setState) =>
    () =>
    {
        setIsOpen(false)
        toast.success('Existing question linked to attribute')
        setState(
            state =>
            ({
                ...state,
                weightedValues: state.weightedValues.map(
                    wv => wv.id == valueId
                        ? {
                            ...wv,
                            weightedAttributes: wv.weightedAttributes.map(
                                wa => wa.id == attributeId
                                    ? {
                                        ...wa,
                                        questionIds: [
                                            ...wa.questionIds,
                                            questionId,
                                        ]
                                    }
                                    : wa
                            ),
                        }
                        : wv
                ),
            })
        )
    }