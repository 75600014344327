import { useState } from "react";
import { themeOrange } from "../theme";
import logo from "../images/intertru.png";

import { Button, Container, TextArea } from "../Components";
import { sendEmail } from "../utils/email";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

export default function Feedback(props) {
  const { intervieweeName, interviewerName } = props;
  const [pressedSubmit, setPressedSubmit] = useState(false);
  const [feedback, setFeedback] = useState({
    q1: {
      prompt: "The overall interview process",
      rating: "",
      comments: "",
    },
    q2: {
      prompt: "The Intertru software system",
      rating: "",
      comments: "",
    },
    q3: {
      prompt: "The interviewer's questions and conduct",
      rating: "",
      comments: "",
    },
  });

  console.log(intervieweeName, interviewerName);

  if (pressedSubmit) {
    return (
      <div style={{ ...styles.permissionModal, fontSize: 20 }}>
        Call has ended
      </div>
    );
  }
  return (
    <Container className="hor-center" style={styles.container}>
      <div style={{ width: "100%", margin: "auto" }}>
        <div className="hor-center" style={{ width: "100%" }}>
          <img src={logo} className="App-logo" alt="logo" style={styles.logo} />
        </div>

        <div style={styles.headerTextContainer}>
          Thanks for participating in this Intertru Session. We value the
          quality of your interview experience and request your satisfaction
          levels and feedback. Please tell us how you would rank:
        </div>
        {Object.keys(feedback).map((prompt) => {
          let text = feedback[prompt]?.prompt;
          return (
            <SurveyField
              description={text}
              key={prompt}
              writeFeedback={writeFeedback}
              qNum={prompt}
              feedback={feedback}
            />
          );
        })}
        <div className="hor-center" style={{ width: "100%", marginTop: 10 }}>
          <Button filled onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </Container>
  );

  function writeFeedback(value, field, q) {
    const updatedFeedback = cloneDeep(feedback);
    updatedFeedback[q][field] = value;
    setFeedback(updatedFeedback);
  }

  function handleSubmit() {
    const feedbackArrayStr = Object.keys(feedback)
      .map((q) => {
        const { prompt, rating, comments } = feedback[q];
        return `Prompt: ${prompt}\nRating: ${rating}\nComments: ${comments}\n
        `;
      })
      .join("\n");

    const emailText = `Feedback for interviewee ${intervieweeName} and interviewer ${interviewerName}\n\n${feedbackArrayStr}`;
    const emailPayload = {
      to: ["sander@vantasoft.com", "rick@intertru.ai", "mike@intertru.ai"],
      subject: "Intertru Candidate Feedback Result",
      text: emailText,
    };

    sendEmail(emailPayload);
    toast.success("Thank you for submitting your feedback.");
    setPressedSubmit(true);
  }
}

function SurveyField(props) {
  const { description, writeFeedback, qNum, feedback } = props;
  let ratings = ["Excellent", "Good", "Average", "Poor", "Fail"];

  return (
    <div>
      <div style={styles.surveyBox}>
        <div style={styles.promptWrapper}>{description}</div>
        {ratings.map((label) => (
          <Rating
            label={label}
            key={label}
            qNum={qNum}
            feedback={feedback}
            writeFeedback={writeFeedback}
          />
        ))}
      </div>
      <div style={{ margin: "0px 30px" }}>
        <TextArea
          inputStyle={styles.textInputStyle}
          value={feedback[qNum].comments}
          onChange={(notes) => {
            writeFeedback(notes, "comments", qNum);
          }}
          placeholder="Other comments..."
          rows={2}
        />
      </div>
    </div>
  );
}

function Rating(props) {
  const { label, feedback, writeFeedback, qNum } = props;
  const selected = label === feedback[qNum].rating;
  const style = {
    padding: "10px 14px",
    fontWeight: "bold",
    borderRadius: 4,
    textAlign: "center",
    margin: "0px 6px",
  };
  if (selected) {
    style.background = themeOrange;
    style.color = "white";
  }

  return (
    <div
      className="hor-center"
      onClick={() => writeFeedback(label, "rating", qNum)}
    >
      <div style={style} className="rating-label">
        {label}
      </div>
    </div>
  );
}

const styles = {
  container: {
    // background: "blue",
    height: "100%",
  },
  headerTextContainer: {
    fontSize: 18,
    // fontWeight: "600",
    textAlign: "center",
    marginBottom: 20,
  },
  surveyBox: {
    display: "flex",
    width: "100%",
    margin: "0px 20px",
    padding: "14px 0px",
  },
  promptWrapper: {
    padding: "10px 14px",
    margin: "0px 6px",
    width: "50%",
    borderRight: "1px solid #ddd",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // width: "25vw",
    height: "12.5vh",
    marginBottom: 20,
  },
  textInputStyle: {
    padding: 8,
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #555",
    borderRadius: 4,
  },
  permissionModal: {
    height: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
