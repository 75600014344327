export const onAttributeFieldChange = (valueIdToChange) => (attributeIdToChange) => (propToChange) => (setState) => (e) => setState(
    state => ({
        ...state,
        weightedValues: state.weightedValues.map(
            weightedValue => weightedValue.id == valueIdToChange
                ? {
                    ...weightedValue,
                    weightedAttributes: weightedValue.weightedAttributes.map(
                        weightedAttribute => weightedAttribute.id == attributeIdToChange
                            ? {
                                ...weightedAttribute,
                                [propToChange]: e.target.value,
                            }
                            : weightedAttribute
                    )
                }
                : weightedValue
        )
    })
);
