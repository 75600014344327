import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Modal, TextInput, TextArea } from "../Components";
import logo from "../images/intertru.png";
import { sendEmail } from "../utils/email";
import { pdfjs } from "react-pdf";
import { DocModal } from "./ResetPassword";
import { themeOrange } from "../theme";
import { usePageTitle } from "../navigation/usePageTitle";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Rick has linked this screen from his LinkedIn. The login button probably should be hidden

export default function Home() {
  usePageTitle("Home")
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [termsModalVisible, setTermsModalVisible] = useState(false);

  const fontSize = "1.3vw";

  return (
    <>
      <div className="home hor-center" style={{margin: -8}}>
        <Modal
          title="Get a Demo"
          isVisible={modalVisible}
          onClose={() => setModalVisible(false)}
          style={{ zIndex: 100, width: "50vw" }}
        >
          <div style={{ padding: 20 }}>
            {renderEmailForm()}
            <Button title="Submit" filled onClick={onSubmitForm} />
          </div>
        </Modal>
        <DocModal
          title="Intertru Privacy Policy"
          modalVisible={privacyModalVisible}
          setModalVisible={setPrivacyModalVisible}
          content="privacy"
        />

        <DocModal
          title="Intertru Terms of Service"
          modalVisible={termsModalVisible}
          setModalVisible={setTermsModalVisible}
          content="terms"
          style={{ height: "80%" }}
        />
        <img src={logo} className="App-logo" alt="logo" />
        <div className="tagline" style={{ fontSize }}>
          We are on a mission to eliminate bad hiring from planet Earth!
        </div>
        <div className="tagline" style={{ width: "70vw", fontSize }}>
          Intertru is a method-driven interviewing accuracy platform that uses
          AI and HireOS® methodology <br /> to ensure that your company hires
          the strongest people, every time.
        </div>
        <div style={{ paddingTop: 100 }}>
          <div
            className="hor-center"
            style={{ color: "white", width: "100%", marginBottom: 40 }}
          >
            <p style={{ textAlign: "center", width: "70vw", fontSize }}>
              We are in stealth mode & officially launching very soon.
              <br />
              <br /> Join our waitlist or dig deeper to discover more about how
              Intertru will deliver <br />a significant competitive advantage in
              building an outstanding team.
            </p>
          </div>
          <div className="hor-center">
            <Button
              filled
              style={styles.button}
              onClick={() => {
                window.open(
                  "https://calendly.com/rick-girard/intertru-discovery"
                );
              }}
            >
              Dig Deeper
            </Button>
          </div>
        </div>
        <Button style={styles.loginText} onClick={() => navigate("/login")}>
          Log In
        </Button>
      </div>
      <div style={styles.footer}>
        <div className="hor-center">
          <div style={{ color: "white", marginRight: 4 }}>
            Join the Intertru waitlist.
          </div>
          <div style={styles.link} onClick={() => setModalVisible(true)}>
            Contact us here.
          </div>
        </div>

        <div className="hor-center" style={styles.privacyTerms}>
          <span
            style={{ ...styles.link }}
            onClick={() => setPrivacyModalVisible(true)}
          >
            Privacy
          </span>
          <div style={{ width: 30 }} />
          <span style={styles.link} onClick={() => setTermsModalVisible(true)}>
            Terms
          </span>
          <div style={{ width: 30 }} />
          <span style={{ color: "white", fontWeight: "bold" }}>
            © Intertru.
          </span>
        </div>
      </div>
    </>
  );

  function renderEmailForm() {
    return (
      <div style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 20 }}>
          Let us know if you have any questions about our service.
        </div>

        <div style={{ marginRight: 20 }}>
          <div style={styles.inputLabel}>Name</div>
          <TextInput
            inputStyle={styles.inputStyle}
            value={fullName}
            onChange={setFullName}
          />
        </div>
        <div style={{ marginRight: 20 }}>
          <div style={styles.inputLabel}>Email Address</div>
          <TextInput
            inputStyle={styles.inputStyle}
            value={email}
            onChange={setEmail}
          />
        </div>

        <div style={styles.inputLabel}>Message</div>
        <TextArea
          inputStyle={styles.areaInputStyle}
          value={message}
          onChange={setMessage}
        />
      </div>
    );
  }

  function onSubmitForm() {
    const emailPayload = {
      // to: "info@intertru.ai",
      to: ["rick@intertru.ai", "mike@intertru.ai", "sander@vantasoft.com"],
      subject: "Intertru Email Inquiry",
      text: `Name: ${fullName}\nEmail: ${email}\n\nMessage:\n${message}`,
    };
    sendEmail(emailPayload);
    setEmail("");
    setFullName("");
    setMessage("");
    return toast.success("Your email has been sent.");
  }
}

const styles = {
  inputLabel: {
    width: 180,
    maxWidth: 180,
  },
  inputStyle: {
    width: 350,
    height: 40,
    border: "1px solid #555",
    borderRadius: 4,
  },
  areaInputStyle: {
    padding: 5,
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #555",
    borderRadius: 4,
  },
  link: {
    fontWeight: "bold",
    color: "#ee7531",
    cursor: "pointer",
  },
  button: {
    fontSize: 22,
    padding: "6px 14px",
    borderRadius: 8,
  },
  loginText: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "white",
    background: themeOrange,
    fontWeight: "bold",
    fontSize: 20,
    padding: "4px 12px",
    margin: "8px",
    borderRadius: 6,
    cursor: "pointer",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    // justifyContent: "flex-end",
    width: "100vw",
    height: 50,
    background: "#222",
    zIndex: 10,
    padding: "0 30px",
    marginTop: 8,
    marginLeft: -8,
    marginRight: 16,
  },
  privacyTerms: {
    height: "100%",
    width: 300,
    // marginRight: 20,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
};
