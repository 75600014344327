/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createInterview = /* GraphQL */ `mutation CreateInterview(
  $input: CreateInterviewInput!
  $condition: ModelInterviewConditionInput
) {
  createInterview(input: $input, condition: $condition) {
    id
    group
    questions
    interviewInfo
    scheduling
    stage
    companyID
    companyName
    interviewResultData
    questionResponses {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInterviewMutationVariables,
  APITypes.CreateInterviewMutation
>;
export const updateInterview = /* GraphQL */ `mutation UpdateInterview(
  $input: UpdateInterviewInput!
  $condition: ModelInterviewConditionInput
) {
  updateInterview(input: $input, condition: $condition) {
    id
    group
    questions
    interviewInfo
    scheduling
    stage
    companyID
    companyName
    interviewResultData
    questionResponses {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInterviewMutationVariables,
  APITypes.UpdateInterviewMutation
>;
export const deleteInterview = /* GraphQL */ `mutation DeleteInterview(
  $input: DeleteInterviewInput!
  $condition: ModelInterviewConditionInput
) {
  deleteInterview(input: $input, condition: $condition) {
    id
    group
    questions
    interviewInfo
    scheduling
    stage
    companyID
    companyName
    interviewResultData
    questionResponses {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInterviewMutationVariables,
  APITypes.DeleteInterviewMutation
>;
export const createCompany = /* GraphQL */ `mutation CreateCompany(
  $input: CreateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  createCompany(input: $input, condition: $condition) {
    id
    group
    email
    firstName
    lastName
    company
    users {
      nextToken
      __typename
    }
    roles {
      nextToken
      __typename
    }
    interviews {
      nextToken
      __typename
    }
    interviewInfo
    values1
    values2
    plan
    stripeCustomerId
    status
    hasLoggedIn
    displayOrder
    interviewBuilderData
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyMutationVariables,
  APITypes.CreateCompanyMutation
>;
export const updateCompany = /* GraphQL */ `mutation UpdateCompany(
  $input: UpdateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  updateCompany(input: $input, condition: $condition) {
    id
    group
    email
    firstName
    lastName
    company
    users {
      nextToken
      __typename
    }
    roles {
      nextToken
      __typename
    }
    interviews {
      nextToken
      __typename
    }
    interviewInfo
    values1
    values2
    plan
    stripeCustomerId
    status
    hasLoggedIn
    displayOrder
    interviewBuilderData
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyMutationVariables,
  APITypes.UpdateCompanyMutation
>;
export const deleteCompany = /* GraphQL */ `mutation DeleteCompany(
  $input: DeleteCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  deleteCompany(input: $input, condition: $condition) {
    id
    group
    email
    firstName
    lastName
    company
    users {
      nextToken
      __typename
    }
    roles {
      nextToken
      __typename
    }
    interviews {
      nextToken
      __typename
    }
    interviewInfo
    values1
    values2
    plan
    stripeCustomerId
    status
    hasLoggedIn
    displayOrder
    interviewBuilderData
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyMutationVariables,
  APITypes.DeleteCompanyMutation
>;
export const createAttribute = /* GraphQL */ `mutation CreateAttribute(
  $input: CreateAttributeInput!
  $condition: ModelAttributeConditionInput
) {
  createAttribute(input: $input, condition: $condition) {
    id
    group
    name
    attributesData
    valueID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAttributeMutationVariables,
  APITypes.CreateAttributeMutation
>;
export const updateAttribute = /* GraphQL */ `mutation UpdateAttribute(
  $input: UpdateAttributeInput!
  $condition: ModelAttributeConditionInput
) {
  updateAttribute(input: $input, condition: $condition) {
    id
    group
    name
    attributesData
    valueID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAttributeMutationVariables,
  APITypes.UpdateAttributeMutation
>;
export const deleteAttribute = /* GraphQL */ `mutation DeleteAttribute(
  $input: DeleteAttributeInput!
  $condition: ModelAttributeConditionInput
) {
  deleteAttribute(input: $input, condition: $condition) {
    id
    group
    name
    attributesData
    valueID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAttributeMutationVariables,
  APITypes.DeleteAttributeMutation
>;
export const createValue = /* GraphQL */ `mutation CreateValue(
  $input: CreateValueInput!
  $condition: ModelValueConditionInput
) {
  createValue(input: $input, condition: $condition) {
    id
    group
    name
    weightedAttributes
    organizationID
    behaviors
    attributes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateValueMutationVariables,
  APITypes.CreateValueMutation
>;
export const updateValue = /* GraphQL */ `mutation UpdateValue(
  $input: UpdateValueInput!
  $condition: ModelValueConditionInput
) {
  updateValue(input: $input, condition: $condition) {
    id
    group
    name
    weightedAttributes
    organizationID
    behaviors
    attributes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateValueMutationVariables,
  APITypes.UpdateValueMutation
>;
export const deleteValue = /* GraphQL */ `mutation DeleteValue(
  $input: DeleteValueInput!
  $condition: ModelValueConditionInput
) {
  deleteValue(input: $input, condition: $condition) {
    id
    group
    name
    weightedAttributes
    organizationID
    behaviors
    attributes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteValueMutationVariables,
  APITypes.DeleteValueMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    id
    group
    name
    companyID
    organizationData
    values {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    id
    group
    name
    companyID
    organizationData
    values {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    id
    group
    name
    companyID
    organizationData
    values {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    group
    firstName
    lastName
    title
    companyID
    email
    phone
    status
    role
    hasLoggedIn
    lastLoggedIn
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    group
    firstName
    lastName
    title
    companyID
    email
    phone
    status
    role
    hasLoggedIn
    lastLoggedIn
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    group
    firstName
    lastName
    title
    companyID
    email
    phone
    status
    role
    hasLoggedIn
    lastLoggedIn
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createRole = /* GraphQL */ `mutation CreateRole(
  $input: CreateRoleInput!
  $condition: ModelRoleConditionInput
) {
  createRole(input: $input, condition: $condition) {
    id
    group
    name
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRoleMutationVariables,
  APITypes.CreateRoleMutation
>;
export const updateRole = /* GraphQL */ `mutation UpdateRole(
  $input: UpdateRoleInput!
  $condition: ModelRoleConditionInput
) {
  updateRole(input: $input, condition: $condition) {
    id
    group
    name
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRoleMutationVariables,
  APITypes.UpdateRoleMutation
>;
export const deleteRole = /* GraphQL */ `mutation DeleteRole(
  $input: DeleteRoleInput!
  $condition: ModelRoleConditionInput
) {
  deleteRole(input: $input, condition: $condition) {
    id
    group
    name
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRoleMutationVariables,
  APITypes.DeleteRoleMutation
>;
export const createJobRole = /* GraphQL */ `mutation CreateJobRole(
  $input: CreateJobRoleInput!
  $condition: ModelJobRoleConditionInput
) {
  createJobRole(input: $input, condition: $condition) {
    id
    group
    status
    jobRoleData
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobRoleMutationVariables,
  APITypes.CreateJobRoleMutation
>;
export const updateJobRole = /* GraphQL */ `mutation UpdateJobRole(
  $input: UpdateJobRoleInput!
  $condition: ModelJobRoleConditionInput
) {
  updateJobRole(input: $input, condition: $condition) {
    id
    group
    status
    jobRoleData
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobRoleMutationVariables,
  APITypes.UpdateJobRoleMutation
>;
export const deleteJobRole = /* GraphQL */ `mutation DeleteJobRole(
  $input: DeleteJobRoleInput!
  $condition: ModelJobRoleConditionInput
) {
  deleteJobRole(input: $input, condition: $condition) {
    id
    group
    status
    jobRoleData
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobRoleMutationVariables,
  APITypes.DeleteJobRoleMutation
>;
export const createSession = /* GraphQL */ `mutation CreateSession(
  $input: CreateSessionInput!
  $condition: ModelSessionConditionInput
) {
  createSession(input: $input, condition: $condition) {
    id
    questions
    initTime
    subquestionsOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSessionMutationVariables,
  APITypes.CreateSessionMutation
>;
export const updateSession = /* GraphQL */ `mutation UpdateSession(
  $input: UpdateSessionInput!
  $condition: ModelSessionConditionInput
) {
  updateSession(input: $input, condition: $condition) {
    id
    questions
    initTime
    subquestionsOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSessionMutationVariables,
  APITypes.UpdateSessionMutation
>;
export const deleteSession = /* GraphQL */ `mutation DeleteSession(
  $input: DeleteSessionInput!
  $condition: ModelSessionConditionInput
) {
  deleteSession(input: $input, condition: $condition) {
    id
    questions
    initTime
    subquestionsOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSessionMutationVariables,
  APITypes.DeleteSessionMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview(
  $input: CreateReviewInput!
  $condition: ModelReviewConditionInput
) {
  createReview(input: $input, condition: $condition) {
    id
    interviewId
    author
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview(
  $input: UpdateReviewInput!
  $condition: ModelReviewConditionInput
) {
  updateReview(input: $input, condition: $condition) {
    id
    interviewId
    author
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview(
  $input: DeleteReviewInput!
  $condition: ModelReviewConditionInput
) {
  deleteReview(input: $input, condition: $condition) {
    id
    interviewId
    author
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createNetworkQualityLog = /* GraphQL */ `mutation CreateNetworkQualityLog(
  $input: CreateNetworkQualityLogInput!
  $condition: ModelNetworkQualityLogConditionInput
) {
  createNetworkQualityLog(input: $input, condition: $condition) {
    timestamp
    username
    sessionId
    description
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNetworkQualityLogMutationVariables,
  APITypes.CreateNetworkQualityLogMutation
>;
export const updateNetworkQualityLog = /* GraphQL */ `mutation UpdateNetworkQualityLog(
  $input: UpdateNetworkQualityLogInput!
  $condition: ModelNetworkQualityLogConditionInput
) {
  updateNetworkQualityLog(input: $input, condition: $condition) {
    timestamp
    username
    sessionId
    description
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNetworkQualityLogMutationVariables,
  APITypes.UpdateNetworkQualityLogMutation
>;
export const deleteNetworkQualityLog = /* GraphQL */ `mutation DeleteNetworkQualityLog(
  $input: DeleteNetworkQualityLogInput!
  $condition: ModelNetworkQualityLogConditionInput
) {
  deleteNetworkQualityLog(input: $input, condition: $condition) {
    timestamp
    username
    sessionId
    description
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNetworkQualityLogMutationVariables,
  APITypes.DeleteNetworkQualityLogMutation
>;
export const createQuestionResponse = /* GraphQL */ `mutation CreateQuestionResponse(
  $input: CreateQuestionResponseInput!
  $condition: ModelQuestionResponseConditionInput
) {
  createQuestionResponse(input: $input, condition: $condition) {
    id
    interviewId
    stage
    rating
    timestamp
    interviewerName
    question
    notes
    order
    subquestionResponses {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionResponseMutationVariables,
  APITypes.CreateQuestionResponseMutation
>;
export const updateQuestionResponse = /* GraphQL */ `mutation UpdateQuestionResponse(
  $input: UpdateQuestionResponseInput!
  $condition: ModelQuestionResponseConditionInput
) {
  updateQuestionResponse(input: $input, condition: $condition) {
    id
    interviewId
    stage
    rating
    timestamp
    interviewerName
    question
    notes
    order
    subquestionResponses {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionResponseMutationVariables,
  APITypes.UpdateQuestionResponseMutation
>;
export const deleteQuestionResponse = /* GraphQL */ `mutation DeleteQuestionResponse(
  $input: DeleteQuestionResponseInput!
  $condition: ModelQuestionResponseConditionInput
) {
  deleteQuestionResponse(input: $input, condition: $condition) {
    id
    interviewId
    stage
    rating
    timestamp
    interviewerName
    question
    notes
    order
    subquestionResponses {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionResponseMutationVariables,
  APITypes.DeleteQuestionResponseMutation
>;
export const createSubquestionResponse = /* GraphQL */ `mutation CreateSubquestionResponse(
  $input: CreateSubquestionResponseInput!
  $condition: ModelSubquestionResponseConditionInput
) {
  createSubquestionResponse(input: $input, condition: $condition) {
    id
    questionResponseId
    question
    notes
    order
    displayTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSubquestionResponseMutationVariables,
  APITypes.CreateSubquestionResponseMutation
>;
export const updateSubquestionResponse = /* GraphQL */ `mutation UpdateSubquestionResponse(
  $input: UpdateSubquestionResponseInput!
  $condition: ModelSubquestionResponseConditionInput
) {
  updateSubquestionResponse(input: $input, condition: $condition) {
    id
    questionResponseId
    question
    notes
    order
    displayTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubquestionResponseMutationVariables,
  APITypes.UpdateSubquestionResponseMutation
>;
export const deleteSubquestionResponse = /* GraphQL */ `mutation DeleteSubquestionResponse(
  $input: DeleteSubquestionResponseInput!
  $condition: ModelSubquestionResponseConditionInput
) {
  deleteSubquestionResponse(input: $input, condition: $condition) {
    id
    questionResponseId
    question
    notes
    order
    displayTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSubquestionResponseMutationVariables,
  APITypes.DeleteSubquestionResponseMutation
>;
export const createRecording = /* GraphQL */ `mutation CreateRecording(
  $input: CreateRecordingInput!
  $condition: ModelRecordingConditionInput
) {
  createRecording(input: $input, condition: $condition) {
    id
    interviewId
    stage
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRecordingMutationVariables,
  APITypes.CreateRecordingMutation
>;
export const updateRecording = /* GraphQL */ `mutation UpdateRecording(
  $input: UpdateRecordingInput!
  $condition: ModelRecordingConditionInput
) {
  updateRecording(input: $input, condition: $condition) {
    id
    interviewId
    stage
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRecordingMutationVariables,
  APITypes.UpdateRecordingMutation
>;
export const deleteRecording = /* GraphQL */ `mutation DeleteRecording(
  $input: DeleteRecordingInput!
  $condition: ModelRecordingConditionInput
) {
  deleteRecording(input: $input, condition: $condition) {
    id
    interviewId
    stage
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRecordingMutationVariables,
  APITypes.DeleteRecordingMutation
>;
export const createQuestion = /* GraphQL */ `mutation CreateQuestion(
  $input: CreateQuestionInput!
  $condition: ModelQuestionConditionInput
) {
  createQuestion(input: $input, condition: $condition) {
    id
    stage
    companyID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionMutationVariables,
  APITypes.CreateQuestionMutation
>;
export const updateQuestion = /* GraphQL */ `mutation UpdateQuestion(
  $input: UpdateQuestionInput!
  $condition: ModelQuestionConditionInput
) {
  updateQuestion(input: $input, condition: $condition) {
    id
    stage
    companyID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionMutationVariables,
  APITypes.UpdateQuestionMutation
>;
export const deleteQuestion = /* GraphQL */ `mutation DeleteQuestion(
  $input: DeleteQuestionInput!
  $condition: ModelQuestionConditionInput
) {
  deleteQuestion(input: $input, condition: $condition) {
    id
    stage
    companyID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionMutationVariables,
  APITypes.DeleteQuestionMutation
>;
export const createSubQuestions = /* GraphQL */ `mutation CreateSubQuestions(
  $input: CreateSubQuestionsInput!
  $condition: ModelSubQuestionsConditionInput
) {
  createSubQuestions(input: $input, condition: $condition) {
    id
    questionID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSubQuestionsMutationVariables,
  APITypes.CreateSubQuestionsMutation
>;
export const updateSubQuestions = /* GraphQL */ `mutation UpdateSubQuestions(
  $input: UpdateSubQuestionsInput!
  $condition: ModelSubQuestionsConditionInput
) {
  updateSubQuestions(input: $input, condition: $condition) {
    id
    questionID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubQuestionsMutationVariables,
  APITypes.UpdateSubQuestionsMutation
>;
export const deleteSubQuestions = /* GraphQL */ `mutation DeleteSubQuestions(
  $input: DeleteSubQuestionsInput!
  $condition: ModelSubQuestionsConditionInput
) {
  deleteSubQuestions(input: $input, condition: $condition) {
    id
    questionID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSubQuestionsMutationVariables,
  APITypes.DeleteSubQuestionsMutation
>;
