import React from "react";
import { cloneDeep } from "lodash";
import { styles } from "./styles";
import { User } from "../Users/index";
import { Button, Modal } from "../../Components";

export default function UsersSelector(props) {
  const {
    interviewers,
    stage,
    users,
    setInterviewers,
    cid,
    userModalVisible,
    setUserModalVisible,
  } = props;

  function changeInterviewer(selected: string) {
    const updatedInterviewers = cloneDeep(interviewers);
    const { firstName, lastName, email, id } = users[selected];
    const name = `${firstName} ${lastName}`;
    updatedInterviewers[stage] = { name, email, id };
    setInterviewers(updatedInterviewers);
  }

  if (users)
    return (
      <div>
        <Modal
          style={{
            width: "auto",
            minWidth: "auto",
            maxHeight: "80%",
          }}
          title="Add User"
          isVisible={userModalVisible}
          onClose={() => setUserModalVisible(false)}
        >
          <div
            style={{
              maxHeight: "calc(80vh - 76px)",
              overflowY: "auto",
              padding: "0 20px",
            }}
          >
            <User mini cid={cid} setUserModalVisible={setUserModalVisible} />
          </div>
        </Modal>

        <div style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
          <div style={{ ...styles.inputLabel, width: "auto", marginRight: 10 }}>
            Lead Interviewer
          </div>
        </div>
        <div className="hor-center">
          <select
            value={interviewers[stage].id}
            onChange={(s) => changeInterviewer(s.target.value)}
            style={{ ...styles.select, width: 290 }}
          >
            {Object.keys(users).map((userId) => {
              if (users[userId]) {
                const { firstName, lastName, email } = users[userId];
                return (
                  <option key={userId} value={userId}>
                    {firstName} {lastName} ({email})
                  </option>
                );
              }
              return null;
            })}
          </select>
          <div style={{ width: 10 }} />
          <div className="hor-center" style={{ width: 50, marginBottom: 16 }}>
            <Button
              filled
              style={styles.participantAddButton}
              onClick={() => setUserModalVisible(true)}
            >
              New
            </Button>
          </div>
        </div>
      </div>
    );
}
