import { Box, HStack, Table, TableContainer, Tbody, Text } from "@chakra-ui/react";
import * as React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ExistingQuestionTR } from "./ExistingQuestionTR";
import { QuestionTableHeaders } from "./QuestionTableHeaders";

export const ExistingQuestionTable = 
    ({ attributeTitle, importableQuestions, setIsOpen, weightedValues, valueId, attributeId, setState }) => 
    importableQuestions.length > 0
        ? <TableContainer>
            <Table>
                <QuestionTableHeaders />
                <Tbody>
                    {importableQuestions.map(
                        (existingQuestion, key) => <ExistingQuestionTR {...{ attributeTitle, setIsOpen, weightedValues, existingQuestion, valueId, attributeId, setState, key }} />
                    )}
                </Tbody>
            </Table>
        </TableContainer>
        : <HStack>
            <Box>
                <AiOutlineInfoCircle />
            </Box>
            <Text>No reusable questions available for this attribute at the moment</Text>
        </HStack>;


