import { CandidateApprovalStatusCard } from "./CandidateApprovalStatusCard";
import { ApprovalStatusEnum } from "../../types/Summary";
import React from "react";
import classNames from "classnames";
import { WeightedValues } from "./state/values/WeightedValues";
import { Questions } from "./state/questions/Questions";
import { Meetings } from "./state/meetings/Meetings";
import { People } from "./state/attendees/People";
import { IntertruDimensionScores } from "./state/scores/IntertruDimensionScores";
import { StageLevelAssessments } from "./state/stages/StageLevelAssessments";
import { questionsReferredToFromWeightedValues } from "./questionsReferredToFromWeightedValues";
import { isNotUndefined } from "./isNotUndefined";
import { mean } from "lodash";
import { ratingForScore } from "./state/scores/ratingForScore";
import { attributesRelatedToQuestion } from "./attributesRelatedToQuestion";
import { intendedAndOrActualAttendeePersonIds } from "./chart/intendedAndOrActualAttendeePersonIds";
import { NamedThumbChip } from "./chart/NamedThumbChip";
import { personById } from "./chart/personById";
import { colors } from "./chart/colors";
import { personsMostRecentQuestionRating } from "./chart/personsMostRecentQuestionRating";
import { Question } from "../../types/Question";

export interface QuestionCardProps {
  question: Question;
  questionIndex: number;
  questionResponse: any;
  meetings: Meetings;
  people: People;
  mlScores: IntertruDimensionScores;
  stageLevelAssessments: StageLevelAssessments;
  dimensions: WeightedValues;
  isLastItem: boolean;
}

export const QuestionCard = ({
  question,
  questionIndex,
  questionResponse,
  meetings,
  people,
  mlScores,
  stageLevelAssessments,
  dimensions,
  isLastItem,
}: QuestionCardProps) => {
  const intertruScoresForQuestion = mlScores
    // @ts-ignore
    .flatMap((mls) => mls.details)
    .flatMap((d) => d.questionScores)
    .filter((qs) => qs.questionId === q.id)
    .map((q) => q.score)
    .filter(isNotUndefined);
  const intertruScore =
    intertruScoresForQuestion.length > 0
      ? mean(intertruScoresForQuestion)
      : undefined;
  const intertruRating = ratingForScore(intertruScore);

  const matchingAttScore = (attributeId: string) =>
    mlScores
      .flatMap((mlScore) => mlScore.details)
      .find((attScore) => attScore.attributeId === attributeId);

  const attsForQuestion = attributesRelatedToQuestion(dimensions)(
    question.id as string,
  );

  
  const lineBreakIndex = question.text.indexOf("\n")
  const firstLine = lineBreakIndex > 0 ? question.text.substring(0, lineBreakIndex) : question.text
  const questionParts = firstLine.split(":");

  return (
    <div
      className={classNames("rounded-[16px] bg-white p-10", {
        "mb-4": !isLastItem,
      })}>
      <div className="grid grid-cols-12">
        <div className="col-span-10 col-start-2 flex flex-col items-start font-['Montserrat'] mb-6">
          <div className="text-gray-900 text-3xl font-bold leading-9">
            {questionIndex + 1 + ". " + questionParts[0]}
          </div>
          <div className="text-gray-500 text-lg font-normal leading-relaxed">
            {questionParts[1]}
          </div>
        </div>
        <div className="col-span-10 col-start-2">
          {intendedAndOrActualAttendeePersonIds(meetings).map(
            (pId: any, i: number) => {
              const rating = personsMostRecentQuestionRating(question.id)(
                pId as string,
              )(meetings);

              let status;
              switch (rating) {
                case "ThumbsSideways":
                  status = ApprovalStatusEnum.Neutral;
                  break;
                case "ThumbsUp":
                  status = ApprovalStatusEnum.Approved;
                  break;
                case "ThumbsDown":
                  status = ApprovalStatusEnum.NotApproved;
                  break;
                default:
                  status = ApprovalStatusEnum.Neutral;
                  break;
              }

              const statusCardKey = `${question.id ?? questionIndex}${pId}`;
              return (
                <CandidateApprovalStatusCard
                  key={statusCardKey}
                  person={personById(people)(pId)}
                  questionResponse={questionResponse}
                  status={status}
                  stageLevelAssessments={stageLevelAssessments}
                />
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
