import { ConclusionRating} from "../questions/Rating";
import { ThumbsDown } from "../questions/ThumbsDown";
import { ThumbsUp } from "../questions/ThumbsUp";

export const stageLevelRatingFromReviewPassValue = (pass: string | undefined): ConclusionRating => {
    const result  =  pass === "yes"
    ? ThumbsUp
    : ThumbsDown
        
    return result
}