import React from "react";

export interface LabelValueProps {
  label: string;
  value: string;
}

export const LabelValue = ({ label, value }: LabelValueProps) => {
  return (
    <div className="text-gray-700 font-['Montserrat'] leading-normal tracking-tight flex flex-col">
      <p className="text-base font-normal">{label}:</p>
      <p className="text-xl font-semibold">{value}</p>
    </div>
  );
};
