import React from "react";

import { useEffect, useState } from 'react'
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import "../../Components/modal.css";
import { ProgressBar } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import { SKILLS } from './DropdownDataCollections';
import './customstyle.css'; 

import {   
    addUserToDB,
    createCognitoUser,
    listCompanies
} from '../../utils/api';
import rs from "randomstring";
import { update } from "lodash";

export function SkillsModal (props) {
    const { onClick, modalVisible, title, currentRequiredSkills, setFinalSelectedSkills, currentOptionalSkills, setFinalOptionalSkills, handleStatusNote } = props;

    const [filteredSkills, setFilteredSkills] = useState(SKILLS);
    const [filteredOptionalSkills, setFilteredOptionalSkills] = useState(SKILLS);
    const [showSkill, setShowSkill] = useState(false);

    const iterations = Array.from({ length: 1 }, (_, index) => index);

    const [selectedRequiredSkills, setSelectedRequiredSkills] = useState([]);
    const [selectedOptionalSkills, setSelectedOptionalSkills] = useState([]);

    const [activeIndex, setActiveIndex] = useState(null);
    const [activeOptionalIndex, setActiveOptionalIndex] = useState(null);

    useEffect(() => {
        console.log(selectedRequiredSkills)
    },[selectedOptionalSkills, selectedRequiredSkills]);
    useEffect(() => {
        const updateCurrentSkills = () => {
            if(currentRequiredSkills.length > 0) {
                const newSkills = [...currentRequiredSkills, { name: '' }];
                setSelectedRequiredSkills(newSkills);
            }
            else {
                setSelectedRequiredSkills([{ name: '' }]);
            }
            if(currentOptionalSkills.length > 0) {
                const newSkills = [...currentOptionalSkills, { name: '' }];
                setSelectedOptionalSkills(newSkills);
            }
            else {
                setSelectedOptionalSkills([{ name: '' }]);
            }
        }
        updateCurrentSkills()
    }, [currentRequiredSkills, currentOptionalSkills]);

    const handleRequieredSkillFilter = (index, event) => {
        const value = event.target.value;
        selectedRequiredSkills[index].name = value;
        const filtered = SKILLS.filter(skill =>
          skill.name.toLowerCase().includes(value.toLowerCase())
        );
        if (!filtered.some(skill => skill.name.toLowerCase() === value.toLowerCase())) {
            filtered.push({ name: value });
        }
        setFilteredSkills(filtered);
        setActiveIndex(index);
    };


    const handleSelectRequiredSkill = (index, skill) => {
        const newSkills = [...selectedRequiredSkills];
        newSkills[index] = skill;
        setSelectedRequiredSkills(newSkills);
        setSelectedRequiredSkills([...newSkills, { name: '' }]);
        setActiveIndex(null); 
    };

    const handleOptionalSkillFilter = (index, event) => {
        const value = event.target.value;
        selectedOptionalSkills[index].name = value;
    
        const filtered = SKILLS.filter(skill =>
          skill.name.toLowerCase().includes(value.toLowerCase())
        );

        if (!filtered.some(skill => skill.name.toLowerCase() === value.toLowerCase())) {
            filtered.push({ name: value });
        }
        setFilteredOptionalSkills(filtered);
        setActiveOptionalIndex(index);
    };

    const handleOptionalSelectSkill = (index, skill) => {
        const newSkills = [...selectedOptionalSkills];
        newSkills[index] = skill;
        setSelectedOptionalSkills(newSkills);
        setSelectedOptionalSkills([...newSkills, { name: '' }]);
        setActiveOptionalIndex(null); 
    };

    const handleAddButtonClick = () => {
        const finalRequiredSkills = selectedRequiredSkills.filter(skill => skill.name !== '');
        setFinalSelectedSkills(finalRequiredSkills);
        setSelectedRequiredSkills(finalRequiredSkills);
        const finalOptionalSkills = selectedOptionalSkills.filter(skill => skill.name !== '')
        setFinalOptionalSkills(finalOptionalSkills);
        setSelectedOptionalSkills(finalOptionalSkills);
        props.onClose();
        props.handleStatusNote();
    }

    const handleCancelButtonClicked = () => {
        props.onClose();
    }

    const handleDeleteRequiredSkill = (skillName, index) => {
        const newSkills = selectedRequiredSkills.filter(skill => skill.name !== skillName);
        setSelectedRequiredSkills(newSkills);
    };

    const handleDeleteOptionalSkill = (skillName) => {
        const newSkills = selectedOptionalSkills.filter(skill => skill.name !== skillName);
        setSelectedOptionalSkills(newSkills);
    };

    const handleAddRequiredSkill = () => {
        setSelectedRequiredSkills([...selectedRequiredSkills, { name: '' }]);
    };
    const handleAddOptionaldSkill = () => {
        setSelectedOptionalSkills([...selectedOptionalSkills, { name: '' }]);
    };
    

    const styles = {
        modal: {
            display: "flex",
            flexDirection: "column",
            height: 656,
            bottom: "50%",
            top: "50%",
            padding: 0,
            borderRadius: 24,
            overflow: "hidden",
            width: 1036,
        },
        modalWrapper: {
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
        },
        headerWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 24,
        },
        headerText: {
            fontSize: 20,
            fontWeight: "bold",
        },
        headerIcon: {
            fontSize: 26,
        },
        body: {
            padding: 8,
            width: 522,
            
            flexShrink: 0,
            borderRadius: 24,
            background: "var(--Gray-Gray-2, #F3F4F6)"
        },
        skillsMainContainer: {
            width: 1036,
            height: 648,
            flexShrink: 0,
            borderRadius: 24,
            background: "var(--Gray-Gray-2, #F3F4F6)",
            padding: 8,
            display: "flex",
            justifyContent: "space-between",
        },
        skillsContainer: {
            padding: 8,
            width: 506,
            height: 552,
            borderRadius: 24,
            background: "var(--White-White, #FFF)",
            zIndex: 999,
            justifyContent: "flex-start",
        },
        fullInputStyle : {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "90%",        
            background: "var(--White-White, #FFF)",
            outline: "none",
            height: 38,
        },

        fullInputStyleDisabled : {
            display: "flex",
            height: 40,
            padding: "8px 16px",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            width: "98%",        
            borderRadius: 8,
            border: "1px solid var(--Stroke, #DFE4EA)",
            background: "#E5E4E2"
        },
        footer: {
            background: "white",
            padding: "6px 20px 0px",
        },
        addNewRoleButton : {
            position: "relative",
            display: "inline-flex", 
            padding: "8px 16px",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "8px",
            borderRadius: "50px",
            background: selectedRequiredSkills || selectedOptionalSkills
            ? "var(--Primary, #F60)" :  "var(--Stroke, #DFE4EA)",
            width: 98,
            height: 40,
            zIndex: 999,
        },
        addNewRollButtonText : {
            color: selectedRequiredSkills || selectedOptionalSkills
            ? "white" : "var(--Disabled, #9CA3AF)",
            textAlign: "center",
    
            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontFize: "16px",
            fontFtyle: "normal",
            fontWeight: 500,
            lineHeight: "24px" /* 150% */
        },
        cancelButton : {
            position: "relative",
            display: "inline-flex", 
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            borderRadius: "50px",
            background: "var(--White-White, #FFF)",
            width: 98,
            height: 40,
            border: "1px solid var(--Stroke, #DFE4EA)",
            zIndex: 999,
            marginLeft: 8,
        },
        cancelButtonText : {
            color: "var(--Primary-Text, #4B5563)",
            textAlign: "center",
    
            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontFize: "16px",
            fontFtyle: "normal",
            fontWeight: 500,
            lineHeight: "24px" /* 150% */
        },
        formLabelText : {
            color: "var(--Primary-Text, #374151)",
    
            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500,
            marginBottom: 8
        },
        dropDownSkills : {
            position: 'absolute', 
            backgroundColor: 'white', 
            zIndex: 1, listStyleType: 'none', 
            padding: 8, 
            margin: 0, 
            height: "auto", 
            maxHeight: 312,
            overflow: 'scroll',
            border: "1px solid var(--Stroke, #DFE4EA)",
            borderRadius: 8,
            width : 355
        },
        listItemStyle : {
            padding: 8,
            cursor: 'pointer'
        },
    
        listItemHoverStyle : {
            backgroundColor: '#f0f0f0'
        },

        mainText : {
            color: "var(--Primary-Text, #374151)",

            /* Body Large/SemiBold */
            fontFamily: "Montserrat",
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 600,
            marginBottom: 8
        },
        addButtonStyle: {
            marginTop: 20,
            padding: 10,
            backgroundColor: 'var(--Primary, #F60)',
            color: 'white',
            border: 'none',
            borderRadius: 10,
            cursor: 'pointer',
        },
    }


    return (
        <Rodal
            visible={props.modalVisible}
            onClose={props.onClose}
            customStyles={{ ...styles.modal, ...props.style }}
            duration={0}
            showCloseButton={false}
        >
        <div style={styles.modalWrapper}>
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
        </div>
        </Rodal>
    );

    function renderHeader() {
        return (
        <div style={styles.headerWrapper}>
            <div style={styles.headerText}>{props.title}</div>
            <div style={{justifyContent: "flex-end", display: "flex", width: "50%"}}>
                <div style={styles.addNewRoleButton}>
                    {true ? (
                    <div className='add-icon'></div>
                    ) : (
                    <div className='add-icon-disable'></div>
                    )}
                    <button 
                        style={styles.addNewRollButtonText}
                        onClick={handleAddButtonClick}
                    >Add</button>
                </div>
                <div style={styles.cancelButton}>
                    <button 
                        style={styles.cancelButtonText}
                        onClick={handleCancelButtonClicked}
                    >Cancel</button>
                </div>
            </div>
        </div>
        );
    }

    function renderBody() {
        return (
            <div style={styles.skillsMainContainer}>
                <div style={styles.skillsContainer}>
                    <div style={{overflow: "scroll", height: "100%", padding: 8}}>
                        <div style={styles.mainText}>Required</div>
                        <div> 
                            Enter skills here that you are genuinely unwilling to compromise on and will not 
                            consider developing in a new hire. Intertru's Discovery Interviews will help you 
                            to filter out candidates who lack any of these skills.
                        </div>
                        <div style={{marginTop: 16}}>
                       
                            <div>
                            {selectedRequiredSkills.map((skill, index) => (
                                <div key={index} style={{marginBottom : 16, position: "relative"}}>
                                    <div style={styles.formLabelText}>Skill {index + 1}</div>
                                    <div className='fullInputStyle'>
                                        <input style={styles.fullInputStyle}
                                            type="text"
                                            name={`skill${index + 1}`}                                            
                                            value={selectedRequiredSkills[index]?.name}
                                            onBlur={() => setTimeout(() => setShowSkill(false), 200)} 
                                            onChange={(event) => handleRequieredSkillFilter(index, event)}
                                        />
                                        <div style={{marginTop: 5, justifySelf: "flex-end"}}>
                                            <button 
                                                className="close-gray"
                                                onClick={() =>handleDeleteRequiredSkill(skill.name, index)}
                                            >
                                             </button>
                                        </div>
                                    </div>
                                    {activeIndex === index && (
                                    <ul style={styles.dropDownSkills}>
                                            {filteredSkills.map(skill => (
                                            <li 
                                                style={styles.listItemStyle}
                                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.listItemHoverStyle.backgroundColor}
                                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                                                key={skill.name} 
                                                onClick={() => handleSelectRequiredSkill(index, skill)}>
                                                {skill.name}
                                            </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))}
                            </div>
                        
                        </div>
                    </div>
                </div>
                <div style={styles.skillsContainer}>
                    <div style={{overflow: "scroll", height: "100%", padding: 8}}>
                        <div style={styles.mainText}>Desired (Optional)</div>
                        <div> 
                        Enter nice to have skills here. Intertru will quantify and assess how well a candidate fits the role. Remember, 
                        values-aligned candidates can learn missing skills faster than they can change their values and behaviors.
                        </div>
                        <div style={{marginTop: 16}}>
                       
                            <div>
                            {selectedOptionalSkills.map((skill, index) => (
                                <div key={index} style={{marginBottom : 16, position: "relative"}}>
                                    <div style={styles.formLabelText}>Skill {index + 1}</div>
                                    <div className='fullInputStyle'>
                                        <input style={styles.fullInputStyle}
                                            type="text"
                                            name={`optionalskill${index + 1}`}                                            
                                            value={selectedOptionalSkills[index]?.name}
                                            onBlur={() => setTimeout(() => setShowSkill(false), 200)} 
                                            onChange={(event) => handleOptionalSkillFilter(index, event)}
                                        />
                                        <div style={{marginTop: 5, justifySelf: "flex-end"}}>
                                            <button 
                                                className="close-gray"
                                                onClick={() =>handleDeleteOptionalSkill(skill.name)}
                                            >
                                             </button>
                                        </div>
                                    </div>
                                    {activeOptionalIndex === index && (
                                    <ul style={styles.dropDownSkills}>
                                            {filteredOptionalSkills.map(skill => (
                                            <li 
                                                style={styles.listItemStyle}
                                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.listItemHoverStyle.backgroundColor}
                                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                                                key={skill.name} 
                                                onClick={() => handleOptionalSelectSkill(index, skill)}>
                                                {skill.name}
                                            </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))}
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function renderFooter() {
        if (props.stickyFooter && !props.loading)
        return <div style={styles.footer}>{props.stickyFooter}</div>;
    }
}

export function JobDescLoadingModal (props) {

    const {showModal, onClose, cancelProgress} = props;
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (props.showModal) {
          setProgress(0); // Reset progress on modal open
          const interval = setInterval(() => {
            setProgress((prevProgress) => {
              if (prevProgress >= 100) {
                clearInterval(interval);
                return 100;
              }
              return prevProgress + 10;
            });
          }, 500);
    
          return () => clearInterval(interval);
        }
      }, [props.showModal]);

    const styles = {
        modal: {
            display: "flex",
            flexDirection: "column",
            height: "auto",
            bottom: "auto",
            top: "auto",
            padding: 0,
            borderRadius: 24,
            overflow: "hidden",
            width: 567,
            position: "absolute",
        },
        rodalContainer: {
            height: 100,
            width: 567,
            backgroundColor: "transparent"
        },
        progressContainer: {
            position: "absolute",
            top: "45%",
            left: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            height: 100,
            width: 567,
            padding: 16,
            borderRadius: 16,
            border: "1px solid var(--Stroke, #DFE4EA)",
            background: "var(--White-White, #FFF)",
    
            /* Shadow 1 */
            boxShadow: "0px 1px 3px 0px rgba(166, 175, 195, 0.40)"
        },
        uploadIconContainer: {
            display: "flex",
            padding: 8,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 8,
            background: "var(--Cyan-Cyan, #01A9DB)",
            height: 64,
            width: 64,
        },
        statusContainer: {
            marginLeft: 16,
            widht: 416,
            height: 64,
            alignItems: "center",
            display: "inline-grid",
            justifyContent: "center",
        },
        uploadingText: {
            color: "var(--Cyan-Cyan-dark, #0B76B7)",
            width: 426,
            /* Body Large/SemiBold */
            fontFamily: "Montserrat",
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 600
        },
    }
    return (
        <div>
            <div style={styles.progressContainer}>
                <div style={styles.uploadIconContainer}>
                    <div className="upload-file"></div>
                </div>
                <div style={styles.statusContainer}>
                    <label style={styles.uploadingText}>Uploading.....</label>
                    <ProgressBar className = "progress-line"
                        now={progress}
                        style={{ width: '100%', color: "var(--Cyan-Cyan-dark, #0B76B7)", height: 8}}
                        solid
                        variant="success"
                    />
                </div>
                <div style={{marginLeft: 8}}>
                    <button className="close-gray" onClick={cancelProgress}></button>
                </div>
            </div>
        </div>
    );
}

export function StatusModal (props) {

    const {showModal, onClose, headerText, statusText, status} = props;
    const [progress, setProgress] = useState(0);

    const styles = {
        rodalContainer: {
            height: 80,
            backgroundColor: "transparent"
        },
        progressContainer: {
            zIndex: 999,
            position: "absolute",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            height: 80,
            width: "auto",
            padding: 8,
            borderRadius: 16,
            border: "1px solid var(--Stroke, #DFE4EA)",
            background: "var(--White-White, #FFF)",
    
            /* Shadow 1 */
            boxShadow: "0px 1px 3px 0px rgba(166, 175, 195, 0.40)"
        },
        uploadIconContainer: {
            display: "flex",
            padding: 8,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 8,
            background: status === "success" ? "var(--Green-Green-light-6, #DAF8E6)" : "var(--Red-Red-light-6, #FEE2E2)",
            height: 64,
            width: 64,
        },
        statusContainer: {
            marginLeft: 16,
            widht: "auto",
            height: 64,
            alignItems: "center",
            display: "inline-grid",
            justifyContent: "center",
        },
        uploadingText: {
            color: status === "success" ? "var(--Green-Green-dark, #1A8245)" : "var(--Red-Red-dark, #C53030)",
            width: 350,
            /* Body Large/SemiBold */
            fontFamily: "Montserrat",
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 600
        },
        statusText: {
            color: "var(--Secondary-Text, #6B7280)",
            width: 'auto',
            /* Body Large/SemiBold */
            fontFamily: "Montserrat",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 400
        },
    }
    return (
        <div style={{position: "fixed", top: -32, left: "36%", alignSelf: "center", zIndex: 9999}}>
            <div style={styles.progressContainer}>
                <div style={styles.uploadIconContainer}>
                    <div className="check-icon"></div>
                </div>
                <div style={styles.statusContainer}>
                    <label style={styles.uploadingText}>{headerText}</label>
                    <label style={styles.statusText}>{statusText}</label>
                </div>
            </div>
        </div>
    );
}

export function HireOSRoleProfileModal (props) {

    const {onClose, modalVisible} = props
 
    const styles = {
        modal: {
            display: "flex",
            flexDirection: "column",
            height: "auto",
            bottom: "auto",
            top: "auto",
            padding: 24,
            borderRadius: 24,
            overflow: "hidden",
            width: 896,
        },
        modalWrapper: {
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
        },
        headerText :{
            color: "var(--Primary-Text, #374151)",

            /* Heading 6 */
            fontFamily: "Montserrat",
            fontSize: 24,
            fontStyle: "normal",
            fontWeight: 700,
            marginBottom: 16
        },
        contentText : {
            color: "var(--Secondary-Text, #6B7280)",
            marginBottom: 16,
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
        },
        bodyHeaderText :{
            color: "var(--Primary, #F60)",
            
            /* Heading 6 */
            fontFamily: "Montserrat",
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 600,
        },
        okButton :{
            display: "flex",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            borderRadius: 50,
            background: "var(--Primary, #F60)",
            width : 88,
            height: 40,

            color: "var(--White-White, #FFF)",
            textAlign: "center",

            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500
        }
    }

    return (
        <Rodal
            visible={modalVisible}
            onClose={onClose}
            customStyles={{ ...styles.modal, ...props.style }}
            duration={0}
            showCloseButton={false}
        >
        <div style={styles.modalWrapper}>
            {renderHeader()}
            {renderDivider()}
            {renderBody()}
            {renderFooter()}
        </div>
        </Rodal>
    );

    function renderHeader() {
        return (
            <div>
                <div style={styles.headerText}>HireOS™ - Role Profiles</div>
                <div style={styles.contentText}>Organizations have a need for all types of people. Understanding your needs for each role and the team dynamics you are crafting helps establish the right decision criteria for selecting well-aligned individuals.  
                If you have any questions on what to select for your role, don't hesitate to reach out to us at Intertru
                </div>
            </div>
        )
    }

    function renderDivider() {
        return (
            <div style={{background: "#D9D9D9", height: 1, marginBottom: 16}}></div>
        )
    }
    function renderBody() {
        return(
            <div>
                <div style={styles.bodyHeaderText}>BUILDER</div>
                <div style={styles.contentText}>
                    <>
                    <strong>Builders are most enthusiastic about roles where they can initiate projects from scratch. </strong> 
                    They are driven by the desire to make a difference by innovating and creating new solutions. 
                    Proactive and eager to solve problems as they arise, builders are ideally suited for start-up environments. 
                    They often serve as the initial employees within a company, team, or specific function.
                    </>
                </div>
                <div style={styles.bodyHeaderText} >IMPROVER</div>
                <div style={styles.contentText}>
                    <>
                    <strong>Improvers excel in stable, expanding companies</strong> where resources such as funding and staff time enable them to 
                    make gradual investments in existing products, systems, services, frameworks, or business relationships. 
                    This incremental approach creates added business value for the company and professional satisfaction for themselves. 
                    Improvers focus on enhancing existing revenue-generating assets and can typically measure the outcomes of their efforts 
                    in tangible ways.
                    </>
                    
                </div>
                <div style={styles.bodyHeaderText} >MAINTAINER</div>
                <div style={styles.contentText}>
                    <>
                    <strong>Maintainers are reliable, typically very organized, and carry out a plan efficiently. </strong>
                    They prefer to work autonomously, adhering to established protocols without necessarily seeking to innovate or initiate 
                    new projects. Their strength lies in efficiently executing existing systems and protocols, making them particularly valuable 
                    in larger companies.
                    </>
                    
                </div>
            </div>
        )
    }
    function renderFooter() {
        return(
            <button style={styles.okButton}
             onClick={onClose}
            >
                <div className="check-circle-outline"></div>
                <div>Ok</div>
            </button>
        )
    }

}

export function JobDescriptionConfirmationModal (props) {

    const {onClose, modalVisible, deleteJobDesctiptionAction} = props

    const styles = {
        modal: {
            display: "flex",
            flexDirection: "column",
            height: "auto",
            bottom: "auto",
            top: "auto",
            padding: 24,
            borderRadius: 24,
            overflow: "hidden",
            width: 536,
        },
        modalWrapper: {
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
        },
        headerText :{
            color: "var(--Primary-Text, #374151)",

            /* Heading 6 */
            fontFamily: "Montserrat",
            fontSize: 24,
            fontStyle: "normal",
            fontWeight: 700,
        },
        contentText : {
            color: "var(--Secondary-Text, #6B7280)",
            marginBottom: 16,
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
        },
        footer :{
            justifyContent : "inline-flex",
            display : "flex"
        },
        yesButton :{
            display: "flex",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            borderRadius: 50,
            background: "var(--Primary, #F60)",
            width : 92,
            height: 40,
            marginRight: 8,

            color: "var(--White-White, #FFF)",
            textAlign: "center",

            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500
        },
        cancelButton :{
            display: "flex",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            borderRadius: 50,
            background: "var(--White-White, #FFF)",
            border: "1px solid var(--Stroke, #DFE4EA)",
            width : 92,
            height: 40,

            color: "color: var(--Primary-Text, #374151)",
            textAlign: "center",

            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500
        }
    }

    return (
        <Rodal
            visible={modalVisible}
            onClose={onClose}
            customStyles={{ ...styles.modal, ...props.style }}
            duration={0}
            showCloseButton={false}
        >
        <div style={styles.modalWrapper}>
            {renderHeader()}
            {renderDivider()}
            {renderBody()}
            {renderFooter()}
        </div>
        </Rodal>
    );

    function renderHeader() {
        return (
            <div style={styles.headerText}>
                Delete
            </div>
        )
    }

    function renderDivider() {
        return (
            <div style={{background: "#D9D9D9", height: 1, marginBottom: 16, marginTop: 16}}></div>
        )
    }

    function renderBody() {
        return(
            <div style={styles.contentText}>
                Are you sure you want to delete this job description?
            </div>
        )
    }

    function renderFooter() {
        return(
            <div style={styles.footer}>
                <button style={styles.yesButton}
                     onClick={deleteJobDesctiptionAction}
                >
                    <div className="check-circle-outline"></div>
                    <div>Yes</div>
                </button>
                <div style={styles.cancelButton}>
                    <button
                        onClick={onClose}
                    >
                    <div>Cancel</div>
                    </button>
                    
                </div>
            </div>
        )
    }
}

export function AddNewManagerModal (props) {

    const {onClose, modalVisible, companyID, success, setSelectedHiringManager} = props

    const [managerName, setManagerName] = useState()
    const [managerEmail, setManagerEmail] = useState()
    const [enableSaveButton, setEnableSaveButton] = useState(false)

    const styles = {
        modal: {
            display: "flex",
            flexDirection: "column",
            height: "auto",
            bottom: "auto",
            top: "auto",
            borderRadius: 24,
            overflow: "hidden",
            width: 752,
        },
        modalWrapper: {
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
        },
        headerContainer :{
            display: "flex",
            justifyContent : "space-between",
            padding : 24
        },
        buttonsContainer :{
            display: "flex",
            justifyContent : "space-between",
        },
        headerText :{
            color: "var(--Primary-Text, #374151)",

            /* Heading 6 */
            fontFamily: "Montserrat",
            fontSize: 24,
            fontStyle: "normal",
            fontWeight: 700,
        },
        saveButton :{
            display: "flex",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            borderRadius: 50,
            background: enableSaveButton ? "var(--Primary, #F60)" : "var(--Stroke, #DFE4EA)",
            color : enableSaveButton ? "var(--White-White, #FFF)" : "var(--Disabled, #9CA3AF)",
            cursor: enableSaveButton ? 'pointer' : 'not-allowed',
            width : 102,
            height: 40,
            marginRight: 8,
            textAlign: "center",

            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500
        },
        cancelButton :{
            display: "flex",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            borderRadius: 50,
            background: "var(--White-White, #FFF)",
            border: "1px solid var(--Stroke, #DFE4EA)",
            width : 88,
            height: 40,
            color: "color: var(--Primary-Text, #374151)",
            textAlign: "center",

            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500
        },
        mainContainer :{
            width: 752,
            height: 120,
            flexShrink: 0,
            borderRadius: 24,
            background: "var(--Gray-Gray-2, #F3F4F6)",
            padding: 8
        },
        formArea: {
            display: "flex",
            justifyContent: "space-between",
            background: "var(--White-White, #FFF)",
            borderRadius: 24,
            width: 736,
            height: 104,
            flexShrink: 0,
            padding: 24,
            alignItems: "center"
        },
        nameEntry : {
            width : "45%"
        },
        emailEntry : {
            width : "45%"
        }
    }

    const handleManagerNameChange = (event) => {
        setManagerName(event.target.value);
        setEnableSaveButton(true)
        if (event.target.value === "" || managerEmail === "") {
            setEnableSaveButton(false)
        }
    };
    const handleManagerEmailChange = (event) => {
        setManagerEmail(event.target.value);
        setEnableSaveButton(true)
        if (managerName === "" || event.target.value === "") {
            setEnableSaveButton(false)
        }
    };

    const handleSaveButtonClicked = async () => {
        setEnableSaveButton(false)
        try {
            let userId;
            const password = rs.generate(10);
            const params = {
                username: managerEmail,
                password,
                company: companyID,
              };
            userId = await createCognitoUser(params).then(async (data) => {
                if (data) {
                    const userInfo = await addUserToDB({
                        id: userId,
                        email : managerEmail,
                        firstName : managerName.split(' ')[0],
                        lastName: managerName.split(' ')[1],
                        title : "Hiring Manager",
                        group: companyID,
                        companyID: companyID,
                        status: "Active",
                        role: "admin",
                    });
                    if(userInfo) {
                        onClose()
                        setSelectedHiringManager(userInfo.id)
                        success(userInfo)
                    }
                }
            });
            
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <Rodal
            visible={modalVisible}
            onClose={onClose}
            customStyles={{ ...styles.modal, padding: 0 }}
            duration={0}
            showCloseButton={false}
        >
        <div style={styles.modalWrapper}>
            {renderHeader()}
            {renderBody()}
        </div>
        </Rodal>
    );

    function renderHeader() {
        return(
            <div style={styles.headerContainer}>
                <div style={styles.headerText}>New Hiring Manager</div>
                <div style={styles.buttonsContainer}>
                    <button style={styles.saveButton}
                        onClick={handleSaveButtonClicked}
                    >   
                        {enableSaveButton ? (
                            <div className="save-icon-active"></div>
                        ) : (
                            <div className="save-icon-disable"></div>
                        )}
                        <div>Save</div>
                    </button>
                    <button style={styles.cancelButton}
                        onClick={onClose}
                    >
                        <div>Cancel</div>
                    </button>
                </div>
            </div>
        )
    }

    function renderBody() {
        return(
            <div style={styles.mainContainer}>
                <div style={styles.formArea}>
                    <div style={styles.nameEntry}>
                        <div style={{marginBottom: 8}}>Name</div>
                        <input className='fullInputStyle'
                            type="text"
                            value={managerName}
                            onChange={handleManagerNameChange}
                        />
                    </div>
                    <div style={styles.emailEntry}>
                        <div style={{marginBottom: 8}}>Email</div>
                        <input className='fullInputStyle'
                            type="email"
                            value={managerEmail}
                            onChange={handleManagerEmailChange}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export function AddNewJobRoleCompanySelection (props) {

    const {onClose, modalVisible} = props

    const navigate = useNavigate();
    const [companyLists, setCompanyLists] = useState()

    useEffect(() => {
        const fetchCompanies = async () => {
          try {
            const data = await listCompanies();
            setCompanyLists(data.items);
          } catch (error) {
            console.error('Error fetching company lists:', error);
          }
        };
    
        fetchCompanies();
      }, []);
 
    const styles = {
        modal: {
            display: "flex",
            flexDirection: "column",
            height: "auto",
            bottom: "auto",
            top: "auto",
            padding: 24,
            borderRadius: 24,
            overflow: "hidden",
            width: 896,
        },
        modalWrapper: {
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
            padding : 24
        },
        headerContainer :{
            display: "flex",
            justifyContent : "space-between",
        },
        headerText :{
            color: "var(--Primary-Text, #374151)",

            /* Heading 6 */
            fontFamily: "Montserrat",
            fontSize: 24,
            fontStyle: "normal",
            fontWeight: 700,
        },
        cancelButton :{
            display: "flex",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            borderRadius: 50,
            background: "var(--White-White, #FFF)",
            border: "1px solid var(--Stroke, #DFE4EA)",
            width : 88,
            height: 40,
            color: "color: var(--Primary-Text, #374151)",
            textAlign: "center",

            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500
        },
        companyListsContainer : {
            display: "flex",
            flexDirection: "column",
            maxHeight: 400,
            overflow: "scroll",
            padding : 8,
            marginTop:16
        },
        companyButton : {
            display: "flex",
            padding: "8px 16px",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            width: "98%",        
            borderRadius: 16,
            background: "var(--Primary, #F60)",
            color: "white",
            marginBottom: 8
        }
    }

    const handleCompanySelected = (companyID) => {
        if(companyID) {
            props.onClose()
            navigate(`/admin/jobrole?compID=${companyID}`)
        }
    }

    const handleCancelButtonClicked = () => {
        setCompanyLists(null)
        props.onClose()
    }

    return (
        <Rodal
            visible={modalVisible}
            onClose={onClose}
            customStyles={{ ...styles.modal, padding: 0 }}
            duration={0}
            showCloseButton={false}
        >
        <div style={styles.modalWrapper}>
            {renderHeader()}
            {renderBody()}
        </div>
        </Rodal>
    );

    function renderHeader() {
        return(
            <div style={styles.headerContainer}>
                <div style={styles.headerText}>Select Company</div>
                <button 
                    style={styles.cancelButton}
                    onClick={() => handleCancelButtonClicked()}
                >Cancel</button>
            </div>
        )
    }

    function renderBody(){
        return(
            <div style={styles.companyListsContainer}>
                {companyLists && companyLists.map(companyData => (
                    <div>
                        <button
                            style={styles.companyButton}
                            onClick={() => handleCompanySelected(companyData.id)}>
                            <div>{companyData.company}</div>
                        </button>
                    </div>
                ))}
            </div>
        )
    }
}


