import { newExample } from "./newExample";

export const onAddExampleClick = (setState) => (valueId) => (attributeId) => (e) => setState(
    state => ({
        ...state,
        weightedValues: state.weightedValues.map(
            weightedValue => weightedValue.id == valueId
                ? {
                    ...weightedValue,
                    weightedAttributes: weightedValue.weightedAttributes.map(
                        weightedAttribute => weightedAttribute.id == attributeId
                            ? {
                                ...weightedAttribute,
                                examples: [
                                    ...weightedAttribute.examples,
                                    newExample(),
                                ]
                            }
                            : weightedAttribute
                    )
                }
                : weightedValue
        )
    })
);
