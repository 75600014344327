import React, { Dispatch, SetStateAction } from "react";
import { StateForChart } from "../state/StateForChart";
import { SetInterviewAnalyticsState } from "./SetInterviewAnalyticsState";
import { StageChartV2 } from "./StageChartV2";
import { Spacer } from "@chakra-ui/react";
import { TopSection } from "../TopSection";

type SetState<T> = Dispatch<SetStateAction<T>>;
export type InterviewAnalyticsV2Props = {
  stateForChart: StateForChart;
  currentStageName: string;
  setCurrentStageName: SetState<string | undefined>;
  setStateForChart: SetInterviewAnalyticsState;
  interviewData: any;
};

export const InterviewAnalyticsV2 = ({
  stateForChart,
  currentStageName,
  setCurrentStageName,
  setStateForChart,
  interviewData,
}: InterviewAnalyticsV2Props) => {
  const { people, valuesDefinition, candidateId, stages } = stateForChart ?? {};
  return (
    <>
      <div className="shadow rounded-[24px] bg-white">
        <TopSection interviewData={interviewData} />
        <div className="w-full justify-center flex p-[16px] grid-cols-10 gap-[16px] bg-background-lighter rounded-[24px]">
          {stages.map((stage) => {
            const { name, mlScores } = stage;
            //TODO: Navigate to a stage-specific summary url instead
            const onClick = () => setCurrentStageName(name);
            const isSelected = currentStageName === name;
            return (
              <StageChartV2
                key={name}
                {...{
                  candidateId,
                  onClick,
                  isSelected,
                  stage,
                  mlScores,
                  people,
                  valuesDefinition,
                  setStateForChart,
                }}
              />
            );
          })}
        </div>
      </div>
      <Spacer height="2em" />      
    </>
  );
};
