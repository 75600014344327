import React from "react"
import DownArrow from "./downArrow.svg"

export const SelectedChartPointer = 
  ({ isSelected }:any) => 
  (isSelected 
    ? <img
        src={DownArrow as any}
        alt=""
        height="4em"
        style={{ margin: "0px"}}
      />
    : <></>
  )