import { toast } from "react-toastify";

import { GraphQL } from "./api";
import { createReview } from "../graphql/mutations";
import sleep from "./sleep";

export default async function createParticipantReview(
  participantNotes,
  interviewId,
  author,
  notesMode = false
) {
  console.log("submitting as participant");
  const variables = {
    input: {
      interviewId,
      author,
      notes: JSON.stringify(participantNotes),
    },
  };
  const query = createReview;
  let res;
  try {
    res = await GraphQL({ query, variables, authMode: "" });
    if (!notesMode) {
      toast.success("Notes successfully recorded.");
    }
  } catch (e) {
    console.log(e);
  }
  if (notesMode) return res;
  if (!notesMode) {
    await sleep(2000);
    console.log("attempt window close");
    return window.close();
  }
}
