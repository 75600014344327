import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container, Button, TextInput, Modal } from "../Components";
import { updateCompany } from "../graphql/mutations";
import { GraphQL } from "../utils/api";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import termsPdf from "../Documents/TermsService.pdf";
import privacyPdf from "../Documents/PrivacyPolicy.pdf";
import { pdfjs } from "react-pdf";
import { privacyContent, termsContent } from "../Documents/legalContent";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ResetPassword() {
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [checked, setChecked] = useState(false);

  async function handleSubmit() {
    if (!code || !password) return toast.error("All fields are required.");
    if (!checked)
      return toast.error(
        "Please check the box indicating that you have read and agreed to the Privacy Policy and the Terms of Service."
      );

    const authUser = await Auth.currentAuthenticatedUser();
    console.log(authUser);
    const username = authUser.attributes.email;

    try {
      const res = await Auth.forgotPasswordSubmit(username, code, password);
      console.log(res);
      const query = updateCompany;
      const variables = {
        input: {
          id: authUser.username,
          hasLoggedIn: true,
        },
      };
      const updateCompanyRes = await GraphQL({
        query,
        variables,
        authMode: "",
      });
      console.log(updateCompanyRes);
      toast.success("Successfully changed password.");
      navigate("/admin/dashboard");
    } catch (e) {
      console.error(e);
      return toast.error(
        "An error occured. Please confirm that your verification code matches the code sent to your email address."
      );
    }
  }

  return (
    <Container>
      <DocModal
        title="Intertru Privacy Policy"
        modalVisible={privacyModalVisible}
        setModalVisible={setPrivacyModalVisible}
        docPdf={privacyPdf}
      />
      <DocModal
        title="Intertru Terms of Service"
        modalVisible={termsModalVisible}
        setModalVisible={setTermsModalVisible}
        docPdf={termsPdf}
      />
      <div style={{ fontSize: 30, fontWeight: "bold", marginBottom: 40 }}>
        Reset Password
      </div>
      <form>
        <div>
          <div style={styles.inputLabel}>Verification Code</div>
          <TextInput
            inputStyle={styles.inputStyle}
            value={code}
            onChange={setCode}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>New Password</div>
          <TextInput
            inputStyle={styles.inputStyle}
            value={password}
            onChange={setPassword}
          />
        </div>
        <div style={{ ...styles.flexStart, margin: "20px 0px" }}>
          <input
            type="checkbox"
            checked={checked}
            readOnly
            style={{ position: "relative", top: -1 }}
            onClick={() => setChecked(!checked)}
          />
          <div style={{ marginLeft: 8 }}>
            I have read and agreed to the Intertru{" "}
            <span
              style={styles.link}
              onClick={() => setPrivacyModalVisible(true)}
            >
              Privacy Policy
            </span>{" "}
            and the{" "}
            <span
              style={styles.link}
              onClick={() => setTermsModalVisible(true)}
            >
              Terms of Service
            </span>
            .
          </div>
        </div>
        <div style={styles.flexStart}>
          <Button buttonType="button" filled onClick={handleSubmit}>
            Submit
          </Button>
          <div style={{ width: 12 }} />
          <Button buttonType="button" onClick={handleResend}>
            Resend Code
          </Button>
        </div>
      </form>
    </Container>
  );

  async function handleResend() {
    const auth = await Auth.currentAuthenticatedUser();
    const { email } = auth.signInUserSession.idToken.payload;
    await Auth.forgotPassword(email);
    toast.info("A new code has been resent to your email.");
  }
}

export function DocModal(props) {
  const { modalVisible, setModalVisible } = props;

  return (
    <Modal
      style={styles.modal}
      isVisible={modalVisible}
      onClose={() => setModalVisible(false)}
    >
      <div className="doc-modal-content">{renderContent()}</div>
    </Modal>
  );

  function renderContent() {
    if (props.content === "privacy") return privacyContent;
    else if (props.content === "terms") return termsContent;
  }
}

const styles = {
  inputLabel: {
    width: 180,
    maxWidth: 180,
  },
  inputStyle: {
    width: 350,
    height: 40,
    border: "1px solid #555",
    borderRadius: 4,
  },
  areaInputStyle: {
    padding: 5,
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #555",
    borderRadius: 4,
  },
  flexStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  link: {
    textDecoration: "underline",
    color: "blue",
    cursor: "pointer",
  },
  modal: {
    minWidth: 900,
    borderRadius: 0,
  },
};
