export const attributeLibrary = {
    "questions": [
       {
          "libraryId": "q1",
          "id": "26296",
          "text": "Describe a situation where you chose to do the ethical thing, even when it was difficult or when no one was watching",
          "title": "Question Library 1"
       },
       {
          "libraryId": "q2",
          "id": "31868",
          "text": "Have you ever had a personal relationship possibly conflict with a professional objective?",
          "title": "Question Library 2"
       },
       {
          "id": "73499",
          "text": "Describe a specific time when you had to handle a problem that challenged ethical issues",
          "title": ""
       }
    ],
    "attributes": [
        {
           "examples": [],
           "description": "",
           "weight": 0,
           "id": "34835",
           "questionIds": [],
           "title": ""
        },
        {
           "examples": [],
           "description": "A way of thinking or feeling about the bright side of any situation that is usually reflected in a person's can-do behavior",
           "weight": "1",
           "id": "1681",
           "questionIds": [],
           "title": "Positive Attitude"
        },
        {
           "examples": [],
           "description": "The ability to find quick and clever ways to overcome difficulties",
           "weight": "1",
           "id": "7327",
           "questionIds": [],
           "title": "Resourcefulness"
        },
        {
           "examples": [],
           "description": "Being reactive to the needs and feedback of customers and fellow employees and quickly producing a meaningful response.\n",
           "weight": "1",
           "id": "60932",
           "questionIds": [],
           "title": "Responsiveness"
        },
        {
           "examples": [],
           "description": "Prioritizing the physical and mental health of fellow employees.\n",
           "weight": "1",
           "id": "98667",
           "questionIds": [],
           "title": "Employee Well-being"
        },
        {
           "examples": [],
           "description": "Allowing employees the autonomy to make decisions.  Hiring resourceful, self-motivated employees that require very little hand-holding ",
           "weight": "1",
           "id": "5005",
           "questionIds": [],
           "title": "Independence"
        },
        {
           "examples": [],
           "description": "Encouraging calculated risks to drive innovation.",
           "weight": "1",
           "id": "52071",
           "questionIds": [],
           "title": "Risk-Taking"
        },
        {
           "examples": [],
           "description": "Managing costs effectively to enhance profitability.",
           "weight": "1",
           "id": "25178",
           "questionIds": [],
           "title": "Cost-Efficiency"
        },
        {
           "examples": [],
           "description": "Leveraging the inner drive and motivation of other individual and tapping into internal goals and missions to drive positive change in the workplace.",
           "weight": "1",
           "id": "70183",
           "questionIds": [],
           "title": "Passion"
        },
        {
           "examples": [],
           "description": "Constructive use of enjoyable activities, humor, event celebrations, and highlighting accomplishments can create a tighter team, build morale, and retain employees who might otherwise burn out. ",
           "weight": "1",
           "id": "18883",
           "questionIds": [],
           "title": "Fun"
        },
        {
           "examples": [],
           "description": "Promoting truthful and straightforward communication.\n",
           "weight": "1",
           "id": "24921",
           "questionIds": [],
           "title": "Honesty"
        },
        {
           "examples": [],
           "description": "Taking ownership of projects, responsibilities, and outcomes.",
           "weight": "1",
           "id": "76739",
           "questionIds": [],
           "title": "Ownership"
        },
        {
           "examples": [],
           "description": "Understanding and considering the feelings and perspectives of others.\n",
           "weight": "1",
           "id": "49109",
           "questionIds": [],
           "title": "Empathy"
        },
        {
           "examples": [],
           "description": "Being adaptable and responsive to changing market conditions.",
           "weight": "1",
           "id": "80357",
           "questionIds": [],
           "title": "Flexibility"
        },
        {
           "examples": [],
           "description": "Addressing global issues and challenges.\n",
           "weight": "1",
           "id": "86596",
           "questionIds": [],
           "title": "Global Responsibility"
        },
        {
           "examples": [],
           "description": "Prioritizing the safety and well-being of employees and customers.",
           "weight": "1",
           "id": "52749",
           "questionIds": [],
           "title": "Safety"
        },
        {
           "examples": [],
           "description": "Leading by example and inspiring others.",
           "weight": "1",
           "id": "13437",
           "questionIds": [],
           "title": "Leadership"
        },
        {
           "examples": [],
           "description": "Streamlining processes to maximize productivity.",
           "weight": "1",
           "id": "45632",
           "questionIds": [],
           "title": "Efficiency"
        },
        {
           "examples": [],
           "description": "Commitment to delivering high-quality products or services.",
           "weight": "1",
           "id": "98709",
           "questionIds": [],
           "title": "Quality"
        },
        {
           "examples": [],
           "description": "Actively participating in and supporting local communities.\n",
           "weight": "1",
           "id": "37396",
           "questionIds": [],
           "title": "Community Engagement"
        },
        {
           "examples": [],
           "description": "Encouraging innovative thinking and problem-solving.",
           "weight": "1",
           "id": "43820",
           "questionIds": [],
           "title": "Creativity"
        },
        {
           "examples": [],
           "description": "Ensuring that all individuals are valued and included.",
           "weight": "1",
           "id": "3104",
           "questionIds": [],
           "title": "Inclusivity"
        },
        {
           "examples": [],
           "description": "Striving for financial success and sustainable profitability.",
           "weight": "1",
           "id": "32328",
           "questionIds": [],
           "title": "Profitability"
        },
        {
           "examples": [],
           "description": "Encouraging open dialogue and active listening.\n",
           "weight": "1",
           "id": "96557",
           "questionIds": [],
           "title": "Open Communication"
        },
        {
           "examples": [],
           "description": "Incorporating environmentally sustainable practices.",
           "weight": "1",
           "id": "94949",
           "questionIds": [],
           "title": "Sustainability"
        },
        {
           "examples": [],
           "description": "Promoting teamwork and cooperation across departments.",
           "weight": "1",
           "id": "63947",
           "questionIds": [],
           "title": "Collaboration"
        },
        {
           "examples": [],
           "description": "Embracing a diverse workforce and fostering inclusivity.",
           "weight": "1",
           "id": "26093",
           "questionIds": [],
           "title": "Diversity and Inclusion"
        },
        {
           "examples": [],
           "description": "Being nimble and quick to respond to market demands.\n",
           "weight": "1",
           "id": "35527",
           "questionIds": [],
           "title": "Agility"
        },
        {
           "examples": [],
           "description": "Investing in employee growth and skill enhancement.",
           "weight": "1",
           "id": "98976",
           "questionIds": [],
           "title": "Learning and Development"
        },
        {
           "examples": [],
           "description": "Encouraging employees to take initiative and make decisions.",
           "weight": "1",
           "id": "3899",
           "questionIds": [],
           "title": "Empowerment"
        },
        {
           "examples": [],
           "description": "Engaging in environmentally and socially responsible practices.",
           "weight": "1",
           "id": "74839",
           "questionIds": [],
           "title": "Social Responsibility"
        },
        {
           "examples": [],
           "description": "Promoting a healthy balance between work and personal life.",
           "weight": "1",
           "id": "93066",
           "questionIds": [],
           "title": "Work-Life Balance"
        },
        {
           "examples": [],
           "description": "Building and maintaining trust among employees, customers, and partners.",
           "weight": "1",
           "id": "78822",
           "questionIds": [],
           "title": "Trust"
        },
        {
           "examples": [],
           "description": "Being flexible and responsive to change and market dynamics.",
           "weight": "1",
           "id": "70649",
           "questionIds": [],
           "title": "Adaptability"
        },
        {
           "examples": [],
           "description": "Being open and honest in communication and decision-making.\n",
           "weight": "1",
           "id": "69589",
           "questionIds": [],
           "title": "Transparency"
        },
        {
           "examples": [],
           "description": "Treating others with dignity, inclusivity, and fairness.\n",
           "weight": "1",
           "id": "60674",
           "questionIds": [],
           "title": "Respect"
        },
        {
           "examples": [],
           "description": "Taking responsibility for actions and outcomes.",
           "weight": "1",
           "id": "74963",
           "questionIds": [],
           "title": "Accountability"
        },
        {
           "examples": [],
           "description": "Striving for high-quality work and continuous improvement",
           "weight": "1",
           "id": "60795",
           "questionIds": [],
           "title": "Excellence"
        },
        {
           "examples": [
              {
                 "exampleScore": 1,
                 "id": "v1a2e1",
                 "text": "We had an engineering team that was constantly adding new costs and destroying our margin.  Luckily, I had the authority and discipline to reject these so-called innovations and actually improve margins "
              },
              {
                 "exampleScore": 4,
                 "id": "v1a2e2",
                 "text": "My team actually came up with bold new approach.  When they brought me the first hand-drawn hydrailic actuator, I quickly green lighted it because I could tell it would make the aircraft much more stable in turbulence and high wind."
              },
              {
                 "exampleScore": 10,
                 "id": "v1a2e3",
                 "text": "I was awarded 4 patents for various unique aspects of the next generation VTOL aircraft which used hydraulic actuators managed by a real-time computer."
              }
           ],
           "attributeLibraryId": "ATTR_LIB_2",
           "description": "Innovative candidates create new technologies, services, and business models that open up entirely new markets or solve a problem in a substantially better way.",
           "weight": "1",
           "id": "75480",
           "questionIds": [
              "26296"
           ],
           "title": "Innovation"
        },
        {
           "examples": [
              {
                 "exampleScore": 1,
                 "id": "v1a1e1",
                 "text": "the deal would not have happened if I hadn't taken written the entire proposal, lead all the meetings, and negotiated the contract myself."
              },
              {
                 "exampleScore": 4,
                 "id": "v1a1e2",
                 "text": "They say there is no I in team, but sometimes I found that my team would simply not live up to customer expectations if I wasn't in there translating their request into specific requirements that we needed to meet.  Once I realized I needed to do that whenever possible, we stopped shooting ourselves in the foot."
              },
              {
                 "exampleScore": 9,
                 "id": "v1a1e3",
                 "text": "I was part of a great twenty four by seven customer support team which allowed us to have the best customer sat scores and work-life balance I have experienced in my twenty years of doing customer support.  Alot of it was due to great documentation and record keeping discipline so that you could walk away from a ticket at any point. "
              }
           ],
           "attributeLibraryId": "ATTR_LIB_4",
           "description": "Collaborating and valuing contributions from all team members.",
           "weight": "1",
           "id": "85575",
           "questionIds": [],
           "title": "Teamwork"
        },
        {
           "examples": [
              {
                 "exampleScore": 1,
                 "id": "v1a1e1",
                 "text": "IBM was a big customer paying us a lot of money every year, so we naturally needed to say yes to most requests they made, even if it only benefited them."
              },
              {
                 "exampleScore": 5,
                 "id": "v1a1e2",
                 "text": "As a technical resource partnered with the Customer Success Team, most of my time was spent adding customizations and workarounds to ensure our customers were able to feel like they were getting good business value.  In one case, I worked for two months straight with an engineer at a customer to build out a custom data interchange module that processed events going both directions."
              },
              {
                 "exampleScore": 9,
                 "id": "v1a1e3",
                 "text": "We didn't have a customer success team yet, so it was up to me to fight tooth and nail with product management and company execs to get my customer's features prioritized and delivered on the deadlines they needed them on.  I must have done 7 or 8 different presentations, both to the customer to make sure I had heard them properly, and then advocating to the company stakeholders in product, engineering, finance, sales, and the CEO twice. "
              }
           ],
           "attributeLibraryId": "ATTR_LIB_3",
           "description": "No matter their title, every employee must take responsibility for the customer's success. At the associate level, one way to do this is to ensure customer service agents are empowered, can handle requests quickly, and have the tools they need to delight customers.",
           "weight": "1",
           "id": "44437",
           "questionIds": [
              "31868"
           ],
           "title": "Customer Focus"
        },
        {
           "examples": [
              {
                 "exampleScore": 8,
                 "text": "example text"
              },
              {
                 "exampleScore": 3,
                 "text": "example text 2"
              }
           ],
           "attributeLibraryId": "ATTR_LIB_1",
           "description": "Acting ethically and with honesty in all business dealings",
           "weight": "1",
           "id": "71300",
           "questionIds": [
              "73499",
              "26296",
              "31868"
           ],
           "title": "Integrity"
        }
     ]
 }
