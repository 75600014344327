export const onExampleDelete = (valueId) => (attributeId) => (exampleId) => (setState) => (e) => {
    setState(
        state => ({
            ...state,
            weightedValues: state.weightedValues.map(
                v => v.id == valueId
                    ? {
                        ...v,
                        weightedAttributes: v.weightedAttributes.map(
                            a => a.id == attributeId
                                ? {
                                    ...a,
                                    examples: a.examples.filter(e => e.id != exampleId)
                                }
                                : a
                        )
                    }
                    : v
            )
        })
    );
};
