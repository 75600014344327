import { debounce } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingScreen } from "../../../Components";
import { getCompanyInterviewBuilderData, updateCompanyInterviewBuilderData } from "../../../utils/api";
import { InterviewBuilder } from "./InterviewBuilder";
import { interviewBuilderEmptyState } from "./Values/List/interviewBuilderEmptyState";

export const InterviewBuilderController = () => {
    const [state, setState] = useState(interviewBuilderEmptyState);
    const [isLoading, setIsLoading] = useState(true);
    const [updatedAt, setUpdatedAt] = useState()
    const { customerId } = useParams()

    const memoizedDebouncedSave =
        useCallback(
            debounce(
                async (stateToSave) => {
                    try {
                        const newlyUpdatedAt = await updateCompanyInterviewBuilderData(customerId, stateToSave)
                        setUpdatedAt(newlyUpdatedAt)
                    }
                    catch(e) {
                        console.error(e)
                        toast.error(`There was an error saving. You may continue editing offline for now`)
                    }
                },
                3000
            ),
            [customerId, setUpdatedAt]
        )

    useEffect(
        () => {
            //todo: no need to save right after loading
            if(state) memoizedDebouncedSave(state)
        },
        [state, memoizedDebouncedSave]
    )

    useEffect(
        () => {
            const loadAndSetState = async () => {
                setIsLoading(true)
                const { interviewBuilderData, updatedAt } = await getCompanyInterviewBuilderData(customerId)
                const interviewBuilderDataParsed = JSON.parse(interviewBuilderData)
                setIsLoading(false)
                setUpdatedAt(updatedAt)
                setState(interviewBuilderDataParsed || interviewBuilderEmptyState)
            }
            loadAndSetState()
        },
        [customerId]
    )
    return isLoading
        ? <LoadingScreen />
        : <InterviewBuilder {...{ updatedAt, customerId, state, setState }} />;
};
