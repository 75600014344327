
export const onExampleFieldChange = 
    (valueId) => (attributeId) => (exampleId) => (fieldName) => (setState) => (e) => setState(
    state => ({
        ...state,
        weightedValues: state.weightedValues.map(
            weightedValue => weightedValue.id == valueId
                ? ({
                    ...weightedValue,
                    weightedAttributes: weightedValue.weightedAttributes.map(
                        weightedAttribute => weightedAttribute.id == attributeId
                            ? ({
                                ...weightedAttribute,
                                examples: weightedAttribute.examples.map(
                                    example => example.id == exampleId
                                        ? {
                                            ...example,
                                            [fieldName]: e.target.value,
                                        }
                                        : example
                                ),
                            })
                            : weightedAttribute
                    )
                })
                : weightedValue
        )
    })
);
