import { isNotUndefined } from "../isNotUndefined";
import { ValuesDefintition } from "../state/values/ValuesDefintition";
import { MLAttributeScorePayloadWithoutIweeOrIwer } from "./MLAttributeScorePayloadWithoutIweeOrIwer";

export const mlAttributeScorePayloadFromValueDefs = 
    (valuesDefinition:ValuesDefintition):MLAttributeScorePayloadWithoutIweeOrIwer =>
    ({
        action: "attribute_score",
        data: {
            interview_attributes: {
                questions: Object.fromEntries(
                    valuesDefinition.weightedValues
                        .flatMap(wv => wv.weightedAttributes)
                        .flatMap(wa => wa.questionIds)
                        .map(qId => valuesDefinition.questions.find(q => q.id === qId))
                        .filter(isNotUndefined)
                        .map(({ id, text }) => [id, text])
                ),
                attributes: valuesDefinition.weightedValues
                    .flatMap(wv => wv.weightedAttributes)
                    .map(wa => ({
                        name: wa.id,
                        description: wa.description, 
                        question_tags: wa.questionIds,
                        examples: wa.examples.map(
                            ({ exampleScore, text }) => 
                            ({ score: parseFloat(`${exampleScore}`), text })
                        ),
                    }))
            }
        }
    })