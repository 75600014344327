import React, {useState} from 'react';
import { Box, Text } from '@chakra-ui/react';
import { onAddAttributeClick } from "../../Pages/Interview/Builder/Attributes/List/onAddAttributeClick";
import { useNavigate } from "react-router-dom";

interface AttributeCardProps {
  weightedAttribute: {
    id: string,
    title: string,
    description: string,
    questionIds: string[]
  },
  setIsOpen: () => void,
  setState: () => void,
  valueId: string
}

const Card: React.FC<AttributeCardProps> = ({ valueId, weightedAttribute: {id, title, description, questionIds}, setIsOpen, setState }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  
  const toggleHoverOn = () => {
    setIsHovered(true);
  };

  const toggleHoverOff = () => {
    setIsHovered(false);
  }

  const maxH = isHovered ? 'unset' : '3em'; // Adjust maxH based on hover state
  if(!description) return null;
  return (
    <>
    <Box
      position="relative">
      <Box
        maxW="300px"
        p={4}
        cursor={isHovered ? 'pointer' : 'normal'}
        bg="white"
        border="1px solid #ccc"
        borderRadius="md"
        height="auto"
        transition="transform 0.2s, box-shadow 0.2s"
        transform={isHovered ? 'scale(1.05)' : 'scale(1)'}
        boxShadow={isHovered ? 'lg' : 'md'}
        onMouseEnter={toggleHoverOn}
        onMouseLeave={toggleHoverOff}
        position={isHovered ? "absolute" : "relative"}
        zIndex={isHovered ? 3 : 2}
        onClick={onAddAttributeClick(valueId)(id)(navigate)(setIsOpen)(setState)}
      >
        <Text fontSize="lg"  height="auto" fontWeight="bold">
          {title}
        </Text>
        <Text
          height="auto"
          fontSize="sm"
          mt={2}
          overflow="hidden"
          display="flex"
          textOverflow='ellipsis'
          whiteSpace={isHovered? "normal" : "nowrap"}
          maxW="100%"
          transition="max-height 0.3s, opacity 0.3s"
          opacity={isHovered ? 1 : 0.8}
        >
          {description}
        </Text>
        {isHovered && (
          <Text
            height="auto"
            fontSize="xs"
            textAlign="right"
            mt={2}
          >
            {questionIds.length} Questions
          </Text>
        )}
      </Box>
    </Box>
  </>
  );
}

export default Card;


