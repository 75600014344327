import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { Header, LoadingScreen } from "../Components";
import {
  Customer,
  Dashboard,
  Interview,
  Settings,
  Subscribe,
  Summary,
  User,
  Users,
  JobRoles,
  JobRole,
  RoleMigration,
  OrganizationProfile,
} from "../Pages";
import {
  getCompany,
  getStripeSubscription,
  getUser,
  updateUser,
} from "../utils/api";

import { Auth } from "aws-amplify";
import { CustomersRoutes } from "../Pages/Customers/CustomersRoutes";
import { checkRoles } from "../utils/auth";
import { companyIdFromLocalStorage } from "../Pages/companyIdFromLocalStorage";

export default function AdminApp() {
  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function checkValid() {
      try {
        // make sure user is logged in
        const groups = await checkRoles();
        const isAdmin = groups?.includes("Admin");
        let user = null;
        if (!isAdmin) {
          user = await Auth.currentAuthenticatedUser();
        }
        //update last logged in
        if (!isAdmin && user) {
          const res = await getUser(user?.username);
          await updateUser({
            id: res?.id,
            lastLoggedIn: new Date().toISOString(),
          });
          localStorage.setItem("companyID", res?.companyID);
        }
        // skip subscription check if user is admin
        if (groups.includes("Admin")) return setIsValid(true);
      } catch (e) {
        console.log("auth error", e);
        navigate("/");
      }

      const companyId = companyIdFromLocalStorage();

      // check if user has a non-expired credit card on file
      const { stripeCustomerId = "", plan } = await getCompany(companyId);
      let payPlanLabel = JSON.parse(plan).metadata.label;
      if (stripeCustomerId && payPlanLabel !== "Free") {
        // navigate to onboarding if no subscription on file
        try {
          const sub = await getStripeSubscription({ stripeCustomerId });
          if (!sub || sub.status !== "active") navigate("/admin/subscribe");
        } catch (e) {
          console.log(e);
        }
      }

      setIsValid(true);
    }
    checkValid();
  }, [navigate]);

  if (!isValid) return <LoadingScreen />;

  return (
    <>
      <Header />
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="customers/*" element={<CustomersRoutes />} />
        <Route path="users" element={<Users />} />
        <Route path="jobroles" element={<JobRoles />} />
        <Route path="jobrole" element={<JobRole />} />
        <Route path="rolemigration" element={<RoleMigration />} />
        <Route path="organizationprofile" element={<OrganizationProfile />} />
        <Route path="customer" element={<Customer />} />
        <Route path="user" element={<User />} />
        <Route path="settings" element={<Settings />} />
        <Route path="interview" element={<Interview />} />
        <Route path="summary/:candidateId" element={<Summary />} />
        <Route path="subscribe" element={<Subscribe />} />
        <Route path="*" element={<Navigate replace to="/admin/dashboard" />} />
      </Routes>
    </>
  );
}
