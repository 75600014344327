import { Modal, Button } from "../../Components";
import { errorRed } from "../../theme";

export default function ConfirmModal(props) {
  const { confirmModalVisible, setConfirmModalVisible, onConfirm } = props;
  return (
    <Modal
      title="Interview Stage Change"
      style={styles.modal}
      isVisible={confirmModalVisible}
      onClose={() => setConfirmModalVisible(false)}
    >
      <div style={{ padding: "0px 20px 20px" }}>
        <div style={{ marginBottom: 12 }}>
        Warning: Skipping interview stages increases the risks of a bad hire.
        </div>
        <div className="hor-center" style={{ justifyContent: "flex-start" }}>
          <Button filled onClick={onConfirm}>
            OK
          </Button>
          <div style={{ width: 10 }} />
          <Button
            style={{ color: errorRed, border: `2px solid ${errorRed}` }}
            onClick={() => setConfirmModalVisible(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const styles = {
  modal: {},
};
