import { Auth } from "aws-amplify";

export default async function getUserId() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes.sub;
  } catch (e) {
    return null;
  }
}
