import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RadioGroup, Radio, Stack } from "@chakra-ui/react";
import { FiCornerUpLeft as BackIcon } from "react-icons/fi";
import { toast } from "react-toastify";
import qs from "query-string";
import rs from "randomstring";

import {
  createStripeCustomer,
  createCompany,
  getPrices,
  createCognitoUser,
  addUserToDB,
  getCompany as getCompanyData,
  updateCompany,
  listCompanies,
  listUsers,
  updateSubscription,
  getProduct,
} from "../../utils/api";
import { Container, Button, TextInput, LoadingScreen } from "../../Components";
import UsersTable from "../Users/UsersTable";
import { styles } from "./styles";
import { validateEmail, isValidEmail } from "../../utils/validation";

import { sendEmail } from "../../utils/email";
import { DeleteModal } from "../Interview";
import { A } from "../Interview/Builder/general/A";
import { valuesAbsolutePath } from "../Interview/Builder/Values/valuesAbsolutePath";
import { usePageTitle } from "../../navigation/usePageTitle";

const ccEmail = process.env.REACT_APP_CC_EMAIL;

export default function Customer() {
  usePageTitle("Customer Details")
  const navigate = useNavigate();
  const location = useLocation();

  // company information
  const [company, setCompany] = useState("");
  const [planIndex, setPlanIndex] = useState("0");
  const [paymentPlans, setPaymentPlans] = useState(null);
  const [users, setUsers] = useState([]);
  const [stripeId, setStripeId] = useState(null);

  // owner information
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // stripe information
  const [product, setProduct] = useState(null);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [waiting, setWaiting] = useState(false);

  const { uid } = qs.parse(location.search);

  let mode = "add";
  let pageTitle = "Add Customer";
  if (!!uid) {
    pageTitle = "Customer Details";
    mode = "edit";
  }

  useEffect(() => {
    async function getStripeData() {
      try {
        const res = await getPrices();
        setPaymentPlans(res);
      }
      catch(e) {
        toast.error(`There was an error fetching stripe settings`)
        console.error(e)
        setPaymentPlans([]);
      }
      try {
        const product = await getProduct();
        setProduct(product);
      }
      catch(e) {
        toast.error(`There was an error fetching stripe settings`)
        console.error(e)
        setProduct(product);
      }
    }
    getStripeData();
  }, []);

  useEffect(() => {
    const populate = async () => {
      const getCompany = await getCompanyData(uid);
      setStripeId(getCompany?.stripeCustomerId);
      setUsers(getCompany?.users);
      setCompany(getCompany.company);
      setPlanIndex(getIndexOfPlan(JSON.parse(getCompany?.plan).id));
    };
    if (mode === "edit") populate();
  }, [mode, uid, paymentPlans]);

  const getIndexOfPlan = (planId) => {
    let index;
    for (const plan in paymentPlans) {
      index = plan;
      if (paymentPlans[plan].id === planId) return index;
    }
  };

  if (!(users && paymentPlans)) return <LoadingScreen />;

  function renderOwnerForm() {
    return (
      <div className="hor-center" style={styles.ownerFormContainer}>
        <div>
          <p style={styles.ownerHeader}>Billing Contact/Owner</p>
          <TextInput
            label="First Name"
            inputStyle={styles.inputStyle}
            value={firstName}
            onChange={setFirstName}
          />
          <TextInput
            label="Last Name"
            inputStyle={styles.inputStyle}
            value={lastName}
            onChange={setLastName}
          />
          <TextInput
            label="Title"
            inputStyle={styles.inputStyle}
            value={title}
            onChange={setTitle}
            optional
          />
          <TextInput
            label="Email Address"
            inputStyle={styles.inputStyle}
            value={email}
            onChange={setEmail}
          />
          <TextInput
            label="Phone Number"
            inputStyle={styles.inputStyle}
            value={phone}
            onChange={setPhone}
            optional
          />
        </div>
      </div>
    );
  }

  async function archiveCompany() {
    try {
      const res = await updateCompany({ id: uid, status: "Archived" });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
    toast.success("Customer archived");
    navigate("/admin/customers");
  }

  function renderFooter() {
    return (
      <div style={styles.footer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 8,
          }}
        >
          <Button
            filled
            buttonType="button"
            onClick={handleSubmit}
            waiting={waiting}
          >
            {mode === "add" ? "Submit" : "Update"}
          </Button>
          <div style={{ width: 10 }} />
          {mode === "edit" && (
            <Button
              onClick={() => setDeleteModalVisible(true)}
              style={styles.archiveButton}
            >
              Archive
            </Button>
          )}
        </div>
      </div>
    );
  }

  async function handleSubmit() {
    setWaiting(true);
    // handle update logic
    if (mode === "edit") {
      try {
        // update company in DB and cognito
        await updateCompany({
          id: uid,
          company,
          plan: JSON.stringify(paymentPlans[planIndex]),
        });

        if (!!stripeId) {
          // update subscription plan
          await updateSubscription({
            stripeCustomerId: stripeId,
            priceId: paymentPlans[planIndex].id,
          });
        }

        toast.success("Company information updated");
        return navigate("/admin/customers");
      } catch (e) {
        setWaiting(false);
        console.log(e);
        return toast.error("An error has occurred, please try again later.");
      }
    }

    // validate inputs
    const ownerInfo = !!(firstName && lastName && email);
    if (!company || !planIndex || !ownerInfo || !email) {
      setWaiting(false);
      return toast.error("Please fill out all required fields.");
    }
    const emailValid = isValidEmail(email);
    if (!emailValid) {
      setWaiting(false);
      return toast.error("Invalid email format");
    }

    // check if customer name already exists
    const { items } = await listCompanies();
    const uniqueCompanies = items.map((company) =>
      company.company.toLowerCase().trim()
    );
    if (uniqueCompanies.includes(company.toLowerCase().trim())) {
      setWaiting(false);
      return toast.error("Customer name already exists.");
    }

    // check if email already exists
    const users = await listUsers();
    const uniqueUsers = users.items.map((user) =>
      user.email.toLowerCase().trim()
    );
    if (uniqueUsers.includes(email.toLowerCase().trim())) {
      setWaiting(false);
      return toast.error("User email already exists.");
    }

    // create new Stripe customer
    const { id: stripeCustomerId } = await createStripeCustomer({
      description: company,
    });
    console.log("stripe customer created");

    // create company in DB and group for cognito
    const companyInfo = await createCompany({
      company,
      stripeCustomerId,
      plan: JSON.stringify(paymentPlans[planIndex]),
    });
    console.log("company created: ", companyInfo);

    // create cognito user
    let newOwnerUserId;
    try {
      newOwnerUserId = await createCognitoUser({
        username: email,
        password: rs.generate(10),
        company: companyInfo.id,
      });

      console.log("cognito user created");
      setWaiting(false);
    } catch (e) {
      setWaiting(false);
      return toast.error(e.response.data);
    }

    // add user to DB
    const userInfo = await addUserToDB({
      id: newOwnerUserId,
      email,
      firstName,
      lastName,
      phone,
      title,
      group: companyInfo.id,
      companyID: companyInfo.id,
      status: "Active",
      role: "owner",
    });

    console.log("user created: ", userInfo);

    // add the new owner user as the default interviewer
    const name = `${firstName} ${lastName}`;
    const interviewInfo = JSON.stringify({
      defaultInterviewers: {
        discovery: { name, email, id: newOwnerUserId },
        value1: { name, email, id: newOwnerUserId },
        working: { name, email, id: newOwnerUserId },
        value2: { name, email, id: newOwnerUserId },
        accepted: { name: "", email: "", id: "" },
        rejected: { name: "", email: "", id: "" },
      },
    });
    await updateCompany({
      id: companyInfo.id,
      company,
      stripeCustomerId,
      interviewInfo,
      plan: JSON.stringify(paymentPlans[planIndex]),
    });

    // send email notification to user
    const email_ = email.toLowerCase().trim();

    const emailValidationResult = validateEmail(email_);
    if (emailValidationResult) return toast.error(emailValidationResult);

    const text = `Hi ${firstName},
    \n\nCongratulations and welcome to the Intertru tribe!
    \n\nTo get started, click the link below and set a new password. Create your first interview and voila! ...let the magic begin.
    \n\n${window.location.origin}/welcome?uid=${newOwnerUserId}
    \n\nPlease reach out to us for any assistance.
    \n\nAloha,\nRick`;

    const emailPayload = {
      to: [email],
      cc: [ccEmail],
      subject: "Intertru Company Account Created",
      text,
    };
    console.log("sending email confirmation");
    sendEmail(emailPayload);
    toast.success(`Account for ${email} created`);

    setWaiting(false);
    toast.success(`Customer for ${company} created`);
    navigate("/admin/customers");
  }

  return (
    <Container style={{ paddingBottom: 80 }}>
      <div style={{ width: "100%", display: "flex" }}>
        <DeleteModal
          onClick={archiveCompany}
          modalVisible={deleteModalVisible}
          setModalVisible={setDeleteModalVisible}
          title="Archive Customer"
          description="Are you sure you want to archive this customer?"
        />
        <div style={{ width: "100%" }}>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
          >
            <BackIcon
              size={28}
              style={{ marginRight: 20, cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </div>
          <div style={styles.inputLabel}>Customer Name</div>

          <TextInput
            inputStyle={{ ...styles.inputStyle, marginBottom: 10 }}
            value={company}
            onChange={setCompany}
          />
          {!!planIndex && product && (
            <>
              <div style={styles.inputLabel}>Payment Plan</div>
              <RadioGroup
                style={styles.radioGroup}
                onChange={setPlanIndex}
                value={planIndex}
              >
                <Stack style={{ padding: 4, width: "50%" }} direction="column">
                  {paymentPlans.map((plan, i) => {
                    const { unit_amount, metadata, id } = plan;
                    const { label, description } = metadata;
                    const dollars = unit_amount / 100;
                    let descriptor = `${label} - $${dollars} ${product?.metadata?.description}`;
                    return (
                      <div
                        style={{ paddingTop: 10, borderTop: "1px solid #ddd" }}
                        key={id}
                      >
                        <Radio value={getIndexOfPlan(id)}>
                          <p style={styles.radio}>{descriptor}</p>
                        </Radio>
                        <p style={{ margin: "0px 0px 0px 24px" }}>
                          {description}
                        </p>
                      </div>
                    );
                  })}
                </Stack>
              </RadioGroup>
            </>
          )}
          {mode === "edit" && <>
            <div style={{...styles.inputLabel, marginTop: '1em', marginBottom: '1em'}}>
              <A to={valuesAbsolutePath(uid)}>Company Values</A>
            </div>
          </>}
        </div>
        {mode === "add" && renderOwnerForm()}
      </div>

      {mode === "edit" && (
        <div style={{ marginTop: 20 }}>
          <div style={styles.inputLabel}>Users</div>
          <UsersTable users={users.items} style={{ height: 300 }} />
        </div>
      )}
      {renderFooter()}
    </Container>
  );
}
