import { useEffect } from "react"
import { browserTabTitle } from "./browserTabTitle"
import { browserTitleBase } from "./browserTitleBase"

export const usePageTitle = (pageTitle) => {
    useEffect(
      () => { 
        document.title = browserTabTitle(pageTitle) 
        return () => { document.title = browserTitleBase }
      },
      [pageTitle],
    )
  }