import { errorRed } from "../../theme";

export const styles = {
  // customer page styles
  inputLabel: {
    width: 300,
    maxWidth: 300,
    fontWeight: 600,
    marginBottom: 8,
  },
  inputStyle: {
    width: 350,
    height: 40,
    border: "1px solid #555",
    borderRadius: 4,
  },
  areaInputStyle: {
    padding: 5,
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #555",
    borderRadius: 4,
  },
  radioGroup: {
    marginTop: 2,
    marginBottom: 8,
  },
  radio: {
    width: 400,
    fontWeight: "bold",
  },
  ownerFormContainer: {
    background: "#f4f4f4",
    borderRadius: 8,
    width: 400,
    minWidth: 400,
    padding: "20px 6px",
    marginBottom: 16,
  },
  ownerHeader: {
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 16,
  },
  archiveButton: {
    borderColor: errorRed,
    color: errorRed,
  },
  footer: {
    height: 64,
    background: "white",
    position: "fixed",
    paddingTop: 4,
    bottom: 0,
    width: 1000,
    borderTop: "1px solid #ddd",
  },

  // customer table styles
  tableWrapper: {
    marginTop: 30,
    border: "1px solid #ccc",
    borderRadius: 10,
    overflow: "hidden",
  },
  tableContainer: {
    width: "100%",
    height: "70vh",
    overflowY: "scroll",
  },
  tableHeader: {
    position: "sticky",
    background: "white",
    top: 0,
  },
  link: {
    color: "#0000ff",
    textDecoration: "underline",
    cursor: "pointer",
  },
  th: {
    textAlign: "center",
  },
};
