import { OpenAI } from 'openai';
import JobRole from './JobRole';




interface JobRoleData {
    jobTitle: string;
    salaryType: 'Hourly' | 'Monthly' | 'Annual';
    minimumAnnualSalary?: number;
    maximumAnnualSalary?: number;
    offeredAnnualSalary?: number;
    requiredSkills: string[];
    desiredSkills: string[];
    jobWorkLocationType: 'Remote' | 'Office' | 'Hybrid';
    officeLocation?: string | null;
    roleType: 'Builder' | 'Improver' | 'Maintainer';
    workAuthorizationRequirement: 'None' | 'Work Visa Required' | 'Citizenship Required';
  }
  
  export async function extractJobRoleData(jobDescription: string): Promise<JobRoleData> {

    const openAIApiKey = process.env.REACT_APP_INTERTRU_OPENAI_KEY;

    const openai = new OpenAI({
        apiKey: openAIApiKey,
        dangerouslyAllowBrowser: true
    });

    const prompt = `

    Generate a JSON Data Format that is error free from the job description that contains the following interface
    
    JobRoleData Interace

    jobTitle: string; // Get the most closest job title
    salaryType: 'Hourly' | 'Monthly' | 'Annual';
    minimumAnnualSalary?: number; //check base salary start
    maximumAnnualSalary?: number; // check base salary end
    offeredAnnualSalary?: number;
    requiredSkills: string[]; // Array of string listed out as required skills
    desiredSkills: string[]; Array of string listed out as desired skills
    jobWorkLocationType: 'Remote' | 'Office' | 'Hybrid';
    officeLocation?: string | null;
    roleType: 'Builder' | 'Improver' | 'Maintainer';
    workAuthorizationRequirement: 'None' | 'Work Authorization Visa' | 'Citizenship Required';

    Rank highest closest values from the job description especially the "Job Title" as indicated here  ${jobDescription}
   `;
  
    interface OpenAIWithCompletion extends OpenAI {
        createCompletion(params: any): Promise<any>;
    }

    const openaiWithCompletion = openai as OpenAIWithCompletion;

    try {
        var jobData: JobRoleData = {
            jobTitle: '',
            salaryType: 'Hourly',
            requiredSkills: [],
            desiredSkills: [],
            jobWorkLocationType: 'Remote',
            roleType: 'Builder',
            workAuthorizationRequirement: 'None',
            minimumAnnualSalary: 0,
            maximumAnnualSalary: 0,
            offeredAnnualSalary: 0,
            officeLocation: ''
        };
        await openai.completions.create({
            model: 'gpt-3.5-turbo-instruct',
            prompt: prompt,
            max_tokens: 3000,
        }).then((response) => {
            try {
                jobData = JSON.parse(response.choices[0].text.trim());
            }
            catch(e) {
                console.error('Error processing the job description:', e);
                return jobData;
            }
        });
        return jobData;
    } catch (error) {
        console.error('Error processing the job description:', error);
    }
  }