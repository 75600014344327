import moment from "moment-timezone";

export default async function getInterviewState(interviewObj) {
  const { interviewInfo, scheduling, stage } = interviewObj;
  const { dateTime } = JSON.parse(interviewInfo);

  const duration = stage === "working" ? 90 : 45;
  const wrapUpDuration = 15;
  const totalTime = duration; // not including wrap up time

  const schedule = JSON.parse(scheduling);

  const now = moment();
 

  if (stage === "accepted") return "offer_accepted";
  else if (stage === "rejected") return "rejected";
  else if (stage === "parked") return "parked";

  if (schedule) {
    if (!!schedule[stage]) {
      const endOfInterview = moment(schedule[stage]).add(totalTime, "m");
      if (now > endOfInterview) return "passed";
      else return "is_scheduled";
    } else return "not_scheduled";
  }
  // legacy schedule time
  if (dateTime) {
    const endOfInterview = moment(dateTime).add(totalTime, "m");
    if (now > endOfInterview) return "passed";
    else return "is_scheduled";
  } else {
    return "not_scheduled";
  }
}
