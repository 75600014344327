import React from "react";
import classNames from "classnames";
import { Person } from "./state/attendees/Person";
import { HandRightIcon } from "../../Components/common/icons/HandRightIcon";
import { FrontHandIcon } from "../../Components/common/icons/FrontHandIcon";
import { ConclusionRating } from "./state/questions/Rating";
import { ThumbsUp } from "./state/questions/ThumbsUp";
import { ThumbsDown } from "./state/questions/ThumbsDown";

export interface ConclusionApprovalStatusCardProps {
  candidateName: string;
  reason: string;
  rating: ConclusionRating;
  person: Person | undefined;
}

export const ConclusionApprovalStatusCard = ({
  candidateName,
  reason,
  rating,
  person,
}: ConclusionApprovalStatusCardProps) => {
  const interviewerName =  person?.friendlyName ?? 'Interviewer';
  let conslusionInfo: {
    notesTitle: string;
    reasonTitle: string;
  }
  switch (rating) {
    case ThumbsUp:
      conslusionInfo = {
        notesTitle: `${interviewerName} recommended that ${candidateName} proceed to the next stage.`,
        reasonTitle: 'Why?',        
      }
      break;
    case ThumbsDown:
    default:
      conslusionInfo = {
        notesTitle: `${interviewerName} recommended that ${candidateName} NOT proceed to the next stage and release the candidate.`,
        reasonTitle: 'Why not?',
      }
      break;
  }
  
  return (
    <div
      className={classNames(
        `w-full h-64 px-4 py-6 bg-white rounded-2xl border relative`,
        {
          "border-status-proceed": rating === ThumbsUp,
          "border-status-not-proceed": rating === ThumbsDown,
        },
      )}
    >
      <div
        className={classNames(
          `w-11 h-11 absolute top-6 left-4 rounded-full flex justify-center items-center bg-white bg-white border border-solid`,
          {
            "border-status-proceed": rating === ThumbsUp,
            "border-status-not-proceed": rating === ThumbsDown,
          },
        )}
      >
        <div className="text-white text-base font-bold font-['Montserrat'] leading-normal">
          {rating === ThumbsUp ? <HandRightIcon /> : <FrontHandIcon />}
        </div>
      </div>
      <div className="flex-col justify-start flex ml-14">
        <div className="self-stretch text-gray-900 text-lg font-semibold font-['Montserrat'] leading-relaxed">
          {conslusionInfo.notesTitle}
        </div>
        <div className="self-stretch text-gray-900 text-lg font-semibold font-['Montserrat'] leading-relaxed mt-8">
          {conslusionInfo.reasonTitle}
          <div className="font-normal font-['Montserrat']">
                {reason}
                <br />
              </div>  
        </div>        
      </div>
    </div>
  );
};
