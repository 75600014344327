import React from "react";

export interface HandRightIconProps {
    className?: string;
}

export const HandRightIcon = ({ className }: HandRightIconProps) => {
    return (
        <svg 
        className={className}
        width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="hand-point-right 2">
            <path id="Vector" d="M21 6H12.675C12.7219 6.24375 12.75 6.49219 12.75 6.75V7.5H14.25H21C21.4125 7.5 21.75 7.1625 21.75 6.75C21.75 6.3375 21.4125 6 21 6ZM10.5 6.75C10.5 5.92031 9.82969 5.25 9 5.25H7.875C4.76719 5.25 2.25 7.76719 2.25 10.875V13.125C2.25 15.5859 3.82969 17.6766 6.03281 18.4406C6.00937 18.2953 6 18.15 6 18C6 17.0625 6.43125 16.2234 7.10625 15.6703C6.87656 15.2484 6.75 14.7609 6.75 14.25C6.75 13.5422 6.99844 12.8906 7.40625 12.375C6.99375 11.8594 6.75 11.2078 6.75 10.5V8.625C6.75 8.00156 7.25156 7.5 7.875 7.5C8.49844 7.5 9 8.00156 9 8.625V10.5C9 10.9125 9.3375 11.25 9.75 11.25C10.1625 11.25 10.5 10.9125 10.5 10.5V8.625V6.75ZM9 3C9.84375 3 10.6219 3.28125 11.25 3.75H21C22.6547 3.75 24 5.09531 24 6.75C24 8.40469 22.6547 9.75 21 9.75H17.1562C17.2172 9.98906 17.25 10.2422 17.25 10.5C17.25 11.6859 16.5609 12.7125 15.5625 13.2C15.6844 13.5281 15.75 13.8797 15.75 14.25C15.75 15.1875 15.3187 16.0266 14.6437 16.5797C14.8734 17.0016 15 17.4891 15 18C15 19.6547 13.6547 21 12 21H9H7.875C3.525 21 0 17.475 0 13.125V10.875C0 6.525 3.525 3 7.875 3H9ZM12 18.75C12.4125 18.75 12.75 18.4125 12.75 18C12.75 17.5875 12.4125 17.25 12 17.25H9.75H9C8.5875 17.25 8.25 17.5875 8.25 18C8.25 18.4125 8.5875 18.75 9 18.75H12ZM12.75 10.5C12.75 10.7578 12.7172 11.0109 12.6562 11.25H12.75H14.25C14.6625 11.25 15 10.9125 15 10.5C15 10.0875 14.6625 9.75 14.25 9.75H12.75V10.5ZM11.625 13.5H9.75C9.3375 13.5 9 13.8375 9 14.25C9 14.6625 9.3375 15 9.75 15H12H12.75C13.1625 15 13.5 14.6625 13.5 14.25C13.5 13.8375 13.1625 13.5 12.75 13.5H11.625Z" fill="#0074D9"/>
            </g>
        </svg>

    )
}