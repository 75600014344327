import { useEffect, useState } from "react";
import { Button, Container, LoadingScreen, Modal } from "../Components";
import CreditCardInput from "react-credit-card-input";
import { toast } from "react-toastify";

import {
  getCompany,
  getStripeCards,
  createPaymentMethod,
  getUser,
  getStripeSubscription,
} from "../utils/api";
import { Auth } from "aws-amplify";
import useGroups from "../utils/useGroups";
import { companyIdFromLocalStorage } from "./companyIdFromLocalStorage";

const lsCompanyId = companyIdFromLocalStorage()

export default function Settings(props) {
  const groups = useGroups();
  const [stripeId, setStripeId] = useState("");
  const [cardData, setCardData] = useState(null);
  const [cardModalVisible, setCardModalVisible] = useState(false);

  const [subscription, setSubscription] = useState(null);

  const [role, setRole] = useState(null);

  const [waiting, setWaiting] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setIsAdmin(groups?.includes("Admin"));
  }, [groups]);

  useEffect(() => {
    async function getUserData() {
      const { username } = await Auth.currentAuthenticatedUser();
      const user = await getUser(username);
      setRole(user?.role);
    }
    if (!isAdmin) getUserData();
  }, [isAdmin]);

  useEffect(() => {
    setWaiting(true);
    async function getCompanyData() {
      const company = await getCompany(lsCompanyId);
      setStripeId(company?.stripeCustomerId);
    }
    if (lsCompanyId) getCompanyData();
    setWaiting(false);
  }, [isAdmin]);

  useEffect(() => {
    if (!stripeId) return;
    setWaiting(true);
    async function getCards() {
      const data = await getStripeCards({ stripeCustomerId: stripeId });

      setCardData(data.card);
      setWaiting(false);
    }
    if (!isAdmin) getCards();
    //get card info
  }, [stripeId, isAdmin]);

  useEffect(() => {
    async function getSubscription() {
      setWaiting(true);
      const res = await getStripeSubscription({ stripeCustomerId: stripeId });
      setSubscription(res);
      setWaiting(false);
    }
    if (stripeId) getSubscription();
  }, [stripeId, role]);

  if (!(isAdmin || cardData || role || subscription) || waiting)
    return <LoadingScreen />;
  return (
    <Container>
      <div style={styles.title}>Settings</div>
      <CardModal {...{ stripeId, cardModalVisible, setCardModalVisible }} />
      {!!subscription && !isAdmin && role === "owner" && (
        <div>
          <div style={styles.inputLabel}>Payment Method</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {renderCardInfoBox()}
            <Button filled onClick={() => setCardModalVisible(true)}>
              {stripeId ? "Update" : "Add"}
            </Button>
          </div>
        </div>
      )}
    </Container>
  );

  function renderCardInfoBox() {
    let text = "No card information found";
    const brand = cardData?.brand;
    if (brand)
      text = `${brand[0].toUpperCase() + brand.slice(1)} Card ending in ${
        cardData.last4
      }`;

    return (
      <div style={styles.cardInfoBox}>
        <div style={{ fontWeight: 600 }}>{text}</div>
      </div>
    );
  }
}

function CardModal(props) {
  const { stripeId } = props;
  const { cardModalVisible, setCardModalVisible } = props;
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");

  const [waiting, setWaiting] = useState(false);

  return (
    <Modal
      title="Update Card Information"
      isVisible={cardModalVisible}
      onClose={() => setCardModalVisible(false)}
      style={{ width: 600 }}
    >
      <div style={{ padding: "0px 20px 16px" }}>
        <CreditCardInput
          style={{ width: 600 }}
          fieldClassName="cc-input-settings"
          cardNumberInputProps={{
            value: cardNumber,
            onChange: (e) => setCardNumber(e.target.value),
          }}
          cardExpiryInputProps={{
            value: expiry,
            onChange: (e) => setExpiry(e.target.value),
          }}
          cardCVCInputProps={{
            value: cvc,
            onChange: (e) => setCvc(e.target.value),
          }}
        />
        <div style={{ height: 16 }} />
        <Button filled waiting={waiting} onClick={onUpdate}>
          Save
        </Button>
      </div>
    </Modal>
  );

  async function onUpdate() {
    setWaiting(true);
    if (!cardNumber) return toast.error("Credit card number is required.");
    if (!expiry) return toast.error("Credit card expiration date is required.");
    if (!cvc) return toast.error("Credit card CVC is required.");
    const [exp_month, exp_year] = expiry.split(" / ");

    try {
      const res = await createPaymentMethod({
        stripeCustomerId: stripeId,
        number: cardNumber,
        exp_month,
        exp_year,
        cvc,
      });
      console.log(res);
      toast.success("Card information updated.");
      setWaiting(false);
      setCardModalVisible(false);
    } catch (e) {
      toast.error(e.response.data);
    }
  }
}

const styles = {
  title: {
    fontSize: 30,
    fontWeight: "600",
    marginBottom: 30,
  },
  inputLabel: {
    width: 180,
    maxWidth: 180,
  },
  inputStyle: {
    width: 350,
    height: 40,
    border: "1px solid #555",
    borderRadius: 4,
  },
  cardInfoBox: {
    display: "flex",
    alignItems: "center",
    height: 40,
    width: 300,
    // background: "#eee",
    // borderRadius: 5,
    marginRight: 20,
  },
  cardImg: {
    maxWidth: 40,
    marginRight: 8,
  },
};
