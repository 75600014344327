import React from "react";
import { Rating } from "./state/questions/Rating";
import { IoMdThumbsUp, IoMdThumbsDown } from "react-icons/io";
import { AiFillQuestionCircle } from "react-icons/ai";
import { GiShrug } from "react-icons/gi"

export const iconForRating = 
  (props:any = {}) =>
  (rating:Rating | undefined) =>
  rating === "ThumbsUp"
      ? <IoMdThumbsUp {...props} />
      : rating === "ThumbsDown"
        ? <IoMdThumbsDown {...props} />
        : rating === "ThumbsSideways"
          ? <GiShrug {...props} />
          : <AiFillQuestionCircle {...props} />