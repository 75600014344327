import { Route, Routes } from "react-router-dom";
import { interviewBuilderPathPart } from "../Interview/Builder/interviewBuilderPathPart";
import { InterviewBuilderController } from "../Interview/Builder/InterviewBuilderController";
import Customers from "./Customers";


export const CustomersRoutes = () => {
  return <Routes>
    <Route path="/" element={<Customers />} />
    <Route path={`/:customerId/${interviewBuilderPathPart}/*`} element={<InterviewBuilderController />} />
  </Routes>;
};
