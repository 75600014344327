import { useState, useEffect, useRef } from "react";
import { stagesV2 as stages } from "../../../utils/stages";
import getInterviewState from "../../../utils/getInterviewState";
import CardList from "./CardList";
import DroppableColumn from "./DroppableColumn";

export default function Column(props) {
  const {
    stage,
    draggable,
    interviews,
    isAdmin,
    setSelectedInterviewId,
    setSelectedInterviewForSchedule,
    setWaiting,
    search,
    setReleased,
    filter: filters,
    setSelectedInterviewForPark,
    tab,
  } = props;

  const scroller = useRef(null);

  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const [scroll, setScroll] = useState(0);
  const [interviewData, setInterviewData] = useState([]);


  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [search, tab]);

  useEffect(() => {
    async function getInterviewStatus() {
      const activeFilters = Object.keys(filters).filter(
        (filter) => filters[filter].active,
      );
      let sortedInterviews = interviews.filter((interview) => {
        const { candidateName, candidateRole } = JSON.parse(
          interview.interviewInfo,
        );
        return (
          candidateName.toLowerCase().includes(search.toLowerCase()) ||
          candidateRole.name.toLowerCase().includes(search.toLowerCase())
        );
      });
      const parsed = sortedInterviews.map(async (interview) => ({
        ...interview,
        state: await getInterviewState(interview),
      }));
      const promise = await Promise.all(parsed);
      const filteredInterviews = promise.filter((interview) =>
        activeFilters.includes(interview.state),
      );
      setInterviewData(filteredInterviews);
    }
    if (debouncedSearch !== undefined) getInterviewStatus();
  }, [interviews, debouncedSearch, filters]);

  useEffect(() => {
    if (scroller.current)
      scroller.current.addEventListener("scroll", () => {
        setScroll(scroller.current.scrollTop);
      });
  }, [scroller]);

  //  console.log("interviewData", interviewData);

  const column = (
    <div style={{ height: "100%" }}>
      <CardList
        {...{
          interviewData,
          isAdmin,
          draggable,
          scroll,
          setSelectedInterviewId,
          setSelectedInterviewForSchedule,
          setReleased,
          setWaiting,
          setSelectedInterviewForPark,
          stage,
        }}
      />
    </div>
  );

  const display = stage === "accepted" ? "Accepted" : stage;

  return (
    <div className="col-wrap">
      <div className="col-label horizontal">
        {stages[stage]?.label || display} - {interviewData.length}
      </div>
      <div id="scroller" ref={scroller} className="col-scroll hide-scroll">
        {
        draggable ? (
          <DroppableColumn stage={stage}>{column}</DroppableColumn>
        ) : (
          column
        )}
      </div>
    </div>
  );
}
