import RealTimeData from "../../Components/RealTimeData";
import InterventionOverlay from "./InterventionOverlay";
import React, { forwardRef, useEffect, useState } from "react";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

export interface InterventionOverlayStackProps {
  isVisible: boolean
  popperStyles: any;
  attributes: any;
  forceUpdate?: () => void;
}

export const InterventionOverlayStack = forwardRef(
  (
    { popperStyles, attributes, isVisible, forceUpdate }: InterventionOverlayStackProps,
    forwardedRef: any,
  ) => {
    const [interventionOverlayRef, setInterventionOverlayRef] =
      React.useState<HTMLDivElement | null>(null);
    const [data, setData] = React.useState([]);
    const [popperHeight, setPopperHeight] = React.useState(30);

    const handleDismiss = (index: number) => {
      const newData = [...data];
      newData.splice(index, 1);
      setData(newData);
      hide()
    };

    useEffect(() => {
      if (interventionOverlayRef?.offsetHeight) {
        setPopperHeight(interventionOverlayRef.offsetHeight || 30);
      }
    }, [interventionOverlayRef?.offsetHeight]);

    const [visible, setVisible] = useState(isVisible);

    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    useEffect(() => {
      if (forceUpdate && popperHeight) {
        forceUpdate();
      }
    }, [forceUpdate, popperHeight]);

    const styles = {
      modal: {
          display: "flex",
          flexDirection: "column",
          height: 177,
          bottom: "auto",
          top: 200,
          padding: 0,
          borderRadius: 24,
          overflow: "hidden",
          width: 447,
      },
      modalWrapper: {
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
      },
    }

    return (
      <div>
        <Rodal 
          visible={data.length > 0} 
          onClose={hide}
          showCloseButton={false}
          customStyles={{ ...styles.modal, background: "rgba(0, 0, 0, 0.7)" }}
          customMaskStyles={{
            background: 'rgba(0, 0, 0, 0.75)', 
          }}
          >
          <div style={styles.modalWrapper}>
            <div
              ref={forwardedRef}
              className={"z-[100] w-[45%]"}
              style={{ height: 400, opacity: 1 }}
              {...attributes.popper}
            >
              <RealTimeData data={data} setData={setData} />
              
              {data.map((item, index) => (
                
                <InterventionOverlay 
                  ref={setInterventionOverlayRef}
                  key={index}
                  item={item}
                  onDismiss={() => handleDismiss(index)}
                />
              ))}
            </div>
          </div>
        </Rodal>
      </div>
    );
  },
);

