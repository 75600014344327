import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ValuesRoutes } from "./Values/ValuesRoutes";
import { valuesRelativePath } from "./Values/valuesRelativePath";
import { Text } from "@chakra-ui/react";
import moment from "moment-timezone";

export const InterviewBuilderRoutes = 
    ({updatedAt, customerId, state, setState}) => 
    <>
        <Text marginBottom="-1em" align="right" size="sm">{moment(updatedAt).format('M/d/y h:m')}</Text>
        <Routes>
            <Route path="/" element={<Navigate to={valuesRelativePath} />} />
            <Route path={`${valuesRelativePath}/*`} element={<ValuesRoutes {...{ updatedAt, customerId, state, setState }} />} />
        </Routes>
    </>
