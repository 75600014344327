import { Input } from "@chakra-ui/react";
import * as React from "react";
import { titleStyle } from "../../general/titleStyle";


export const ValueTitleInput = ({ title, onChange }) => <Input
    placeholder="New Value Title Here"
    style={titleStyle}
    onChange={e => onChange(e.target.value)}
    value={title} />;
