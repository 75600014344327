import React, { useState, useEffect, CSSProperties } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import { Observable } from "zen-observable-ts";
import qs from "query-string";

import {
  FiVideo,
  FiVideoOff,
  FiMic,
  FiMicOff,
  FiPhoneMissed,
  FiAirplay,
} from "react-icons/fi";

import { getInterview, getSession } from "../../graphql/queries";
import { updateSession } from "../../graphql/mutations";
import { onUpdateSessionById } from "../../graphql/subscriptions";

import {
  AcceptFormModal,
  DeviceModal,
  VideoControlButton,
} from "../../Components";
import {
  RecordButton,
  RecordLoading,
  RecordSomething,
} from "../../Components/RecordButton";
import GreenRoom from "../Interviewee/GreenRoom";
import { ChevronExpand } from "../Interviewee";
import "./index.css";

import { GraphQL, startRecording, stopRecording } from "../../utils/api";

import useAudioStream from "../../utils/useAudioStream";
import useOpenVidu from "../../utils/useOpenVidu";

import { themeOrange } from "../../theme";
import { Interview, Stage } from "../../types/Interview";
import InterviewerFeeds from "./InterviewerFeeds";
import NotesArea from "./NotesArea/NotesArea";
import CandidateFeed from "./CandidateFeed";
import OnboardingOverlay from "./OnboardingOverlay";

interface SessionUpdateData {
  value: {
    data: {
      onUpdateSessionById: any;
    };
  };
}

export default function Interviewer(props) {
  const { isParticipant } = props;

  const location = useLocation();
  const parsedQuery = qs.parse(location.search);
  const interviewId = Array.isArray(parsedQuery.id)
    ? parsedQuery.id[0]
    : parsedQuery.id;

  const [modalVisible, setModalVisible] = useState(false);
  const [deviceModalVisible, setDeviceModalVisible] = useState(false);
  const [recordingId, setRecordingId] = useState(null);

  const [interview, setInterview] = useState<Interview | null>(null);

  const [permission, setPermission] = useState(false);
  const [initTime, setInitTime] = useState<number | null>(null);
  const [startStopRecording, setStartStopRecording] = useState(false);
  const [closeInterview, setCloseInterview] = useState(false);

  const [audioMode, setAudioMode] = useState(null);

  const [currentDig, setCurrentDig] = useState<number[]>([]);
  const [isWeFlagged, setIsWeFlagged] = useState<number>(0);
  const [isElaborateFlagged, setIsElaborateFlagged] = useState<number>(0);

  const [isOnboarded, setIsOnboarded] = useState(false);

  const stage: Stage | undefined = interview?.stage;
  const interviewInfo = interview?.interviewInfo;

  let name: string | undefined;
  if (isParticipant) name = props.name;
  else {
    if (interview) {
      const { interviewInfo, stage } = interview;
      name = interviewInfo.interviewers[stage].name;
    }
  }

  const role = isParticipant ? "participant" : "interviewer";

  const openVidu = useOpenVidu({
    role,
    name: name ?? undefined,
    interviewId: interviewId ?? undefined,
    stage: stage ?? undefined,
  });

  useEffect(() => {
    const onboarded = !!localStorage.getItem("onboard");
    setIsOnboarded(onboarded);
  }, []);

  useEffect(() => {
    if (!permission || !openVidu) return;
    openVidu.initPublisher();
  }, [permission, openVidu]);

  // stream audio data to socket for nlp & storage
  const audioStreamParams = {
    ...interview,
    channel: isParticipant ? 2 : 1,
    isAudioEnabled: openVidu.isAudioEnabled && !!recordingId,
  };

  // start and stop individual stream recording
  const { startStreamRecording, stopStreamRecording, socket } =
    useAudioStream(audioStreamParams);

  // socket logic for "too much we" flag
  useEffect(() => {
    if (!socket) return;
    socket.on("we_count_exceeded", (data) => {
      setIsWeFlagged(Date.now());
      // TODO: implement toomuchwe logic
    });

    return () => {
      socket.off("we_count_exceeded");
      socket.disconnect();
    };
  }, [socket]);

  // start recording once interviewer & interviewee have joined
  useEffect(() => {
    if (openVidu.shouldStartRecording) {
      handleStartRecording();
    }
  }, [openVidu.shouldStartRecording]);

  const onChangeDevice = (deviceId) => {
    if (audioMode) openVidu.changeAudioFeed(deviceId);
    else openVidu.changeVideoFeed(deviceId);
  };

  // load in current digDeeper status
  // TODO: call getSession here
  useEffect(() => {
    if (interview && stage) {
      const questions = interview.questions[stage];
      const digStatus: number[] = [];
      for (let digIndex = 0; digIndex <= questions.length; digIndex++) {
        digStatus.push(0);
      }
      setCurrentDig(digStatus);
    }
  }, [interview, stage, setCurrentDig]);

  // set session to subscribe to updates
  useEffect(() => {
    if (!stage) return;
    getSessionData();

    if (isParticipant) {
      (
        API.graphql({
          query: onUpdateSessionById,
          variables: { id: `${interviewId}-${stage}` },
        }) as Observable<SessionUpdateData>
      ).subscribe({
        next: ({ value }) => {
          // incoming payload
          const data = value.data.onUpdateSessionById;
          if (data.questions) setCurrentDig(JSON.parse(data.questions));

          if (data.initTime && !initTime) setInitTime(parseInt(data.initTime));
        },
        error: console.warn,
      });
    }

    // Stop receiving data updates from the subscription
    // return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage]);

  const getSessionData = async () => {
    const query = getSession;
    const variables = { id: `${interviewId}-${stage}` };
    const res = await GraphQL({ query, variables, authMode: "" });
    console.log("session: ", res.getSession);
    if (res.getSession) setInitTime(parseInt(res.getSession.initTime));
    if (isParticipant && res.getSession && res.getSession.questions)
      setCurrentDig(JSON.parse(res.getSession.questions));
  };

  // popup behavior when user leaves page
  useEffect(() => {
    if (closeInterview) return;
    const beforeUnloadListener = (event) => {
      event.preventDefault();
      return (event.returnValue = "");
    };
    window.addEventListener("beforeunload", beforeUnloadListener);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeInterview]);

  useEffect(() => {
    getInterviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewId]);

  const getInterviewData = async () => {
    const query = getInterview;
    const variables = { id: interviewId };
    const res = await GraphQL({ query, variables, authMode: "" });
    const interview = res.getInterview;
    interview.questions = JSON.parse(interview.questions);
    interview.interviewInfo = JSON.parse(interview.interviewInfo);
    setInterview(interview);
  };

  useEffect(() => {
    if (!initTime) return;
    startStreamRecording();
  }, [initTime, startStreamRecording]);

  const intervieweeStream = openVidu.subscribers.find((s) =>
    s.stream.connection.data.includes("interviewee")
  );

  const handleStartRecording = async () => {
    setStartStopRecording(true);
    console.log("STARTING RECORDING");

    if (!initTime) {
      const currentTime = Date.now();
      const query = updateSession;
      const variables = {
        input: {
          id: `${interviewId}-${stage}`,
          initTime: currentTime,
        },
      };
      await GraphQL({ query, variables, authMode: "" });
      setInitTime(currentTime);
    }

    const response = await startRecording(interviewId, stage);
    setStartStopRecording(false);
    setRecordingId(response.id);
  };

  const handleLeaveCall = async () => {
    setModalVisible(true);
    stopStreamRecording();
    if (isParticipant) openVidu.leaveSession();
    else {
      console.log("Stopping recording...");
      console.log("recording Id: ", recordingId);
      setCloseInterview(true);
      if (recordingId) await stopRecording(recordingId);
      await openVidu.closeSession();
      toast.success("Recording uploaded successfully.");
      console.log("Recording stopped!");
      setRecordingId(null);
    }
  };

  if (!permission) {
    return <GreenRoom onContinue={() => setPermission(true)} />;
  }

  if (!interview) return null;
  if (openVidu.isRoomFull) return "Room Full";

  return (
    <div className="hor-center" style={styles.main}>
      {!isOnboarded && <OnboardingOverlay setIsOnboarded={setIsOnboarded} />}
      <DeviceModal
        audioMode={audioMode}
        deviceModalVisible={deviceModalVisible}
        setDeviceModalVisible={setDeviceModalVisible}
        deviceList={audioMode ? openVidu.audioDevices : openVidu.videoDevices}
        currentDevice={
          audioMode ? openVidu.audioDeviceId : openVidu.videoDeviceId
        }
        onChangeDevice={onChangeDevice}
      />
      <AcceptFormModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        stage={stage}
        interviewId={interviewId}
        interviewInfo={interviewInfo}
        meetingId={interviewId}
        isParticipant={isParticipant}
        author={props.name}
      />
      <InterviewerFeeds
        videoConfig={openVidu}
        renderVideoControls={renderVideoControls}
        screenShareStream={openVidu.screenShareStream}
        // intervieweeStream={intervieweeStream}
        // isParticipant={isParticipant}
        subscribers={openVidu.subscribers}
      />
      <CandidateFeed
        intervieweeStream={intervieweeStream}
        screenShareStream={openVidu.screenShareStream}
        initTime={initTime}
      />
      {!!stage && (
        <NotesArea
          interview={interview}
          stage={stage}
          handleLeaveCall={handleLeaveCall}
          initTime={initTime}
          isParticipant={isParticipant}
          name={props.name}
          currentDig={currentDig}
          setCurrentDig={setCurrentDig}
          isWeFlagged={isWeFlagged}
          isElaborateFlagged={isElaborateFlagged}
          setIsElaborateFlagged={setIsElaborateFlagged}
        />
      )}
    </div>
  );

  function renderVideoControls() {
    return (
      <div style={styles.videoControlsWrap}>
        {!isParticipant && renderRecordingButton()}
        <VideoControlButton
          disable={openVidu.toggleAudioEnabled}
          state={openVidu.isAudioEnabled}
          cancelText="Mute"
          uncancelText="Unmute"
          activeIcon={FiMic}
          inactiveIcon={FiMicOff}
          canExpand={
            <ChevronExpand
              title="Change Microphone"
              audioMode={true}
              setAudioMode={setAudioMode}
              setDeviceModalVisible={setDeviceModalVisible}
            />
          }
        />

        <VideoControlButton
          disable={openVidu.toggleVideoEnabled}
          state={openVidu.isVideoEnabled}
          cancelText="Stop Video"
          uncancelText="Start Video"
          activeIcon={FiVideo}
          inactiveIcon={FiVideoOff}
          canExpand={
            <ChevronExpand
              title="Change Camera"
              audioMode={false}
              setAudioMode={setAudioMode}
              setDeviceModalVisible={setDeviceModalVisible}
            />
          }
        />
        <VideoControlButton
          disable={openVidu.toggleScreenShare}
          state={openVidu.isSharingScreen}
          cancelText="Stop Share"
          uncancelText="Share"
          activeIcon={FiAirplay}
          inactiveIcon={FiAirplay}
        />
        <VideoControlButton
          disable={handleLeaveCall}
          uncancelText={`${isParticipant ? "Leave" : "End Call"}`}
          activeIcon={FiPhoneMissed}
          inactiveIcon={FiPhoneMissed}
        />
      </div>
    );
  }

  function renderRecordingButton() {
    if (!recordingId)
      return (
        <VideoControlButton
          disable={handleStartRecording}
          state={!startStopRecording}
          cancelText="Record"
          uncancelText="Record"
          activeIcon={RecordButton}
          inactiveIcon={RecordLoading}
        />
      );
    else
      return (
        <VideoControlButton
          disable={async () => {
            setStartStopRecording(true);
            if (recordingId) await stopRecording(recordingId);
            toast.success("Recording uploaded successfully.");
            setRecordingId(null);
            setStartStopRecording(false);
            //TODO
          }}
          state={startStopRecording}
          cancelText="Saving..."
          uncancelText="Stop"
          activeIcon={RecordLoading}
          inactiveIcon={RecordSomething}
        />
      );
  }
}

const styles: Record<string, CSSProperties> = {
  main: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    background: "#999999",
  },
  header: {
    height: 60,
    width: "100%",
    background: "#fafafa",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  muteButton: {
    // width: 30,
    // height: 30,
    padding: 6,
    borderRadius: 50,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  stageBanner: {
    width: "100%",
    background: themeOrange,
    height: 54,
    letterSpacing: 6,
    fontWeight: "bold",
    fontSize: 22,
    color: "white",
    textAlign: "center",
    boxShadow: `inset 0 0 5px 0 #aaa`,
  },
  notesAreaStyle: {
    minWidth: "100%",
    maxWidth: "100%",
    flexGrow: 1,
    height: "100%",
    // marginTop: 14,
    padding: 20,
    background: "#f5f5f5",
    boxShadow: "inset 0 0 4px 0 #ddd",
    borderRadius: 12,
    whiteSpace: "pre-line",
  },
  bottomHalf: {
    height: "100%",
    flexGrow: 1,
  },
  poweredBy: {
    marginTop: 10,
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  recordingIndicatorWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    background: "black",
    paddingRight: 6,
    zIndex: 10,
    borderRadius: "0 0 0 6px",
  },
  recDot: {
    borderRadius: 20,
    height: 12,
    width: 12,
    background: "red",
  },
  darkOpacityOverlay: {
    position: "absolute",
    height: 65,
    bottom: 0,
    width: "100%",
    opacity: 0.4,
    zIndex: 2,
    background: "black",
  },
  nameLabel: {
    position: "absolute",
    top: 0,
    left: 0,
    background: "black",
    color: "white",
    padding: "0px 2px",
    zIndex: 1,
    fontSize: 14,
  },
  videoControlsWrap: {
    flexDirection: "row",
    height: 60,
    width: "100%",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    alignItems: "center",
    position: "relative",
    zIndex: 3,
  },
};
