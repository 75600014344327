import { Droppable } from "react-beautiful-dnd";

export default function DroppableColumn({ stage, children }) {
  return (
    <Droppable droppableId={stage}>
      {(provided) => (
        <div
          style={{ minHeight: "100%" }}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
