import { Amplify } from "aws-amplify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import awsconfig from "../aws-exports";

import {
  Feedback,
  Home,
  Interviewee,
  Interviewer,
  Login,
  Participant,
  Welcome,
  JobRoles,
  JobRole,
  RoleMigration,
  OrganizationProfile,
} from "../Pages";

import AdminApp from "../navigation/AdminRouter";

Amplify.configure(awsconfig);

export default function Router() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<Welcome reset />} />
          <Route path="/interviewer" element={<Interviewer />} />
          <Route path="/interviewee" element={<Interviewee />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/participant" element={<Participant />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/admin/*" element={<AdminApp />} />
          <Route path="/jobroles" element={<JobRoles />} />
          <Route path="/jobrole" element={<JobRole />} />
          <Route path="/rolemigration" element={<RoleMigration />} />
          <Route path="/organizationprofile" element={<OrganizationProfile />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
