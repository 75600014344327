import React, { CSSProperties, useState } from "react";
import { useEffect, useRef } from "react";
import { TextArea } from "../../../Components";
import DigDeeperBlock from "./DigDeeperBlock";
import { FaInfo } from "react-icons/fa";
import { themeOrange } from "../../../theme";
import { cloneDeep } from "lodash";
import SubquestionModal from "./SubquestionModal";
import { Stage } from "../../../types/Interview";
import { Question } from "../../../types/Question";
import { Subquestion } from "../../../types/Subquestion";
import { Session } from "../../../types/Session";
import { GraphQL } from "../../../utils/api";
import { getSession } from "../../../graphql/queries";
import { usePopper } from "react-popper";
import { InterventionOverlayStack } from "../InterventionOverlayStack";
import { createPortal } from "react-dom";
import { reference } from "@popperjs/core";

export interface QuestionResponse {
  id: string;
  notes: string;
  // Add other properties as needed
}

type DialogProps = {
  questions: string[];
  stage: Stage | undefined;
  questionResponse: Question;
  setQuestionResponse: (q: Question) => void;
  subQRs: Subquestion[];
  autoSubQs: Subquestion[];
  setSubQRs: (subQRs: Subquestion[]) => void;
  setAutoSubQs: (autoSubQRs: Subquestion[]) => void;
  closedArray: boolean[];
  setClosedArray: (closedArray: boolean[]) => void;
  currentQIndex: number;
  currentDig: number[];
  isAIObservationVisible: string | null;
  setIsAIObservationVisible: (isVisible: string | null) => void;
  isSubQRModalVisible: boolean;
  isMoreNotesNeeded: string | null;
  setIsMoreNotesNeeded: (isNeeded: string | null) => void;
  incrementDig: () => void;
  isParticipant: boolean;
  isWeFlagged?: number;
  isElaborateFlagged?: number;
  id?: string;
};

export default function Dialog(props: DialogProps) {
  const {
    questions,
    stage,
    questionResponse,
    setQuestionResponse,
    subQRs: allSubQRs,
    autoSubQs,
    setAutoSubQs,
    setSubQRs,
    closedArray,
    setClosedArray,
    currentQIndex,
    currentDig,
    isAIObservationVisible,
    setIsAIObservationVisible,
    isSubQRModalVisible,
    // setIsSubQRModalVisible,
    isMoreNotesNeeded,
    setIsMoreNotesNeeded,
    incrementDig,
    isParticipant,
    isWeFlagged,
    isElaborateFlagged,
    id,
  } = props;
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [interventionTarget, setInterventionTarget] = useState<any>(null);
  const [popperElement, setPopperElement] = useState(null);
  const {
    styles: popperStyles,
    attributes,
    forceUpdate,
  } = usePopper(referenceElement, popperElement, {
    placement: "left",
  });

  let setSubQuestionReference:
    | React.Dispatch<React.SetStateAction<null>>
    | null
    | undefined = null;
  let setMainQuestionReference = null;

  if (interventionTarget?.name === "subQuestion") {
    setSubQuestionReference = setReferenceElement;
  } else {
    setMainQuestionReference = setReferenceElement;
  }

  const [order, setOrder] = useState<any>([]);

  // const [renderedSubQRs, setRenderedSubQRs] = useState<Subquestion[]>([]);

  // Use reduce to separate subQRs into two arrays: subQRs and others
  const subQRs: Subquestion[] = [];
  const answeredAutoSubQs: Subquestion[] = [];

  for (const subQR of allSubQRs) {
    if (subQR) {
      if (subQR?.question?.startsWith("(")) answeredAutoSubQs.push(subQR);
      else subQRs.push(subQR);
    }
  }


  const scrollRef = useRef<HTMLDivElement>(null);

  const currentQuestionText = questions[currentQIndex] || "";

  let mainQuestion = "";
  let subQuestions: any[] = [];
  if (stage !== "discovery" || currentQIndex !== questions.length - 1) {
    mainQuestion = currentQuestionText.split("\n")[0];

    mainQuestion = mainQuestion.replace(/\s*\[\d+\]$/, "");
    mainQuestion = mainQuestion.replace(/^[^:]*:\s*/, "");
    subQuestions = currentQuestionText
      .split("\n")
      .slice(1)
      .map((text) => ({ text, isClosed: false }));
  } else mainQuestion = currentQuestionText;

  useEffect(() => {
    const updatedAutoSubQs = autoSubQs.map((autoSubQ) => {
      const matchedSubQ = answeredAutoSubQs.find(
        (answeredSubQ) => answeredSubQ.question === autoSubQ.question
      );
      return matchedSubQ || autoSubQ;
    });
    setAutoSubQs(updatedAutoSubQs);
  }, [setAutoSubQs, allSubQRs]);

  useEffect(() => {
    
    if (subQuestions.length)
      setClosedArray(new Array(subQuestions.length).fill(false));
  }, [subQuestions.length, setClosedArray]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [currentDig, closedArray, isWeFlagged]);

  useEffect(() => {
    const getSessionData = async () => {
      const getRes = await GraphQL({
        query: getSession,
        variables: { id: `${id}-${stage}` },
        authMode: "",
      });
      // setSession(getRes.getSession);
      setOrder(
        JSON.parse(getRes.getSession.subquestionsOrder[currentQIndex]).order
      );
    };
    if (isParticipant) getSessionData();
  }, [id, stage, isParticipant, currentQIndex]);

  useEffect(() => {
    setOrder([]);
    setPositionOfIntervention('', 2)
  }, [currentQIndex]);

  const setPositionOfIntervention = (text, i) => {
    let interventionTarget = null;

    if (text === "") {
      if (i > 0) {
        interventionTarget = {
          name: "subQuestion",
          index: i - 1,
        };
      } else {
        interventionTarget = {
          name: "mainQuestion",
        };
      }
    } else {
      interventionTarget = {
        name: "subQuestion",
        index: i,
      };
    }

    // If no subQR with notes was found, set the mainQuestion as the interventionTarget
    if (!interventionTarget) {
      interventionTarget = {
        name: "mainQuestion",
      };
    }

    setInterventionTarget(interventionTarget);
  };

  const handleMainNotesChange = (text: string) => {
    const updatedQuestionResponse = cloneDeep(questionResponse);
    updatedQuestionResponse.notes = text;
    setQuestionResponse(updatedQuestionResponse);
  };

  const handleSubquestionNotesChange = (text: string, index: number) => {
    const updatedSubquestionResponses = cloneDeep(subQRs);
    updatedSubquestionResponses[index].notes = text;
    setSubQRs(updatedSubquestionResponses);
    setIsMoreNotesNeeded(null);
    return;
  };

  const handleAutoSubQsNotesChange = (text: string, index: number) => {
    const updatedAutoSubQResponses = cloneDeep(autoSubQs);
    updatedAutoSubQResponses[index].notes = text;
    setAutoSubQs(updatedAutoSubQResponses);
    return;
  };

  const handleClose = (index: number, questionType: string) => {
    if (questionType === "intervention") {
      const updatedAutoSubQResponses = cloneDeep(autoSubQs);
      updatedAutoSubQResponses[index].hidden = true;
      setAutoSubQs(updatedAutoSubQResponses);
    }
    if (questionType === "standard") {
      if (currentDig[currentQIndex] < subQuestions.length) incrementDig();
      setIsAIObservationVisible(null);

      const updatedClosedArray = [...closedArray];
      updatedClosedArray[index] = true;
      setClosedArray(updatedClosedArray);
    }
  };

  const isSubquestionAnswered = (questionText: string): boolean => {
    return answeredAutoSubQs.some((subQ) => subQ.question === questionText);
  };

  const shouldRenderInterventionAtTime = (subquestion: Subquestion) => {
    const mappings = {
      toomuchwe: isWeFlagged,
      elaboration: isElaborateFlagged,
    };

    const { question } = subquestion;
    const prefixMatch = question.match(/^\(([^)]+)\)/);
    const prefix = prefixMatch
      ? prefixMatch[1].replace(/\s/g, "").toLowerCase()
      : "";

    return mappings[prefix] ?? 0;
  };

  const subQRSort = (a: Subquestion, b: Subquestion) => {
    const aTime =
      !!a.displayTime && a.displayTime > 0
        ? a.displayTime
        : shouldRenderInterventionAtTime(a);
    const bTime =
      !!b.displayTime && b.displayTime > 0
        ? b.displayTime
        : shouldRenderInterventionAtTime(b);
    return aTime - bTime ?? 0;
  };

  const moreNotesNeededMessage =
    isMoreNotesNeeded === "thumb"
      ? "Please enter notes explaining why you want to provide this rating."
      : `Please enter notes on what you observed before asking a "Dig
  Deeper" question.`;

  if (currentQuestionText)
    return (
      <div
        className="customScrollbar"
        ref={scrollRef}
        style={{
          ...styles.scrollableArea,
          ...(isParticipant ? { minHeight: "100%" } : {}),
        }}
      >
        {createPortal(

          <InterventionOverlayStack
            ref={setPopperElement}
            popperStyles={popperStyles}
            attributes={attributes}
            forceUpdate={forceUpdate}
            isVisible={true}
          />,
          document.body
        )}
        <div style={styles.dialogAndPopups}>
          <div
            style={{
              width: "calc(100% - 10px)",
              paddingLeft: "calc(50% - 10px)",
            }}
          >
            {isMoreNotesNeeded && (
              <div
                className="fade-in"
                style={styles.aiObservationFloatingContainer}
              >
                <div style={styles.digDeeperOrangeBlock}>
                  <div className="hor-center">
                    <div className="hor-center" style={styles.digShovelWrapper}>
                      <FaInfo color={themeOrange} size={16} />
                    </div>
                    <div style={styles.intertruDigText}>Intertru</div>
                  </div>
                </div>
                <div style={{ fontSize: 12, padding: "6px 6px 0px 6px" }}>
                  {moreNotesNeededMessage}
                </div>
              </div>
            )}

            {isSubQRModalVisible ? (
              <SubquestionModal
                subQuestions={subQuestions}
                subQRs={subQRs}
                currentDig={currentDig}
                currentQIndex={currentQIndex}
              />
            ) : (
              <>
                <div
                  ref={setMainQuestionReference}
                  style={styles.mainQuestionWrapper}
                >
                  {mainQuestion}
                </div>
                <TextArea
                  inputStyle={styles.textAreaInput}
                  placeholder="Enter notes..."
                  value={questionResponse.notes}
                  onChange={handleMainNotesChange}
                  rows={6}
                  customScrollbar
                />
                <div style={{ height: 18 }} />
                {isParticipant
                  ? renderParticipantDialog()
                  : renderLeadInterviewerDialog()}
              </>
            )}
          </div>
        </div>
      </div>
    );

  function renderLeadInterviewerDialog() {
    return (
      <>
        {[...subQRs, ...autoSubQs].sort(subQRSort).map((subquestion, i) => {
          const { question, hidden, order } = subquestion;
          const isAutoSubQ = autoSubQs.includes(subquestion);
          const renderTime = shouldRenderInterventionAtTime(subquestion);
          const originalIndex = isAutoSubQ
            ? autoSubQs.findIndex((sq) => sq.order === order)
            : subQRs.findIndex((sq) => sq.order === order);
          const isClosed = closedArray[originalIndex];
          const isSubquestionAnsweredResult = isSubquestionAnswered(question);

          // For autoSubQs, check if it's not hidden and either answered or time to render
          if (
            isAutoSubQ &&
            !hidden &&
            (isSubquestionAnsweredResult || renderTime)
          ) {
            return (
              <DigDeeperBlock
                key={i}
                handleClose={() => handleClose(originalIndex, "intervention")}
                text={question.replace(/^\([^)]+\)\s*/, "")}
                subquestionResponse={subquestion}
                setSubquestionResponse={(text: string) =>
                  handleAutoSubQsNotesChange(text, originalIndex)
                }
                isAIObservationVisible={isAIObservationVisible}
              />
            );
          }
          // For subQRs, check if it's within the currentDig range and not closed
          else if (
            !isAutoSubQ &&
            originalIndex < currentDig[currentQIndex] &&
            !isClosed
          ) {
            return (
              <DigDeeperBlock
                key={i}
                isLast={originalIndex === currentDig[currentQIndex] - 1}
                handleClose={() => handleClose(originalIndex, "standard")}
                text={question}
                subquestionResponse={subquestion}
                setSubquestionResponse={(text: string) =>
                  handleSubquestionNotesChange(text, originalIndex)
                }
                isAIObservationVisible={isAIObservationVisible}
                isParticipant={isParticipant}
              />
            );
          }
          return null;
        })}
      </>
    );
  }

  function renderParticipantDialog() {
    let sortedItems: Subquestion[] = [];
    const items = [...subQRs, ...autoSubQs];
    if (order && items) {
      const displayTimeMap = order.reduce((acc, item) => {
        acc[item.question] = item.displayTime;
        return acc;
      }, {});

      const updatedItems = items.map((item) => ({
        ...item,
        displayTime: displayTimeMap[item.question] || item.displayTime,
      }));

      sortedItems = updatedItems.sort((a, b) => {
        if (a.displayTime === 0 || a.displayTime === null) return 1;
        if (b.displayTime === 0 || b.displayTime === null) return -1;
        return a.displayTime - b.displayTime;
      });
    }

    return (
      <>
        {sortedItems?.map((subquestion, i) => {
          const { question, hidden, order } = subquestion;
          const isAutoSubQ = autoSubQs.some(
            (sq) => sq.question === subquestion.question
          );
          const originalIndex = isAutoSubQ
            ? autoSubQs.findIndex((sq) => sq.order === order)
            : subQRs.findIndex((sq) => sq.order === order);
          const isClosed = closedArray[originalIndex];
          const isSubquestionAnsweredResult = isSubquestionAnswered(question);

          // For autoSubQs, check if it's not hidden and either answered or time to render
          if (isAutoSubQ && !hidden) {
            return (
              <DigDeeperBlock
                key={i}
                handleClose={() => handleClose(originalIndex, "intervention")}
                text={question.replace(/^\([^)]+\)\s*/, "")}
                subquestionResponse={subquestion}
                setSubquestionResponse={(text: string) =>
                  handleAutoSubQsNotesChange(text, originalIndex)
                }
                isAIObservationVisible={isAIObservationVisible}
              />
            );
          }
          // For subQRs, check if it's within the currentDig range and not closed
          else if (
            !isAutoSubQ &&
            originalIndex < currentDig[currentQIndex] &&
            !isClosed
          ) {
            return (
              <DigDeeperBlock
                key={i}
                isLast={originalIndex === currentDig[currentQIndex] - 1}
                handleClose={() => handleClose(originalIndex, "standard")}
                text={question}
                subquestionResponse={subquestion}
                setSubquestionResponse={(text: string) =>
                  handleSubquestionNotesChange(text, originalIndex)
                }
                isAIObservationVisible={isAIObservationVisible}
                isParticipant
              />
            );
          }
          return null;
        })}
      </>
    );
  }
}

const styles: Record<string, CSSProperties> = {
  scrollableArea: {
    width: "calc(200% + 40px)",
    height: "calc(100% - 40px)",
    maxHeight: "calc(100% - 40px)",
    fontSize: 16,
    margin: -20,
    padding: "10px 0px 0px 0px",
    overflowY: "scroll",
    overflowX: "hidden",
    alignSelf: "flex-end",
  },
  mainQuestionWrapper: {
    color: "white",
    border: "2px solid white",
    borderRadius: 16,
    padding: 16,
    whiteSpace: "pre-line",
    marginBottom: 12,
    fontWeight: "bold",
    position: "relative",
  },
  textAreaInput: {
    width: "100%",
    maxWidth: "none",
    background: "#000000bb",
    outline: "none",
    padding: 12,
    borderRadius: 6,
  },
  dialogAndPopups: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    marginLeft: 6, //scrollbar width - 1
  },
  aiObservationFloatingContainer: {
    position: "absolute",
    width: "100%",
    maxWidth: 300,
    bottom: "-11%",
    right: "110%",
    borderRadius: 20,
    background: "#000000bb",
    padding: 8,
    fontWeight: "bold",
  },
  intertruDigText: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  digDeeperOrangeBlock: {
    borderRadius: "20px 20px 0px 20px",
    height: 28,
    background: themeOrange,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 4,
    paddingRight: 4,
  },
  digShovelWrapper: {
    borderRadius: 50,
    height: 22,
    width: 22,
    minHeight: 22,
    minWidth: 22,
    background: "white",
    marginRight: 8,
    padding: 4,
  },
  digDeeperWrapper: {
    background: "white",
    borderRadius: "20px 20px 0px 20px",
    padding: 8,
    marginBottom: 12,
    cursor: "pointer",
  },
  digDeeperQuestion: {
    color: "black",
    fontSize: 14,
    padding: "6px 6px 0px 6px",
  },
};
