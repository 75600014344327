import { Meetings } from "../state/meetings/Meetings";
import { IntertruDimensionScores } from "../state/scores/IntertruDimensionScores";
import { WeightedValues } from "../state/values/WeightedValues";
import { intendedAndOrActualAttendeePersonIds } from "./intendedAndOrActualAttendeePersonIds";
import { RadarChartDimensions } from "./radarChartDimensions";
import { valueScore } from "./valueScore";
import _ from "lodash";

export const radarChartDimensionsFromState = (
  valuesRelatedToStage: WeightedValues,
  meetings: Meetings,
  mlScores: IntertruDimensionScores
): RadarChartDimensions => {
  // console.log("valuesRelatedToStage: ", valuesRelatedToStage);
  // console.log("meetings:", meetings);
  // console.log("mlScores:", mlScores);
  return valuesRelatedToStage.map((wv) => {
    // const intertruScore = mlScores.find((m) => m.valueId === wv.id)?.score;
    const intertruScore = mlScores
      .find((m) => m.valueId === wv.id)
      ?.details.map((attribute) => _.map(attribute.questionScores, "score"));
    const interviewerScore = Object.fromEntries(
      intendedAndOrActualAttendeePersonIds(meetings).map((personId) => {
        return [personId, _.max(valueScore(meetings)(personId)(wv))];
      })
    );
    return {
      dimensionName: wv.title,
      attributes: _.map(wv.weightedAttributes, "title"),
      ...interviewerScore,
      scores: {
        ...Object.fromEntries(
          intendedAndOrActualAttendeePersonIds(meetings).map((personId) => {
            return [personId, valueScore(meetings)(personId)(wv)];
          })
        ),
        ...(intertruScore ? { Intertru: intertruScore } : {}),
      },
      ...(intertruScore ? { Intertru: _.max(intertruScore) } : {}),
    };
  });
};
