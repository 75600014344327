import { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { cloneDeep } from "lodash";

import { GraphQL, getCompany, getUser, updateCompany } from "../../utils/api";
import { stagesV2 as stages } from "../../utils/stages";
import Column from "./Column/Column";
import useGroups from "../../utils/useGroups";
import { getCognitoUsername } from "../../utils/auth";
import ConfirmModal from "./ConfirmModal";
import { updateInterview } from "../../graphql/mutations";

export default function Kanban(props) {
  const {
    interviews,
    tab,
    isAdmin,
    waiting,
    setWaiting,
    setSelectedInterviewId,
    setSelectedInterviewForSchedule,
    setReleased,
    setSelectedInterviewForPark,
    search,
    filter,
  } = props;
  const groups = useGroups();

  const [exited, setExited] = useState(null);
  const [columns, setColumns] = useState(interviews);
  const [result, setResult] = useState(null);
  const [order, setOrder] = useState({});
  const [cid, setCid] = useState(null);

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const [dragRequest, setDragRequest] = useState({});

  const [scheduling, setScheduling] = useState({});

  // update the customer object's order whenever it changes
  // useEffect(() => {
  //   async function updateOrder() {
  //     const res = await updateCompany({
  //       id: cid,
  //       displayOrder: JSON.stringify(order),
  //     });
  //     localStorage.setItem("order", JSON.stringify(order));
  //   }
  //   if (order && cid) updateOrder();
  // }, [cid, order]);

  // get order from DB
  useEffect(() => {
    async function getOrder() {
      if (!groups || groups.includes("Admin")) return;

      const res = await getCognitoUsername();

      const { companyID } = await getUser(res);
      setCid(companyID);

      const company = await getCompany(companyID);
      let order = company.displayOrder ? JSON.parse(company.displayOrder) : {};

      if (Object.keys(order).length === 0) {
        const savedOrder = localStorage.getItem("order");
        if (savedOrder && Object.keys(JSON.parse(savedOrder)).length > 0) {
          order = JSON.parse(savedOrder);
        } else {
          order = interviews;
        }
      }
      setOrder(order);
    }
    // getOrder();
  }, [interviews, groups]);

  // handle rejected candidates
  useEffect(() => {
    let exited = {
      0: { label: "No Show", data: [] },
      1: { label: "Discovery Call", data: [] },
      2: { label: "Value Alignment", data: [] },
      3: { label: "Working Session", data: [] },
      4: { label: "Final Interview", data: [] },
      5: { label: "Offer Outcome", data: [] },
      6: { label: "Parked", data: [] },
    };
    setColumns(interviews);
   
    for (const interview of interviews?.rejected) {
      let review = JSON.parse(interview.interviewInfo).review;
      if (!review) exited[0].data.push(interview);
      else {
        const length = Object.keys(
          JSON.parse(interview.interviewInfo).review,
        ).length;
        exited[length].data.push(interview);
      }   
    }
    
    for (const interview of interviews.parked) {
      exited[6].data.push(interview);
    }
    
    setExited(exited);
  }, [interviews]);

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (!columns[source.droppableId][source.index]) {
      // console.log("what", source, columns);
    }
    const { id } = columns[source.droppableId][source.index];
    const dRequest = { id, destination };
    setDragRequest(dRequest);

    setResult(result);
    // if moving to a different column
    if (source.droppableId !== destination.droppableId) {
      if (source.droppableId === "accepted") {
        await moveColumn(columns, result);
      } else if (destination.droppableId === "accepted") {
        setConfirmModalVisible(true);
      } else {
        const prevCol = stages[source?.droppableId].order;
        const nextCol = stages[destination?.droppableId].order;
        if (nextCol - prevCol > 0) setConfirmModalVisible(true);
        else await moveColumn(columns, result);
      }
    } else {
      // // if moving within the same column
      // const updatedColumns = cloneDeep(columns);
      // const column = updatedColumns[source.droppableId];
      // const copiedItems = [...column];
      // const [removed] = copiedItems.splice(source.index, 1);
      // copiedItems.splice(destination.index, 0, removed);
      // updatedColumns[source.droppableId] = copiedItems;
      // setColumns(updatedColumns);
      // // update the order state
      // const updatedOrder = { ...order };
      // updatedOrder[source.droppableId] = copiedItems;
      // setOrder(updatedOrder);
      // update the customer object's order
    }
    await updateCompany({ id: cid, displayOrder: JSON.stringify(order) });
  };

  const moveColumn = async (columns, result) => {
    if (result) {
      const { draggableId: id, source, destination } = result;
      const updatedColumns = cloneDeep(columns);

      const query = updateInterview;
      const res = await GraphQL({
        query,
        variables: { input: { id, stage: destination.droppableId } },
        authMode: "",
      });
      console.log(res);

      const sourceColumn = updatedColumns[source.droppableId];
      const destColumn = updatedColumns[destination.droppableId];

      // Remove the card from the source column
      const [card] = sourceColumn.splice(source.index, 1);

      // Insert the card into the destination column at the specified index
      destColumn.splice(destination.index, 0, card);

      // Update the columns
      updatedColumns[source.droppableId] = sourceColumn;
      updatedColumns[destination.droppableId] = destColumn;

      setColumns(updatedColumns);
    }
  };

  if (interviews)
    return (
      <div className="kanban-wrapper">
        <ConfirmModal
          {...{ confirmModalVisible, setConfirmModalVisible }}
          onConfirm={async () => {
            await moveColumn(columns, result);
            setConfirmModalVisible(false);
          }}
        />
        {tab === "inProgress" && (
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            {Object.keys(stages).map((stage) => {
              if (stage === "parked") return;
              return (
                <Column
                  draggable
                  key={stage}
                  interviews={columns[stage]}
                  {...{
                    stage,
                    isAdmin,
                    waiting,
                    setWaiting,
                    setSelectedInterviewId,
                    setSelectedInterviewForSchedule,
                    setReleased,
                    search,
                    setSelectedInterviewForPark,
                    filter,
                    tab,
                  }}
                />
              );
            })}
            <Column
              draggable
              stage={"accepted"}
              interviews={columns.accepted}
              {...{
                isAdmin,
                waiting,
                setWaiting,
                setSelectedInterviewId,
                setSelectedInterviewForSchedule,
                setReleased,
                setSelectedInterviewForPark,
                search,
                filter,
                tab,
              }}
            />
          </DragDropContext>
        )}
        {tab === "released" && (
          <>
            {Object.keys(exited).map((stage) => {
              return (
                <Column
                  draggable={false}
                  stage={exited[stage].label}
                  key={stage}
                  interviews={exited[stage].data}
                  {...{
                    isAdmin,
                    waiting,
                    setWaiting,
                    setSelectedInterviewId,
                    setSelectedInterviewForSchedule,
                    setReleased,
                    setSelectedInterviewForPark,
                    search,
                    filter,
                    tab,
                  }}
                />
              );
            })}
          </>
        )}
      </div>
    );
}
