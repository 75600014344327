import { API } from "aws-amplify";
import axios from "axios";
import socketIO from "socket.io-client";
import {
  listUsers as gqlListUsers,
  getUser as gqlGetUser,
  listInterviews,
  listJobRoles,
} from "../graphql/queries";
import {
  createCompany as gqlCreateCompany,
  createUser as gqlCreateUser,
  updateUser as gqlUpdateUser,
  updateCompany as gqlUpdateCompany,
  createJobRole as gqlCreateJobRole,
  updateJobRole as gqlUpdateJobRole,
  deleteJobRole as gqlDeleteJobRole,
} from "../graphql/mutations";
import { v4 as uuid } from "uuid";
import {
  getCompanyData,
  getInterviewBuilderData,
  getInterviewCount as gqlGetInterviewCount,
  listCompaniesWithUsers as gqlListCompanies,
  updateInterviewBuilderData,
} from "../graphql/custom";
import { checkRoles } from "./auth";

const { REACT_APP_ENV, REACT_APP_SERVER_URL = "http://localhost:5001/", USER_BRANCH } =
  process.env;

const SOCKET_URL = process.env.REACT_APP_ENV === "dev" ? "https://interview-listener-dev.intertru.com" 
                                                        : "https://interview-listener.intertru.com";
const { REACT_APP_SOCKET_URL = SOCKET_URL, USER_URL } = 
  process.env;

export async function getToken(interviewId, username) {
  const sessionId = await createSession(interviewId);
  return await createToken(sessionId, username);
}

// api utils

async function get(path, params) {
  const url = REACT_APP_SERVER_URL + path;
  const { data } = await axios.get(url, params);
  return data;
}

async function post(path, payload) {
  const url = REACT_APP_SERVER_URL + path;
  const { data } = await axios.post(url, payload);
  return data;
}

async function put(path, payload) {
  const url = REACT_APP_SERVER_URL + path;
  const { data } = await axios.put(url, payload);
  return data;
}

// socket utils

export function getSocket(initParams) {
  // const url = REACT_APP_SERVER_URL + "interview";
  const url = REACT_APP_SOCKET_URL;
  return socketIO(url, {path: "/ws/socket.io/",query: initParams});
}


function createSession(interviewId) {
  return post("sessions", { customSessionId: interviewId });
}

function createToken(sessionId, username) {
  const path = "sessions/" + sessionId + "/connections";
  return post(path, { username });
}

export async function listConnections(sessionId) {
  return get("sessions/" + sessionId + "/list-connections");
}

export function closeSession(sessionId) {
  return post("close-session", { sessionId });
}

export function startRecording(sessionId, stage) {
  return post("recording/start", {
    hasAudio: true,
    hasVideo: false,
    sessionId,
    stage,
  });
}

export function stopRecording(recordingId) {
  console.log("recording id: ", recordingId);
  return post("recording/stop", { recordingId });
}

export async function GraphQL(params) {
  const {
    query,
    variables = {},
    authMode = "AMAZON_COGNITO_USER_POOLS",
  } = params;
  const res = await API.graphql({
    query,
    variables,
    authMode,
  });
  return res.data;
}

export function createCognitoUser(params) {
  // cognito create user
  const { username, password, company } = params;
  return post(`user/signup`, {
    username,
    password,
    companyID: company,
  });
}

export async function addUserToDB(params) {
  // db create user
  const {
    id,
    firstName,
    lastName,
    group,
    companyID,
    title,
    email,
    phone,
    status,
    role,
  } = params;

  const { createUser } = await GraphQL({
    query: gqlCreateUser,
    variables: {
      input: {
        id,
        firstName,
        lastName,
        companyID,
        group,
        title,
        email,
        phone,
        status,
        role,
        hasLoggedIn: false,
      },
    },
  });

  return createUser;
}

export function onboardUser(params) {
  const { username, password } = params;
  return post(`user/onboarding`, {
    username,
    password,
  });
}

export async function createCompany(params) {
  const companyId = uuid();
  const { interviewInfo, company, stripeCustomerId, plan } = params;
  const { createCompany } = await GraphQL({
    query: gqlCreateCompany,
    variables: {
      input: {
        id: companyId,
        group: companyId,
        company,
        hasLoggedIn: false,
        status: "Active",
        stripeCustomerId,
        plan,
        interviewInfo: JSON.stringify(interviewInfo),
      },
    },
  });

  // company cognito group creation
  const data = await post(`customer/create`, { company, id: companyId });
  console.log("created cognito group for company: ", data);

  return createCompany;
}

export async function updateCompany(params) {
  const variables = { input: params };
  const { updateCompany } = await GraphQL({
    query: gqlUpdateCompany,
    variables,
  });

  // update customer within cognito
  await put(`customer/update`, params);

  return updateCompany;
}

export async function listCompanies() {
  const { listCompanies } = await GraphQL({
    query: gqlListCompanies,
    variables: { filter: { status: { eq: "Active" } } },
  });

  return listCompanies;
}

export async function listUsers() {
  const { listUsers } = await GraphQL({
    query: gqlListUsers,
    variables: { filter: { status: { eq: "Active" } } },
  });
  return listUsers;
}

export async function userArchived(email) {
  const { listUsers } = await GraphQL({
    query: gqlListUsers,
    variables: { filter: { status: { eq: "Archived" } } },
  });
  let res = listUsers?.items.map((user) => user?.email).includes(email);
  return res;
}

export async function listArchivedUsers() {
  const { listUsers } = await GraphQL({
    query: gqlListUsers,
    variables: { filter: { status: { eq: "Archived" } } },
  });
  return listUsers;
}

export async function getUser(sub) {
  const { getUser } = await GraphQL({
    query: gqlGetUser,
    variables: { id: sub },
  });
  return getUser;
}

export async function listOwnerUsers() {
  const { listUsers } = await GraphQL({
    query: gqlListUsers,
    variables: {
      filter: { role: { eq: "owner" } },
    },
  });
  return listUsers;
}

export async function getInterviewCount(companyId) {
  let totalCount = 0;
  let nextToken = null;
  do {
    const query = listInterviews;
    const {
      listInterviews: { items, nextToken: newNextToken },
    } = await GraphQL({
      query,
      variables: {
        limit: 1000,
        nextToken,
        filter: { companyID: { eq: companyId } },
      },
    });
    totalCount += items.length;
    nextToken = newNextToken;
  } while (nextToken);

  return totalCount;
}

export async function getInterviewCountAggregate(companyId) {
  let totalCount = 0;
  let nextToken = null;
  do {
    const {
      searchInterviews: { total, nextToken: newNextToken },
    } = await GraphQL({
      query: gqlGetInterviewCount,
      variables: {
        nextToken,
        // filter: { companyID: { eq: companyId } },
        limit: 1000,
      },
    });
    console.log("response: ", total, nextToken);
    totalCount += total;
    // nextToken = newNextToken;
  } while (nextToken);

  return totalCount;
}

export async function getUserCount(companyId) {
  const {
    getCompany: {
      users: { items },
    },
  } = await GraphQL({
    query: getCompanyData,
    variables: { id: companyId, userStatus: "Active" },
  });
  return items.length;
}

export async function getCompany(cId) {
  const groups = await checkRoles();
  if (groups.includes("Admin")) {
    const { getCompany } = await GraphQL({
      query: getCompanyData,
      variables: { id: cId, userStatus: "Active" },
    });
    return getCompany;
  }

  if (cId) {
    let variables = { id: cId, userStatus: "Active" };
    const { getCompany } = await GraphQL({
      query: getCompanyData,
      variables,
    });
    return getCompany;
  }
}

export async function getCompanyInterviewBuilderData(cId) {
  const { getCompany } = await GraphQL({
    query: getInterviewBuilderData,
    variables: { id: cId },
  });
  return getCompany;
}

export async function updateCompanyInterviewBuilderData(
  id,
  interviewBuilderData,
) {
  const variables = {
    input: {
      id,
      interviewBuilderData: JSON.stringify(interviewBuilderData, null, 3),
    },
  };
  const graphQlArgs = {
    query: updateInterviewBuilderData,
    variables,
  };
  const {
    updateCompany: { updatedAt },
  } = await GraphQL(graphQlArgs);
  return updatedAt;
}

export async function updateUser(params) {
  const variables = { input: params };
  const { updateUser } = await GraphQL({ query: gqlUpdateUser, variables });

  return updateUser;
}

export async function createJobRole(params) {
  const jobRoleId = uuid();
  const { jobRoleData, companyID, workingSession } = params;

  const variables = {
      input: {
          id: jobRoleId,
          group: companyID,
          companyID: companyID,
          status: "Open",
          jobRoleData: JSON.stringify(jobRoleData),
          workingSession: workingSession,
      },
  };

  try {
      const response = await GraphQL({
          query: gqlCreateJobRole,
          variables: variables,
      });

      return response;
  } catch (error) {
      console.error('Error creating job role:', error);
  }
}

export async function migrateJobRole(params) {
  const { id, jobRoleData, companyID, workingSession } = params;

  const variables = {
      input: {
          id: id,
          group: companyID,
          companyID: companyID,
          status: "Open",
          jobRoleData: JSON.stringify(jobRoleData),
          workingSession: workingSession,
      },
  };

  try {
      const response = await GraphQL({
          query: gqlCreateJobRole,
          variables: variables,
      });

      return response;
  } catch (error) {
      console.error('Error migrating job role:', error);
  }
}

export async function updateJobRole(params) {

  const { id, jobRoleData, status } = params;

  const variables = {
    input: {
      id : id,
      status: status,
      jobRoleData: JSON.stringify(jobRoleData),
    },
  };
  const { updateJobRole } = await GraphQL({ query: gqlUpdateJobRole, variables });

  return updateJobRole;
}

export async function updateJobRoleGroup(params) {

  const { id, group } = params;
  const variables = {
    input: {
      id : id,
      group: group,
    },
  };
  const { updateJobRoleGroup } = await GraphQL({ query: gqlUpdateJobRole, variables });

  return updateJobRoleGroup;
}

export async function updateJobRoleStatus(params) {

  const { id, status } = params;

  const variables = {
    input: {
      id : id,
      status: status,
    },
  };
  const { updateJobRole } = await GraphQL({ query: gqlUpdateJobRole, variables });

  return updateJobRole;
}

export async function listJobRolesReqByCompId(compId) {
  const listJobRolesReq = await GraphQL({
    query: listJobRoles,
    variables: {
      filter: {
        companyID: { eq: compId }
      }
    }
  });
 
  return listJobRolesReq;
}

export async function listJobRolesReq() {
  const listJobRolesReq = await GraphQL({
    query: listJobRoles,
  });
 
  return listJobRolesReq;
}

export async function deleteJobRole(jrId) {
  const deleteJobRole = await GraphQL({
    query: gqlDeleteJobRole,
    variables: {
        input: {
            id: jrId
        }
    }
  });
  return deleteJobRole;
}

// stripe endpoints

export function createStripeCustomer(payload) {
  return post(`stripe/customer`, payload);
}

export function getStripeCustomer(params) {
  return get(`stripe/customer`, params);
}

export function createSubscription(payload) {
  return post(`stripe/subscription`, payload);
}

export function getStripeSubscription(params) {
  return get(`stripe/subscription`, { params });
}

export function updateSubscription(payload) {
  return put(`stripe/subscription`, payload);
}

export function createPaymentMethod(payload) {
  return put(`stripe/card`, payload);
}

export function getStripeCards(params) {
  return get(`stripe/card`, { params });
}

export function getProduct(params) {
  return get(`stripe/product`, params);
}

export function getPrices(params) {
  return get(`stripe/prices`, params);
}
