import React, { useState } from "react";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { toast } from "react-toastify";

import { Modal, Button, TextArea } from ".";
import { updateInterview } from "../graphql/mutations";
import createParticipantReview from "../utils/createParticipantReview";
import sleep from "../utils/sleep";
import { GraphQL } from "../utils/api";
import { nextStage } from "../utils/nextStage";

export function AcceptFormModal(props) {
  const {
    modalVisible,
    setModalVisible,
    stage,
    interviewId,
    interviewInfo,
    isParticipant,
    author,
  } = props;

  const [pass, setPass] = useState<string>("");
  const [reason, setReason] = useState("");

  return (
    <Modal
      style={{ maxWidth: "none", width: "45vw" }}
      title="Review Candidate"
      isVisible={modalVisible}
      onClose={() => setModalVisible(false)}
    >
      <div style={{ marginBottom: 20, padding: "0px 20px" }}>
        <div className="hor-center" style={{ justifyContent: "flex-start" }}>
          <div style={{ ...styles.inputLabel, width: "auto", marginRight: 20 }}>
            Should this candidate move on to the next stage?
          </div>
          <div style={{ marginBottom: 10 }}>
            <RadioGroup onChange={setPass} value={pass}>
              <Stack direction="row">
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Stack>
            </RadioGroup>
          </div>
        </div>
        <div style={styles.inputLabel}>Why or why not?</div>
        <TextArea
          inputStyle={styles.areaInputStyle}
          value={reason}
          onChange={setReason}
          rows={8}
        />
      </div>
      <div style={{ padding: "0px 20px 20px" }}>
        <Button title="Submit" filled onClick={() => onSubmitForm()} />
      </div>
    </Modal>
  );

  async function onSubmitForm() {
    if (!pass || !reason) return toast.error("All fields are required.");

    // if submitting by participant
    if (isParticipant) {
      console.log("submitting participant review...");
      createParticipantReview({ stage, pass, reason }, interviewId, author);
      setModalVisible(false);
    }

    // if submitting by main interviewer
    else {
      const payloadInterview = { ...interviewInfo };
      if (!payloadInterview.review) payloadInterview.review = {};
      payloadInterview.review[stage] = { pass, reason };
      payloadInterview.participants = [];
      const variables = {
        input: {
          id: interviewId,
          interviewInfo: JSON.stringify(payloadInterview),
          stage: pass === "yes" ? nextStage(stage) : "rejected",
        },
      };

      const query = updateInterview;
      await GraphQL({ query, variables, authMode: "" });
      setModalVisible(false);
    }
    toast.success("Interview updated. Exiting interview.", { autoClose: 3000 });
    await sleep(3000);
    window.close();
  }
}

export function DeviceModal(props) {
  const {
    audioMode,
    deviceModalVisible,
    setDeviceModalVisible,
    deviceList,
    currentDevice,
    onChangeDevice,
  } = props;

  const title = audioMode ? "Change Microphone" : "Change Camera";

  if (deviceList.length > 0)
    return (
      <Modal
        style={{ width: "45vw" }}
        title={title}
        isVisible={deviceModalVisible}
        onClose={() => setDeviceModalVisible(false)}
      >
        <div style={{ marginBottom: 20, padding: "0px 20px" }}>
          <RadioGroup
            value={currentDevice}
            onChange={(deviceId) => {
              onChangeDevice(deviceId);
              setDeviceModalVisible(false);
            }}
          >
            <Stack>
              {deviceList.map((device) => {
                const { deviceId, label } = device;
                const labelWithoutHash = label.replace(
                  /\s\(\w{4}:\w{4}\)$/g,
                  ""
                );
                return (
                  <Radio key={deviceId} value={deviceId}>
                    {labelWithoutHash}
                  </Radio>
                );
              })}
            </Stack>
          </RadioGroup>
        </div>
      </Modal>
    );
}

const styles = {
  inputLabel: {
    width: 300,
    marginBottom: 12,
  },
  inputStyle: {
    width: 350,
    height: 40,
    border: "1px solid #555",
    borderRadius: 4,
  },
  areaInputStyle: {
    padding: 5,
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #555",
    borderRadius: 4,
  },
};
