import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import * as React from "react";
import { styles } from "../../../styles";
import { TextWithInfoTooltip } from "../../general/TextWithInfoTooltip";
import { WeightedAttributeTR } from "./WeightedAttributeTR";


export const WeightedAttributeTable = ({ customerId, valueId, weightedAttributes, setState }) => <TableContainer>
    <Table variant="striped" size="sm">
        <Thead style={styles.tableHeader}>
            <Tr>
                <Th className="header-th">Title</Th>
                <Th className="header-th">Description</Th>
                <Th className="header-th">
                    <TextWithInfoTooltip content="Weight" tooltipLabel="A weight between 0-100 establishing how important the attribute is to the overall value being assessed" />
                </Th>
                <Th className="header-th"></Th>
                <Th className="header-th"></Th>
            </Tr>
        </Thead>
        <Tbody>
            {weightedAttributes.map(
                (weightedAttribute, key) => <WeightedAttributeTR {...{ customerId, weightedAttribute, valueId, setState, key }} />
            )}
        </Tbody>
    </Table>
</TableContainer>;
