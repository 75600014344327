import { Button, Center, Container, Flex, HStack, Heading, Spacer, Text, Tooltip } from "@chakra-ui/react";
import * as React from "react";
import { AiFillPlusCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { isEmpty } from "../../general/isEmpty";
import { MoreExamplesIconWithTooltip } from "./MoreExamplesIconWithTooltip";
import { onAddExampleClick } from "./onAddExampleClick";
import { ExampleModal } from "./ExampleModal";
import { HelpForScoredExample } from "./HelpForScoredExample";
import { ScoredExampleTable } from "./ScoredExampleTable";
import { EmptyExamples } from "./EmptyExamples";


export const ScoredExampleTableWithAddButtons = ({ valueId, attributeTitle, attributeId, examples, setState }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    return <Container maxWidth="100%" >
        <a id="examples"></a>
        <Flex width="100%" mb={2}>
            <Center gap={2}>
                <HStack>
                    <Tooltip placement="top" label={<HelpForScoredExample {...{ attributeTitle }} />}>
                        <HStack>
                            <Heading size="md">
                                Scored Examples (and counter-examples)
                            </Heading>
                            <AiOutlineInfoCircle />
                        </HStack>
                    </Tooltip>
                    {examples.length < 3 && <MoreExamplesIconWithTooltip />}
                </HStack>
            </Center>
            <Spacer />
            <Tooltip placement="top" label={<Text>Add a <i>scored example</i> from our smart suggestions or create your own</Text>}>
                {/* <Button onClick={() => setIsOpen(true)}> */}
                <Button onClick={onAddExampleClick(setState)(valueId)(attributeId)}>
                    <HStack gap={1}>
                        <AiFillPlusCircle />
                        <Text>Example</Text>
                    </HStack>
                </Button>
            </Tooltip>
        </Flex>
        {/* NOTE: We have multiple modals in the vdom */}
        <ExampleModal {...{ setState, valueId, isOpen, setIsOpen, examples }} />
        {isEmpty(examples)
            ? <EmptyExamples />
            : <ScoredExampleTable {...{ valueId, attributeId, examples, setState }} />}
    </Container>;
};
