import { Textarea } from "@chakra-ui/react";
import * as React from "react";
import { descriptionInputStyle } from "../../general/descriptionInputStyle";

export const ValueDescriptionInput = ({ description, onChange }) => <Textarea
    placeholder="Description of Value (1-4 sentences)"
    rows={4}
    style={descriptionInputStyle}
    onChange={e => onChange(e.target.value)}
    value={description} />;
