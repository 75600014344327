import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import qs from "query-string";

import { Container, Button, TextInput, Modal } from "../Components";
import { onboardUser } from "../utils/api";
import { toast } from "react-toastify";
import { signIn } from "../utils/auth";
import termsPdf from "../Documents/TermsService.pdf";
import privacyPdf from "../Documents/PrivacyPolicy.pdf";
import { Document, Page, pdfjs } from "react-pdf";
import { usePageTitle } from "../navigation/usePageTitle";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Welcome(props) {
  usePageTitle("Welcome")
  const { reset = false } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [checked, setChecked] = useState(false);

  const [termPages, setTermPages] = useState(null);
  const [privacyPages, setPrivacyPages] = useState(null);

  const [waiting, setWaiting] = useState(false);

  const username = qs.parse(location.search).uid;

  useEffect(() => {
    if (!username) navigate("/");
  }, [username, navigate]);

  function onTermsSuccess(res) {
    setTermPages(res.numPages);
  }

  function onPrivacySuccess(res) {
    setPrivacyPages(res.numPages);
  }

  async function handleSubmit() {
    if (!password || !confirmPassword)
      return toast.error("All fields are required.");
    if (password !== confirmPassword)
      return toast.error("Passwords do not match.");
    if (!reset && !checked)
      return toast.error(
        "Please check the box indicating that you have read and agreed to the Privacy Policy and the Terms of Service."
      );

    setWaiting(true);

    // const username = "4d9e9ecb-99dd-43fe-b23e-0088fb0fcf83";

    try {
      await onboardUser({ username, password });

      //login user
      await signIn(username, password);
      navigate("/admin/dashboard");
    } catch (e) {
      console.log(e);
      setWaiting(false);
      return toast.error(e.message);
    }
  }

  return (
    <Container>
      <Modal
        style={styles.modal}
        title="Intertru Privacy Policy"
        isVisible={privacyModalVisible}
        onClose={() => setPrivacyModalVisible(false)}
      >
        {renderPrivacyModal()}
      </Modal>
      <Modal
        style={styles.modal}
        title="Intertru Terms of Service"
        isVisible={termsModalVisible}
        onClose={() => setTermsModalVisible(false)}
      >
        {renderTermsModal()}
      </Modal>
      <div style={{ fontSize: 30, fontWeight: "bold", marginBottom: 40 }}>
        {reset ? "Password Reset" : "Welcome to Intertru!"}
      </div>
      <form>
        <TextInput
          label="New Password"
          type="password"
          inputStyle={styles.inputStyle}
          value={password}
          onChange={setPassword}
        />
        <TextInput
          label="Confirm Password"
          type="password"
          inputStyle={styles.inputStyle}
          value={confirmPassword}
          onChange={setConfirmPassword}
        />
        {!reset && (
          <div
            style={{
              ...styles.flexStart,
              margin: "20px 0px",
              cursor: "pointer",
            }}
            onClick={() => setChecked(!checked)}
          >
            <input
              type="checkbox"
              checked={checked}
              readOnly
              style={{ position: "relative", top: -1 }}
              onClick={() => setChecked(!checked)}
            />
            <div style={{ marginLeft: 8 }}>
              I have read and agreed to the Intertru{" "}
              <span
                style={styles.link}
                onClick={(e) => {
                  e.stopPropagation();
                  setPrivacyModalVisible(true);
                }}
              >
                Privacy Policy
              </span>{" "}
              and the{" "}
              <span
                style={styles.link}
                onClick={(e) => {
                  e.stopPropagation();
                  setTermsModalVisible(true);
                }}
              >
                Terms of Service
              </span>
              .
            </div>
          </div>
        )}
        <Button
          buttonType="button"
          filled
          onClick={handleSubmit}
          waiting={waiting}
        >
          Submit
        </Button>
      </form>
    </Container>
  );

  function renderPrivacyModal() {
    let pages = [];
    for (let i = 0; i < privacyPages; i++) {
      pages.push(i + 1);
    }
    return (
      <div style={{ border: "1px solid #bbb" }}>
        <Document file={privacyPdf} onLoadSuccess={onPrivacySuccess}>
          {pages.map((pageNumber) => {
            return (
              <Page height={1000} key={pageNumber} pageNumber={pageNumber} />
            );
          })}
        </Document>
      </div>
    );
  }

  function renderTermsModal() {
    let pages = [];
    for (let i = 0; i < termPages; i++) {
      pages.push(i + 1);
    }
    return (
      <div style={{ border: "1px solid #bbb" }}>
        <Document file={termsPdf} onLoadSuccess={onTermsSuccess}>
          {pages.map((pageNumber) => {
            return (
              <Page height={1000} key={pageNumber} pageNumber={pageNumber} />
            );
          })}
        </Document>
      </div>
    );
  }
}

const styles = {
  inputLabel: {
    width: 180,
    maxWidth: 180,
  },
  inputStyle: {
    width: 350,
    height: 40,
    border: "1px solid #555",
    borderRadius: 4,
  },
  areaInputStyle: {
    padding: 5,
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #555",
    borderRadius: 4,
  },
  flexStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  link: {
    textDecoration: "underline",
    color: "blue",
    cursor: "pointer",
    zIndex: 20,
  },
  modal: {
    minWidth: 900,
    height: "90%",
    overflowY: "scroll",
  },
};
