import React, { useState, useEffect } from "react";
import { RadioGroup, Radio, Stack } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { FiCornerUpLeft as BackIcon } from "react-icons/fi";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import qs from "query-string";
import rs from "randomstring";

import { Container, Button, TextInput, LoadingScreen } from "../../Components";
import { DeleteModal } from "../Interview";

import {
  addUserToDB,
  createCognitoUser,
  getUser,
  listCompanies,
  updateUser,
  userArchived,
} from "../../utils/api";
import { roles } from "../../utils/roles";
import { validateEmail } from "../../utils/validation";
import { sendEmail } from "../../utils/email";

import { styles } from "./styles";
import useGroups from "../../utils/useGroups";
import { getCognitoUsername } from "../../utils/auth";
import { usePageTitle } from "../../navigation/usePageTitle";
const ccEmail = process.env.REACT_APP_CC_EMAIL;

type UserObject = {
  uid?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  email: string;
  phone?: string;
  status?: string;
  role?: string;
};

type Company = {
  id: string;
  company: string;
};

export default function User(props: any) {
  const { mini = false, setUserModalVisible = null, cid } = props;
  const navigate = useNavigate();
  const groups = useGroups();
  const location = useLocation();
  const { uid } = qs.parse(location.search);
  
  const pageTitle = mini
    ? "Interview Details"
    : (!uid ? "Add User" : "User Details");
  usePageTitle(pageTitle);
  let mode = "add";
  if (!!uid) mode = "edit";
  
  const [customers, setCustomers] = useState<Company[]>([]);
  const [companyID, setCompanyID] = useState<string>(cid || "");
  const [user, setUser] = useState<UserObject>({
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    phone: "",
    status: "",
  });
  const [role, setRole] = useState("interviewer");
  const [permission, setPermission] = useState(null);

  const [isAdmin, setIsAdmin] = useState(false);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [passResetModalVisible, setPassResetModalVisible] = useState(false);
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      if (mode === "edit" && uid) {
        const res = await getUser(uid);
        const { firstName, lastName, title, email, phone, status, role } = res;
        setUser({
          uid,
          firstName,
          lastName,
          title,
          email,
          phone,
          status,
          role,
        });
      }
    };
    getUserData();
  }, [mode, uid]);

  useEffect(() => {
    async function getData() {
      setWaiting(true);
      if (cid) setCompanyID(cid);
      // check if user is an Intertru Admin
      const isAdministrator = groups?.includes("Admin") || false;
      setIsAdmin(isAdministrator);

      let username: string = "";
      if (!uid) username = await getCognitoUsername();

      // populate user's data (user, auth role, and companyID;

      // get companies as an intertru admin user
      if (isAdministrator) {
        const companies = await listCompanies();
        setCustomers(companies.items);
        const user = await getUser(uid || username);
        if (user) {
          setUser(user);
          setRole(user.role);
          if (!cid) setCompanyID(user.companyID);
        }
      }
      // if not an intertru admin (regular user)
      else {
        // check permission level of the authed user
        // and set role, companyID state
        const username = await getCognitoUsername();
        const user = await getUser(username);

        setPermission(user?.role);
        if (!cid) setCompanyID(user?.companyID);
        // const res = await getCompany(user.companyID);
      }
      setWaiting(false);
    }
    if (groups) {
      if (mode === "edit") if (!uid) return;
      getData();
    }
  }, [uid, mode, groups, cid]);

  const changeUser = async (value, key) => {
    const updatedUser = cloneDeep(user);
    updatedUser[key] = value;
    setUser(updatedUser);
  };

  const handleSelect = async (option) => {
    setCompanyID(option.target.value);
  };

  const archiveUser = async () => {
    let newStatus = user?.status === "Archived" ? "Active" : "Archived";

    try {
      await updateUser({ id: uid, status: newStatus });
    } catch (e) {
      console.log(e);
    }
    toast.success("User status updated");
    navigate("/admin/users");
  };

  const handleCreate = async () => {
    // validate inputs
    const { firstName, lastName, email, role, phone, title } = user;
    if (!firstName || !lastName || !email || !role)
      return toast.error("Please fill out all required fields.");
    if (!companyID) {
      return toast.error(
        "There was a problem with your request. Please contact technical support for help."
      );
    }

    const email_ = email.toLowerCase().trim();

    const emailValidationResult = validateEmail(email_);
    if (emailValidationResult) return toast.error(emailValidationResult);

    setWaiting(true);

    let userId;
    const password = rs.generate(10);
    try {
      // check if user is archived
      const userIsArchived = await userArchived(email);
      if (userIsArchived) {
        toast.error(
          "An archived user account with that email address already exists. Please contact technical support to restore or delete it."
        );
        return setWaiting(false);
      }

      const params = {
        username: email,
        password,
        company: companyID,
      };
      // create cognito account and add to company group
      userId = await createCognitoUser(params);
      console.log("cognito user created");
      await addUserToDB({
        id: userId, //cognito user ID
        email,
        firstName,
        lastName,
        phone,
        title,
        group: companyID,
        companyID,
        status: "Active",
        role,
      });
      console.log("user created");
      setWaiting(false);
    } catch (e) {
      setWaiting(false);
      return toast.error(e.response.data);
    }

    // send email notification to user
    const text = `Hi ${firstName},\n\nCongratulations and welcome to the Intertru tribe!\n\nTo get started, click the link below and set a new password. Create your first interview and voila! ...let the magic begin.\n\n${window.location.origin}/welcome?uid=${userId}\n\nPlease reach out to us for any assistance.\n\nAloha,\nRick`;
    const emailPayload = {
      to: [email],
      cc: [ccEmail],
      subject: "Intertru Company Account Created",
      text,
    };
    sendEmail(emailPayload);
    toast.success(`Account for ${email} created`);
    if (!mini) navigate("/admin/users");
    else setUserModalVisible(false);
  };

  const handleUpdate = async () => {
    const { firstName, lastName, title, email, phone, role } = user;
    const params = { firstName, lastName, title, email, phone, role };
    try {
      await updateUser({ id: uid, ...params });
    } catch (e) {
      console.log(e);
    }
    toast.success("Updated successfully");
    navigate("/admin/users");
  };

  const sendPassReset = async () => {
    console.log("sendPassReset user is ", user);
    const { firstName, email, id } = user;
    const text = `Hi ${firstName},
    \n\nWe received a reqeust to reset the password for your account. To ensure the security of your account, please follow the instructions below:
    \n1. Click on this password reset link: ${window.location.origin}/reset?uid=${id}
    \n2. Follow the instructions on the page to reset your password.
    \n\nIf you did not request this password reset, please disregard this email.
    \n\nPlease reach out to us for any assistance.
    \n\nThe Intertru Team`;

    const emailPayload = {
      to: [email],
      subject: "Intertru Password Reset Request",
      text,
    };
    sendEmail(emailPayload);
    toast.success("Password reset email sent.");
    navigate("/admin/users");
  };

  if (!(mode === "add")) {
    if (!role || (mode === "edit" && (!user || !companyID)))
      return <LoadingScreen />;
  }

  return (
    <Container ignorePadding={mini}>
      <DeleteModal
        onClick={archiveUser}
        modalVisible={deleteModalVisible}
        setModalVisible={setDeleteModalVisible}
        title={
          user?.status === "Active" ? "Deactivate User" : "Reactivate User"
        }
        description={`Are you sure you want to ${
          user?.status === "Active" ? "deactivate" : "reactivate"
        } this user?`}
      />
      <DeleteModal
        onClick={sendPassReset}
        modalVisible={passResetModalVisible}
        setModalVisible={setPassResetModalVisible}
        title="Send Password Reset Link"
        description="Are you sure you want to reset this user's password? Intertru will email instructions to the user to set a new one."
      />
      {!mini && (
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
        >
          <BackIcon
            size={28}
            style={{ marginRight: 20, cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <div style={{ fontSize: 30, fontWeight: "600" }}>{pageTitle}</div>
        </div>
      )}
      <form>
        <TextInput
          label="First Name"
          inputStyle={styles.inputStyle}
          value={user?.firstName || ""}
          onChange={(input) => changeUser(input, "firstName")}
        />
        <TextInput
          label="Last Name"
          inputStyle={styles.inputStyle}
          value={user?.lastName || ""}
          onChange={(input) => changeUser(input, "lastName")}
        />
        {!mini && mode === "add" && isAdmin && (
          <>
            <div style={styles.inputLabel}>Customer</div>
            <select
              value={companyID ? companyID : "--"}
              onChange={handleSelect}
              style={styles.select}
            >
              <option value="--" disabled>
                Select Customer
              </option>
              {customers
                .sort((a, b) => {
                  if (a.company.toLowerCase() < b.company.toLowerCase())
                    return -1;
                  return 1;
                })
                .map((customer, i) => {
                  const { company: cName, id } = customer;
                  return (
                    <option key={i} value={id}>
                      {cName}
                    </option>
                  );
                })}
            </select>
          </>
        )}
        <TextInput
          label="Title"
          inputStyle={styles.inputStyle}
          value={user?.title || ""}
          onChange={(input) => changeUser(input, "title")}
          optional
        />
        <TextInput
          label="Email"
          inputStyle={styles.inputStyle}
          value={user?.email || ""}
          onChange={(input) => changeUser(input, "email")}
        />
        <TextInput
          label="Phone"
          inputStyle={styles.inputStyle}
          value={user?.phone || ""}
          onChange={(input) => changeUser(input, "phone")}
          optional
        />
        {(["owner", "admin"].includes(permission ?? "") || isAdmin) && (
          <>
            <div style={styles.inputLabel}>Permission Level</div>
            <RadioGroup
              style={styles.radioGroup}
              onChange={(input) => changeUser(input, "role")}
              value={user?.role}
            >
              <Stack style={{ padding: 4, width: "50%" }} direction="column">
                {Object.keys(roles).map((role) => (
                  <Radio key={role} value={role}>
                    <p style={styles.radio}>{roles[role]}</p>
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </>
        )}
      </form>
      {renderFooter()}
    </Container>
  );

  function renderFooter() {
    let style = { ...styles.footer };
    return (
      <div style={style}>
        {mode === "add" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              filled
              buttonType="button"
              onClick={handleCreate}
              waiting={waiting}
            >
              Submit
            </Button>
          </div>
        )}
        {mode === "edit" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              filled
              onClick={handleUpdate}
              style={styles.createButton}
              waiting={waiting}
            >
              Update
            </Button>
            {permission !== "interviewer" && (
              <>
                <div style={{ width: 10 }} />
                <Button
                  filled
                  onClick={() => setPassResetModalVisible(true)}
                  style={styles.passResetButton}
                >
                  Password Reset
                </Button>
              </>
            )}
            <div style={{ width: 10 }} />
            <Button
              filled
              onClick={() => setDeleteModalVisible(true)}
              style={styles.archiveButton}
              waiting={waiting}
            >
              {user?.status === "Archived" ? "Reactivate" : "Deactivate"}
            </Button>
          </div>
        )}
      </div>
    );
  }
}
