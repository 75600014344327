import React from "react";
import { useEffect } from 'react'
import tip from '../images/icons/tip.png'


export default function TipModal(props) {

    const { 
        headerText, 
        content,
        contentLinks,
        showModalTip,
        buttonRef,
        modalRef,
        modal,
        top,
        left,
        showHireOSRole
    } = props;

    const handleLearnProfilesClick  = () => {
        showHireOSRole(true)
        showModalTip(false)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (buttonRef.current && !buttonRef.current.contains(event.target) &&
          modalRef.current && !modalRef.current.contains(event.target)) {
            showModalTip(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const styles = {
        toolTipModal: {
            backgroundColor: "var(--White-White, #FFF)",
            width: 402, 
            top: top,
            left: left,
            position: "fixed",
            transform: modal === 'worklife' ? "translateX(-70%)": modal === 'roleimpact' ? "translateX(-70%)" : "translateX(-50%)",
            boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.40)",
            borderRadius: 24,
            display: "block",
            padding: "8px 8px 16px 8px",
            flexDirection: "column",
            alignItems: "center",
            gap: 16
        },
        toolTipHeaderText : {
            color: "var(--Primary-Text, #FFF)",

            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 600,
            marginLeft: 8
        },
        tipModal: {
            position: "fixed",
            top: modal === "hireos" ? 222 : modal ==="worklife" ? 243: modal === "roletype" ? 306 : 306, /* Adjust distance from button */
            left: modal === "hireos" ? "44%" : modal==="worklife" ? 255: modal==="roletype" ? 175 : 255,
            zIndex: 999,
            width: 12,
            height: 7
        },
    };


  return (
      <div style={styles.toolTipModal}>
            <div className='tool-tip-modal' ref={modalRef}>
                <div className='tip-modal-header' style={{justifyContent: 'space-between'}}>
                    <div style={{display: "flex", justifyContent: 'flex-start'}}>
                        <div className='modal-info-icon'></div>
                        <div style={styles.toolTipHeaderText}>{headerText}</div>
                    </div>
                    <button className='modal-close-icon'
                        onClick={() => showModalTip(false)}
                    ></button>
                </div>
                <div style={{backgroundColor: 'white', marginTop:16}}>
                    <p style={{marginRight: 6, marginLeft: 6}}>{content}</p>
                    <div style={{height: 16}}></div>
                    {contentLinks && contentLinks.map((link, index) => (
                        <button style={{color: "blue", marginRight: 6, marginLeft: 6}}
                            onClick={() => handleLearnProfilesClick()}
                        >{link.text}</button>
                    ))}
                </div>
            </div>
            <div className="modal-tip" style={styles.tipModal}></div>
        </div>
  );
}