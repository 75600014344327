/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getInterview = /* GraphQL */ `query GetInterview($id: ID!) {
  getInterview(id: $id) {
    id
    group
    questions
    interviewInfo
    scheduling
    stage
    companyID
    companyName
    interviewResultData
    questionResponses {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInterviewQueryVariables,
  APITypes.GetInterviewQuery
>;
export const listInterviews = /* GraphQL */ `query ListInterviews(
  $filter: ModelInterviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listInterviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      questions
      interviewInfo
      scheduling
      stage
      companyID
      companyName
      interviewResultData
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInterviewsQueryVariables,
  APITypes.ListInterviewsQuery
>;
export const searchInterviews = /* GraphQL */ `query SearchInterviews(
  $filter: SearchableInterviewFilterInput
  $sort: [SearchableInterviewSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableInterviewAggregationInput]
) {
  searchInterviews(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      group
      questions
      interviewInfo
      scheduling
      stage
      companyID
      companyName
      interviewResultData
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchInterviewsQueryVariables,
  APITypes.SearchInterviewsQuery
>;
export const getCompany = /* GraphQL */ `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    group
    email
    firstName
    lastName
    company
    users {
      nextToken
      __typename
    }
    roles {
      nextToken
      __typename
    }
    interviews {
      nextToken
      __typename
    }
    interviewInfo
    values1
    values2
    plan
    stripeCustomerId
    status
    hasLoggedIn
    displayOrder
    interviewBuilderData
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyQueryVariables,
  APITypes.GetCompanyQuery
>;
export const listCompanies = /* GraphQL */ `query ListCompanies(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      email
      firstName
      lastName
      company
      interviewInfo
      values1
      values2
      plan
      stripeCustomerId
      status
      hasLoggedIn
      displayOrder
      interviewBuilderData
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompaniesQueryVariables,
  APITypes.ListCompaniesQuery
>;
export const getAttribute = /* GraphQL */ `query GetAttribute($id: ID!) {
  getAttribute(id: $id) {
    id
    group
    name
    attributesData
    valueID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAttributeQueryVariables,
  APITypes.GetAttributeQuery
>;
export const listAttributes = /* GraphQL */ `query ListAttributes(
  $filter: ModelAttributeFilterInput
  $limit: Int
  $nextToken: String
) {
  listAttributes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      name
      attributesData
      valueID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAttributesQueryVariables,
  APITypes.ListAttributesQuery
>;
export const getValue = /* GraphQL */ `query GetValue($id: ID!) {
  getValue(id: $id) {
    id
    group
    name
    weightedAttributes
    organizationID
    behaviors
    attributes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetValueQueryVariables, APITypes.GetValueQuery>;
export const listValues = /* GraphQL */ `query ListValues(
  $filter: ModelValueFilterInput
  $limit: Int
  $nextToken: String
) {
  listValues(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      name
      weightedAttributes
      organizationID
      behaviors
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListValuesQueryVariables,
  APITypes.ListValuesQuery
>;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    group
    name
    companyID
    organizationData
    values {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      name
      companyID
      organizationData
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    group
    firstName
    lastName
    title
    companyID
    email
    phone
    status
    role
    hasLoggedIn
    lastLoggedIn
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      firstName
      lastName
      title
      companyID
      email
      phone
      status
      role
      hasLoggedIn
      lastLoggedIn
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getRole = /* GraphQL */ `query GetRole($id: ID!) {
  getRole(id: $id) {
    id
    group
    name
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRoleQueryVariables, APITypes.GetRoleQuery>;
export const listRoles = /* GraphQL */ `query ListRoles(
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      name
      workingSession
      companyID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRolesQueryVariables, APITypes.ListRolesQuery>;
export const getJobRole = /* GraphQL */ `query GetJobRole($id: ID!) {
  getJobRole(id: $id) {
    id
    group
    status
    jobRoleData
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJobRoleQueryVariables,
  APITypes.GetJobRoleQuery
>;
export const listJobRoles = /* GraphQL */ `query ListJobRoles(
  $filter: ModelJobRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      status
      jobRoleData
      workingSession
      companyID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJobRolesQueryVariables,
  APITypes.ListJobRolesQuery
>;
export const getSession = /* GraphQL */ `query GetSession($id: ID!) {
  getSession(id: $id) {
    id
    questions
    initTime
    subquestionsOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSessionQueryVariables,
  APITypes.GetSessionQuery
>;
export const listSessions = /* GraphQL */ `query ListSessions(
  $filter: ModelSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      questions
      initTime
      subquestionsOrder
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSessionsQueryVariables,
  APITypes.ListSessionsQuery
>;
export const getReview = /* GraphQL */ `query GetReview($id: ID!) {
  getReview(id: $id) {
    id
    interviewId
    author
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const listReviews = /* GraphQL */ `query ListReviews(
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      interviewId
      author
      notes
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsQueryVariables,
  APITypes.ListReviewsQuery
>;
export const getNetworkQualityLog = /* GraphQL */ `query GetNetworkQualityLog($id: ID!) {
  getNetworkQualityLog(id: $id) {
    timestamp
    username
    sessionId
    description
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNetworkQualityLogQueryVariables,
  APITypes.GetNetworkQualityLogQuery
>;
export const listNetworkQualityLogs = /* GraphQL */ `query ListNetworkQualityLogs(
  $filter: ModelNetworkQualityLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listNetworkQualityLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      timestamp
      username
      sessionId
      description
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNetworkQualityLogsQueryVariables,
  APITypes.ListNetworkQualityLogsQuery
>;
export const getQuestionResponse = /* GraphQL */ `query GetQuestionResponse($id: ID!) {
  getQuestionResponse(id: $id) {
    id
    interviewId
    stage
    rating
    timestamp
    interviewerName
    question
    notes
    order
    subquestionResponses {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuestionResponseQueryVariables,
  APITypes.GetQuestionResponseQuery
>;
export const listQuestionResponses = /* GraphQL */ `query ListQuestionResponses(
  $filter: ModelQuestionResponseFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuestionResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      interviewId
      stage
      rating
      timestamp
      interviewerName
      question
      notes
      order
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuestionResponsesQueryVariables,
  APITypes.ListQuestionResponsesQuery
>;
export const getSubquestionResponse = /* GraphQL */ `query GetSubquestionResponse($id: ID!) {
  getSubquestionResponse(id: $id) {
    id
    questionResponseId
    question
    notes
    order
    displayTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubquestionResponseQueryVariables,
  APITypes.GetSubquestionResponseQuery
>;
export const listSubquestionResponses = /* GraphQL */ `query ListSubquestionResponses(
  $filter: ModelSubquestionResponseFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubquestionResponses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      questionResponseId
      question
      notes
      order
      displayTime
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSubquestionResponsesQueryVariables,
  APITypes.ListSubquestionResponsesQuery
>;
export const getRecording = /* GraphQL */ `query GetRecording($id: ID!) {
  getRecording(id: $id) {
    id
    interviewId
    stage
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRecordingQueryVariables,
  APITypes.GetRecordingQuery
>;
export const listRecordings = /* GraphQL */ `query ListRecordings(
  $filter: ModelRecordingFilterInput
  $limit: Int
  $nextToken: String
) {
  listRecordings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      interviewId
      stage
      path
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRecordingsQueryVariables,
  APITypes.ListRecordingsQuery
>;
export const getQuestion = /* GraphQL */ `query GetQuestion($id: ID!) {
  getQuestion(id: $id) {
    id
    stage
    companyID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuestionQueryVariables,
  APITypes.GetQuestionQuery
>;
export const listQuestions = /* GraphQL */ `query ListQuestions(
  $filter: ModelQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      stage
      companyID
      isActive
      text
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuestionsQueryVariables,
  APITypes.ListQuestionsQuery
>;
export const getSubQuestions = /* GraphQL */ `query GetSubQuestions($id: ID!) {
  getSubQuestions(id: $id) {
    id
    questionID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubQuestionsQueryVariables,
  APITypes.GetSubQuestionsQuery
>;
export const listSubQuestions = /* GraphQL */ `query ListSubQuestions(
  $filter: ModelSubQuestionsFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      questionID
      isActive
      text
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSubQuestionsQueryVariables,
  APITypes.ListSubQuestionsQuery
>;
