import React from "react";
import { useMediaQuery } from "react-responsive";

export default function LoginContainer(props) {
  const {
    width = 1480,
    ignorePadding = false,
    className = "",
    style = {},
  } = props;

  // sm breakpoint
  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });

  let padding = isMobile ? 20 : 40;
  if (ignorePadding) padding = 0;
  const maxWidth = 1536;
  const stylePlus = {
    margin: "auto",
    maxWidth,
    padding,
    ...props.style,
  };
  return (
    <div className={className} style={stylePlus}>
      {props.children}
    </div>
  );
}
