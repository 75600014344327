import { Stage } from "../state/stages/Stage";
import { ValuesDefintition } from "../state/values/ValuesDefintition";
import { valuesRelatedToStage } from "./valuesRelatedToStage";

export const valuesDefinitionRelatedToStage = 
  (valuesDefinition:ValuesDefintition) =>
  (stage:Stage):ValuesDefintition => 
  {
    const weightedValues = valuesRelatedToStage(valuesDefinition)(stage)
    return {
      weightedValues,
      questions: valuesDefinition.questions.filter(
        q => 
        weightedValues
          .flatMap(wv => wv.weightedAttributes)
          .flatMap(wa => wa.questionIds)
          .includes(q.id)
      )
    }
  }