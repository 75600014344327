export const sampleObservationData = [
  {
    assessment: {
      value: [
        {
          name: "Integrity",
          evidence: [
            {
              text: "I instituted a comp plan to make sure all the sales reps were compensated fairly and equitably",
              start: 121.0,
              end: 131.9,
            },
            {
              text: "When I found out she was making much less than her male colleagues I worked with HR to get that corrected",
              start: 189,
              end: 201,
            },
            {
              text: "I reported that customers were not getting what they were being told they were buying",
              start: 338.5,
              end: 368.1,
            },
          ],
          attributes: [
            { name: "Acting ethically with colleagues", score: 9.5 },
            { name: "Acting ethically with customers", score: 8 },
          ],
          score: 8.75,
        },
      ],
      final: "true",
    },
  },
  {
    assessment: {
      value: [
        {
          name: "Customer Focused",
          evidence: [
            {
              text: "That’s what the customer wanted, so I asked all my partner teams to buckle down and I managed to actually deliver that",
              start: 211,
              end: 221,
            },
            {
              text: "Despite their order being totally custom, I rallied the team and we actually learned a lot in making that customer very happy",
              start: 491,
              end: 504,
            },
          ],
          attributes: [
            { name: "Customer is always right", score: 7 },
            { name: "Everyone is in customer service", score: 9 },
          ],
          score: 8,
        },
      ],
      final: "true",
    },
  },
  {
    assessment: {
      value: [
        {
          name: "Teamwork",
          evidence: [
            {
              text: "For the benefit of the team, I created a communication tool that kept us all coordinated and learning from each other",
              start: 214.44,
              end: 227.89,
            },
            {
              text: "Inter-team collaboration was so important to us that we all agreed to come into the office every Tuesdays, Wednesdays, and Thursdays",
              start: 494.9,
              end: 507.4,
            },
          ],
          attributes: [
            { name: "Promoting collaboration", score: 7.2 },
            {
              name: "Encouraging contributions from all team members",
              score: 9,
            },
          ],
          score: 7.9,
        },
      ],
      final: "true",
    },
  },
  {
    assessment: {
      value: [
        {
          name: "Accountability",
          evidence: [
            {
              text: "I said 'that's on me' and took ownership for my mistake, so I stayed late for 3 nights and came up with something that actually was recognized with an award at a company meeting",
              start: 214.44,
              end: 227.89,
            },
            {
              text: "Inter-team collaboration was so important to us that we all agreed to come into the office every Tuesdays, Wednesdays, and Thursdays",
              start: 494.9,
              end: 507.4,
            },
          ],
          attributes: [
            { name: "Taking responsibility for actions", score: 9.2 },
            { name: "Outcome-oriented", score: 9 },
          ],
          score: 9.1,
        },
      ],
      final: "true",
    },
  },
];
