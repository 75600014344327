import { Meetings } from "../state/meetings/Meetings";
import { defaultDateFormat } from "./defaultDateFormat";
import { earliestStart } from "../state/general/earliestStart";
import { latestEnd } from "../state/general/latestEnd";

export const stageDisplayDateString = (meetings: Meetings) => {
  const s = earliestStart(meetings);
  const f = latestEnd(meetings);
  if (meetings.length === 0 || s === undefined || f === undefined) return 'Not Scheduled';
  return s.isSame(f, "day")
    ? s.format(defaultDateFormat)
    : s.isSame(f, "month")
      ? `${s.format(defaultDateFormat)} - ${f.format("D")}`
      : `${s.format(defaultDateFormat)} - ${f.format("D")}`;
};
