import { newGuid } from "../../general/newGuid";
import { libraryQuestionById } from "../../Questions/Library/libraryQuestionById";
import { isCustomAtribute } from "./isCustomAtribute";
import { libraryAttributeById } from "./libraryAttributeById";
import { mappedQuestionId } from "../../Questions/Library/mappedQuestionId";
import { newQuestionFromLibrary } from "../../Questions/Library/newQuestionFromLibrary";
import { notInExistingQuestions } from "../../Questions/Library/notInExistingQuestions";

export const newAttributeAndQuestionsFromLibrary = 
    (attributeLibraryId) =>
    (existingQuestions) =>
    {
        const libraryAttribute = libraryAttributeById(attributeLibraryId)
        const libraryQuestions = libraryAttribute.questionIds.map(libraryQuestionById)
        const nonExistentLibraryQuestions = libraryQuestions.filter(notInExistingQuestions(existingQuestions))
        const newlyAddedQuestions = nonExistentLibraryQuestions.map(newQuestionFromLibrary)
        const questions = [
            ...existingQuestions,
            ...newlyAddedQuestions,
        ]
        return {
            questions,
            newAttribute: {
                ...libraryAttribute,
                id: newGuid(),
                weight: 0,
                attributeLibraryId: isCustomAtribute(libraryAttribute) ? undefined : libraryAttribute.id,
                questionIds: libraryAttribute.questionIds.map(mappedQuestionId(questions))
            }
        }
    }