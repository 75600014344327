import { themeBlue } from "../../theme";

export const styles = {
  daj: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 30,
    fontWeight: "600",
  },
  subheader: {
    width: "100%",
    height: 60,
    borderTop: "1px solid #555",
    fontWeight: "bold",
    margin: "8px 0px 16px 0px",
    paddingTop: 12,
  },
  inputRow: {
    display: "flex",
    alignItems: "center",
  },
  inputLabel: {
    width: 180,
  },
  inputStyle: {
    width: 350,
    height: 40,
    border: "1px solid #555",
    borderRadius: 4,
  },
  stageInput: {
    width: 350,
    border: "1px solid #555",
    borderRadius: 4,
  },
  areaInputStyle: {
    padding: 5,
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #555",
    borderRadius: 4,
    whiteSpace: "pre-line",
  },
  select: {
    border: "1px solid #555",
    padding: "0px 10px 0px 5px",
    borderRadius: 4,
    height: 40,
    width: 350,
    marginBottom: 16,
  },
  linksWrapper: {
    flexDirection: "column",
    display: "flex",
    marginTop: 16,
  },
  dateInput: {
    border: "1px solid #555",
    padding: 10,
    marginTop: 2,
    borderRadius: 4,
    maxWidth: 180,
    height: 40,
    minWidth: 180,
  },
  tabRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  questionNumber: {
    padding: 8,
    background: themeBlue,
    fontWeight: "bold",
    color: "white",
    borderRadius: 8,
    marginBottom: 6,
    width: 130,
    textAlign: "center",
  },
  noteTextHeader: {
    borderBottom: "1px solid black",
  },
  roleButton: {
    color: "white",
    borderRadius: 4,
    border: "none",
    background: themeBlue,
    padding: "8px",
    minWidth: 0,
    minHeight: 0,
    maxHeight: 30,
    cursor: "pointer",
  },
  footer: {
    height: 70,
    background: "white",
    position: "sticky",
    bottom: 0,
    width: 1000,
    padding: "8px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid #ddd",
  },
  createButton: {
    height: 44,
    minWidth: 180,
    width: 180,
  },
  removeParticipantButton: {
    padding: 6,
    cursor: "pointer",
  },
  participantAddButton: {
    borderRadius: 4,
    background: themeBlue,
    border: "none",
    maxWidth: 50,
    minWidth: 50,
    minHeight: 0,
    height: 32,
  },
};
