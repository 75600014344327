import React, { useState } from "react";
import Card from "../Card/Card";
import { Button } from "../../../Components";

export default function CardList(props) {
  const {
    interviewData,
    isAdmin,
    draggable,
    scroll,
    setSelectedInterviewId,
    setSelectedInterviewForSchedule,
    setWaiting,
    setReleased,
    setSelectedInterviewForPark,
    stage,
  } = props;

  const [itemsToShow, setItemsToShow] = useState(20); // Start with 20 items

  // Function to handle the click event
  const handleClick = () => {
    setItemsToShow(itemsToShow + 20); // Load 10 more items when button is clicked
  };

  return (
    <>
      {interviewData.slice(0, itemsToShow).map((interview, index) => (
        <Card
          key={interview.id}
          id={interview.id}
          {...{
            index,
            interview,
            isAdmin,
            draggable,
            scroll,
            state: interview.state,
            setSelectedInterviewId,
            setSelectedInterviewForSchedule,
            setReleased,
            setWaiting,
            setSelectedInterviewForPark,
            stage,
          }}
        />
      ))}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {itemsToShow < interviewData.length && (
          <Button filled onClick={handleClick}>
            Load more
          </Button>
        )}
      </div>
    </>
  );
}
