import { Button, Input, Td, Textarea, Tooltip, Tr } from "@chakra-ui/react";
import * as React from "react";
import { AiFillDelete } from "react-icons/ai";
import { onExampleDelete } from "./onExampleDelete";
import { onExampleFieldChange } from "./onExampleFieldChange";

export const ScoredExampleTR = ({ valueId, attributeId, example: { id, text, exampleScore }, setState }) => {
    return <Tr>
        <Td width="60%">
            <Textarea
                placeholder="Example Statement (1-2 sentences)"
                value={text}
                onChange={onExampleFieldChange(valueId)(attributeId)(id)('text')(setState)} />
        </Td>
        <Td>
            <Input
                placeholder="Score (0-10)"
                width="5em"
                type="number"
                title="score"
                onChange={onExampleFieldChange(valueId)(attributeId)(id)('exampleScore')(setState)}
                value={exampleScore} />
        </Td>
        <Td>
            <Tooltip label="Delete Example">
                <Button onClick={onExampleDelete(valueId)(attributeId)(id)(setState)}><AiFillDelete /></Button>
            </Tooltip>
        </Td>
    </Tr>;
};
