import { HStack, Text } from "@chakra-ui/react";
import * as React from "react";
import { A } from "../../general/A";
import { valuesAbsolutePath } from "../valuesAbsolutePath";
import { AiOutlineArrowUp } from "react-icons/ai";


export const AllValuesLink = 
    ({customerId}) => 
    <A to={valuesAbsolutePath(customerId)}>
        <HStack>
            <AiOutlineArrowUp />
            <Text>All Values</Text>
        </HStack>
    </A>;
