import React from "react";
import { ThumbNeutralIcon } from "../../Components/common/icons/ThumbNeutralIcon";
import { ThumbApprovedIcon } from "../../Components/common/icons/ThumbApprovedIcon";
import { ThumbNotApprovedIcon } from "../../Components/common/icons/ThumbNotApprovedIcon";
import classNames from "classnames";
import { ApprovalStatusEnum } from "../../types/Summary";
import { StageLevelAssessments } from "./state/stages/StageLevelAssessments";
import { Person } from "./state/attendees/Person";

export interface CandidateApprovalStatusCardProps {
  status: ApprovalStatusEnum;
  questionResponse: any;
  person: Person | undefined;
  stageLevelAssessments: StageLevelAssessments;
}

export const CandidateApprovalStatusCard = ({
  status,
  questionResponse,
  person,
  stageLevelAssessments,
}: CandidateApprovalStatusCardProps) => {
  let Icon;
  switch (status) {
    case ApprovalStatusEnum.Neutral:
      Icon = ThumbNeutralIcon;
      break;
    case ApprovalStatusEnum.Approved:
      Icon = ThumbApprovedIcon;
      break;
    case ApprovalStatusEnum.NotApproved:
      Icon = ThumbNotApprovedIcon;
      break;
  }

  const interviewerName = questionResponse?.interviewerName ?? person?.friendlyName ?? 'Interviewer';
  const notesTitle = `${interviewerName}'s Notes`
  return (
    <div
      className={classNames(
        `w-full h-64 px-4 py-6 bg-white rounded-2xl border relative`,
        {
          "border-status-neutral": status === ApprovalStatusEnum.Neutral,
          "border-status-approved": status === ApprovalStatusEnum.Approved,
          "border-status-not-approved":
            status === ApprovalStatusEnum.NotApproved,
        },
      )}
    >
      <div
        className={classNames(
          `w-11 h-11 absolute top-6 left-4 rounded-full flex justify-center items-center`,
          {
            "bg-status-neutral": status === ApprovalStatusEnum.Neutral,
            "bg-status-approved": status === ApprovalStatusEnum.Approved,
            "bg-status-not-approved": status === ApprovalStatusEnum.NotApproved,
          },
        )}
      >
        <div className="text-white text-base font-bold font-['Montserrat'] leading-normal">
          <Icon className="text-white" />
        </div>
      </div>
      <div className="flex-col justify-start flex ml-14">
        <div className="self-stretch text-gray-900 text-lg font-semibold font-['Montserrat'] leading-relaxed">
          {notesTitle}
        </div>
        <div className="text-gray-500 text-base leading-normal h-[176px] overflow-auto">
        <span className="font-normal font-['Montserrat']">
                {questionResponse.notes}
                <br />
              </span>
          <br />
          {questionResponse.subquestionResponses.items.map(
            (subquestionResponse: any) => {
              return (
                <>
                  <div key={subquestionResponse.questionId}>
                    <span className="font-semibold font-['Montserrat']">
                      {subquestionResponse.question}
                      <br />
                    </span>
                    <span className="font-normal font-['Montserrat']">
                      {subquestionResponse.notes}
                      <br />
                    </span>
                  </div>
                  <br />
                </>
              );
            },
          )}
          <div>
            <span className="fontsemibold font['Montserrat']">
              What else?
              <br />
            </span>
            <span className="fontnormal font['Montserrat']">
              Nothing else to add to notes.
              <br />
            </span>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};
