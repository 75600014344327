import { Button, Heading, ListItem, Textarea, UnorderedList } from "@chakra-ui/react";
import { orderBy } from "lodash";
import * as React from "react";
import { mlAttributeScorePayloadFromValueDefs } from "../../../../Summary/ml-client/mlAttributeScorePayloadFromValueDefs";
import { A } from "../../general/A";
import { isValidJSON } from "../../general/isValidJSON";
import { jsonStringify } from "../../general/jsonStringify";
import { interviewBuilderAbsolutePath } from "../../interviewBuilderBaseUrl";
import { onCopyClick } from "./onCopyClick";
import { onJsonTextFieldChange } from "./onJsonTextFieldChange";
import { onResetToInitialState } from "./onResetToInitialState";
import { normalizeWeightsForValues, questionSortScore } from "./questionSortScore";
import { upArrowString } from "./upArrowString";

export const JsonValuesView = ({ customerId, state, setState }) => {
    const [jsonString, setJsonString] = React.useState(jsonStringify(state));
    const isValid = isValidJSON(jsonString);
    const possibleMLPayload = isValid ? jsonStringify(mlAttributeScorePayloadFromValueDefs(state)) : 'invalid json, cannot show ML paylod'
    const { weightedValues, questions } = state
    const normalizedWeightedValues = normalizeWeightsForValues(weightedValues)
    const questionScorePairs = questions.map(
        q =>
        [q, questionSortScore(q)(normalizedWeightedValues)]
    )
    const sortedQuestionScorePairs = orderBy(
        questionScorePairs,
        ([,score]) => score, 
        "desc",
    )
    
    return <>
        <br />
        <Button disabled={!isValid} onClick={onCopyClick(isValid)(jsonString)}>Copy to Clipboard</Button>
        <Button onClick={onResetToInitialState(setState)(setJsonString)}>Reset to Initial State</Button>
        <A to={interviewBuilderAbsolutePath(customerId)}>{upArrowString} back to normal view</A>
        <Textarea
            backgroundColor={isValid ? 'lightgreen' : 'pink'}
            height="600px"
            value={jsonString}
            mb={3}
            onChange={onJsonTextFieldChange(setState)(setJsonString)} />
        <Heading size="md">ML Payload (w fake iwee / iwer s3 paths)</Heading>
        <Button disabled={!isValid} onClick={onCopyClick(isValid)(possibleMLPayload)}>Copy to Clipboard</Button>
        <Textarea height="600px" value={possibleMLPayload} disabled={!isValid} />
        <Heading size="md" mt="1em" mb="1em">Questions sorted by score</Heading>
        <UnorderedList>
        {
            sortedQuestionScorePairs.map(
                ([q,score]) =>
                <ListItem>
                    {score} - {q.text} 
                    <Button onClick={onCopyClick(true)(q.text)}>Copy to Clipboard</Button>
                </ListItem>
            )
        }
        </UnorderedList>
    </>;
};
