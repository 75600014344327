import React, { CSSProperties } from "react";
import UserVideo from "../../Components/UserVideo";
import logo from "../../images/intertru.png";
import SoundBar from "./SoundBar";
import { StreamManager, Subscriber } from "openvidu-browser";
import { stagesV2 } from "../../utils/stages";

type CandidateFeedProps = {
  intervieweeStream: Subscriber | undefined;
  screenShareStream: StreamManager | null;
  initTime?: number | null;
  isInterviewee?: boolean;
  companyName?: string;
  stage?: string | undefined;
  roleName?: string;
  interviewerName?: string | null;
};

export default function CandidateFeed(props: CandidateFeedProps) {
  const {
    intervieweeStream,
    screenShareStream,
    initTime,
    isInterviewee,
    companyName,
    stage,
    roleName,
    interviewerName,
  } = props;

  return (
    <div style={styles.main}>
      {isInterviewee ? (
        <div style={styles.intervieweeHeader}>
          <div className="hor-center">
            <span style={{ fontWeight: "bold", fontSize: 20, marginRight: 16 }}>
              {companyName}
            </span>
            <span>
              {!!stage && stagesV2[stage].label} Interview for Role: {roleName}
            </span>
          </div>
          <div className="hor-center">
            <span style={{ marginRight: 4 }}>Powered by</span>
            <img src={logo} style={styles.candidateLogo} alt="logo" />
          </div>
        </div>
      ) : (
        <img src={logo} style={styles.logo} alt="logo" />
      )}
      <div className="hor-center" style={styles.mainCandidateWindow}>
        {intervieweeStream && (
          <UserVideo
            streamManager={intervieweeStream}
            isHidden={!!screenShareStream}
          />
        )}
        {screenShareStream && (
          <div style={{ height: "100%", width: "100%" }}>
            <UserVideo
              streamManager={screenShareStream}
              isSharingWindow
              style={{
                height: "calc(100% - 8%)",
                width: `calc(100% - ${isInterviewee ? "15%" : "25%"} - 12px)`,
              }}
            />
          </div>
        )}
      </div>
      {isInterviewee ? (
        interviewerName ? (
          <div className="hor-center" style={styles.bottomDiv}>
            <div style={styles.interviewerNameWrapper}>{interviewerName}</div>
          </div>
        ) : null
      ) : (
        <div className="hor-center" style={styles.soundBarWrapper}>
          <SoundBar initTime={initTime} />
        </div>
      )}
    </div>
  );
}

const styles: Record<string, CSSProperties> = {
  main: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  logo: {
    height: 40,
    position: "absolute",
    top: 6,
    left: 6,
    zIndex: 20,
    borderRadius: 4,
  },
  candidateLogo: {
    height: 36,
    zIndex: 20,
  },
  mainCandidateWindow: {
    background: "#999999",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  intervieweeLabel: {
    position: "absolute",
    top: 0,
    left: 0,
    background: "black",
    color: "white",
    padding: "2px 4px",
    zIndex: 1,
    fontSize: 18,
  },
  soundBarWrapper: {
    position: "absolute",
    bottom: 6,
    width: "100%",
    height: "10%",
    alignItems: "flex-end",
  },
  intervieweeHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "calc(100% - 12px)",
    background: "#00000099",
    position: "absolute",
    top: 0,
    zIndex: 10,
    margin: 6,
    borderRadius: 100,
    padding: "10px 20px",
    color: "white",
  },
  bottomDiv: {
    width: "100%",
    position: "absolute",
    bottom: 6,
    // height: 70,
  },
  interviewerNameWrapper: {
    background: "#000000aa",
    color: "white",
    padding: "12px 30px",
    borderRadius: 50,
    fontSize: 16,
    fontWeight: "bold",
  },
};
