import { UnscoredAttributes } from "../scores/UnscoredAttributes";

export const mapIncorrectMLResponseToRequestedSchema = (zubinJSON: any): UnscoredAttributes => Object.entries(zubinJSON.body).map(
    ([attributeId, value]: any) => ({
        attributeId,
        questionScores: Object.entries(value).map(
            ([questionId, score]) => ({
                questionId,
                score: score ? Number(score) : undefined,
            })
        )
    })
);
