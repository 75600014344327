import React from "react";

export const EmptyChartSVG = () => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="200" height="200" rx="100" fill="#C6F6D5" />
    <rect width="200" height="200" rx="100" stroke="white" />
    <rect
      x="20.5"
      y="20.5"
      width="159"
      height="159"
      rx="79.5"
      fill="#FEFCBF"
      stroke="white"
    />
    <rect
      x="40.5"
      y="40.5"
      width="119"
      height="119"
      rx="59.5"
      fill="#FED7D7"
      stroke="white"
    />
    <rect
      x="60.5"
      y="60.5"
      width="79"
      height="79"
      rx="39.5"
      fill="#FED7D7"
      stroke="white"
    />
    <rect
      x="80.5"
      y="80.5"
      width="39"
      height="39"
      rx="19.5"
      fill="#FED7D7"
      stroke="white"
    />
  </svg>
);
