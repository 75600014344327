import { themeOrange } from "../../theme";

export default function QuestionsTab(props) {
  const { selected, label } = props;
  let style = { background: "#e5e5e5", color: "black" };
  if (selected) style = { background: themeOrange, color: "white" };
  return (
    <div
      onClick={props.onClick}
      style={{
        marginRight: 6,
        cursor: "pointer",
        padding: "8px 16px",
        borderRadius: 2,
        ...style,
      }}
    >
      <div style={{ fontWeight: 600 }}>{label}</div>
    </div>
  );
}
