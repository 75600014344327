import React from "react";

export interface ThumbNeutralIconProps {
  className?: string;
}

export const ThumbNeutralIcon = ({ className }: ThumbNeutralIconProps) => {
  return (
    <svg
      className={className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="thumb_neutral_alt">
        <path
          id="Vector"
          d="M22.5 15L22.5 6C22.5 5.17 22 4.46 21.28 4.16L14.23 1.14C14 1.05 13.76 1 13.5 1L11.5 1C10.4 0.999999 9.5 1.9 9.5 3L9.5 9.31L4.93 8.36L4.61 8.33C4.2 8.33 3.82 8.5 3.55 8.77L2.5 9.83L9.09 16.41C9.45 16.78 9.95 17 10.5 17L20.5 17C21.6 17 22.5 16.1 22.5 15ZM10.5 15L6.16 10.66L11.5 11.77L11.5 3L13.5 3L20.5 6L20.5 15L10.5 15ZM22.5 19L22.5 23L10.5 23L10.5 19L22.5 19Z"
        />
      </g>
    </svg>
  );
};
