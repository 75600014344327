import { allAttributesWithQuestionId } from "../Attributes/Detail/allAttributesWithQuestionId";

//TODO: Permanently delete? https://intertru.slack.com/archives/C05CU4S8SDD/p1694194563164259
export const onQuestionDelete = 
    (valueId) => 
    (attributeId) => 
    (questionId) => 
    (setState) => 
    (e) => 
    {
        setState(
            state => {
                const numAttributesWithQuestion = allAttributesWithQuestionId(state.weightedValues)(questionId).length
                console.log({numAttributesWithQuestion})
                return {
                    ...state,
                    questions: numAttributesWithQuestion == 1
                        ? state.questions.filter(q => q.id != questionId)
                        : state.questions,
                    weightedValues: state.weightedValues.map(
                        v => v.id == valueId
                            ? {
                                ...v,
                                weightedAttributes: v.weightedAttributes.map(
                                    a => a.id == attributeId
                                        ? {
                                            ...a,
                                            questionIds: a.questionIds.filter(qId => qId != questionId)
                                        }
                                        : a
                                )
                            }
                            : v
                    )
                }
            }
        );
    };
