import { newGuid } from "../general/newGuid"
import { withValue } from "../general/withValue"

export const newWeightedValue = 
    () =>
    withValue
        (newGuid())
        (
            id => 
            ({
                id,
                title: '',
                description: "",
                weight: 10,
                weightedAttributes: [],
            })
        )
