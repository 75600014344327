import * as React from "react";
import { AttributeDetailsPage } from "./AttributeDetailsPage";
import { useParams } from "react-router-dom";
import { findById } from "../../general/findById";

export const AttributePageController = 
    ({customerId, questions, weightedValue, weightedValues, setState}) => {
        const { attributeId} = useParams()
        const weightedAttribute = findById(weightedValue.weightedAttributes)(attributeId)
        const { id: valueId, title: valueTitle } = weightedValue
        const attributeQuestions = weightedAttribute.questionIds.map(
            findById(questions)
        )
        return <AttributeDetailsPage 
            {...{customerId, valueId, valueTitle, weightedAttribute, setState, weightedValues}}
            questions={attributeQuestions}
            existingQuestions={questions}
        />
    }