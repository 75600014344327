import React from "react";
import classNames from "classnames";
import { Meetings } from "./state/meetings/Meetings";
import { People } from "./state/attendees/People";
import { StageLevelAssessments } from "./state/stages/StageLevelAssessments";
import { intendedAndOrActualAttendeePersonIds } from "./chart/intendedAndOrActualAttendeePersonIds";
import { personById } from "./chart/personById";
import { ConclusionApprovalStatusCard } from "./ConclusionApprovalStatusCard";

export interface ConclusionCardProps {
  candidateName: string;
  meetings: Meetings;
  people: People;
  stageLevelAssessments: StageLevelAssessments;
}

export const ConclusionCard = ({
  candidateName,
  meetings,
  people,
  stageLevelAssessments,
}: ConclusionCardProps) => {
  
  return (
    <div
      className={classNames("rounded-[16px] bg-white p-10")}>
      <div className="grid grid-cols-12">
        <div className="col-span-10 col-start-2 flex flex-col items-start font-['Montserrat'] mb-6">
          <div className="text-gray-900 text-3xl font-bold leading-9">
            Interview Conclusion
          </div>
        </div>
        <div className="col-span-10 col-start-2">
          {intendedAndOrActualAttendeePersonIds(meetings).map(
            (pId: any) => {
                const assessment = stageLevelAssessments.find(a => a.personId === pId)
                if (!assessment) {
                    console.error('Could not find assessment for person', pId)
                    return null
                }
                const rating = assessment.stageLevelRating
                const reason = assessment.reason
              
                return (
                  <ConclusionApprovalStatusCard
                    key={`assessment_${pId}`}
                    person={personById(people)(pId)}
                    reason={reason}
                    rating={rating}
                    candidateName={candidateName}
                  />
                );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default ConclusionCard;
