import React, { useEffect, useState } from "react";
import { Button } from "../../Components";
import { errorRed } from "../../theme";
import "./GreenRoom.css";

export default function GreenRoom(props) {
  const { isAutoContinue, onContinue } = props;
  const [isGranted, setIsGranted] = useState(false);

  useEffect(() => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(() => {
          setIsGranted(true);
          if (!isAutoContinue) onContinue();
        })
        .catch((error) => {
          console.log("Error accessing media devices:", error);
          console.log("Error message:", error.message);
          setIsGranted(false);
        });
    } else {
      console.log("Browser does not support media devices");
    }
  }, [setIsGranted, isAutoContinue, onContinue]);

  return (
    <div className="green-room">
      <div className="green-room-title">
        This video interview will be recorded.
      </div>
      <div className="green-room-text">
        Please enable your Microphone and Camera for the recording of this
        interview.
      </div>
      <div className="hor-center" style={{ marginTop: 20 }}>
        <Button
          title="Leave"
          style={{ color: errorRed, border: `2px solid ${errorRed}` }}
          onClick={() => window.close()}
        />
        <div style={{ width: 16 }} />
        <Button
          disabled={!isGranted}
          title="Continue"
          filled
          onClick={onContinue}
        />
      </div>
    </div>
  );
}
