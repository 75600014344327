import { Button, Heading, Table, Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import { range } from "lodash";
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { equals } from "../general/equals";
import { none } from "../general/none";
import { newQuestionFromSuggestion } from "./newQuestionFromSuggestion";
import { onAddQuestionClick } from "./onAddQuestionClick";

const SuggestedQuestion = 
    ({suggestion, setIsOpen, setState, valueId, attributeId}) => 
    <Tooltip label="Associate suggested question with attribute">
        <Tr 
            className="custom-tr-striped" 
            onClick={onAddQuestionClick(setIsOpen)(setState)(valueId)(attributeId)(newQuestionFromSuggestion(suggestion))}
            margin="2em"
        >
            <Td>
                <Link >
                    {suggestion}
                </Link>
            </Td>
        </Tr>
    </Tooltip>

const excludedCharacters = [',','.']
const randomWordSource = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
const randomWords = randomWordSource.split("").filter(n => none(equals(n))(excludedCharacters)).join("").split(" ")
const randomInteger = (min) => (max) => min + Math.round(Math.random() * (max - min))
const randomWord = () => randomWords.at(randomInteger(0)(randomWords.length - 1))
const randomSentence = (numWords) => range(0, numWords).map(_ => randomWord()).join(" ")
const randomSuggestions = 
    (numSuggestions) => 
    range(0,numSuggestions).map(
        _ => `${randomSentence(randomInteger(5)(15))}?`
    )

const generateSuggestions = 
    (setIsGenerating) =>
    (setSuggestions) =>
    () =>
    {
        setIsGenerating(true)
        setTimeout(
            () => {
                setIsGenerating(false)
                setSuggestions(randomSuggestions(randomInteger(3)(7)))
            },
            1500,
        )
    }

export const SuggestedQuestionsSection = 
    ({setIsOpen, setState, valueId, attributeId, suggestions, setSuggestions, questions}) => 
    {
        const [isGenerating, setIsGenerating] = useState(false)
        const buttonLabel = suggestions?.length > 1 ? "Generate New Suggestions": "Generate Suggestions"
        const generateButton = isGenerating
            ? <Text mt={2}>Generating suggestions, one moment...</Text>
            : <Button disabled={isGenerating} onClick={generateSuggestions(setIsGenerating)(setSuggestions)}>
                {buttonLabel}
            </Button>

        const suggestionsToShow = suggestions?.filter(s => none(e => e.text == s)(questions))
        return <>
            <Heading size="md" mt={5}>Suggested Questions</Heading>
            <Text>Given the attribute, value, and company description, Intertru can suggest effective questions to ask the candidate. Click generate to see these suggestions</Text>
            {
                suggestionsToShow?.length > 0
                    ? <>
                        <Table my={2}>
                            <Tbody>
                            {
                                suggestionsToShow.map(
                                    suggestion => 
                                    <SuggestedQuestion key={suggestion} {...{suggestion, setIsOpen, setState, valueId, attributeId }} />
                                )
                            } 
                            </Tbody>
                        </Table>
                        {generateButton}
                    </>
                    : generateButton
            }
        </>;
    }
