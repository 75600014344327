import { useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";

export default function Player(props) {
  const {
    downloadUrls,
    currentTab,
    elapsed,
    onPlay,
    onPause,
    audioRef,
    onChangeCurrentTimeError,
  } = props;

  useEffect(() => {
    if (!!audioRef && audioRef.current)
      audioRef.current.audio.current.currentTime = elapsed;
  }, [elapsed, audioRef]);

  // if (!!downloadUrls && !!urlObj[currentTab])
  return (
    <AudioPlayer
      ref={audioRef}
      onPlay={onPlay}
      onPause={onPause}
      className="audio-player"
      src={downloadUrls[currentTab]}
      autoPlayAfterSrcChange={false}
      onChangeCurrentTimeError={onChangeCurrentTimeError}
    />
  );
}
