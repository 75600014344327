import { both } from "../../general/both";
import { not } from "../../general/not";
import { isLibraryAttributeUsed } from "./isLibraryAttributeUsed";
import { isMatchingSearchText } from "./isMatchingSearchText";

export const isLibraryItemShown = 
    weightedAttributes =>
    searchText =>
    both
        (not(isLibraryAttributeUsed(weightedAttributes)))
        (isMatchingSearchText(searchText))