import React from "react";
import InfoHover from "./InfoHover";

interface TextInputProps {
  disabled?: boolean;
  password?: boolean;
  optional?: boolean;
  infoHover?: { content: React.ReactNode };
  label?: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  type?: string;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
}
export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      disabled,
      password,
      optional,
      infoHover,
      label,
      value,
      onChange,
      placeholder,
    } = props;

    let type = props.type || "text";
    if (password) type = "password";

    const nextProps = {
      ref,
      disabled,
      value,
      type,
      placeholder,
    };

    return (
      <div style={{ marginBottom: 16, ...props.containerStyle }}>
        <div style={{ marginBottom: 2 }}>
          {label}
          {optional && <span style={{ color: "gray" }}> - Optional</span>}
          {infoHover && renderInfoHover()}
        </div>
        <input
          {...nextProps}
          onChange={(evt) => onChange(evt.target.value)}
          style={{ ...styles.inputStyle, ...props.inputStyle }}
        />
      </div>
    );

    function renderInfoHover() {
      const content = infoHover?.content;
      if (!content) return;
      return <InfoHover>{content}</InfoHover>;
    }
  }
);

export function TextArea(props) {
  const {
    disabled,
    optional,
    infoHover,
    label,
    value,
    onChange,
    placeholder,
    rows = 3,
    customScrollbar = false,
  } = props;

  const textAreaClass = customScrollbar ? "customScrollbar" : "";

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginBottom: 2 }}>
        {label}
        {infoHover && renderInfoHover()}
      </div>
      {optional && <span className="gray"> - Optional</span>}
      <textarea
        className={textAreaClass}
        rows={rows}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
        style={{ maxWidth: 400, borderColor: "#aaa", ...props.inputStyle }}
      />
    </div>
  );

  function renderInfoHover() {
    const { content } = infoHover;
    return <InfoHover>{content}</InfoHover>;
  }
}

export function Checkbox(props) {
  const { hidden, disabled, label, infoHover, checked, style } = props;

  let className = "";
  if (!disabled) className += "form-checkbox";
  if (props.error) className += " delete";

  if (hidden) return null;
  return (
    <div style={style}>
      <span style={styles.checkbox} className={className} onClick={onClick}>
        <input disabled={disabled} type="checkbox" readOnly checked={checked} />
        <div style={{ marginLeft: 8, color: disabled ? "#999" : "#000" }}>
          {label}
        </div>
        {infoHover && renderInfoHover()}
      </span>
    </div>
  );

  function renderInfoHover() {
    const { content } = infoHover;
    return <InfoHover>{content}</InfoHover>;
  }

  function onClick() {
    if (disabled) return;
    props.onClick();
  }
}

const styles = {
  inputStyle: {
    height: 32,
    maxWidth: 500,
    padding: "0 5px",
  },
  checkbox: {
    display: "inline-flex",
    alignItems: "center",
  },
};
