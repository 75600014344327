import { ScoresForQuestionRatings } from "./ScoresForQuestionRatings";
import { ThumbsDown } from "../questions/ThumbsDown";
import { ThumbsSideways } from "../questions/ThumbsSideways";
import { ThumbsUp } from "../questions/ThumbsUp";

export const scoresForQuestionRatingsV1: ScoresForQuestionRatings = {
    [ThumbsUp]: 9.5,
    [ThumbsSideways]: 7.5,
    [ThumbsDown]: 2,
};
