import { Text } from "@chakra-ui/react";
import * as React from "react";

export const HelpForAttributeQuestion = 
    ({ attributeTitle }) => 
    <Text>
        A <i>Question</i> is asked during an interview, and when associated with an attribute
        (such as <i>{attributeTitle || 'This Untitled Attribute'}</i>), the intent is that the question elicits evidence for or 
        against the attribute being considered
    </Text>;
