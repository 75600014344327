import React from "react";

export interface EllipseIconProps {
    className?: string;
}

export const EllipseIcon = ({ className }: EllipseIconProps) => {
    return (        
        <svg className= {`fill-current ${className}`} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle id="Ellipse 1" cx="6.5" cy="6" r="6" />
        </svg>
    )
}