import { Table, TableContainer, Tbody } from "@chakra-ui/react";
import * as React from "react";
import { QuestionTR } from "./QuestionTR";
import { QuestionTableHeaders } from "./QuestionTableHeaders";


export const QuestionTable = 
    ({ customerId, weightedValues, valueId, attributeId, questions, setState }) => 
    <TableContainer>
        <Table variant="striped">
            <QuestionTableHeaders showActions />
            <Tbody>
                {questions.map(
                    (question, key) => <QuestionTR {...{ customerId, weightedValues, question, valueId, attributeId, setState, key }} />
                )}
            </Tbody>
        </Table>
    </TableContainer>;
