import React, { CSSProperties } from "react";
import { FiChevronUp } from "react-icons/fi";
import { Tooltip } from "@chakra-ui/react";

export default function ChevronExpand(props) {
  const { title, audioMode, setAudioMode, setDeviceModalVisible } = props;

  return (
    <div
      style={styles.chevronExpandContainer}
      onClick={(e) => {
        e.stopPropagation();
        setAudioMode(audioMode);
        setDeviceModalVisible(true);
      }}
    >
      <Tooltip
        label={<span style={{ fontWeight: "bold" }}>{title}</span>}
        placement="top"
        fontSize="md"
      >
        <span>
          <FiChevronUp size={18} color="#ffffff" />
        </span>
      </Tooltip>
    </div>
  );
}

const styles: Record<string, CSSProperties> = {
  chevronExpandContainer: {
    position: "absolute",
    marginRight: "-16%",
    marginTop: "2.5%",
    cursor: "pointer",
    height: 20,
    width: 20,
  },
};
