import { breadcrumbsForValue } from "../../Values/Detail/breadcrumbsForValue";
import { attributeDetailHref } from "./attributeDetailHref";

export const breadcrumbsForAttribute = 
    (customerId) =>
    ({ title, valueId, valueTitle, id }) => 
    [
        ...breadcrumbsForValue(customerId)({ title: valueTitle, id: valueId }),
        { label: `Attribute - ${title || 'Untitled'}`, href: attributeDetailHref(customerId, valueId, id) }
    ];
