import { toast } from "react-toastify";

export const onAddQuestionClick = 
    (setIsOpen) => 
    (setState) => 
    (valueId) => 
    (attributeId) => 
    (question) => 
    (e) => 
    {
        toast.success('New question added to attribute')
        setIsOpen(false)
        setState(
            state => ({
                ...state,
                questions: [
                    ...state.questions,
                    question,
                ],
                weightedValues: state.weightedValues.map(
                    weightedValue => weightedValue.id == valueId
                        ? {
                            ...weightedValue,
                            weightedAttributes: weightedValue.weightedAttributes.map(
                                weightedAttribute => weightedAttribute.id == attributeId
                                    ? {
                                        ...weightedAttribute,
                                        questionIds: [
                                            ...weightedAttribute.questionIds,
                                            question.id,
                                        ]
                                    }
                                    : weightedAttribute
                            )
                        }
                        : weightedValue
                )
            })
        )
        setIsOpen(false)
    }
