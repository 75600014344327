import React, { CSSProperties } from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import {
  createQuestionResponse,
  createSubquestionResponse,
} from "../../../graphql/mutations";
import { GraphQL } from "../../../utils/api";
import shovel from "../../../images/shovel_white.png";
import { stageMapping } from "../../../utils/stageMapping";
import moment from "moment-timezone";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { Interview, Stage } from "../../../types/Interview";
import { Question } from "../../../types/Question";
import { Subquestion } from "../../../types/Subquestion";

type FooterProps = {
  interview: Interview;
  stage: Stage;
  questions: string[];
  isLastQuestion: boolean;
  setFadeIn: (fade: boolean) => void;
  currentQuestion: string;
  currentQIndex: number;
  setCurrentQIndex: (n: number) => void;
  currentDig: number[];
  questionResponse: Question;
  setQuestionResponse: (qr: Question) => void;
  subQRs: Subquestion[];
  setSubQRs: (subQRs: Subquestion[]) => void;
  autoSubQs: Subquestion[];
  setAutoSubQs: (autoSubQs: Subquestion[]) => void;
  setIsAIObservationVisible: (isVisible: string | null) => void;
  setIsMoreNotesNeeded: (isNeeded: string | null) => void;
  questionIDs: string[];
  setQuestionIDs: (ids: string[]) => void;
  onQuestionChange: (payload: any) => Promise<void>;
  initTime: number | null;
  incrementDig: () => void;
  onClickShovel: () => void;
  closedArray: boolean[];
  setClosedArray: (closeds: boolean[]) => void;
  isParticipant: boolean;
  isSubQRModalVisible: boolean;
  name?: string;
  requiredSubQRs: number;
};

export default function Footer(props: FooterProps) {
  const {
    interview,
    stage,
    questions,
    isLastQuestion,
    setFadeIn,
    currentQuestion,
    currentQIndex,
    setCurrentQIndex,
    currentDig,
    questionResponse,
    setQuestionResponse,
    subQRs,
    setSubQRs,
    autoSubQs,
    setIsAIObservationVisible,
    setIsMoreNotesNeeded,
    questionIDs,
    setQuestionIDs,
    onQuestionChange,
    initTime,
    incrementDig,
    onClickShovel,
    closedArray,
    setClosedArray,
    isParticipant,
    isSubQRModalVisible,
    name,
    requiredSubQRs,
  } = props;

  const [timeMarker, setTimeMarker] = useState<number | null>(null);

  let mainQuestion = "";
  let subQuestions: string[] = [];

  if (stage !== "discovery" || !isLastQuestion) {
    mainQuestion = currentQuestion.split("\n")[0];
    subQuestions = currentQuestion.split("\n").slice(1);
  } else mainQuestion = currentQuestion;

  const handleThumbClick = async (rating: string) => {
    let timestamp: number | string;
    let elapsed: number;
    if (!initTime) timestamp = "N/A";
    else {
      elapsed = Math.floor(Date.now() - initTime);
      timestamp = moment.utc(timeMarker).format("H:mm:ss");
      if (currentQIndex === 0) timestamp = "0:00:01";
    }
    // if no main notes typed, pop up more notes needed dialog
    if (!questionResponse?.notes) return setIsMoreNotesNeeded("thumb");

    // logic for modal to appear
    // (not applicable to participants for the time being)
    if (!isParticipant) {
      // if this question has subquestions AND
      // interviewer has not reached required subquestions
      if (subQuestions.length) {
        // if required subquestions not met, show 1st one with modal
        if (currentDig[currentQIndex] < requiredSubQRs) {
          incrementDig();
          return setIsAIObservationVisible(
            "We're lacking evidence. I suggest we dig deeper.",
          );
        }

        if (subQRs.length && !subQRs.some((subquestion) => subquestion.notes)) {
          if (currentDig[currentQIndex] === 0) incrementDig();
          return setIsAIObservationVisible(
            "We're lacking evidence. I suggest we dig deeper.",
          );
        }
        // if there aren't any subQR notes, but all digs have been exited,
        // open the first closed dig
        else if (closedArray.every((value) => value === true)) {
          const newArray = [false, ...closedArray.slice(1)];
          setClosedArray(newArray);
          return setIsAIObservationVisible(
            "We're lacking evidence. I suggest we dig deeper.",
          );
        }
      }
    }
    const QRExists = questionIDs[currentQIndex];
    // submit QR to db if doesn't exist already
    if (QRExists)
      onQuestionChange({
        rating,
        notes: questionResponse.notes,
        subQRs: subQRs.concat(autoSubQs),
      });
    // update and clear out inputs
    // if QR already exists, update it
    else if (interview && stage) {
      const qrRes = await GraphQL({
        query: createQuestionResponse,
        variables: {
          input: {
            interviewId: interview.id,
            stage: stageMapping[stage],
            rating,
            timestamp,
            interviewerName: isParticipant
              ? name
              : interview.interviewInfo.interviewers[stage].name,
            question: mainQuestion,
            notes: questionResponse.notes,
            order: currentQIndex,
          },
        },
        authMode: "",
      });
      console.log(qrRes);

      // add the newly created QR's id to the
      // qrid's array
      const { order, id } = qrRes.createQuestionResponse;
      const updatedQRIDsArray = [...questionIDs];
      updatedQRIDsArray[order] = id;
      setQuestionIDs(updatedQRIDsArray);

      // submit all subQRs to db
      if (subQRs.length > 0) {
        const subQRPayloads = subQRs.map(async ({ question, notes, order }) =>
          GraphQL({
            query: createSubquestionResponse,
            variables: {
              input: {
                questionResponseId: id,
                question,
                notes,
                order,
              },
            },
            authMode: "",
          }),
        );
        await Promise.all(subQRPayloads);
      }
      // submit all intervention subQRs to db
      if (autoSubQs.length > 0) {
        const autoSubQRPayloads = autoSubQs.map(
          async ({ question, notes, order }) =>
            GraphQL({
              query: createSubquestionResponse,
              variables: {
                input: {
                  questionResponseId: id,
                  question,
                  notes,
                  order,
                },
              },
              authMode: "",
            }),
        );
        await Promise.all(autoSubQRPayloads);
      }
    }

    // const updatedQuestionResponse = cloneDeep(questionResponse);
    // updatedQuestionResponse["rating"] = rating;
    // setQuestionResponse(updatedQuestionResponse);

    // if there are still more questions, move on

    if (!isLastQuestion) {
      setFadeIn(false);
      setTimeout(() => {
        setTimeMarker(elapsed); // set time for future question
        setIsAIObservationVisible(null); // clear out ai bubble state
        const nextIndex = currentQIndex + 1;
        setCurrentQIndex(nextIndex);
        setQuestionResponse({
          interviewId: interview.id,
          stage,
          interviewerName: interview.interviewInfo.interviewers[stage].name,
          question: questions[nextIndex],
          notes: "",
          order: nextIndex,
        });
        setSubQRs([]);
        setFadeIn(true);
      }, 500);
    } else {
      // TODO: decide on behavior for thumbing last question
      setQuestionResponse({ ...questionResponse, rating });
    }
  };

  return (
    <div
      style={{
        ...styles.bottomNotesRatingSection,
        height: isParticipant ? "9%" : "15%",
      }}
    >
      {!isParticipant && (
        <div onClick={onClickShovel} style={styles.digDeeperShovelButton}>
          {isSubQRModalVisible ? (
            <>
              <MdClose size={26} style={{ color: "white", marginRight: 4 }} />
              <div style={{ color: "white", fontWeight: "bold" }}>Cancel</div>
            </>
          ) : (
            <>
              <img
                className="shovel"
                src={shovel}
                style={styles.shovelImage}
                alt="shovel"
              />
              <div style={{ color: "white", fontWeight: "bold" }}>
                Dig Deeper...
              </div>
            </>
          )}
        </div>
      )}
      <div
        className="hor-center"
        style={{
          height: isParticipant ? "100%" : "55%",
          borderTop: "1px solid #bbbbbb",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ marginRight: 8, color: "white", fontWeight: "bold" }}>
          Rate Overall Response
        </div>

        <div style={{ height: "100%" }}>
          <div className="hor-center" style={{ height: "100%" }}>
            <div
              className="hor-center hover-green"
              style={{
                ...styles.thumbWrapper,
                border: "2px solid green",
                background:
                  questionResponse?.rating === "positive" ? "green" : "none",
                cursor:
                  questionResponse?.rating === "positive"
                    ? "default"
                    : "pointer",
              }}
              onClick={() => handleThumbClick("positive")}
            >
              <FaThumbsUp color="white" style={{ fontSize: 18 }} />
            </div>
            <div
              className="hor-center hover-red"
              style={{
                ...styles.thumbWrapper,
                border: "2px solid red",
                background:
                  questionResponse?.rating === "negative" ? "red" : "none",
                cursor:
                  questionResponse?.rating === "negative"
                    ? "default"
                    : "pointer",
              }}
              onClick={() => handleThumbClick("negative")}
            >
              <FaThumbsDown color="white" style={{ fontSize: 18 }} />
            </div>
            <div
              className="hor-center hover-yellow"
              style={{
                ...styles.thumbWrapper,
                border: "2px solid #e1c200",
                background:
                  questionResponse?.rating === "neutral" ? "#e1c200" : "none",
                cursor:
                  questionResponse?.rating === "neutral"
                    ? "default"
                    : "pointer",
              }}
              onClick={() => handleThumbClick("neutral")}
            >
              <FaThumbsUp
                color="white"
                style={{ fontSize: 18, transform: "rotate(-90deg)" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const sideMargin = 6;

const styles: Record<string, CSSProperties> = {
  main: {
    width: "25%",
    height: `calc(100% - ${sideMargin * 2}px)`,
    zIndex: 2,
    margin: sideMargin,
    // overflow: "hidden",
    position: "relative",
  },
  mainWindow: {
    background: "#000000b5",
    padding: 20,
    color: "white",
    height: "100%",
    width: "100%",
    borderRadius: 14,
  },
  candidateName: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
  },
  questionLabel: {
    width: "100%",
    fontSize: 16,
    paddingBottom: 10,
    marginBottom: 14,
    alignItems: "center",
    justifyContent: "space-between",
  },
  textAreaInput: {
    width: "100%",
    maxWidth: "none",
    background: "#000000bb",
    outline: "none",
    padding: 12,
    borderRadius: 6,
  },
  thumbWrapper: {
    borderRadius: 50,
    height: 40,
    minHeight: 40,
    width: 40,
    minWidth: 40,
    marginLeft: 4,
    marginRight: 4,
  },
  bottomNotesRatingSection: {
    zIndex: 10,
    position: "absolute",
    bottom: 0, // padding of parent
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
  },
  digDeeperShovelButton: {
    height: "40%",
    width: "50%",
    display: "flex",
    alignItems: "center",
    padding: 6,
    cursor: "pointer",
  },
  stageAndTitleWrapper: {
    fontSize: 16,
    paddingBottom: 16,
    borderBottom: "1px solid white",
    marginBottom: 20,
  },

  shovelImage: {
    height: 50,
    padding: 10,
    marginLeft: -4,
    marginRight: 4,
    // transform: "rotate(45deg)",
  },
};
