import { Card, CardBody, CardHeader, Center, Divider, Flex, Heading, Input, InputGroup, InputRightElement, Spacer, Textarea, VStack } from "@chakra-ui/react";
import * as React from "react";
import { AiOutlinePercentage } from "react-icons/ai";
import { Breadcrumbs } from "../../general/Breadcrumbs";
import { descriptionInputStyle } from "../../general/descriptionInputStyle";
import { titleStyle } from "../../general/titleStyle";
import { onAttributeFieldChange } from "./onAttributeFieldChange";
import { ParentValueLink } from "./ParentValueLink";
import { breadcrumbsForAttribute } from "./breadcrumbsForAttribute";
import { ScoredExampleTableWithAddButtons } from "../Examples/ScoredExampleTableWithAddButtons";
import { QuestionTableWithAddButtons } from "../../Questions/QuestionTableWithAddButtons";
import { useState } from "react";

export const AttributeDetailsPage = 
    ({ customerId, weightedValues, valueId, valueTitle, weightedAttribute: { id, title, description, weight, examples }, questions, existingQuestions, setState }) =>
    {
        const [ suggestions, setSuggestions ] = useState()
        const onFieldChange = onAttributeFieldChange(valueId)(id)
        return <>
            <Breadcrumbs {...{breadcrumbs: breadcrumbsForAttribute(customerId)({title, valueId, valueTitle, id}) }} mb={5} />
            <Flex width="100%">
                <Center gap={2}>
                    <Heading as='h1' size='lg'>Attribute Details: {title || 'Untitled'}</Heading>
                </Center>
                <Spacer />
                <Center gap={2}>
                    <ParentValueLink {...{customerId}} title={valueTitle} id={valueId} />
                </Center>
            </Flex>
            <Card shadow='xl' width="100%" mt={5} maxHeight="80vh" overflowY="auto">
                <CardHeader>
                    <Flex>
                        <Center gap={2}>
                            <Input 
                                placeholder="New Attribute Title Here" 
                                style={titleStyle} 
                                onChange={onFieldChange('title')(setState)} 
                                value={title} 
                            />
                            <InputGroup>
                                <Input 
                                    placeholder="Weight: 0-100" 
                                    title="weight"
                                    style={titleStyle} 
                                    onChange={onFieldChange('weight')(setState)} 
                                    value={weight} 
                                />
                                <InputRightElement pointerEvents='none' >
                                    <AiOutlinePercentage />
                                </InputRightElement>
                            </InputGroup>
                        </Center>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <VStack align="left">
                        <Textarea 
                            placeholder="Description of Attribute (1-4 sentences)" 
                            rows={4} 
                            style={descriptionInputStyle} 
                            onChange={onFieldChange('description')(setState)} 
                            value={description} 
                        />
                        <Divider />
                        <ScoredExampleTableWithAddButtons {...{ valueId, examples, attributeTitle: title, attributeId: id, setState}} />
                        <Divider />
                        <QuestionTableWithAddButtons  {...{ customerId, suggestions, setSuggestions, weightedValues, valueId, questions, existingQuestions, attributeTitle: title, attributeId: id, setState}} />
                    </VStack>
                </CardBody>
            </Card>
        </>
    }