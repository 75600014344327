import React from "react";
import { cloneDeep } from "lodash";

export default function RoleSelector(props) {
  const {
    company,
    roleMap,
    candidateRole,
    setCandidateRole,
    questions,
    setQuestions,
    roleLists,
    isRoleManagementEnabled,
  } = props;

  let roles;

  try {
    roles = isRoleManagementEnabled ? roleLists : company.roles.items;
  } catch (e) {
    roles = [];
  }
  let roleId = "";
  if (roleMap && roleMap[candidateRole]) roleId = candidateRole;
  return (
    <div>
      <select
        value={roleId}
        onChange={(e) => changeRole(e.target.value)}
        style={{ ...styles.select, width: 290 }}
      >
        <option value="" disabled>
          Select Candidate Role
        </option>
        {roles &&
          roles.map((role, i) => {
            const { name, id } = role;
            return (
              <option key={i} value={id}>
                {name}
              </option>
            );
          })}
      </select>
    </div>
  );

  async function changeRole(roleId) {
    setCandidateRole(roleId);
    // setCandidateRole(role);

    const updatedQuestions = cloneDeep(questions);
    const workingSession = roleMap[roleId]?.workingSession === undefined ? [""] : roleMap[roleId].workingSession;
    updatedQuestions["working"] = [{ text: workingSession }];
    setQuestions(updatedQuestions);
  }
}

const styles = {
  select: {
    border: "1px solid #555",
    padding: "0px 10px 0px 5px",
    borderRadius: 4,
    height: 40,
    width: 350,
    marginBottom: 16,
  },
};
