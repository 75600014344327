import * as React from "react";
import { Link } from "react-router-dom";
import { attributeDetailHref } from "../Attributes/Detail/attributeDetailHref";
import { fullyQualifiedAttributeName } from "./fullyQualifiedAttributeName";

export const RelatedAttributeLink = ({ customerId, value, attribute }) => {
    return <Link
        style={{ color: 'blue' }}
        to={attributeDetailHref(customerId, value.id, attribute.id)}>
        {fullyQualifiedAttributeName(value)(attribute)}
    </Link>;
};
