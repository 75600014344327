import { equals } from "./equals";
import { firstName } from "./firstName";
import { lastInitial } from "./lastInitial";

export const shortestFriendlyName = (interviewerName: string) => (otherNames: string[]) => otherNames.map(firstName).some(equals(firstName(interviewerName)))
    ? lastInitial(interviewerName)
        ? otherNames.map(lastInitial).some(equals(lastInitial(interviewerName)))
            ? interviewerName
            : `${firstName(interviewerName)} ${lastInitial(interviewerName)}`
        : interviewerName
    : firstName(interviewerName);
