import { newQuestion } from "./newQuestion";


export const newQuestionFromSuggestion = (suggestion) => {
    const base = newQuestion(suggestion);
    return {
        ...base,
        title: `${suggestion.split(" ")[0]}-${base.id.substring(0, 6)}`,
        originalSuggestion: suggestion, //todo: test / type
    };
};
