export const interviewBuilderInitialState = {
   "weightedValues": [
      {
         "id": "22660",
         "title": "Re-Evolutionary",
         "description": "We are people who defy the shackles of conventional constraints. We reject 'best practices', 'conventional wisdom', or the 'status quo' perspectives that produce mediocre results. With a relentless spirit of rebellion, we celebrate failure and challenge one another to break boundaries and rewrite the rules of what real success means. ",
         "weight": 25,
         "weightedAttributes": [
            {
               "id": "84901",
               "title": "Innovative",
               "description": "Innovative candidates create new technologies, services, and business models that open up entirely new markets or solve a problem in a substantially better way.",
               "weight": 20,
               "examples": [
                   {
                      "id": "v1a2e1",
                      "text": "We had an engineering team that was constantly adding new costs and destroying our margin.  Luckily, I had the authority and discipline to reject these so-called innovations and actually improve margins ",
                      "exampleScore": 1
                   },
                   {
                      "id": "v1a2e2",
                      "text": "My team actually came up with bold new approach.  When they brought me the first hand-drawn hydrailic actuator, I quickly green lighted it because I could tell it would make the aircraft much more stable in turbulence and high wind.",
                      "exampleScore": 4
                   },
                   {
                      "id": "v1a2e3",
                      "text": "I was awarded 4 patents for various unique aspects of the next generation VTOL aircraft which used hydraulic actuators managed by a real-time computer.",
                      "exampleScore": 10
                   }
               ],
               "questionIds": []
            },
            {
               "id": "96750",
               "title": "Rebellious",
               "description": "",
               "weight": 40,
               "examples": [
                   {
                      "id": "v1a2e1",
                      "text": "In the end, we could not say anything to the boss about it without her flying off the handle.  I decided to leave the company because they were totally inflexible about approving my request to work from home on Fridays.",
                      "exampleScore": 1
                   },
                   {
                      "id": "v1a2e2",
                      "text": "I got the CEO to put into the next year's budget an allocation for an advanced research team that could innovate outside the normal product develpment processes and try things that were truly innovative.",
                      "exampleScore": 6
                   },
                   {
                      "id": "v1a2e3",
                      "text": "I just hated being constrained by the notion that we had to use the same old battery pack we had had for the last 5 years.  I fought management on it from day one.  Ultimately, I was able to locate a more economical battery chemistry that was being developed in Sweden and was able to convince them to send me an early prototype, which I put on my own credit card.  One Monday morning, everyone came into the lab to find the machine I set it up on had been running continuously for 20 hours, totally blowing away the wimpy 6-7 hour battery life the product had at the time.",
                      "exampleScore": 10
                   }
               ],
               "questionIds": []
            },
            {
               "id": "26156",
               "title": "Seek Failure",
               "description": "",
               "weight": 30,
               "examples": [
                   {
                      "id": "v1a2e1",
                      "text": "I prefer to not getting involved in risky projects that could end up being considered a failure.  I prefer to understand and mitigate risks proactively and that requires the king of project documentation we just don't get anymore.",
                      "exampleScore": 1
                   },
                   {
                      "id": "v1a2e2",
                      "text": "Unfortunately, this engineer conducted experiments that had a high risk of the product failing in ways that were sometime quite spectacular. Because of these experiments, we had a lot more data about what happened outside the normal boundaries of every day usage. In the end, we had a much more robust product, albeit with a stressed out engineering team.",
                      "exampleScore": 4
                   },
                   {
                      "id": "v1a2e3",
                      "text": "Even as a chold, I was taking things around the house apart just to find out how they worked.  When my mother was so mad because i couldn;t get it back together again, I treated that as motivation to rebuild it with better parts or a more servicable design.  It taught me the power of failures at a young age, and I have often specialized in breaking things, which gave me the idea to lead the quality assurance team.  I wanted the team to proactively invent new failure scenarios to try so we'd know how bad things might get when our product was eing operated outside what some product manager claimed were normal operation conditions.",
                      "exampleScore": 10
                   }
               ],
               "questionIds": []
            }
         ]
      },
      {
         "id": "1",
         "title": "You First",
         "description": "At Intertru we prioritize and empower your growth. We care deeply about you and your business. Our actions demonstrate genuine empathy, understanding, and solutions that deliver measurable value to you. Also, we say please, thank you, and hold the door open for others. ",
         "weight": 25,
         "weightedAttributes": [
            {
               "id": "v1a1",
               "title": "Customer Empathy",
               "description": "No matter their title, every employee must take responsibility for the customer's success. At the associate level, one way to do this is to ensure customer service agents are empowered, can handle requests quickly, and have the tools they need to delight customers.",
               "weight": 5,
               "examples": [
                  {
                     "id": "v1a1e1",
                     "text": "IBM was a big customer paying us a lot of money every year, so we naturally needed to say yes to most requests they made, even if it only benefited them.",
                     "exampleScore": 1
                  },
                  {
                     "id": "v1a1e2",
                     "text": "As a technical resource partnered with the Customer Success Team, most of my time was spent adding customizations and workarounds to ensure our customers were able to feel like they were getting good business value.  In one case, I worked for two months straight with an engineer at a customer to build out a custom data interchange module that processed events going both directions.",
                     "exampleScore": 5
                  },
                  {
                     "id": "v1a1e3",
                     "text": "We didn't have a customer success team yet, so it was up to me to fight tooth and nail with product management and company execs to get my customer's features prioritized and delivered on the deadlines they needed them on.  I must have done 7 or 8 different presentations, both to the customer to make sure I had heard them properly, and then advocating to the company stakeholders in product, engineering, finance, sales, and the CEO twice. ",
                     "exampleScore": 9
                  }
               ],
               "questionIds": []
            },
            {
               "id": "v1a2",
               "title": "Solution-oriented",
               "description": "A solution-oriented candidate instantly begins to think of approaches to narrow or close the gap which will lead to positive change.  A solutions-oriented person does not dwell on what could, would or should have happened but instead focuses on what can be done to make things right or better.",
               "weight": 3,
               "examples": [
                  {
                     "id": "v1a2e1",
                     "text": "I spent a ton of time documenting instances of the system going down and why it went down.  Sometimes I would spend hours going over the system log and writing up details in the spreadsheet so that the core engineering team had all the data they needed to prioritize fixes.",
                     "exampleScore": 2
                  },
                  {
                     "id": "v1a2e2",
                     "text": "One of the hardest problems we had to solve was a total team effort.  I devised a fix to the initial problem, which was insufficient logging and visibility of what was happening.  That enabled another engineer to realize that the sump pump was maxing out and tripping the thermal breaker for short periods of time.  Finally we tracked it down to a valve controller that we got replaced upstream. ",
                     "exampleScore": 5
                  },
                  {
                     "id": "v1a2e3",
                     "text": "My boss was prepared to tell the customer that th problem was inherent in the design and there was nothing we could do, but I got him to delay a few days and spent those days in the lab, setting up an elaborate simulation of the customer's inputs so I could try different timing conditions and see which sequences of events and flow rates were causing us to fail.  In the end, after spending 2.5 days setting up the apparatus, I had the conditions identified and the actual fix only took me half an hour.",
                     "exampleScore": 10
                  }
               ],
               "questionIds": []
            }
         ]
      },
      {
         "id": "2",
         "title": "Dig Deeper",
         "description": "We listen to understand, not to respond and it is in our nature to ask the difficult questions that enable us to attack the root of the issue. We consistently hone our expertise using targeted data. Making sure our solutions are proven in the real world with concrete results. ",
         "weight": 25,
         "weightedAttributes": [
            {
               "id": "v2a1",
               "title": "Curious",
               "description": "v2a1 description",
               "weight": 50,
               "examples": [
                  {
                     "id": "v2a1e1",
                     "text": "I came up with an experiment just to see what would happen if we left out all the validation and checking in the process for a few customers.  I got my VP to approve it and we just did it quietly, but when I was able to show that we could deliver in 2 days and not 10-12, we totally re-wrote the standard process to be much more reative and lightweight.",
                     "exampleScore": 9
                  },
                  {
                     "id": "v2a1e2",
                     "text": "I've always been interested in learning French, so when they assigned me to maanage the business relationship with Renault, I dove in and started taking courses and practicing my French with a few specific contacts I had one on one meetings with.",
                     "exampleScore": 5
                  }
               ],
               "questionIds": []
            },
            {
               "id": "v2a2",
               "title": "Expertise",
               "description": "Candidates that have developed a high level of expertise in an area are able to contribute to the development of other staff members, write documentation, and lead projects that require high levels of subject matter expertise.",
               "weight": 20,
               "examples": [
                  {
                     "id": "v2a2e1",
                     "text": "I was very impressed in the technical depth that my boss had, so I've started taking some of the technical classes he told me he took when he joined",
                     "exampleScore": 2
                  },
                  {
                     "id": "v2a2e2",
                     "text": "I would attend customer meetings when they needed my advice on how to integrate our service with their complex ERP, accounting, or payroll systems",
                     "exampleScore": 7
                  },
                  {
                     "id": "v2a2e3",
                     "text": "I really enjoyed being given the freedom to author or co-author research papers in the AI/ML domain.  Part of one of those projects involved contributing to the Llama two open-source language model and showing how our data feed could be used for few-shot learning.  We won the Best Paper award for that one, so the recognition was nice, even if the salary wasn't.",
                     "exampleScore": 10
                  }
               ],
               "questionIds": []
            },
            {
               "id": "9064",
               "title": "Listening",
               "description": "",
               "weight": 30,
               "examples": [
                   {
                      "id": "v1a2e1",
                      "text": "Just so I understand your question",
                      "exampleScore": 5
                   },
                   {
                      "id": "v1a2e2",
                      "text": "What I heard you say is",
                      "exampleScore": 4
                   },
                   {
                      "id": "v1a2e3",
                      "text": "What you said about project Orion was fascinating and I have written down a list of questions on it, someof which you already answered",
                      "exampleScore": 9
                   }
               ],
               "questionIds": []
            }
         ]
      },
      {
         "id": "87493",
         "title": "Commit Action",
         "description": "We set measurable goals, hold each other accountable, and take corrective action. Our approach is to initiate communication that uncovers issues before they become real problems. We operate from win-win and walk from win-lose situations",
         "weight": 25,
         "weightedAttributes": [
            {
               "id": "3738",
               "title": "Proactive",
               "description": "",
               "weight": 45,
               "examples": [
                   {
                      "id": "v1a2e1",
                      "text": "I wish I could be more proactive, but und=fortuantely with the level of tech debt I was saddled with, I was troubleshooting problems and reacting to the latest customer drama of the day.",
                      "exampleScore": 2
                   },
                   {
                      "id": "v1a2e2",
                      "text": "I kept telling them that we were going to have a scaling problem and all that it would take for us to overload those servers was a surprise event with one or two customers.  Eventually, I went to the CEO and asked for a special allocation of a team of two people to avert what I thought was an extensive outage just waiting to happen.",
                      "exampleScore": 7
                   },
                   {
                      "id": "v1a2e3",
                      "text": "Over the first 18 months, I was able to move the team out of constant fire fighting mode and stop being forced to be reactive all the time.  Today, we are 80-90% proactive, where my team comes to me and tells me about the crisis or eventual outage they prevented from happening.",
                      "exampleScore": 10
                   }
               ],
               "questionIds": []
            },
            {
               "id": "55486",
               "title": "Accountability",
               "description": "",
               "weight": 45,
               "examples": [
                   {
                      "id": "v1a2e1",
                      "text": "I apologize that I was late for this interview.  You wouldn't believe the meeting I just came from. It ran over by 35 minutes.",
                      "exampleScore": 1
                   },
                   {
                      "id": "v1a2e2",
                      "text": "I took charge of the lack of on-demand scaling, as that was a mistake I had made early on in the project due to the incredible time pressure the project was under from the CEO.",
                      "exampleScore": 5
                   },
                   {
                      "id": "v1a2e3",
                      "text": "As the Sales Leader, I own that number.  It doesn't matter that marketing did or didn't provide good collateral.  If we don't make the quarterly sales target, that's on me, so what I did was have a couple of my more creative and experienced reps join me in the office one week and we just hammered out our own product info sheets that listed all our value props.  In the end, these got improved on, but for the next two quarters, we were handing these out and getting follow-up calls at a much higher rate, like 35% of the time. ",
                      "exampleScore": 10
                   }
               ],
               "questionIds": []
            }
         ]
      }
   ],
   "questions": []
}