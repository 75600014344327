import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import * as React from "react";
import { useState } from "react";


export const ExampleModal = ({ setState, valueId, isOpen, examples, setIsOpen }) => {
    const [searchText, setSearchText] = useState("");
    return <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="3xl">
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Add Scored Example</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text>Add Scored Example</Text>
            </ModalBody>
        </ModalContent>
    </Modal>;
};
