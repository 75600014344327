import React, { CSSProperties, useState } from "react";
import { themeOrange } from "../../theme";
import { MdCheck, MdClose, MdInfo } from "react-icons/md";

export default function OnboardingOverlay(props) {
  const { setIsOnboarded } = props;
  const [currentStage, setCurrentStage] = useState<number>(1);
  const [checked, setChecked] = useState(false);

  const handleNext = () => {
    if (currentStage === 3) setCurrentStage(1);
    else setCurrentStage(currentStage + 1);
  };

  const handleDismiss = () => {
    setCurrentStage(0);
    if (checked) {
      localStorage.setItem("onboard", "hidden");
      setIsOnboarded(true);
    }
  };

  if (currentStage > 0)
    return (
      <div style={styles.overlay}>
        <div
          style={{
            ...styles.tooltip,
            ...tooltips[currentStage - 1].positioning,
          }}
        >
          <div style={styles.tooltipOrangeHeader}>
            <div className="hor-center">
              <MdInfo color="#ffffff" size={26} style={{ marginRight: 8 }} />
              <div>Interviewing {currentStage} of 3</div>
            </div>
            <MdClose
              color="#ffffff"
              size={20}
              style={{ marginRight: 4, cursor: "pointer" }}
              onClick={handleDismiss}
            />
          </div>
          <div style={{ padding: 4, marginBottom: 12 }}>
            {tooltips[currentStage - 1].text}
          </div>
          <div style={styles.flexBetween}>
            <div
              className="hor-center"
              style={{ cursor: "pointer" }}
              onClick={() => setChecked(!checked)}
            >
              <div className="hor-center" style={styles.checkbox}>
                {checked && <MdCheck />}
              </div>
              <div>Do not show again</div>
            </div>
            <div className="hor-center">
              <div
                className="hover-opacity"
                style={styles.nextButton}
                onClick={handleNext}
              >
                {currentStage === 3 ? "Repeat" : "Next"}
              </div>
              {currentStage === 3 && (
                <div className="hover-opacity" style={styles.xButton}>
                  <MdClose size={20} color="white" onClick={handleDismiss} />
                </div>
              )}
            </div>
          </div>
          <div style={{ ...tooltips[currentStage - 1].line }} />
        </div>
      </div>
    );
}

type Tooltip = {
  text: string;
  positioning: CSSProperties;
  line: CSSProperties;
};

const tooltips: Tooltip[] = [
  {
    text: "Ask this question and use the space below for your notes.",
    positioning: { top: 125, right: "calc(25% + 12px)" },
    line: {
      position: "absolute",
      top: "50%",
      right: "-8%",
      height: 2,
      width: "8%",
      background: "#000000aa",
    },
  },
  {
    text: "You must ask follow-up questions to gather details and evidence in order to move on. Click 'Dig Deeper' for suggestions.",
    positioning: { bottom: "8.75%", right: "calc(25% + 12px)" },
    line: {
      position: "absolute",
      bottom: "15%",
      right: "-8%",
      height: 2,
      width: "8%",
      background: "#000000aa",
    },
  },
  {
    text: "Rate the candidate's responses to the main and follow-up questions. You'll then move on to the next main question.",
    positioning: { bottom: "12.5%", right: "2%" },
    line: {
      position: "absolute",
      right: "50%",
      height: "22.5%",
      width: 2,
      background: "#000000aa",
    },
  },
];

const styles: Record<string, CSSProperties> = {
  overlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    // background: "#44004466",
    zIndex: 200,
    pointerEvents: "none",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tooltip: {
    padding: 8,
    background: "#000000aa",
    color: "white",
    position: "absolute",
    maxWidth: "22%",
    borderRadius: 20,
    zIndex: 200,
    pointerEvents: "auto",
  },
  tooltipOrangeHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: themeOrange,
    fontWeight: "bold",
    padding: 2,
    borderRadius: 50,
    marginBottom: 2,
  },
  checkbox: {
    height: 14,
    width: 14,
    borderRadius: 3,
    border: "1px solid white",
    marginLeft: 4,
    marginRight: 6,
    cursor: "pointer",
  },
  nextButton: {
    padding: "4px 16px",
    border: "1px solid white",
    borderRadius: 50,
    cursor: "pointer",
  },
  xButton: {
    borderRadius: 50,
    border: "1px solid white",
    padding: 4,
    marginLeft: 6,
    cursor: "pointer",
  },
};
