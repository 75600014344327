
import React from 'react';
import { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import AppConfig from 'aws-sdk/clients/appconfig';
import { v4 as uuidv4 } from 'uuid';
import { Auth } from "aws-amplify";
import { GraphQL, listCompanies, listJobRolesReqByCompId, migrateJobRole, updateJobRoleGroup} from '../../utils/api'
import { getCompanyWithRolesAndUsers } from "../../graphql/custom";

export default function RoleMigration() {

    const [isAllowedToAccess, setIsAllowedToAccess] = useState(false); 
    const [companies, setCompanies] = useState([]);
    const [roleLists, setRoleLists] = useState([]);
    const [jobRoleLists, setJobRoleLists] = useState([]);

    useEffect(() => {
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_AWS_REGION,
          });
          const getConfig = async () => {
            try {
              await Auth.currentAuthenticatedUser().then(async (user) => {
                  let userEmail = user.attributes.email;
      
                  let appConfig = new AppConfig();
          
                  const params = {
                      Application: process.env.REACT_APP_APICONFIG_APPID,
                      Environment: process.env.REACT_APP_APICONFIG_ENVID,
                      Configuration: process.env.REACT_APP_APICONFIG_CONFIGPROFILEID,
                      ClientId: uuidv4(),
                  };
          
                  try {
                    let data = await appConfig.getConfiguration(params).promise();
                    let configContent = data.Content.toString('utf-8');
                    let config = JSON.parse(configContent);
                    
                    if (config.featureManagement.jobRoleMigration.allowedUsers.length === 0) {
                      setIsAllowedToAccess(true)
                    }
                    else {
                      if (config.featureManagement.jobRoleMigration.enabled && config.featureManagement.jobRoleMigration.allowedUsers.includes(userEmail)) {
                        setIsAllowedToAccess(true);
                        
                      }
                      else {
                        setIsAllowedToAccess(false)
                      }
                    }
                    
                  } catch (error) {
                      console.error('Error fetching configuration:', error);
                  }
              });
              
              
            } catch (error) {
              console.error('Error fetching configuration:', error);
            }
          };
      
          getConfig();
    }, []);

    useEffect(() => {
        
        const getListOfCompanies = async () => {
            try {
                await listCompanies().then((comp) => {
                    setCompanies(comp.items);
                });
                
            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        }
        getListOfCompanies();
    }, []);

    const handleCompanyChange = (compId) => {
        getRoleLists(compId);
        getJobRoleLists(compId);
    }

    async function getRoleLists(compId) {

        const query = getCompanyWithRolesAndUsers;
        let variables = { id: compId, userStatus: "Active" };
        await GraphQL({ query, variables }).then((res) => {
            let selectedCompany = res.getCompany;
            let roleList = selectedCompany.roles.items;
            setRoleLists(roleList);
        });
    }

    async function getJobRoleLists(compId) {
        await listJobRolesReqByCompId(compId).then((res) => {
            setJobRoleLists(res.listJobRoles.items);
        });
    }

    const handleJobRoleGroupUpdate = async (jobRoleLists) => {   
        try {
            jobRoleLists.map(async (role) => {
                const params = {
                    id: role.id,
                    group: role.companyID,
                };
                await updateJobRoleGroup(params).then((res) => {
                    if(res) {
                        
                    }
                });
            });
        }
        catch (error) {
            console.error('Group Update Failed:', error);
        }
        
    }

    const handleMigrateButtonClicked = async (roleLists) => {
        roleLists.map(async (role, index) => {
            if(jobRoleLists?.find(jobRole => jobRole.id === role.id)) {
                console.log('Role already migrated:', role.name);
                return;
            }
            try {
                let jData = prepareRolePayload(role);
                if(jData) {
                    const params = {
                        id: role.id,
                        companyID: role.companyID,
                        jobRoleData: jData,
                        workingSession: role.workingSession
                    };
                    console.log(params);
                    await migrateJobRole(params).then((res) => {
                        if(res) {
                            console.log('Role Migrated:', res);
                            handleCompanyChange(role.companyID)
                        }
                    });
                }
                
            } catch (error) {
                console.error('Job Creation Failed:', error);
            }
        });
    }
    const prepareRolePayload = (role) => {
        const rolePayload = {
            title: role.name,
            companyID: role.companyID,
        }
        return rolePayload;
    }

    const styles = {
        accessDenied: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
            flexDirection: "column",
            fontSize: "24px",
            fontWeight: "bold",
            color: "red",
            width: "100vw"
        },
        headerText: {
            fontSize: "24px",
            fontWeight: "bold",
            padding: 24
        },
        bodyContainer: {
            padding: 24
        },
        companyDropdown: {
            marginTop: 24,
            width: 275,
        },
        selectionDropdown: {
            outline: "none",
            width: 265,
        },
        roleContainer: {
            marginTop: 24,
            display: "flex",
            justifyContent: "space-between",
            border: "1px solid #000",
            borderRadius: 24,
            padding: 16,
            height: "calc(100vh - 365px)",
        },
        tableHeader: {
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "1px solid #000",
            marginBottom: 8
        },
        roleLists: {
            display: "flex", 
            justifyContent: "space-between", 
            marginTop: 16, 
            overflow: "scroll", 
            height: "calc(100vh - 465px)"
        },
        roleItem: {
            display: "flex", 
            justifyContent: "flex-start", 
            alignItems: "center", 
            height: 32, 
            marginRight: 8
        },
        migrateButton: {
            padding: "8px 16px",
            backgroundColor: "#F66",
            color: "#fff",
            border: "none",
            borderRadius: 4,
            cursor: "pointer",
            marginTop: 24
        }

    }
    function renderHeader() {
        return (
            <div style={styles.headerText}>
                <h1>Role Migration</h1>
            </div>
        );
    }
    function renderBody() {
        return (
            <div style={styles.bodyContainer}>
                <div>Please select a company to migrate data from Role to Job Role</div>
                <div className="fullInputStyle" style={styles.companyDropdown}>
                    <select style={styles.selectionDropdown}
                        onChange={(e) => {
                            handleCompanyChange(e.target.value);
                        }}
                    >
                        <option value="">Select Company</option>
                        {companies.map((company) => (
                            <option key={company.id} value={company.id}>{company.company}</option>
                        ))}
                    </select>
                </div>
                <div style={styles.roleContainer}>
                    <div style={{width: "45%"}}>
                        <div style={styles.tableHeader}>Roles Table</div>
                        {roleLists?.length > 0 ? (
                            <div>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{fontWeight: 700}}>Role Name</div>
                                    <div style={{fontWeight: 700}}>Is Migrated</div>
                                </div>
                                <div style={styles.roleLists}>
                                    <div>
                                    {roleLists.map((role) => (
                                        <div style={{alignItems: "center", height: 32}} key={role.id}>{role.name}</div>
                                    ))}
                                    </div>
                                    <div>
                                        {roleLists.map((role) => (
                                            <div style={styles.roleItem}>
                                                {jobRoleLists?.find(jobRole => jobRole.id === role.id) ? (
                                                    <div className='check-circle-outline' style={{backgroundColor : "green", borderRadius: 5}}></div>
                                                ): (
                                                    <div className='close-gray'></div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>No Existing Roles Found</div>
                        )}
                    </div>
                    <div style={{width: "45%", height: "100%"}}>
                        <div style={styles.tableHeader}>Job Roles Table</div>
                        {jobRoleLists?.length > 0 ? (
                            <div>
                                <div div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{fontWeight: 700}}>Job Role Name</div>
                                    <div style={{fontWeight: 700}}>Migrated from Roles</div>
                                </div>
                                <div style={styles.roleLists}>
                                    <div>
                                        {jobRoleLists.map((jobRole) => (
                                        <div style={{alignItems: "center", height: 32}} key={jobRole.id}>{JSON.parse(jobRole.jobRoleData).title}</div>
                                    ))}
                                    </div>
                                    <div>
                                        {jobRoleLists.map((jobRole) => (
                                            <div style={styles.roleItem}>
                                                {roleLists?.find(role => role.id === jobRole.id) ? (
                                                    <div className='check-circle-outline' style={{backgroundColor : "green", borderRadius: 5}}></div>
                                                ): (
                                                    <div className='close-gray'></div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>No Existing Job Roles Found</div>
                        )}
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <button style={styles.migrateButton}
                        onClick={() => {
                            handleMigrateButtonClicked(roleLists);
                        }}
                    >Migrate</button>
                    <button style={styles.migrateButton}
                        onClick={() => {
                            handleJobRoleGroupUpdate(jobRoleLists);
                        }}
                    >Mass Update Role Group</button>
                </div>
            </div>
        );
    }
    
    return (
        <div>
            {isAllowedToAccess ? (
            <div>
                {renderHeader()}
                {renderBody()}
            </div>
        ) : (
            <div style={styles.accessDenied}>
                <div>Access Denied</div>
                <div>You do not have permission to access this page.</div>
            </div>
        )}
        </div>     
    );

    

}