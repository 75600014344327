import {
  Device,
  OpenVidu,
  Publisher,
  Session,
  StreamManager,
  Subscriber,
} from "openvidu-browser";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GraphQL, closeSession as closeOVSession, getToken } from "./api";
import { createNetworkQualityLog } from "../graphql/mutations";

function getOV() {
  const OV = new OpenVidu();
  OV.setAdvancedConfiguration({
    forceMediaReconnectionAfterNetworkDrop: true,
  });
  OV.enableProdMode();
  const session = OV.initSession();
  return { OV, session };
}

type OpenViduParams = {
  role?: string;
  name?: string;
  interviewId?: string;
  stage?: string;
};

export default function useOpenVidu(params: OpenViduParams) {
  const { role, name, interviewId, stage } = params;

  const [ov, setOV] = useState<OpenVidu | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [publisher, setPublisher] = useState<Publisher | null>(null);

  const [screenOV, setScreenOV] = useState<OpenVidu | null>(null);
  const [screenSession, setScreenSession] = useState<Session | null>(null);
  const [screenPublisher, setScreenPublisher] = useState<Publisher | null>(
    null
  );
  const [screenShareStream, setScreenShareStream] =
    useState<StreamManager | null>(null);
  const [isSharingScreen, setIsSharingScreen] = useState<boolean>(false);

  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);

  const [isVideoEnabled, setIsVideoEnabled] = useState<boolean>(false);
  const [videoDevices, setVideoDevices] = useState<Device[]>([]);
  const [videoDeviceId, setVideoDeviceId] = useState<string | null>(null);

  const [isAudioEnabled, setIsAudioEnabled] = useState<boolean>(false);
  const [audioDevices, setAudioDevices] = useState<Device[]>([]);
  const [audioDeviceId, setAudioDeviceId] = useState<string | null>(null);

  const [isNetworkUnstable, setIsNetworkUnstable] = useState<boolean>(false);
  const [shouldStartRecording, setShouldStartRecording] =
    useState<boolean>(false);

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isRoomFull, setIsRoomFull] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [isDisconnected, setIsDisconnected] = useState<boolean>(false);

  useEffect(() => {
    console.log("OV: SUBSCRIBERS", subscribers);
  }, [subscribers]);

  useEffect(() => {
    console.log("OV: PUBLISHER", publisher);
  }, [publisher]);

  useEffect(() => {
    if (!role || !name || !interviewId || isConnected) return;
    initOpenVidu();
  }, [role, name, interviewId, isConnected]);

  const initOpenVidu = async () => {
    const { OV, session } = getOV(); // webcam & audio
    const { OV: screenOV, session: screenSession } = getOV(); // screen share
    setOV(OV);
    setSession(session);
    setScreenOV(screenOV);
    setScreenSession(screenSession);

    const username = `${role}|${name}`;
    const sessionId = `${interviewId}-${stage}`;

    console.log("OV: USERNAME", username);
    console.log("OV: SESSION_ID", sessionId);

    // Add the stream to subscribers array
    session.on("streamCreated", async (event) => {
      if (event.stream.typeOfVideo === "CAMERA") {
        const subscriber = session.subscribe(event.stream, undefined);

        setSubscribers((prevSubs) => {
          return [...prevSubs, subscriber];
        });

        // set recording flag to true
        const streamType = subscriber.stream.connection.data;
        if (streamType.includes("interviewee") && role === "interviewer") {
          setShouldStartRecording(true);
        }
      }
    });

    // Screen share event listeners
    screenSession.on("streamCreated", (event) => {
      if (event.stream.typeOfVideo === "SCREEN") {
        const subscriber = screenSession.subscribe(event.stream, undefined);
        setScreenShareStream(subscriber);
      }
    });

    screenSession.on("signal:stopSharing", () => {
      setScreenShareStream(null);
    });

    // Remove the stream from 'subscribers' array
    session.on("streamDestroyed", (event) => {
      const { streamId } = event.stream;
      setSubscribers((prevSubs) => {
        let update = [...prevSubs];
        update = update.filter((sub) => sub.stream.streamId !== streamId);
        return update;
      });
    });

    session.on("exception", console.warn);

    // Log network quality events
    session.on("reconnecting", () =>
      logNetworkQualityEvent(
        username,
        sessionId,
        "User is reconnecting to the session."
      )
    );

    session.on("reconnected", () => {
      logNetworkQualityEvent(
        username,
        sessionId,
        "User reconnected to the session."
      );
    });

    session.on("sessionDisconnected", (event) => {
      setIsDisconnected(true);
      if (["networkDisconnect", "disconnect"].includes(event.reason)) {
        logNetworkQualityEvent(
          username,
          sessionId,
          "User disconnected from the session."
        );
      }
    });

    session.on("networkQualityLevelChanged", async (event) => {
      // this is network connection of the user
      if (event.connection.connectionId === session.connection.connectionId) {
        if (
          (event.newValue < 3 && event.oldValue >= 3) ||
          (event.newValue > 3 && event.oldValue <= 3)
        ) {
          const timestamp = new Date();
          const description = `Network quality level changed from ${event.oldValue} to ${event.newValue}`;
          const query = createNetworkQualityLog;
          const variables = {
            input: {
              timestamp,
              sessionId,
              username,
              description,
            },
          };
          try {
            await GraphQL({
              query,
              variables,
              authMode: "",
            });
            console.log("Network quality level degradation event occurred.");
          } catch (e) {
            console.error(e);
          }
        }

        if (event.newValue < 3) setIsNetworkUnstable(true);
        else setIsNetworkUnstable(false);
      }
    });

    // Get a token from the OpenVidu deployment
    const token = await getToken(interviewId, username);
    if (!token) return setIsRoomFull(true);
    const tokenScreen = await getToken(interviewId, username);
    await session.connect(token);
    await screenSession.connect(tokenScreen);
    setIsConnected(true);
  };

  const leaveSession = () => {
    console.log("OV: LEAVING SESSION");
    session?.disconnect();
    screenSession?.disconnect();
    setSession(null);
    setScreenSession(null);
    setPublisher(null);
    setScreenPublisher(null);
    setIsPublished(false);
  };

  const closeSession = async () => {
    console.log("OV: CLOSING SESSION");
    await closeOVSession(interviewId);
    setSession(null);
    setScreenSession(null);
    setPublisher(null);
    setScreenPublisher(null);
    setIsPublished(false);
  };

  const initPublisher = async () => {
    if (!ov || !session || !isConnected || isPublished) return;
    if (ov.publishers[0]) return;

    const storedVideo = localStorage.getItem("videoDeviceId");
    const storedAudio = localStorage.getItem("audioDeviceId");

    // Obtain the current video device in use
    const devices = await ov.getDevices();
    const videoDevices = devices.filter((d) => d.kind === "videoinput");
    const audioDevices = devices.filter((d) => d.kind === "audioinput");
    setVideoDevices(videoDevices);
    setAudioDevices(audioDevices);

    let currentVideoDeviceId: any;
    let currentAudioDeviceId: any;

    if (
      storedVideo &&
      videoDevices.some((device) => device.deviceId === storedVideo)
    ) {
      currentVideoDeviceId = storedVideo;
    } else {
      currentVideoDeviceId =
        videoDevices.length > 0 ? videoDevices[0].deviceId : undefined;
      if (currentVideoDeviceId) {
        localStorage.setItem("videoDeviceId", currentVideoDeviceId);
      }
      if (videoDevices.length === 0)
        toast.warning("No available video devices found.");
    }

    if (
      storedAudio &&
      audioDevices.some((device) => device.deviceId === storedAudio)
    ) {
      currentAudioDeviceId = storedAudio;
    } else {
      currentAudioDeviceId =
        audioDevices.length > 0 ? audioDevices[0].deviceId : undefined;
      if (currentAudioDeviceId) {
        localStorage.setItem("audioDeviceId", currentAudioDeviceId);
      }
      if (audioDevices.length === 0)
        toast.warning("No available audio devices found.");
    }

    const publisher = await ov.initPublisherAsync(undefined, {
      audioSource: localStorage.getItem("audioDeviceId") ?? undefined, // The source of audio. If undefined default microphone
      videoSource: localStorage.getItem("videoDeviceId") ?? undefined, // The source of video. If undefined default webcam
      publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
      publishVideo: true, // Whether you want to start publishing with your video enabled or not
      resolution: "800x450", // The resolution of your video
      frameRate: 30, // The frame rate of your video
      insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
      mirror: true, // Whether to mirror your local video or not
    });

    // --- 6) Publish your stream ---
    console.log("OV: PUBLISHING SESSION");
    session.publish(publisher);

    if (currentVideoDeviceId) setVideoDeviceId(currentVideoDeviceId);
    if (currentAudioDeviceId) setAudioDeviceId(currentAudioDeviceId);

    setPublisher(publisher);
    setIsPublished(true);

    const { publishVideo, publishAudio } = (publisher as any).properties;
    setIsAudioEnabled(publishAudio);
    setIsVideoEnabled(publishVideo);
  };

  const initScreenPublisher = async () => {
    if (!screenOV || !screenSession) return;
    const publisher = await screenOV.initPublisherAsync(undefined, {
      audioSource: undefined, // The source of audio. If undefined default microphone
      videoSource: "screen", // The source of video. If undefined default webcam
      publishAudio: false, // Whether you want to start publishing with your audio unmuted or not
      publishVideo: true, // Whether you want to start publishing with your video enabled or not
      resolution: "800x450", // The resolution of your video
      frameRate: 30, // The frame rate of your video
      insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
      mirror: false, // Whether to mirror your local video or not
    });

    publisher.once("accessAllowed", (event) => {
      publisher.stream
        .getMediaStream()
        .getVideoTracks()[0]
        .addEventListener("ended", () => {
          // unpublish the stream
          screenSession.unpublish(publisher);
          screenSession.signal({ type: "stopSharing" });
          setIsSharingScreen(false);
        });
      setIsSharingScreen(true);
      screenSession.publish(publisher);
    });

    publisher.once("accessDenied", (event) => {
      console.warn("ScreenShare: Access Denied");
      setIsSharingScreen(false);
    });

    setScreenPublisher(publisher);
    setScreenShareStream(publisher);
  };

  const toggleVideoEnabled = () => {
    if (!publisher) return;
    publisher.publishVideo(!isVideoEnabled);
    setIsVideoEnabled(!isVideoEnabled);
  };

  const toggleAudioEnabled = () => {
    if (!publisher) return;
    publisher.publishAudio(!isAudioEnabled);
    setIsAudioEnabled(!isAudioEnabled);
  };

  const changeAudioFeed = async (deviceId: string) => {
    if (!publisher) return;

    const index = audioDevices
      .map((device) => device?.deviceId)
      .indexOf(deviceId);

    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: { exact: audioDevices[index]?.deviceId } },
    });

    const newTrack = mediaStream.getAudioTracks()[0];
    publisher.replaceTrack(newTrack);
    localStorage.setItem("audioDeviceId", deviceId);
    setAudioDeviceId(deviceId);
  };

  const changeVideoFeed = async (deviceId: string) => {
    if (!publisher) return;

    const index = videoDevices
      .map((device) => device?.deviceId)
      .indexOf(deviceId);

    const currentTrack = publisher.stream.getMediaStream().getVideoTracks()[0];
    const currentSettings = currentTrack.getSettings();

    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: { exact: videoDevices[index]?.deviceId },
        width: { ideal: currentSettings.width },
        height: { ideal: currentSettings.height },
      },
    });

    const newTrack = mediaStream.getVideoTracks()[0];
    publisher.replaceTrack(newTrack);
    localStorage.setItem("videoDeviceId", deviceId);
    setVideoDeviceId(deviceId);
  };

  const toggleScreenShare = async () => {
    if (!screenShareStream) {
      initScreenPublisher();
    } else if (isSharingScreen) {
      screenSession?.unpublish(screenShareStream as Publisher);
      screenSession?.signal({ type: "stopSharing" });
      setScreenShareStream(null);
      setIsSharingScreen(false);
    } else if (screenShareStream) {
      return toast.error("Someone is currently sharing their screen.");
    }
  };

  useEffect(() => {
    if (isNetworkUnstable) {
      const toastText =
        "You appear to be having networking issues. Your interview experience may be degraded.";
      toast.warning(toastText);
      return;
    }
    // dismiss all warning toasts otherwise
    toast.dismiss();
  }, [isNetworkUnstable]);

  const openVidu = {
    ov,
    session,
    publisher,
    screenOV,
    screenSession,
    screenShareStream,
    leaveSession,
    subscribers,
    isAudioEnabled,
    isVideoEnabled,
    isNetworkUnstable,
    toggleVideoEnabled,
    toggleAudioEnabled,
    videoDevices,
    audioDevices,
    videoDeviceId,
    audioDeviceId,
    changeAudioFeed,
    changeVideoFeed,
    isSharingScreen,
    toggleScreenShare,
    shouldStartRecording,
    initPublisher,
    isConnected,
    isPublished,
    isRoomFull,
    isDisconnected,
    closeSession,
  };

  return openVidu;
}

async function logNetworkQualityEvent(
  username: string,
  sessionId: string,
  description: string
) {
  console.warn(description);
  const timestamp = new Date();
  const query = createNetworkQualityLog;
  const variables = {
    input: {
      timestamp,
      sessionId,
      username,
      description,
    },
  };
  try {
    await GraphQL({
      query,
      variables,
      authMode: "",
    });
  } catch (e) {
    console.error(e);
  }
}
