import { CSSProperties } from "react";

export const styles: Record<string, CSSProperties> = {
  // user page styles
  inputLabel: {
    width: 180,
    maxWidth: 180,
  },
  inputStyle: {
    width: 350,
    height: 40,
    border: "1px solid #555",
    borderRadius: 4,
  },
  areaInputStyle: {
    padding: 5,
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #555",
    borderRadius: 4,
  },
  radioGroup: {
    marginTop: 2,
    marginBottom: 8,
  },
  radio: {
    marginRight: 8,
  },
  select: {
    border: "1px solid #555",
    padding: "0px 10px 0px 5px",
    borderRadius: 4,
    height: 40,
    width: 350,
    marginBottom: 16,
  },
  passResetButton: {
    background: "#ddd",
    border: "none",
    color: "black",
  },
  archiveButton: {
    background: "#ddd",
    border: "none",
    color: "black",
    // borderColor: errorRed,
    // background: errorRed,
    // // color: errorRed,
  },
  footer: {
    background: "white",
    position: "sticky",
    bottom: 0,
    width: "100%",
    maxWidth: 1000,
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    height: 60,
  },

  // user table styles
  tableWrapper: {
    border: "1px solid #ccc",
    borderRadius: 10,
    overflow: "hidden",
  },
  tableContainer: {
    width: "100%",
    height: "70vh",
    overflowY: "scroll",
  },
  tableHeader: {
    position: "sticky",
    background: "white",
    top: 0,
  },
  link: {
    color: "#0000ff",
    textDecoration: "underline",
    cursor: "pointer",
  },
  statusCircle: {
    borderRadius: 20,
    height: 10,
    width: 10,
    marginRight: 6,
  },
};
