import React, { CSSProperties } from "react";
import { IconType } from "react-icons";

type VideoControlButtonProps = {
  disable?: () => void;
  state?: boolean;
  cancelText?: string;
  uncancelText?: string;
  activeIcon: IconType;
  inactiveIcon: IconType;
  canExpand?: React.JSX.Element;
};

export default function VideoControlButton(props: VideoControlButtonProps) {
  const {
    disable,
    state,
    cancelText,
    uncancelText,
    activeIcon,
    inactiveIcon,
    canExpand,
  } = props;
  const Active = activeIcon;
  const Inactive = inactiveIcon;

  return (
    <div className="hor-center" style={styles.main}>
      <div
        className="hor-center"
        style={{ cursor: "pointer" }}
        onClick={disable}
      >
        {state ? (
          <Active style={{ fontSize: 18, color: "white" }} />
        ) : (
          <Inactive style={{ fontSize: 18, color: "white" }} />
        )}
        {canExpand ?? null}
      </div>
      <div style={styles.textLabel}>{state ? cancelText : uncancelText}</div>
    </div>
  );
}

const styles: Record<string, CSSProperties> = {
  main: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  textLabel: {
    marginTop: 4,
    width: "100%",
    textAlign: "center",
    fontSize: 11,
    color: "white",
    fontWeight: "bold",
  },
};
