import * as React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { findById } from "../../general/findById";
import { AttributePageController } from "../../Attributes/Detail/AttributePageController";
import { ValueDetailsPage } from "./ValueDetailsPage";

export const ValueRoutes = ({customerId, weightedValues, questions, setState}) => {
    const { valueId } = useParams();
    const weightedValue = findById(weightedValues)(valueId)
    return  <Routes>
        <Route path="/" element={<ValueDetailsPage {...{customerId, weightedValue, setState}} />} />
        <Route path="/attributes/:attributeId" element={<AttributePageController {...{ customerId, weightedValues, weightedValue, questions, setState }} />} />
    </Routes>
}
