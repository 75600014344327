import React, {PropsWithChildren} from 'react';
import { SimpleGrid } from '@chakra-ui/react';

const CardList: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {children}
    </SimpleGrid>
  );
};

export default CardList;
