import { MouseEventHandler } from "react";
import { toast } from "react-toastify";
import { mlAttributeScorePayloadFromValueDefs } from "../ml-client/mlAttributeScorePayloadFromValueDefs";
import { getMLScores } from "../state/exampleData/getMLScores";
import { Stage } from "../state/stages/Stage";
import { ValuesDefintition } from "../state/values/ValuesDefintition";
import { VantasoftStageName } from "../state/vantasoft/vantasoftState";
import { SetInterviewAnalyticsState } from "./SetInterviewAnalyticsState";

export const onReloadClick =
  (candidateId: string) =>
  (currentStage: Stage) =>
  (valuesRelatedToStage: ValuesDefintition) =>
  (setIsReloading: any) =>
  (
    setState: SetInterviewAnalyticsState
  ): MouseEventHandler<HTMLButtonElement> =>
  async (e) => {
    e.stopPropagation();
    try {
      setIsReloading(true);
      const mlScores = await getMLScores(candidateId)(
        currentStage.name as VantasoftStageName
      )(valuesRelatedToStage)(
        mlAttributeScorePayloadFromValueDefs(valuesRelatedToStage)
      );

      setIsReloading(false);
      console.log({ mlScores });
      setState((state) => ({
        ...state,
        stages: state.stages.map((stage) =>
          stage.name === currentStage.name
            ? {
                ...stage,
                mlScores,
              }
            : stage
        ),
      }));
    } catch (e: any) {
      setIsReloading(false);
      console.error(e);
      const errorMessageToDisplay = e.response?.data || e.message;
      toast.error(errorMessageToDisplay);
    }
  };
