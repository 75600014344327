import { Button, Center, Container, Flex, HStack, Heading, Spacer, Text, Tooltip } from "@chakra-ui/react";
import * as React from "react";
import { AiFillPlusCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { isEmpty } from "../../general/isEmpty";
import { EmptyAttributes } from "./EmptyAttributes";
import { WeightedAttributeTable } from "./WeightedAttributeTable";
import { AttributeLibraryModal } from "../Library/AttributeLibraryModal";
import { TextWithInfoTooltip } from "../../general/TextWithInfoTooltip";
import { AttributeTooltipContent } from "./AttributeTooltipContent";

export const WeightedAttributeTableWithAddButtons = ({ customerId, valueId, weightedAttributes, setState }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    return <Container maxWidth="100%">
        <Flex width="100%" mb={2}>
            <Center gap={2}>
                <TextWithInfoTooltip 
                    content={<Heading size="md">Measurable Attributes</Heading>} 
                    tooltipLabel={<AttributeTooltipContent />} 
                />
            </Center>
            <Spacer />
            <Tooltip placement="top" label={<Text>Add a <i>measurable attribute</i> from our curated library or create your own</Text>}>
                <Button onClick={() => setIsOpen(true)}>
                    <HStack gap={1}>
                        <AiFillPlusCircle />
                        <Text>Attribute</Text>
                    </HStack>
                </Button>
            </Tooltip>
        </Flex>
        <AttributeLibraryModal {...{ setState, valueId, isOpen, setIsOpen, weightedAttributes }} />
        {isEmpty(weightedAttributes)
            ? <EmptyAttributes />
            : <WeightedAttributeTable {...{ customerId, valueId, weightedAttributes, setState }} />}
    </Container>;
};
