import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

export async function checkRoles() {
  const user = await Auth.currentAuthenticatedUser();
  const groups = user.signInUserSession.idToken.payload["cognito:groups"];
  return groups;
}

export async function getCognitoUsername() {
  const { username } = await Auth.currentAuthenticatedUser();
  return username;
}

export async function signUp(payload) {
  // username is the same as email
  const { username, password } = payload;
  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email: username,
      },
    });
    return user;
  } catch (error) {
    console.log("error from signUp", error);
    return error;
  }
}

export async function signIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    console.log("sign in user", user);
  } catch (e) {
    console.log("error signing in", e);
    toast.error("Unable to sign in");
    throw e;
  }
}

export async function signOut() {
  try {
    await Auth.signOut();
    localStorage.clear();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}
