import { Box } from "@chakra-ui/react";
import * as React from "react";
import { InterviewBuilderRoutes } from "./InterviewBuilderRoutes";

export const InterviewBuilder = 
    ({ updatedAt, state, setState, customerId }) => (
    <Box padding="30px" backgroundColor="rgb(250,250,250)">
        <InterviewBuilderRoutes {...{updatedAt, customerId, state, setState}} />
    </Box>
);
