import { Spacer } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingScreen } from "../../Components";
import { usePageTitle } from "../../navigation/usePageTitle";
import { getCompanyInterviewBuilderData } from "../../utils/api";
import getInterviewUrls from "../../utils/getInterviewUrls";
import Audio from "./Audio";
import { InterviewAnalyticsV2 } from "./chart/InterviewAnalyticsV2";
import "./index.css";
import { populateInterviewData } from "./populateInterviewData";
import { sampleObservationData } from "./sampleObservationData";
import { stateForChartFromInterviewRecord } from "./state/vantasoft/stateForChartFromInterviewRecord";
import useGroups from "../../utils/useGroups";
import { valuesRelatedToStage } from "./chart/valuesRelatedToStage";
import QuestionCard from "./QuestionCard";
import { questionsReferredToFromWeightedValues } from "./questionsReferredToFromWeightedValues";
import { Helmet } from "react-helmet";
import { uniqBy } from "lodash";
import { isNotUndefined } from "./isNotUndefined";
import ConclusionCard from "./ConclusionCard";

function getFirstLine(input) { // looking forward to removing all this nonsense
  // Remove all characters starting with the first newline
  let result = input.split('\n')[0].trim();
  
  // Remove any square-bracket expressions at the end of the string
  result = result.replace(/\[\d+\]$/, '').trim();
  
  // If the resulting string contains a colon, remove all characters before the colon, the colon itself, and any whitespace following the colon
  //if (result.includes(':')) {
  //    result = result.split(':')[1].trim();
  //}
  return result;
}

export default function Summary() {
  usePageTitle("Summary");
  const audioRef = useRef();
  const [currentStageName, setCurrentStageName] = useState(null);
  const [elapsed, setElapsed] = useState(0);
  const [paused, setPaused] = useState(true);
  const [audioSrc, setAudioSrc] = useState(null);
  const [audioURLs, setAudioURLs] = useState({});
  const { candidateId } = useParams();
  const [stateForChart, setStateForChart] = useState();
  const selectedStage = currentStageName;
  const groups = useGroups();
  const { people, valuesDefinition, stages } = stateForChart ?? {};
  const { questions } = valuesDefinition ?? {};
  const currentStage = stages?.find((s) => s.name === currentStageName);
  const [interviewData, setInterviewData] = useState(null);

  useEffect(() => {
    async function loadCompanyInfo() {
      const { interviewBuilderData } = await getCompanyInterviewBuilderData(
        interviewData.companyID,
      );
      const valuesDefinition = JSON.parse(interviewBuilderData);
      const stateForChart =
        stateForChartFromInterviewRecord(candidateId)(valuesDefinition)(
          interviewData,
        );
      
      setStateForChart(stateForChart);
      const mostRecentStage = [...stateForChart.stages]
        .reverse()
        .find((stage) => {
          
          const meetingEvents = stage.meetings.flatMap((meeting) => meeting.meetingEvents);
          if (meetingEvents.length > 0) return true;
          return false
        }) ?? stateForChart.stages[0];
      
      setCurrentStageName(mostRecentStage.name);
    }
    if (interviewData) loadCompanyInfo();
  }, [interviewData, candidateId]);

  useEffect(() => {
    async function getAudioURLs() {
      const res = await getInterviewUrls(candidateId);
      setAudioURLs(res);
    }
    getAudioURLs();
  }, [candidateId]);

  useEffect(() => {
    populateInterviewData(candidateId, setInterviewData);
  }, [candidateId]);

  if (!interviewData || !stateForChart) {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <LoadingScreen />
      </div>
    );
  }

  const { interviewInfo } = interviewData;

  // TODO Review this later
  // if (!!interviewInfo && !interviewInfo.review)
  //   return (
  //     <div style={{ width: "100%", height: "100%", margin: "2em" }}>
  //       Not enough data to show summary for candidate
  //     </div>
  //   );

  const dimensions = valuesRelatedToStage(valuesDefinition)(currentStage);

  const matchingQuestions = questionsReferredToFromWeightedValues(
    dimensions,
    questions,
  );
  return (
    <>
      <Helmet>
        <style>{"html, body { background-color: #E5E7EB; }"}</style>
      </Helmet>
      <div className="w-full bg-background p-8" style={{maxHeight: "100vh", overflowY: "auto", 
        paddingLeft: "8px", paddingRight: "8px", paddingTop: "4px", paddingBottom: "72px"
      }}>
        <InterviewAnalyticsV2
          interviewData={interviewInfo}
          {...{
            currentStageName,
            stateForChart,
            setCurrentStageName,
            setStateForChart,
          }}
        />
        <div className="shadow rounded-[24px] bg-white overflow-auto">
          <Spacer height="1em" />
          <Audio
            {...{
              audioURLs,
              elapsed,
              setElapsed,
              selectedStage,
              audioSrc,
              setAudioSrc,
              paused,
              setPaused,
              audioRef,
            }}
            data={sampleObservationData}
            showTicks
          /> */}
          <Spacer height="1em" />
          {currentStage && (
            <div className="bg-background-lighter rounded-[24px] p-4" >
              {matchingQuestions?.map((question, index, questions) => {
                const questionResponse =
                  interviewData.questionResponses.items.filter(i => i.stage === currentStageName).find(
                    (response) => {
                      const firstLine = getFirstLine(question.text)
                      return (
                        response.question.startsWith(firstLine)
                      );
                    },
                  );      
                if (!questionResponse) return null;
                return (
                  <QuestionCard
                    key={question.id}
                    question={question}
                    questionIndex={index}
                    questionResponse={questionResponse}
                    meetings={currentStage.meetings}
                    people={people}
                    mlScores={currentStage.mlScores}
                    stageLevelAssessments={currentStage.stageLevelAssessments}
                    dimensions={dimensions}
                    isLastItem={questions.length - 1 === index}
                  />
                );
              })}
              <div className="mt-4" >
                        <ConclusionCard
                          key={interviewInfo.candidateName}
                          candidateName = {interviewInfo.candidateName} 
                          meetings={currentStage.meetings}
                          people={people}
                          stageLevelAssessments={currentStage.stageLevelAssessments}
                        />
                        </div>
            </div>
                        
          )}
        </div>
      </div>
    </>
  );
}
