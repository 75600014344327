import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { FiUser } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUser, getCompany, listCompanies } from "../utils/api";
import { getCognitoUsername, signOut } from "../utils/auth";
import useGroups from "../utils/useGroups";

import logo from "../images/intertru.png";
import { themeOrange } from "../theme";
import classNames from "classnames";

import AWS from 'aws-sdk';
import AppConfig from 'aws-sdk/clients/appconfig';
import { v4 as uuidv4 } from 'uuid';

export default function Header(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const groups = useGroups();
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);

  const paths = {
    dashboard: "Interviews",
    analytics: "Analytics",
    customers: "Customers",
    users: "Users",
    customer: "Customers",
    user: "Users",
    interview: "Interviews",
    summary: "Interviews",
    jobroles: "JobRoles",
    jobrole: "JobRole",
  };

  const path = window.location.pathname;
  // const p = path.substring(7, path.length - 1);
  const p = path.split("/").slice(-1)[0];

  // lg breakpoint
  const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

  let isAdmin = false;
  if (groups && groups.includes("Admin")) isAdmin = true;

  useEffect(() => {

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });
    const getConfig = async () => {
      try {
        await Auth.currentAuthenticatedUser().then(async (user) => {
            let userEmail = user.attributes.email;

            let appConfig = new AppConfig();
    
            const params = {
                Application: process.env.REACT_APP_APICONFIG_APPID,
                Environment: process.env.REACT_APP_APICONFIG_ENVID,
                Configuration: process.env.REACT_APP_APICONFIG_CONFIGPROFILEID,
                ClientId: uuidv4(),
            };
    
            try {
              let data = await appConfig.getConfiguration(params).promise();
              let configContent = data.Content.toString('utf-8');
              let config = JSON.parse(configContent);
              console.log('Config:', config )
              if (config.featureManagement.jobRoleFeature.allowedUsers.length === 0) {
                setIsFeatureEnabled(true)
              }
              else {
                if (config.featureManagement.jobRoleFeature.enabled && config.featureManagement.jobRoleFeature.allowedUsers.includes(userEmail)) {
                  setIsFeatureEnabled(true);
                  
                }
                else {
                  setIsFeatureEnabled(false)
                }
              }
              
            } catch (error) {
                console.error('Error fetching configuration:', error);
            }
        });
        
        
      } catch (error) {
        console.error('Error fetching configuration:', error);
      }
    };

    getConfig();
  }, []);

  return (
    <>
      <div style={styles.headerWrapper}>
        <div className="rounded-b-[24px] bg-top bg-cover p-[8px]">
          <div className="flex justify-between items-center" style={{marginTop: 5}}>
            <div
              style={styles.logo}
              onClick={() => navigate("/admin/dashboard")}
            >
              <img style={styles.logoImage} src={logo} alt="intertru-text" />
            </div>
            <div className="mask-group"></div>
            <div style={{position: "absolute", marginLeft: 160, display: "flex", justifyContent: "space-between"}}>
              <HeaderLink
                navigate={navigate}
                link="/admin/dashboard"
                text="Interviews"
                selected={paths[p] === "Interviews"}
              />
              {isFeatureEnabled && (
                <HeaderLink
                link="/admin/jobroles"
                text="Roles"
                selected={paths[p] === "JobRoles" || paths[p] === "JobRole"}
              />
              )}
               
              <HeaderLink
                link="/admin/users"
                text="Users"
                selected={paths[p] === "Users"}
              />
              {/*<HeaderLink*/}
              {/*  navigate={navigate}*/}
              {/*  link="/admin/roles"*/}
              {/*  text="Roles"*/}
              {/*  selected={paths[p] === "Roles"}*/}
              {/*/>*/}
              {/*<HeaderLink*/}
              {/*  navigate={navigate}*/}
              {/*  link="/admin/analytics"*/}
              {/*  text="Analytics"*/}
              {/*  selected={paths[p] === "Analytics"}*/}
              {/*/>*/}
              {isAdmin && (
                <HeaderLink
                  navigate={navigate}
                  link="/admin/customers"
                  text="Customers"
                  selected={paths[p] === "Customers"}
                />
              )}
            </div>
            <div style={{display: "flex", justifyContent:"space-between"}}>
              <CompanyDropdown 
                isFeatureEnabled={isFeatureEnabled}
                navigate={navigate}
                isAdmin={isAdmin}
                groups={groups}
              />
              {location.pathname !== "/interviews" && (
              <LogOut
               isFeatureEnabled={isFeatureEnabled}
               navigate={navigate} />
              )}
            </div>
            
            
          </div>
        </div>
      </div>
      {location.pathname === "/admin/jobroles" || location.pathname === "/admin/jobrole" ? (
        <div/>
      ) : ( <div/>
        )}
    </>
  );
}

function CompanyDropdown(props) {
  const { isFeatureEnabled, navigate, groups, isAdmin } = props;
  const [user, setUser] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [companyLists, setCompanyLists] = useState([]);
  const [permission, setPermission] = useState(null);
  const [dropDownSelectionVisible, setDropDownSelectionVisible] = useState(false);

  useEffect(() => {
    async function getPermissions() {
      const authed = await Auth.currentAuthenticatedUser();
      const userId = await getCognitoUsername();
      const name = authed?.signInUserSession?.idToken?.payload?.name;
      const user = await getUser(userId); //fails if Intertru admin
      if (!!user) {
        setUser(user);
        if (user?.role) setPermission(user?.role);
      }
    }
    getPermissions();
  }, []);

  useEffect(() => {
    async function setCompanyDefault() {
     if (isAdmin) {
      try{
        await listCompanies().then((companies) => {
          const superAdmin = {company: "Super Admin"};
          const companyCollectionsDefault = [...companies.items, superAdmin];
          setCompanyLists(companyCollectionsDefault);
          setCurrentCompany(superAdmin);
        });
      }
      catch (error) {
        console.error('Error fetching company:', error);
      }
       
    }
    else {
      try{
        await getCompany(groups[0]).then((company) => {
          setCurrentCompany(company);
        });
      }
      catch (error) {
        console.error('Error fetching company:', error);
      }
    
    }
    }
    setCompanyDefault();
  }, [isAdmin, groups]);

  const companyOptions = [
    {
      label: "Profile",
      onClick: () => {
        // props.navigate("/");
      },
    },
    {
      label: "Roles",
      onClick: () => {
        props.navigate(`/admin/jobroles`);
      },
      hidden: !isFeatureEnabled,
    },
    {
      label: "Users",
      onClick: () => {
        props.navigate("/admin/users");
      },
      hidden:
      (user?.id && permission === "interviewer")
    },
    // {
    //   label: "Billing",
    //   onClick: () => {
        
    //   },
    //   hidden:
    //   !(user?.id && permission === "owner")
    // },
    {
      label: "Customers",
      onClick: () => {
        props.navigate("/admin/customers");
      },
      hidden:
        !groups?.includes("Admin"),
    },
    {
      label: "Switch Company",
      onClick: () => {
        // props.navigate("/admin/customers");
      },
      hidden:
        !groups?.includes("Admin"),
    },

  ];

  return(
    <button style={styles.companyName}
      onClick={() => setDropDownSelectionVisible(!dropDownSelectionVisible)}
    >
      <div>{currentCompany?.company}</div>
      <div className="expand-more"></div>
      {dropDownSelectionVisible && (
          <div
            style={styles.settingOverlay}
            onClick={(e) => {
              e.preventDefault();
              setDropDownSelectionVisible(false);
            }}
          >
            <div style={styles.companyModal}>
              {companyOptions.map((option, i) => {
                const { label, onClick, hidden } = option;
                let optionStyle = label === "Profile" || label === "Billing" || label === "Switch Company" ? { ...styles.settingOptionDisabled } : { ...styles.settingOption };
                if (i !== companyOptions.length - 1 && label !== "Billing")
                  optionStyle.borderBottom = "1px solid #f3f3f3";
                if (!hidden)
                  return (
                    <p key={label} style={optionStyle} onClick={onClick}>
                      {label}
                    </p>
                  );
                return null;
              })}
            </div>
          </div>
        )}
    </button>
  )
}

function HeaderLink(props) {
  const { navigate, link, text, selected = false } = props;
  return (
    <div style={{width: 116, alignItems: "center", textAlign: "center"}}>
    <Link style={{fontSize: 16}}
      className={classNames(
        "px-4 py-2 rounded-full text-white text-base font-['Montserrat'] leading-normal",
        {
          "bg-transparent font-bold": selected,
          "bg-transparent font-medium": !selected,
        },
      )}
      to={link}
    >
      {text}
    </Link>
    <div className={classNames(
        {
          "indicator-selected": selected,
          "": !selected,
        },
      )}
    />
    </div>
  );
}

function LogOut(props) {
  const { isFeatureEnabled } = props;
  const groups = useGroups();
  const [modalVisible, setModalVisible] = useState(false);

  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    async function getPermissions() {
      let nameDisplay = "";
      const authed = await Auth.currentAuthenticatedUser();
      const userId = await getCognitoUsername();
      const name = authed?.signInUserSession?.idToken?.payload?.name;
      nameDisplay = name || `${authed?.attributes?.given_name} ${authed?.attributes?.family_name}`;
      const user = await getUser(userId); //fails if Intertru admin
      if (!!user) {
        setUser(user);
        nameDisplay = `${user?.firstName} ${user?.lastName}`;
      }
      setDisplayName(nameDisplay);
    }
    getPermissions();
  }, []);

  
  const userOptions = [
    {
      label: "User Info",
      onClick: () => {
        props.navigate(`/admin/user?uid=${user?.id}`);
      },
      hidden: groups?.includes("Admin"),
    },
    {
      label: "Log Out",
      onClick: async () => {
        signOut();
        props.navigate("/");
      },
    },
  ];

  if (groups && displayName?.length > 0)
    return (
      <div className="text-white" style={{ fontSize: 24 }}>
        <div
          className="hor-center"
          onClick={() => setModalVisible(!modalVisible)}
        >
          <p style={styles.firstName}>{displayName}</p>
          <div className="expand-more">
          </div>
          {/* <div style={styles.notificationHolder}>
              <div className="notification-icon"></div>
          </div> */}
        </div>
        {modalVisible && (
          <div
            style={styles.settingOverlay}
            onClick={(e) => {
              e.preventDefault();
              setModalVisible(false);
            }}
          >
            <div style={styles.settingsModal}>
              {userOptions.map((option, i) => {
                const { label, onClick, hidden } = option;
                let optionStyle = { ...styles.companyOption };
                if (i !== userOptions.length - 1)
                  optionStyle.borderBottom = "1px solid #f3f3f3";
                if (!hidden)
                  return (
                    <p key={label} style={optionStyle} onClick={onClick}>
                      {label}
                    </p>
                  );
                return null;
              })}
            </div>
          </div>
        )}
      </div>
    );
}

let insetHeight = 31;

const styles = {
  headerWrapper: {
    padding: "0 8px",
    marginLeft: 24,
    marginRight: 24,
    borderRadius: "0px 0px 24px 24px",
    background: "linear-gradient(90deg, #090E34 0%, #0B76B7 100%)",
    height: 56,
  },
  inset: {
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    cursor: "pointer",
  },
  logoImage: {
    height: insetHeight,
    maxHeight: insetHeight,
    padding: 0,
  },
  link: {
    paddingLeft: 24,
    paddingRight: 24,
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    cursor: "pointer",
    transition: 0.2,
  },
  backWrapper: {
    position: "absolute",
    left: 0,
    display: "flex",
    alignItems: "center",
  },
  backIcon: {
    marginRight: 2,
    fontSize: 24,
    transition: ".2s",
  },
  barsIcon: {
    position: "absolute",
    left: 0,
    color: "#777",
    fontSize: 28,
    transition: ".2s",
  },
  appLogo: {
    maxHeight: insetHeight,
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: 10,
  },
  settingsModal: {
    position: "absolute",
    top: "6.5%",
    right: 40,
    width: 200,
    background: "#FFF",
    zIndex: 5,
    borderRadius: 28,
    padding: 22,
    color: "#0076BA",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)"
  },
  companyModal: {
    position: "absolute",
    top: "6.5%",
    right: 220,
    width: 202,
    textAlign: "left",
    zIndex: 5,
    borderRadius: 28,
    background: "#FFF",
    padding: 16,
    color: "#0076BA",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)"
  },
  companyOption: {
    zIndex: 20,
    fontSize: 16,
    textAlign: "left",
    fontWeight: "400",
    color: "#0076BA",
    padding: "10px 14px",
    cursor: "pointer",
  },
  settingOption: {
    zIndex: 20,
    fontSize: 16,
    fontWeight: "400",
    color: "#0076BA",
    padding: "10px 14px",
    cursor: "pointer",
  },
  settingOptionDisabled: {
    zIndex: 20,
    fontSize: 16,
    fontWeight: "400",
    color: "gray",
    padding: "10px 14px",
    cursor: "not-allowed",
  },
  settingOverlay: {
    height: "100vh",
    width: "100vw",
    position: "absolute",
    left: 0,
    top: 0,
  },
  firstName: {
    fontSize: 16,
    marginLeft: 12,
  },
  iconCircle: {
    background: "#0076ba",
    padding: 4,
    borderRadius: 1000,
  },
  adminLabel: {
    marginLeft: 8,
    background: themeOrange,
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
    padding: "0px 4px",
    borderRadius: 4,
  },
  companyName: {
    justifyContent: "space-between",
    display: "flex",
    color:" var(--White-White, #FFF)",
    textAlign: "right",

    /* Body Medium/Regular */
    fontFamily: "Montserrat",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    alignItems: "center",
  },
  notificationHolder: {
    display: "flex",
    width: 40,
    height: 40,
    padding: 8,
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: 200,
    background: "#090E34",
    marginLeft: 8,
  },
};
