import { Box, HStack, Text, Tooltip } from "@chakra-ui/react";
import * as React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

export const TextWithInfoTooltip = ({ tooltipLabel, content }) => <Tooltip placement="top" label={tooltipLabel}>
    <HStack>
        {
            typeof(content) === 'string'
                ? <Text>{content}</Text>
                : content
        }
        <Box>
            <AiOutlineInfoCircle />
        </Box>
    </HStack>
</Tooltip>;
