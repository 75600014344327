import moment from "moment-timezone";
import { isNotUndefined } from "../../isNotUndefined";
import { Meetings } from "../meetings/Meetings";
import { both } from "./both";
import { isNotEmptyString } from "./isNotEmptyString";

export const latestEnd = 
    (meetings: Meetings) => 
    {
        const consideredMeetings = meetings
            .map(m => m.scheduledEndDateTime)
            .filter(both(isNotUndefined, isNotEmptyString))
            .map(dts => moment.utc(dts))

        return consideredMeetings.length === 0
            ? undefined
            : moment.max(consideredMeetings)
    }