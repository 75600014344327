import { Button, HStack, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import * as React from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { none } from "../general/none";
import { notEmptyOrUndefined } from "../general/notEmptyOrUndefined";
import { onAddQuestionClick } from "./onAddQuestionClick";
import { ExistingQuestionTable } from "./ExistingQuestionTable";
import { SuggestedQuestionsSection } from "./SuggestedQuestionsSection";
import { newQuestion } from "./newQuestion";

export const QuestionModal = ({ suggestions, setSuggestions, questions, weightedValues, attributeId, attributeTitle, setState, valueId, isOpen, setIsOpen, existingQuestions }) => {
    const importableQuestions = existingQuestions.filter(
        ({title, id, text}) => 
        none(question => question.id == id)(questions) && notEmptyOrUndefined(title) && notEmptyOrUndefined(text)
    )
    return <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="full">
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Add Question</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text>Add a new or existing question to the <i>{attributeTitle}</i> attribute</Text>
                <Heading size="md" mt={5}>New Blank Question</Heading>
                <Text>Enter a new question that should elicit responses relevant to the attribute being defined</Text>
                <Button onClick={onAddQuestionClick(setIsOpen)(setState)(valueId)(attributeId)(newQuestion())}>
                    <HStack>
                        <AiFillPlusCircle />
                        <Text>New Blank Question</Text>
                    </HStack>
                </Button>
                <Heading size="md" mt={5}>Reuse Existing Question</Heading>
                <Text>Use one of the following questions that are already associated with other Attributes but which are relevant to the current attribute</Text>
                <ExistingQuestionTable {...{attributeTitle, importableQuestions, setIsOpen, weightedValues, valueId, attributeId, setState}} />
                <SuggestedQuestionsSection {...{ questions, suggestions, setSuggestions, setIsOpen, setState, valueId, attributeId}} />
            </ModalBody>
        </ModalContent>
    </Modal>;
};
