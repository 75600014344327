import React from "react";

export const WarningIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="white" />
      <path
        d="M16.2251 4.72946C16.1014 4.50778 15.923 4.3236 15.708 4.19552C15.4929 4.06744 15.2489 4 15.0004 4C14.7519 4 14.5078 4.06744 14.2927 4.19552C14.0777 4.3236 13.8993 4.50778 13.7756 4.72946L5.20584 19.7284C4.63469 20.7286 5.31957 22 6.43062 22H23.5688C24.6799 22 25.366 20.7273 24.7936 19.7284L16.2251 4.72946ZM14.9979 9.14416C15.6665 9.14416 16.1902 9.7381 16.1227 10.4233L15.6852 14.9319C15.6705 15.109 15.5918 15.274 15.4645 15.3942C15.3372 15.5144 15.1707 15.5811 14.9979 15.5811C14.8251 15.5811 14.6585 15.5144 14.5312 15.3942C14.404 15.274 14.3252 15.109 14.3105 14.9319L13.8731 10.4233C13.8574 10.2616 13.8747 10.0984 13.9241 9.94401C13.9735 9.78966 14.0537 9.64765 14.1597 9.52715C14.2657 9.40664 14.395 9.3103 14.5393 9.24434C14.6837 9.17838 14.8399 9.14425 14.9979 9.14416ZM15.0004 16.8577C15.3318 16.8577 15.6497 16.9931 15.8841 17.2342C16.1185 17.4753 16.2501 17.8023 16.2501 18.1432C16.2501 18.4842 16.1185 18.8112 15.8841 19.0523C15.6497 19.2934 15.3318 19.4288 15.0004 19.4288C14.6689 19.4288 14.351 19.2934 14.1166 19.0523C13.8823 18.8112 13.7506 18.4842 13.7506 18.1432C13.7506 17.8023 13.8823 17.4753 14.1166 17.2342C14.351 16.9931 14.6689 16.8577 15.0004 16.8577Z"
        fill="#E44B3C" />
    </svg>

  );
}
