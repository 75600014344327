
export const CITIZENSHIPSANDVISA = [
    { value: 'norequirements', label: 'No Requirements' },
    { value: 'workauthorizationvisa', label: 'Work Authorization Visa' },
    { value: 'citizenshiprequired', label: 'Citizenship Required' },
];

export const WOKRLOCATION = [
    { value: 'remote', label: 'Remote' },
    { value: 'hybrid', label: 'Hybrid' },
    { value: 'office', label: 'Office' },
];

export const HIREOSPROFILE = [
    { value: 'builder', label: 'Builder' },
    { value: 'improver', label: 'Improver' },
    { value: 'maintainer', label: 'Maintainer' },
];

export const WORKLIFEBALANCE = [
    { value: 'workcentric', label: 'Work-Centric' },
    { value: 'lifecentric', label: 'Life-Centric' },
];

export const ROLETYPE = [
    { value: 'manager', label: 'Manager' },
    { value: 'individualcontributor', label: 'Individual Contributor' },
    { value: 'hybrid', label: 'Hybrid' },
];

export const ROLEIMPACT = [
    { value: 'veryhigh', label: 'Very High' },
    { value: 'high', label: 'High' },
    { value: 'moderate', label: 'Moderate' },
];

export const SKILLS = [
    { name: "Access Points" },
    { name: "Active Directory" },
    { name: "Active Listening" },
    { name: "Adobe XD" },
    { name: "Agile Methodologies" },
    { name: "Amazon Web Services (AWS)" },
    { name: "Analytical Thinking" },
    { name: "Android" },
    { name: "Android Development" },
    { name: "Angular" },
    { name: "Arch Linux" },
    { name: "Artistic Expression" },
    { name: "Azure Active Directory" },
    { name: "Azure App Service" },
    { name: "Azure DevOps" },
    { name: "Azure Functions" },
    { name: "Azure Kubernetes Service (AKS)" },
    { name: "Azure Monitor" },
    { name: "Azure SQL Database" },
    { name: "Back-end Development" },
    { name: "Big Data" },
    { name: "BigQuery" },
    { name: "Blob Storage" },
    { name: "Brainstorming" },
    { name: "Budgeting" },
    { name: "Business Writing" },
    { name: "C#" },
    { name: "C++" },
    { name: "CentOS" },
    { name: "Change Management" },
    { name: "Classification" },
    { name: "Cloud Firestore" },
    { name: "Cloud Functions" },
    { name: "Cloud Pub/Sub" },
    { name: "Cloud Storage" },
    { name: "CloudFormation" },
    { name: "CloudWatch" },
    { name: "Clustering" },
    { name: "Coaching and Mentoring" },
    { name: "Collaboration" },
    { name: "Communication" },
    { name: "Compassion" },
    { name: "Complaint Handling" },
    { name: "Compute Engine" },
    { name: "Computer Assembly/Repair" },
    { name: "Conflict Resolution" },
    { name: "Cosmos DB" },
    { name: "Creative Thinking" },
    { name: "Critical Thinking" },
    { name: "Cross-functional Teams" },
    { name: "CSS" },
    { name: "Customer Relationship Management (CRM)" },
    { name: "Customer Satisfaction" },
    { name: "Data Engineering" },
    { name: "Data Mining" },
    { name: "Data Visualization" },
    { name: "Database Management" },
    { name: "Debian" },
    { name: "Decision-making" },
    { name: "Deep Learning" },
    { name: "Delegation" },
    { name: "Design Thinking" },
    { name: "DHCP" },
    { name: "Dimensionality Reduction" },
    { name: "Diplomacy" },
    { name: "Django" },
    { name: "DNS" },
    { name: "DynamoDB" },
    { name: "EC2" },
    { name: "ECS" },
    { name: "EKS" },
    { name: "Empathy" },
    { name: "Experimentation" },
    { name: "Exploratory Data Analysis (EDA)" },
    { name: "Express.js" },
    { name: "Fedora" },
    { name: "Figma" },
    { name: "Firebase" },
    { name: "Firewall Management" },
    { name: "Flask" },
    { name: "Front-end Development" },
    { name: "Full-stack Development" },
    { name: "Go" },
    { name: "Google Cloud Platform (GCP)" },
    { name: "Group Dynamics" },
    { name: "Group Policy" },
    { name: "Hardware Maintenance" },
    { name: "HTML" },
    { name: "HTML/CSS" },
    { name: "IAM" },
    { name: "Ideation" },
    { name: "Identity and Access Management (IAM)" },
    { name: "Information Architecture" },
    { name: "Innovation" },
    { name: "Inspirational Leadership" },
    { name: "Intrusion Detection Systems (IDS)" },
    { name: "iOS" },
    { name: "iOS Development" },
    { name: "Java" },
    { name: "JavaScript" },
    { name: "Kanban" },
    { name: "Kinesis" },
    { name: "Kotlin" },
    { name: "Kubernetes Engine" },
    { name: "Lambda" },
    { name: "Leadership" },
    { name: "Linux" },
    { name: "Logical Reasoning" },
    { name: "Machine Learning" },
    { name: "macOS" },
    { name: "MATLAB" },
    { name: "Mesh Networking" },
    { name: "Microsoft Azure" },
    { name: "Mobile Development" },
    { name: "Natural Language Processing (NLP)" },
    { name: "Negotiation Skills" },
    { name: "Network Administration" },
    { name: "Network Security" },
    { name: "Networking Skills" },
    { name: "Node.js" },
    { name: "Objective-C" },
    { name: "Out-of-the-box Thinking" },
    { name: "Penetration Testing" },
    { name: "Performance Management" },
    { name: "Peripheral Configuration" },
    { name: "Persuasion Skills" },
    { name: "PHP" },
    { name: "Predictive Analytics" },
    { name: "Presentation Skills" },
    { name: "Problem-solving" },
    { name: "Product Knowledge" },
    { name: "Project Management" },
    { name: "Project Planning" },
    { name: "Prototyping" },
    { name: "Public Speaking" },
    { name: "Python" },
    { name: "R" },
    { name: "RDS" },
    { name: "React.js" },
    { name: "Red Hat Enterprise Linux (RHEL)" },
    { name: "Regression Analysis" },
    { name: "Relationship Building" },
    { name: "Relationship Management" },
    { name: "Resource Allocation" },
    { name: "Responsive Design" },
    { name: "Risk Management" },
    { name: "Risk-taking" },
    { name: "Root Cause Analysis" },
    { name: "Routing and Switching" },
    { name: "Ruby" },
    { name: "Rust" },
    { name: "S3" },
    { name: "Scope Management" },
    { name: "Scrum" },
    { name: "Security Compliance" },
    { name: "Self-awareness" },
    { name: "Self-regulation" },
    { name: "Signal Interference" },
    { name: "Site Surveys" },
    { name: "Sketch" },
    { name: "SNMP" },
    { name: "SNS" },
    { name: "Social Awareness" },
    { name: "Software Installation" },
    { name: "SQL" },
    { name: "SQS" },
    { name: "Stackdriver" },
    { name: "Stakeholder Management" },
    { name: "Statistical Analysis" },
    { name: "Strategic Planning" },
    { name: "Stress Management" },
    { name: "Swift" },
    { name: "Systems Thinking" },
    { name: "Team Building" },
    { name: "Team Leadership" },
    { name: "Technical Writing" },
    { name: "Text Mining" },
    { name: "Time Management" },
    { name: "Time Series Analysis" },
    { name: "Troubleshooting" },
    { name: "Trust Building" },
    { name: "TypeScript" },
    { name: "Ubuntu" },
    { name: "UI/UX Design" },
    { name: "Upselling/Cross-selling" },
    { name: "User Testing" },
    { name: "Verbal Communication" },
    { name: "Virtual Machines" },
    { name: "Virtual Private Networks (VPNs)" },
    { name: "Visionary Leadership" },
    { name: "VLAN Configuration" },
    { name: "Vue.js" },
    { name: "Waterfall Methodologies" },
    { name: "Wi-Fi Standards" },
    { name: "Windows" },
    { name: "Windows 10" },
    { name: "Windows Server" },
    { name: "Wireframing" },
    { name: "Wireless Networking" },
    { name: "Wireless Security" },
    { name: "Written Communication" }
  ];
  