import React from "react";
import { Radar } from "recharts";

//NOTE: Cannot render as a react component - recharts does not behave, must call as plain function
export type RadarLineProps = { name: string; color: string; key: string };
export const radarLine = ({ name, color, key }: RadarLineProps) => {
  return (
    <Radar
      name={name}
      dataKey={key}
      key={key}
      dot={{ fillOpacity: 1, r: 3 }}
      stroke={color}
      strokeWidth={2}
      fill={color}
      fillOpacity={0}
    />
  );
};
