import { mean } from "lodash";
import { Meetings } from "../state/meetings/Meetings";
import { WeightedValue } from "../state/values/WeightedValue";
import { scoresForQuestionRatingsV1 } from "../state/scores/scoresForQuestionRatingsV1";
import { personsMostRecentQuestionRating } from "./personsMostRecentQuestionRating";
import { weightedAverage } from "./weightedAverage";
import { isNumberPair } from "./isNumberPair";

export const valueScore =
  (meetings: Meetings) => (personId: string) => (wv: WeightedValue) => {
    // const attributeWeightScorePairs = wv.weightedAttributes.map((wa) => {
    //   const questionScores = wa.questionIds.map((qId) => {
    //     const rating = personsMostRecentQuestionRating(qId)(personId)(meetings);
    //     const score = rating ? scoresForQuestionRatingsV1[rating] : undefined;
    //     // console.log({rating, score, qId, wa, wv, meetings, personId})
    //     return score;
    //   });
    //   const completedQuestionScores = questionScores.filter(
    //     (qs) => qs !== undefined
    //   );
    //   // console.log({completedQuestionScores})
    //   const attributeWeightScorePair = [
    //     parseFloat(wa.weight.toString()),
    //     completedQuestionScores.length === 0
    //       ? undefined
    //       : mean(completedQuestionScores),
    //   ] as const;
    //   return attributeWeightScorePair;
    // });
    // // console.log({ attributeWeightScorePairs });
    // const completedAttributeWeightScorePairs =
    //   attributeWeightScorePairs.filter(isNumberPair);
    // const result =
    //   completedAttributeWeightScorePairs.length !==
    //     attributeWeightScorePairs.length ||
    //   completedAttributeWeightScorePairs.length === 0
    //     ? undefined
    //     : weightedAverage(completedAttributeWeightScorePairs);

    const attributeScores = wv.weightedAttributes.map((wa) => {
      const questionScores = wa.questionIds.map((qId) => {
        const rating = personsMostRecentQuestionRating(qId)(personId)(meetings);
        const score = rating ? scoresForQuestionRatingsV1[rating] : undefined;
        return score;
      });
      const completedQuestionScores = questionScores.filter(
        (qs) => qs !== undefined
      );
      const attributeScore =
        completedQuestionScores.length === 0
          ? undefined
          : mean(completedQuestionScores);
      return attributeScore;
    });
    const result = attributeScores.length === 0 ? undefined : attributeScores;

    return result;
  };
