import * as O from 'optics-ts/standalone';
import { compose, prop } from 'optics-ts/standalone';
import { byId } from './byId';
import { weightedValuesO } from './weightedValuesO';

export const setStateForWeightedValueField = 
    (setState:any) => 
    (valueId:string) => 
    (fieldName:string) => 
    (value:string) => 
    setState(
        O.set
            (compose(weightedValuesO, byId(valueId), prop(fieldName)))
            (value)
    )
