import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import { MdClose } from "react-icons/md";
import { TextArea } from "../../../Components";
import { themeOrange } from "../../../theme";
import shovel from "../../../images/shovel_orange.png";
import { FaInfo } from "react-icons/fa";
import { Subquestion } from "../../../types/Subquestion";

type DigDeeperBlockProps = {
  isLast?: boolean;
  handleClose?: () => void;
  text: string;
  subquestionResponse: Subquestion;
  setSubquestionResponse: (text: string) => void;
  isAIObservationVisible: string | null;
  isParticipant?: boolean;
  setReferenceElement?: Dispatch<SetStateAction<null>>;
  refCondition?: boolean;
  setPositionOfIntervention?: Dispatch<SetStateAction<null>>;
};

export default function DigDeeperBlock(props: DigDeeperBlockProps) {
  const {
    isLast,
    handleClose,
    text,
    subquestionResponse,
    setSubquestionResponse,
    isAIObservationVisible,
    isParticipant,
    setReferenceElement,
    refCondition,
    setPositionOfIntervention,
  } = props;

  const ref = useRef(null);

  useEffect(() => {
    if (refCondition && setReferenceElement) {
      console.log("setting ref");
      setReferenceElement(ref.current);
    }
  }, [refCondition, setReferenceElement]);

  const isFloatingObservationVisible =
    isAIObservationVisible &&
    // currentDig[currentQuestionIndex] < 2 &&
    !subquestionResponse?.notes;

  if (subquestionResponse)
    return (
      <div
        className="fade-in hor-center"
        style={{
          marginBottom: isLast ? 0 : 18,
          position: "relative",
          display: "block",
        }}
      >
        <FloatingObservation
          isVisible={isFloatingObservationVisible}
          isAIObservationVisible={isAIObservationVisible}
        />
        <div ref={ref} style={styles.digDeeperWrapper}>
          <div style={styles.digDeeperOrangeBlock}>
            <div className="hor-center">
              <div className="hor-center" style={styles.digShovelWrapper}>
                <img
                  src={shovel}
                  style={{ height: "130%", width: "60%" }}
                  alt="shovel"
                />
              </div>
              <div style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>
                Dig Deeper:
              </div>
            </div>
            {!subquestionResponse?.notes && !isParticipant && (
              <MdClose
                style={{ marginRight: 4, cursor: "pointer" }}
                onClick={handleClose}
              />
            )}
          </div>
          <div style={styles.digDeeperQuestion}>
            {text?.replace(/^-+\s*/, "")}
          </div>
        </div>
        <TextArea
          inputStyle={styles.textAreaInput}
          placeholder="Enter notes..."
          value={subquestionResponse.notes}
          onChange={setSubquestionResponse}
          rows={6}
        />
      </div>
    );
}

function FloatingObservation(props) {
  const { isVisible, isAIObservationVisible } = props;
  if (isVisible)
    return (
      <div className="fade-in" style={styles.aiObservationFloatingContainer}>
        <div style={styles.connectingLine} />
        <div style={styles.digDeeperOrangeBlock}>
          <div className="hor-center">
            <div className="hor-center" style={styles.digShovelWrapper}>
              <FaInfo color={themeOrange} size={16} />
            </div>
            <div style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>
              AI Observation
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: 14,
            fontWeight: "bold",
            padding: "6px 6px 0px 6px",
          }}
        >
          {isAIObservationVisible}
        </div>
      </div>
    );
}

const styles: Record<string, CSSProperties> = {
  digDeeperWrapper: {
    background: "white",
    borderRadius: "20px 20px 0px 20px",
    padding: 8,
    marginBottom: 10,
  },
  digDeeperOrangeBlock: {
    borderRadius: "20px 20px 0px 20px",
    height: 28,
    background: themeOrange,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 4,
    paddingRight: 4,
  },
  digShovelWrapper: {
    borderRadius: 50,
    height: 22,
    width: 22,
    minHeight: 22,
    minWidth: 22,
    background: "white",
    marginRight: 8,
    padding: 4,
  },
  digDeeperQuestion: {
    color: "black",
    fontSize: 14,
    padding: "6px 6px 0px 6px",
  },
  textAreaInput: {
    width: "100%",
    maxWidth: "none",
    background: "#000000bb",
    outline: "none",
    padding: 12,
    borderRadius: 6,
  },
  aiObservationFloatingContainer: {
    position: "absolute",
    // height: "40%",
    width: "80%",
    top: "-5%",
    right: "110%",
    borderRadius: 20,
    background: "#000000bb",
    padding: 8,
  },
  connectingLine: {
    height: 2,
    width: "15%",
    top: "50%",
    right: "-15%",
    position: "absolute",
    background: "#000000bb",
  },
};
