import { FiFilter as Filter, FiSearch as Search } from "react-icons/fi";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Button, TextInput } from "../../Components";
import { MdClose } from "react-icons/md";
import { createInterview, createSession } from "../../graphql/mutations";
import { GraphQL } from "../../utils/api";
import { Auth } from "aws-amplify";
import { initQuestions } from "../Interview";
import { stagesV2 } from "../../utils/stages";

const { REACT_APP_ENV } = process.env;

export default function DashboardHeader(props) {
  const {
    inProgress,
    released,
    tab: selectedTab,
    setTab,
    isAdmin,
    setCompaniesModalVisible,
    search,
    setSearch,
    filter,
    setFilter,
  } = props;
  const navigate = useNavigate();

  const [user, setUser] = useState("");

  useEffect(() => {
    async function getAuthedUser() {
      const authedUser = await Auth.currentAuthenticatedUser();
      setUser(authedUser.signInUserSession.idToken.payload.email);
    }
    getAuthedUser();
  }, []);

  const tabs = {
    inProgress: { label: "In Progress", data: inProgress },
    released: { label: "Released", data: released },
  };

  const activeTab = (tab) => {
    return tab !== selectedTab ? "tab-inactive hover-opacity" : "";
  };

  function handleCreateInterview() {
    if (!isAdmin) {
      navigate("/admin/interview?mode=create");
    } else if (isAdmin) {
      setCompaniesModalVisible(true);
    } else return toast.error("Something went wrong. Please try again.");
  }

  return (
    <div className="dashboard-wrapper">
      <div className="horizontal" style={{ height: "100%" }}>
        {Object.keys(tabs).map((tab, i) => {
          const { label, data } = tabs[tab];
          return (
            <div
              key={i}
              className={`dashboard-tab ${activeTab(tab)}`}
              onClick={() => setTab(tab)}
            >
              {label} - {data}
            </div>
          );
        })}
      </div>
      <DynamicSearchFilter
        {...{
          handleCreateInterview,
          search,
          setSearch,
          filter,
          setFilter,
          navigate,
          user,
        }}
      />
    </div>
  );
}

function DynamicSearchFilter(props) {
  const {
    handleCreateInterview,
    search,
    setSearch,
    filter: filters,
    setFilter,
    navigate,
    user,
  } = props;

  const [isOverlayVisible, setOverlayVisible] = useState(false); // New state for overlay visibility
  const overlayRef = useRef(null); // New ref for the overlay div

  useEffect(() => {
    function handleClickOutside(event) {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setOverlayVisible(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overlayRef]);

  const toggleFilter = (filterKey) => {
    setFilter((prevFilters) => {
      return {
        ...prevFilters,
        [filterKey]: {
          ...prevFilters[filterKey],
          active: !prevFilters[filterKey].active,
        },
      };
    });
  };

  const handleCreateTestInterview = async () => {
    const query = createInterview;
    const res = await GraphQL({ query, variables });
    const id = res.createInterview.id;
    if (id) {
      const interviewLink = `/admin/interview?mode=edit&id=${res.createInterview.id}&cid=7e4d1bc5-ca67-46c8-a982-2afc5848df56`;
      navigate(interviewLink);
    }

    for (const stage of Object.keys(stagesV2)) {
      const sessionVariables = { input: { id: `${id}-${stage}` } };
      const sessionQuery = createSession;

      try {
        const res = await GraphQL({
          query: sessionQuery,
          variables: sessionVariables,
          authMode: "",
        });
        console.log(res);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <>
      <div className="horizontal" style={{ height: "100%" }}>
        {REACT_APP_ENV === "dev" && user === "sander@vantasoft.com" && (
          <Button
            style={{ marginRight: 4 }}
            title="+ Test interview"
            onClick={handleCreateTestInterview}
          />
        )}
        <Button
          title="+ Add an interview"
          filled
          onClick={handleCreateInterview}
        />

        <div
          className="horizontal cursor"
          style={{ marginRight: 24, padding: 10 }}
          onClick={() => setOverlayVisible(!isOverlayVisible)}
        >
          <Filter size={20} style={{ marginRight: 6 }} />
          Filter
        </div>
        {isOverlayVisible && (
          <div className="overlay-dropdown" ref={overlayRef}>
            {Object.keys(filters).map((filter, index) => {
              const { label, active } = filters[filter];
              return (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => toggleFilter(filter)}
                  key={index}
                >
                  <input type="checkbox" checked={active} readOnly />
                  <div key={index}>{label}</div>
                </div>
              );
            })}
          </div>
        )}
        <div
          className="horizontal cursor search-wrapper"
          style={{ justifyContent: "space-between" }}
        >
          <div
            className="horizontal"
            style={{ flexGrow: 1, justifyContent: "space-between" }}
          >
            <div
              className="horizontal"
              style={{ justifyContent: "flex-start" }}
            >
              <Search size={20} style={{ marginRight: 6 }} />
              <TextInput
                containerStyle={{
                  marginBottom: 0,
                  border: "none",
                  flexGrow: 1,
                }}
                inputStyle={{ outline: "none", flexGrow: 1 }}
                value={search}
                onChange={setSearch}
              />
            </div>
            <MdClose onClick={() => setSearch("")} size={20} />
          </div>
        </div>
      </div>
    </>
  );
}

function Overlay(props) {
  const { setOverlayVisible, setCurrentFilter } = props;
  return (
    <div
      className="dark-overlay"
      onClick={() => {
        setOverlayVisible(false);
        setCurrentFilter(null);
      }}
    />
  );
}

const variables = {
  input: {
    questions: JSON.stringify(initQuestions),
    interviewInfo:
      '{"candidateName":"sander test","candidateEmail":"sandertestemail+21123123@vantasoft.com","candidateRole":{"companyID":"7e4d1bc5-ca67-46c8-a982-2afc5848df56","id":"20631c06-948d-4894-a27a-47e6697d3ec5","name":"CTO","workingSession":["working 1 updating working session email"]},"interviewers":{"value2":{"name":"for zh","id":"16a93aaf-b1c5-4203-9f6e-715b5bd78ccf","email":"sdflakjhf@alfshasldf.com"},"value1":{"name":"for zh","id":"16a93aaf-b1c5-4203-9f6e-715b5bd78ccf","email":"sdflakjhf@alfshasldf.com"},"rejected":{"name":"","id":"","email":""},"discovery":{"name":"Sanderr Tang","id":"813941bd-2acb-48ad-936f-822fb0667d21","email":"sander@expresso.io"},"working":{"name":"for zh","id":"16a93aaf-b1c5-4203-9f6e-715b5bd78ccf","email":"sdflakjhf@alfshasldf.com"},"accepted":{"name":"","id":"","email":""}},"participants":[]}',
    scheduling: "{}",
    group: "7e4d1bc5-ca67-46c8-a982-2afc5848df56",
    stage: "discovery",
    companyID: "7e4d1bc5-ca67-46c8-a982-2afc5848df56",
    companyName: "Expresso Inc.",
  },
};
