import * as React from "react";
import { Link } from "react-router-dom";

export const A = ({ children, ...props }) => <Link style={{ color: 'blue' }} {...props}>{children}</Link>;

// NOTE: The below chakra links caused glitchiness on hover in the table / surrounding columns. Reverting for now.
// import * as React from "react";
// import { Link as ReactRouterLink } from 'react-router-dom'
// import { Link as ChakraLink } from '@chakra-ui/react'

// export const A = ({ children, ...props }) => 
//     <ChakraLink as={ReactRouterLink} {...props} >
//         {children}
//     </ChakraLink>

    
