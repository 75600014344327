import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
} from "@chakra-ui/react";

import { Button, Container, LoadingScreen } from "../../Components";
import {
  getInterviewCount,
  getUserCount,
  listCompanies,
} from "../../utils/api";
import { styles } from "./styles";
import { usePageTitle } from "../../navigation/usePageTitle";

export default function Customers() {
  usePageTitle("Customers");
  const navigate = useNavigate();
  const [interviews, setInterviews] = useState(null);

  useEffect(() => {
    async function getInterviewData() {
      const companies = await listCompanies();

      const companyData = companies.items.map(
        async ({ email, company, id, plan, createdAt }) => ({
          email,
          company,
          id,
          // fullName: firstName && lastName ? `${firstName} ${lastName}` : "",
          interviewCount: await getInterviewCount(id),
          userCount: await getUserCount(id),
          plan,
          createdAt,
        })
      );
      const interviewCount = await Promise.all(companyData);
      setInterviews(
        interviewCount.sort((a, b) => {
          if (a.company.toLowerCase() < b.company.toLowerCase()) return -1;
          else return 1;
        })
      );
    }
    getInterviewData();
  }, []);

  // if (!interviews) return <LoadingScreen />;

  return (
    <Container width={1200}>
      <div className="hor-center" style={{ justifyContent: "space-between" }}>
        <div style={{ fontSize: 30, fontWeight: "600" }}>Customers</div>
        <Button filled onClick={() => navigate("/admin/customer")}>
          Create Customer
        </Button>
      </div>
      {!!interviews ? (
        <div style={styles.tableWrapper}>
          <TableContainer style={styles.tableContainer}>
            <Table size="md" variant="simple">
              <Thead style={styles.tableHeader}>
                <Tr className="header-tr">
                  <Th className="header-th">Customer</Th>
                  <Th className="header-th">Platform Subscription</Th>
                  <Th className="header-th" style={styles.th}>
                    Users
                  </Th>
                  {/* <Th className="header-th" style={styles.th}>
                    Open Roles
                  </Th> */}
                  <Th className="header-th" style={styles.th}>
                    Interviews
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {interviews.map((interview, i) => {
                  const { company, userCount, interviewCount, plan, id } =
                    interview;
                  const paymentLabel = JSON.parse(plan).metadata.label;

                  return (
                    <Tr
                      className="custom-tr"
                      key={i}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/admin/customer?uid=${id}`)}
                    >
                      <Td>{company}</Td>
                      <Td>{paymentLabel}</Td>
                      <Td style={styles.th}>{userCount}</Td>
                      {/* <Td style={styles.th}>0</Td> */}
                      <Td style={styles.th}>{interviewCount}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div style={{ ...styles.tableWrapper, height: "70vh" }}>
          <LoadingScreen />
        </div>
      )}
    </Container>
  );
}
