import { sum, zip } from "lodash";
import { attributesRelatedToQuestion } from "../../../../Summary/attributesRelatedToQuestion";
import { WeightedAttribute } from "../../../../Summary/state/attributes/WeightedAttribute";
import { WeightedAttributes } from "../../../../Summary/state/attributes/WeightedAttributes";
import { Question } from "../../../../Summary/state/questions/Question";
import { WeightedValue } from "../../../../Summary/state/values/WeightedValue";
import { WeightedValues } from "../../../../Summary/state/values/WeightedValues";

export const normalize = 
    (weights:number[]) =>
    {
        const denominator = sum(weights)
        return weights.map(
            w => w / denominator
        )
    }

export const normalizeWeightsForAttributes = 
    (was:WeightedAttributes):WeightedAttributes =>
    {
        const normalizedWeights = normalize(was.map(wa => Number(wa.weight)))
        const wvNormalizedWeightPairs = zip(was, normalizedWeights) as readonly [WeightedAttribute, number][]
        return wvNormalizedWeightPairs.map(
            ([wa, normalizedWeight]) => 
            ({
                ...wa,
                weight: normalizedWeight,
            })
        )
    }

export const normalizeWeightsForValues = 
    (wvs:WeightedValues):WeightedValues =>
    {
        const normalizedWeights = normalize(wvs.map(wv => Number(wv.weight)))
        const wvNormalizedWeightPairs = zip(wvs, normalizedWeights) as readonly [WeightedValue, number][]
        return wvNormalizedWeightPairs.map(
            ([wv, normalizedWeight]) => 
            ({
                ...wv,
                weight: normalizedWeight,
                weightedAttributes: normalizeWeightsForAttributes(wv.weightedAttributes)
            })
        )
    }

export const questionSortScore = 
    (question:Question) =>
    (normalizedWeightedValues:WeightedValues) =>
    {
        const atts = attributesRelatedToQuestion(normalizedWeightedValues)(question.id)
        if(atts.length === 0) return 0
        const attScores = atts.map(
            att =>
            {
                const parentValue = normalizedWeightedValues.find(wv => wv.weightedAttributes.some(wa => wa.id === att.id))!
                return Number(att.weight) * Number(parentValue.weight) * (1 / att.questionIds.length)
            }
        )
        return sum(attScores)
    }
    