import { ValuesDefintition } from "../state/values/ValuesDefintition";


export const valuesRelatedToQuestionIds = (valuesDefinition: ValuesDefintition) => (intendedQuestionIds: string[]) => valuesDefinition.weightedValues.filter(
  wv => wv.weightedAttributes.some(
    wa => wa.questionIds.some(
      qId => intendedQuestionIds.includes(qId)
    )
  )
);
