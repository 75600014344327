import { Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import * as React from "react";
import { useState } from "react";
import { styles } from "../../../styles";
import { AttributeLibraryTR } from "./AttributeLibraryTR";
import { attributeLibrary } from "./attributeLibrary";
import { isLibraryItemShown } from "./isLibraryItemShown";
import { sortBy } from "lodash";
import { Card, CardList } from "../../../../../Components/Attribute";

const sortByQuestionCountDesc = 
    (attributes) =>
    sortBy(attributes, a => a.questionIds.length).reverse()

export const AttributeLibraryModal = ({ setState, valueId, isOpen, weightedAttributes, setIsOpen }) => {
    const [searchText, setSearchText] = useState("");
    const shownAttributes = sortByQuestionCountDesc(attributeLibrary.attributes).filter(
        isLibraryItemShown(weightedAttributes)(searchText)
    )
    return <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent minWidth="fit-content" height="fit-content" maxW={"80%"}>
            <ModalHeader>Add Attribute</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <VStack gap={1} width="100%">
                    <Input width={200} value={searchText} type="text" placeholder="Search Attribute Library" onChange={({ target: { value } }) => setSearchText(value)} />
                    <CardList>
                        { shownAttributes.map(weightedAttribute => <Card key={weightedAttribute.id} {...{weightedAttribute, valueId, setIsOpen, setState} } />) }                    </CardList>
                    {/* <TableContainer width="100%">
                        <Table className="custom-table">
                            <Thead style={styles.tableHeader}>
                                <Tr className="header-tr">
                                    <Th className="header-th">Title</Th>
                                    <Th className="header-th">Description</Th>
                                    <Th className="header-th"># Questions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {shownAttributes.map(
                                    (weightedAttribute, key) => <AttributeLibraryTR {...{ weightedAttribute, valueId, setState, setIsOpen, key }} />
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer> */}
                </VStack>
            </ModalBody>
        </ModalContent>
    </Modal>;
};
