import { Button, HStack, Input, InputGroup, InputRightElement, Td, Text, Textarea, Tooltip, Tr, VStack } from "@chakra-ui/react";
import * as React from "react";
import { AiFillDelete, AiFillEdit, AiOutlinePercentage } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { A } from "../../general/A";
import { attributeDetailHref } from "../Detail/attributeDetailHref";
import { examplesHref } from "../Examples/examplesHref";
import { MoreExamplesIconWithTooltip } from "../Examples/MoreExamplesIconWithTooltip";
import { onAttributeDelete } from "./onAttributeDelete";
import { onAttributeFieldChange } from "./onAttributeFieldChange";
import { OfficialAttributeIcon } from "../Library/OfficialAttributeIcon";
import { MoreQuestionsIconWithTooltip } from "../../Questions/MoreQuestionsIconWithTooltip";


export const WeightedAttributeTR = ({ customerId, valueId, weightedAttribute: { title, description, weight, id, examples, questionIds, attributeLibraryId }, setState }) => {
    const navigate = useNavigate();
    return <Tr>
        <Td>
            <HStack>
                {attributeLibraryId && <OfficialAttributeIcon />}
                <Input
                    type="text"
                    minWidth="8em"
                    placeholder="Attribute Title"
                    value={title}
                    onChange={onAttributeFieldChange(valueId)(id)('title')(setState)} />
            </HStack>
        </Td>
        <Td>
            <Textarea
                placeholder="Attribute Description (1-3 sentences)"
                minWidth="12em"
                value={description}
                onChange={onAttributeFieldChange(valueId)(id)('description')(setState)} />
        </Td>
        <Td>
            <InputGroup maxWidth="6em">
                <Input
                    placeholder="%%"
                    type="number"
                    title="weight"
                    onChange={onAttributeFieldChange(valueId)(id)('weight')(setState)}
                    value={weight} />
                <InputRightElement pointerEvents='none'>
                    <AiOutlinePercentage />
                </InputRightElement>
            </InputGroup>
        </Td>
        <Td>
            <VStack>
                <HStack gap={1}>
                    <A to={examplesHref(customerId, valueId, id)}> {examples.length}</A>
                    <Text>{examples.length > 1 ? 'examples' : 'example'}</Text>
                    {examples.length < 3 && <MoreExamplesIconWithTooltip />}
                </HStack>
                <HStack gap={1}>
                    <A to={attributeDetailHref(customerId, valueId, id)}> {questionIds.length}</A>
                    <Text>{questionIds.length > 1 ? 'questions' : 'question'}</Text>
                    {questionIds.length < 1 && <MoreQuestionsIconWithTooltip />}
                </HStack>
            </VStack>
          </Td>
          <Td>
            <HStack gap={0}>
                <Tooltip label="Edit Attribute">
                    <Button onClick={() => navigate(attributeDetailHref(customerId, valueId, id))}><AiFillEdit /></Button>
                </Tooltip>
                <Tooltip label="Delete Attribute">
                    <Button onClick={onAttributeDelete(setState)(valueId)(id)}><AiFillDelete /></Button>
                </Tooltip>
            </HStack>
          </Td>
    </Tr>;
};
