/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onUpdateSessionById = /* GraphQL */ `subscription OnUpdateSessionById($id: ID!) {
  onUpdateSessionById(id: $id) {
    id
    questions
    initTime
    subquestionsOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSessionByIdSubscriptionVariables,
  APITypes.OnUpdateSessionByIdSubscription
>;
export const onCreateInterview = /* GraphQL */ `subscription OnCreateInterview($filter: ModelSubscriptionInterviewFilterInput) {
  onCreateInterview(filter: $filter) {
    id
    group
    questions
    interviewInfo
    scheduling
    stage
    companyID
    companyName
    interviewResultData
    questionResponses {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInterviewSubscriptionVariables,
  APITypes.OnCreateInterviewSubscription
>;
export const onUpdateInterview = /* GraphQL */ `subscription OnUpdateInterview($filter: ModelSubscriptionInterviewFilterInput) {
  onUpdateInterview(filter: $filter) {
    id
    group
    questions
    interviewInfo
    scheduling
    stage
    companyID
    companyName
    interviewResultData
    questionResponses {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInterviewSubscriptionVariables,
  APITypes.OnUpdateInterviewSubscription
>;
export const onDeleteInterview = /* GraphQL */ `subscription OnDeleteInterview($filter: ModelSubscriptionInterviewFilterInput) {
  onDeleteInterview(filter: $filter) {
    id
    group
    questions
    interviewInfo
    scheduling
    stage
    companyID
    companyName
    interviewResultData
    questionResponses {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInterviewSubscriptionVariables,
  APITypes.OnDeleteInterviewSubscription
>;
export const onCreateCompany = /* GraphQL */ `subscription OnCreateCompany($filter: ModelSubscriptionCompanyFilterInput) {
  onCreateCompany(filter: $filter) {
    id
    group
    email
    firstName
    lastName
    company
    users {
      nextToken
      __typename
    }
    roles {
      nextToken
      __typename
    }
    interviews {
      nextToken
      __typename
    }
    interviewInfo
    values1
    values2
    plan
    stripeCustomerId
    status
    hasLoggedIn
    displayOrder
    interviewBuilderData
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanySubscriptionVariables,
  APITypes.OnCreateCompanySubscription
>;
export const onUpdateCompany = /* GraphQL */ `subscription OnUpdateCompany($filter: ModelSubscriptionCompanyFilterInput) {
  onUpdateCompany(filter: $filter) {
    id
    group
    email
    firstName
    lastName
    company
    users {
      nextToken
      __typename
    }
    roles {
      nextToken
      __typename
    }
    interviews {
      nextToken
      __typename
    }
    interviewInfo
    values1
    values2
    plan
    stripeCustomerId
    status
    hasLoggedIn
    displayOrder
    interviewBuilderData
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCompanySubscriptionVariables,
  APITypes.OnUpdateCompanySubscription
>;
export const onDeleteCompany = /* GraphQL */ `subscription OnDeleteCompany($filter: ModelSubscriptionCompanyFilterInput) {
  onDeleteCompany(filter: $filter) {
    id
    group
    email
    firstName
    lastName
    company
    users {
      nextToken
      __typename
    }
    roles {
      nextToken
      __typename
    }
    interviews {
      nextToken
      __typename
    }
    interviewInfo
    values1
    values2
    plan
    stripeCustomerId
    status
    hasLoggedIn
    displayOrder
    interviewBuilderData
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanySubscriptionVariables,
  APITypes.OnDeleteCompanySubscription
>;
export const onCreateAttribute = /* GraphQL */ `subscription OnCreateAttribute($filter: ModelSubscriptionAttributeFilterInput) {
  onCreateAttribute(filter: $filter) {
    id
    group
    name
    attributesData
    valueID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAttributeSubscriptionVariables,
  APITypes.OnCreateAttributeSubscription
>;
export const onUpdateAttribute = /* GraphQL */ `subscription OnUpdateAttribute($filter: ModelSubscriptionAttributeFilterInput) {
  onUpdateAttribute(filter: $filter) {
    id
    group
    name
    attributesData
    valueID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAttributeSubscriptionVariables,
  APITypes.OnUpdateAttributeSubscription
>;
export const onDeleteAttribute = /* GraphQL */ `subscription OnDeleteAttribute($filter: ModelSubscriptionAttributeFilterInput) {
  onDeleteAttribute(filter: $filter) {
    id
    group
    name
    attributesData
    valueID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAttributeSubscriptionVariables,
  APITypes.OnDeleteAttributeSubscription
>;
export const onCreateValue = /* GraphQL */ `subscription OnCreateValue($filter: ModelSubscriptionValueFilterInput) {
  onCreateValue(filter: $filter) {
    id
    group
    name
    weightedAttributes
    organizationID
    behaviors
    attributes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateValueSubscriptionVariables,
  APITypes.OnCreateValueSubscription
>;
export const onUpdateValue = /* GraphQL */ `subscription OnUpdateValue($filter: ModelSubscriptionValueFilterInput) {
  onUpdateValue(filter: $filter) {
    id
    group
    name
    weightedAttributes
    organizationID
    behaviors
    attributes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateValueSubscriptionVariables,
  APITypes.OnUpdateValueSubscription
>;
export const onDeleteValue = /* GraphQL */ `subscription OnDeleteValue($filter: ModelSubscriptionValueFilterInput) {
  onDeleteValue(filter: $filter) {
    id
    group
    name
    weightedAttributes
    organizationID
    behaviors
    attributes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteValueSubscriptionVariables,
  APITypes.OnDeleteValueSubscription
>;
export const onCreateOrganization = /* GraphQL */ `subscription OnCreateOrganization(
  $filter: ModelSubscriptionOrganizationFilterInput
) {
  onCreateOrganization(filter: $filter) {
    id
    group
    name
    companyID
    organizationData
    values {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrganizationSubscriptionVariables,
  APITypes.OnCreateOrganizationSubscription
>;
export const onUpdateOrganization = /* GraphQL */ `subscription OnUpdateOrganization(
  $filter: ModelSubscriptionOrganizationFilterInput
) {
  onUpdateOrganization(filter: $filter) {
    id
    group
    name
    companyID
    organizationData
    values {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrganizationSubscriptionVariables,
  APITypes.OnUpdateOrganizationSubscription
>;
export const onDeleteOrganization = /* GraphQL */ `subscription OnDeleteOrganization(
  $filter: ModelSubscriptionOrganizationFilterInput
) {
  onDeleteOrganization(filter: $filter) {
    id
    group
    name
    companyID
    organizationData
    values {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrganizationSubscriptionVariables,
  APITypes.OnDeleteOrganizationSubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
  onCreateUser(filter: $filter) {
    id
    group
    firstName
    lastName
    title
    companyID
    email
    phone
    status
    role
    hasLoggedIn
    lastLoggedIn
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
  onUpdateUser(filter: $filter) {
    id
    group
    firstName
    lastName
    title
    companyID
    email
    phone
    status
    role
    hasLoggedIn
    lastLoggedIn
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
  onDeleteUser(filter: $filter) {
    id
    group
    firstName
    lastName
    title
    companyID
    email
    phone
    status
    role
    hasLoggedIn
    lastLoggedIn
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateRole = /* GraphQL */ `subscription OnCreateRole($filter: ModelSubscriptionRoleFilterInput) {
  onCreateRole(filter: $filter) {
    id
    group
    name
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRoleSubscriptionVariables,
  APITypes.OnCreateRoleSubscription
>;
export const onUpdateRole = /* GraphQL */ `subscription OnUpdateRole($filter: ModelSubscriptionRoleFilterInput) {
  onUpdateRole(filter: $filter) {
    id
    group
    name
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRoleSubscriptionVariables,
  APITypes.OnUpdateRoleSubscription
>;
export const onDeleteRole = /* GraphQL */ `subscription OnDeleteRole($filter: ModelSubscriptionRoleFilterInput) {
  onDeleteRole(filter: $filter) {
    id
    group
    name
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRoleSubscriptionVariables,
  APITypes.OnDeleteRoleSubscription
>;
export const onCreateJobRole = /* GraphQL */ `subscription OnCreateJobRole($filter: ModelSubscriptionJobRoleFilterInput) {
  onCreateJobRole(filter: $filter) {
    id
    group
    status
    jobRoleData
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateJobRoleSubscriptionVariables,
  APITypes.OnCreateJobRoleSubscription
>;
export const onUpdateJobRole = /* GraphQL */ `subscription OnUpdateJobRole($filter: ModelSubscriptionJobRoleFilterInput) {
  onUpdateJobRole(filter: $filter) {
    id
    group
    status
    jobRoleData
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateJobRoleSubscriptionVariables,
  APITypes.OnUpdateJobRoleSubscription
>;
export const onDeleteJobRole = /* GraphQL */ `subscription OnDeleteJobRole($filter: ModelSubscriptionJobRoleFilterInput) {
  onDeleteJobRole(filter: $filter) {
    id
    group
    status
    jobRoleData
    workingSession
    companyID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteJobRoleSubscriptionVariables,
  APITypes.OnDeleteJobRoleSubscription
>;
export const onCreateSession = /* GraphQL */ `subscription OnCreateSession($filter: ModelSubscriptionSessionFilterInput) {
  onCreateSession(filter: $filter) {
    id
    questions
    initTime
    subquestionsOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSessionSubscriptionVariables,
  APITypes.OnCreateSessionSubscription
>;
export const onUpdateSession = /* GraphQL */ `subscription OnUpdateSession($filter: ModelSubscriptionSessionFilterInput) {
  onUpdateSession(filter: $filter) {
    id
    questions
    initTime
    subquestionsOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSessionSubscriptionVariables,
  APITypes.OnUpdateSessionSubscription
>;
export const onDeleteSession = /* GraphQL */ `subscription OnDeleteSession($filter: ModelSubscriptionSessionFilterInput) {
  onDeleteSession(filter: $filter) {
    id
    questions
    initTime
    subquestionsOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSessionSubscriptionVariables,
  APITypes.OnDeleteSessionSubscription
>;
export const onCreateReview = /* GraphQL */ `subscription OnCreateReview($filter: ModelSubscriptionReviewFilterInput) {
  onCreateReview(filter: $filter) {
    id
    interviewId
    author
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReviewSubscriptionVariables,
  APITypes.OnCreateReviewSubscription
>;
export const onUpdateReview = /* GraphQL */ `subscription OnUpdateReview($filter: ModelSubscriptionReviewFilterInput) {
  onUpdateReview(filter: $filter) {
    id
    interviewId
    author
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReviewSubscriptionVariables,
  APITypes.OnUpdateReviewSubscription
>;
export const onDeleteReview = /* GraphQL */ `subscription OnDeleteReview($filter: ModelSubscriptionReviewFilterInput) {
  onDeleteReview(filter: $filter) {
    id
    interviewId
    author
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReviewSubscriptionVariables,
  APITypes.OnDeleteReviewSubscription
>;
export const onCreateNetworkQualityLog = /* GraphQL */ `subscription OnCreateNetworkQualityLog(
  $filter: ModelSubscriptionNetworkQualityLogFilterInput
) {
  onCreateNetworkQualityLog(filter: $filter) {
    timestamp
    username
    sessionId
    description
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNetworkQualityLogSubscriptionVariables,
  APITypes.OnCreateNetworkQualityLogSubscription
>;
export const onUpdateNetworkQualityLog = /* GraphQL */ `subscription OnUpdateNetworkQualityLog(
  $filter: ModelSubscriptionNetworkQualityLogFilterInput
) {
  onUpdateNetworkQualityLog(filter: $filter) {
    timestamp
    username
    sessionId
    description
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNetworkQualityLogSubscriptionVariables,
  APITypes.OnUpdateNetworkQualityLogSubscription
>;
export const onDeleteNetworkQualityLog = /* GraphQL */ `subscription OnDeleteNetworkQualityLog(
  $filter: ModelSubscriptionNetworkQualityLogFilterInput
) {
  onDeleteNetworkQualityLog(filter: $filter) {
    timestamp
    username
    sessionId
    description
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNetworkQualityLogSubscriptionVariables,
  APITypes.OnDeleteNetworkQualityLogSubscription
>;
export const onCreateQuestionResponse = /* GraphQL */ `subscription OnCreateQuestionResponse(
  $filter: ModelSubscriptionQuestionResponseFilterInput
) {
  onCreateQuestionResponse(filter: $filter) {
    id
    interviewId
    stage
    rating
    timestamp
    interviewerName
    question
    notes
    order
    subquestionResponses {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateQuestionResponseSubscriptionVariables,
  APITypes.OnCreateQuestionResponseSubscription
>;
export const onUpdateQuestionResponse = /* GraphQL */ `subscription OnUpdateQuestionResponse(
  $filter: ModelSubscriptionQuestionResponseFilterInput
) {
  onUpdateQuestionResponse(filter: $filter) {
    id
    interviewId
    stage
    rating
    timestamp
    interviewerName
    question
    notes
    order
    subquestionResponses {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateQuestionResponseSubscriptionVariables,
  APITypes.OnUpdateQuestionResponseSubscription
>;
export const onDeleteQuestionResponse = /* GraphQL */ `subscription OnDeleteQuestionResponse(
  $filter: ModelSubscriptionQuestionResponseFilterInput
) {
  onDeleteQuestionResponse(filter: $filter) {
    id
    interviewId
    stage
    rating
    timestamp
    interviewerName
    question
    notes
    order
    subquestionResponses {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteQuestionResponseSubscriptionVariables,
  APITypes.OnDeleteQuestionResponseSubscription
>;
export const onCreateSubquestionResponse = /* GraphQL */ `subscription OnCreateSubquestionResponse(
  $filter: ModelSubscriptionSubquestionResponseFilterInput
) {
  onCreateSubquestionResponse(filter: $filter) {
    id
    questionResponseId
    question
    notes
    order
    displayTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSubquestionResponseSubscriptionVariables,
  APITypes.OnCreateSubquestionResponseSubscription
>;
export const onUpdateSubquestionResponse = /* GraphQL */ `subscription OnUpdateSubquestionResponse(
  $filter: ModelSubscriptionSubquestionResponseFilterInput
) {
  onUpdateSubquestionResponse(filter: $filter) {
    id
    questionResponseId
    question
    notes
    order
    displayTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSubquestionResponseSubscriptionVariables,
  APITypes.OnUpdateSubquestionResponseSubscription
>;
export const onDeleteSubquestionResponse = /* GraphQL */ `subscription OnDeleteSubquestionResponse(
  $filter: ModelSubscriptionSubquestionResponseFilterInput
) {
  onDeleteSubquestionResponse(filter: $filter) {
    id
    questionResponseId
    question
    notes
    order
    displayTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSubquestionResponseSubscriptionVariables,
  APITypes.OnDeleteSubquestionResponseSubscription
>;
export const onCreateRecording = /* GraphQL */ `subscription OnCreateRecording($filter: ModelSubscriptionRecordingFilterInput) {
  onCreateRecording(filter: $filter) {
    id
    interviewId
    stage
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRecordingSubscriptionVariables,
  APITypes.OnCreateRecordingSubscription
>;
export const onUpdateRecording = /* GraphQL */ `subscription OnUpdateRecording($filter: ModelSubscriptionRecordingFilterInput) {
  onUpdateRecording(filter: $filter) {
    id
    interviewId
    stage
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRecordingSubscriptionVariables,
  APITypes.OnUpdateRecordingSubscription
>;
export const onDeleteRecording = /* GraphQL */ `subscription OnDeleteRecording($filter: ModelSubscriptionRecordingFilterInput) {
  onDeleteRecording(filter: $filter) {
    id
    interviewId
    stage
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRecordingSubscriptionVariables,
  APITypes.OnDeleteRecordingSubscription
>;
export const onCreateQuestion = /* GraphQL */ `subscription OnCreateQuestion($filter: ModelSubscriptionQuestionFilterInput) {
  onCreateQuestion(filter: $filter) {
    id
    stage
    companyID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateQuestionSubscriptionVariables,
  APITypes.OnCreateQuestionSubscription
>;
export const onUpdateQuestion = /* GraphQL */ `subscription OnUpdateQuestion($filter: ModelSubscriptionQuestionFilterInput) {
  onUpdateQuestion(filter: $filter) {
    id
    stage
    companyID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateQuestionSubscriptionVariables,
  APITypes.OnUpdateQuestionSubscription
>;
export const onDeleteQuestion = /* GraphQL */ `subscription OnDeleteQuestion($filter: ModelSubscriptionQuestionFilterInput) {
  onDeleteQuestion(filter: $filter) {
    id
    stage
    companyID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteQuestionSubscriptionVariables,
  APITypes.OnDeleteQuestionSubscription
>;
export const onCreateSubQuestions = /* GraphQL */ `subscription OnCreateSubQuestions(
  $filter: ModelSubscriptionSubQuestionsFilterInput
) {
  onCreateSubQuestions(filter: $filter) {
    id
    questionID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSubQuestionsSubscriptionVariables,
  APITypes.OnCreateSubQuestionsSubscription
>;
export const onUpdateSubQuestions = /* GraphQL */ `subscription OnUpdateSubQuestions(
  $filter: ModelSubscriptionSubQuestionsFilterInput
) {
  onUpdateSubQuestions(filter: $filter) {
    id
    questionID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSubQuestionsSubscriptionVariables,
  APITypes.OnUpdateSubQuestionsSubscription
>;
export const onDeleteSubQuestions = /* GraphQL */ `subscription OnDeleteSubQuestions(
  $filter: ModelSubscriptionSubQuestionsFilterInput
) {
  onDeleteSubQuestions(filter: $filter) {
    id
    questionID
    isActive
    text
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSubQuestionsSubscriptionVariables,
  APITypes.OnDeleteSubQuestionsSubscription
>;
