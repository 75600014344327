import { Breadcrumb, BreadcrumbItem } from "@chakra-ui/react";
import * as React from "react";
import { Link } from "react-router-dom";

export const Breadcrumbs = ({ breadcrumbs, ...props }) => <Breadcrumb separator={'>'} {...props}>
    {breadcrumbs.map(
        ({ isCurrentPage, label, href }) => <BreadcrumbItem {...{ isCurrentPage, key: label }}>
            <Link to={href}>{label}</Link>
        </BreadcrumbItem>
    )}
</Breadcrumb>;
