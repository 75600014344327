
export const onQuestionFieldChange = 
    (questionId) => 
    (fieldName) => 
    (setState) => 
    (e) => 
    setState(
    state => ({
        ...state,
        questions: state.questions.map(
            question =>
            question.id == questionId   
                ? {
                    ...question,
                    [fieldName]: e.target.value,
                }
                : question
        )
    })
);
