import axios from "axios";
import { GetMLScores } from "../../ml-client/GetMLScores";
import { intertruDimensionScoresFromML } from "../../ml-client/intertruDimensionScoresFromML";
import { mapIncorrectMLResponseToRequestedSchema } from "./mapIncorrectMLResponseToRequestedSchema";
// import { exampleNonConformingResponse } from "./exampleNonConformingResponse";

//TODO: DRY react app server url
const { REACT_APP_SERVER_URL = "http://localhost:5001/" } = process.env;

export const getMLScores: GetMLScores =
  (candidateId) =>
  (stageName) =>
  (valuesDefinition) =>
  async (attributeScorePayload) => {
    const mlPath = `ml/summary/${candidateId}/${stageName}/evaluations/now`;
    const url = `${REACT_APP_SERVER_URL}${mlPath}`;
    const response = await axios.post(url, attributeScorePayload);
    if (response.data.errorMessage !== undefined) {
      // console.error(response.data)
      throw new Error(response.data.errorMessage);
    }
    const rawMLResponse = response.data;
    // const rawMLResponse = exampleNonConformingResponse
    console.log({ rawMLResponse });
    const correctedMLResponse =
      mapIncorrectMLResponseToRequestedSchema(rawMLResponse);
    console.log({ correctedMLResponse });
    const intertruDimensionScores =
      intertruDimensionScoresFromML(correctedMLResponse)(valuesDefinition);
    console.log({ intertruDimensionScores });
    return intertruDimensionScores;
  };
