import React from "react";

export const SimpleTooltip = ({headerText, content}:{
    headerText: string,
    content: string
}) => {
    const styles = {
        toolTipModal: {
          backgroundColor: "var(--White-White, #FFF)",
          width: 402, 
          position: "fixed" as "fixed",
          boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.40)",
          borderRadius: 24,
          display: "block",
          padding: "8px 8px 16px 8px",
          flexDirection: "column" as "column",
          alignItems: "center",
          gap: 16
      },
        toolTipHeaderText : {
            color: "var(--Primary-Text, #FFF)",
    
            /* Body Medium/Medium */
            fontFamily: "Montserrat",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 600,
            marginLeft: 8
        },
    };

    return (<div style={styles.toolTipModal}>        
                <div className='tool-tip-modal'>
                    <div className='tip-modal-header' style={{justifyContent: 'space-between'}}>
                        <div style={{display: "flex", justifyContent: 'flex-start'}}>
                            <div className='modal-info-icon'></div>
                            <div style={styles.toolTipHeaderText}>{headerText}</div>
                        </div>                    
                    </div>
                    <div style={{backgroundColor: 'white', marginTop:16}}>
                        <p style={{marginRight: 6, marginLeft: 6, color: "black", whiteSpace: "pre-wrap"}}>{content}</p>
                    </div>
                </div>
            </div>)
}