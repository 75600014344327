export const onAttributeDelete = (setState) => (valueId) => (attributeId) => (e) => setState(
    state => ({
        ...state,
        weightedValues: state.weightedValues.map(
            v => v.id == valueId
                ? {
                    ...v,
                    weightedAttributes: v.weightedAttributes.filter(
                        a => a.id != attributeId
                    )
                }
                : v
        )
    })
);
