import { getInterviewWithAllResponses } from "../../graphql/custom";
import { GraphQL } from "../../utils/api";
import { upToDateInterviewers } from "./chart/upToDateInterviewers";

export async function populateInterviewData(id, setInterviewRecord) {
  if (id) {
    const query = getInterviewWithAllResponses;
    const variables = { id };
    const res = await GraphQL({ query, variables, authMode: "" });
    const interviewRecordWithJSONStringFields = res.getInterview;

    const interviewInfo = JSON.parse(
      interviewRecordWithJSONStringFields.interviewInfo
    );

    const interviewRecord = {
      ...interviewRecordWithJSONStringFields,
      interviewInfo: {
        ...interviewInfo,
        interviewers: await upToDateInterviewers(interviewInfo.interviewers),
      },
      questions: JSON.parse(interviewRecordWithJSONStringFields.questions),
      scheduling: JSON.parse(interviewRecordWithJSONStringFields.scheduling),
    };

    setInterviewRecord(interviewRecord);
  }
}
