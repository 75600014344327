import React, { CSSProperties } from "react";
import { LoadingScreen } from ".";
import { errorRed } from "../theme";

const diameter = 18;

export function RecordButton() {
  return <div style={styles.recording} />;
}

export function RecordLoading() {
  return <LoadingScreen color="white" height={diameter} width={diameter} />;
}

export function RecordSomething() {
  return <div style={styles.inactive} />;
}

const styles: Record<string, CSSProperties> = {
  recording: {
    borderRadius: 20,
    width: diameter,
    height: diameter,
    color: "gray",
    border: "2px solid white",
  },
  inactive: {
    borderRadius: 20,
    width: diameter,
    height: diameter,
    border: "2px solid white",
    background: errorRed,
  },
};
