import React from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import "./modal.css";

export default function Modal(props) {
  return (
    <Rodal
      className={props.className}
      visible={props.isVisible}
      onClose={props.onClose}
      customStyles={{ ...styles.modal, ...props.style }}
      duration={0}
    >
      <div style={styles.modalWrapper}>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </div>
    </Rodal>
  );

  function renderHeader() {
    return (
      <div style={styles.headerWrapper}>
        <div style={styles.headerText}>{props.title}</div>
        <div style={{ marginRight: 24 }}>
          {props.titleButton && props.titleButton}
        </div>
      </div>
    );
  }

  function renderBody() {
    return (
      <div className="modal-body" style={styles.body}>
        {props.children}
      </div>
    );
  }

  function renderFooter() {
    if (props.stickyFooter && !props.loading)
      return <div style={styles.footer}>{props.stickyFooter}</div>;
  }
}

const styles = {
  modal: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    bottom: "auto",
    top: "auto",
    maxWidth: 500,
    padding: 0,
    borderRadius: 12,
    overflow: "hidden",
  },
  modalWrapper: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // paddingBottom: 20,
    padding: 20,
  },
  headerText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  headerIcon: {
    fontSize: 26,
  },
  body: {
    overflowY: "auto",
    fontSize: 16,
    flexGrow: 1,
  },
  footer: {
    background: "white",
    padding: "6px 20px 0px",
  },
};
