import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Checkbox, Container } from "../../Components";
import UsersTable from "./UsersTable";
import { listUsers, listArchivedUsers, getUser } from "../../utils/api";
import { checkRoles, getCognitoUsername } from "../../utils/auth";
import { usePageTitle } from "../../navigation/usePageTitle";

export default function UsersTablePage() {
  usePageTitle("Users")
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [showArchived, setShowArchived] = useState(false);

  const [permission, setPermission] = useState(null);

  useEffect(() => {
    async function getPermissions() {
      const userId = await getCognitoUsername();
      const user = await getUser(userId);
      setPermission(user?.role);
    }
    if (!isAdmin) getPermissions();
  }, [isAdmin]);

  useEffect(() => {
    async function getRoles() {
      const res = await checkRoles();
      setIsAdmin(res.includes("Admin"));
    }
    getRoles();
  }, []);

  useEffect(() => {
    async function getUsersData() {
      const users = await listUsers();
      let archivedUsers = { items: [] };
      if (showArchived) archivedUsers = await listArchivedUsers();
      setUsers(users.items.concat(archivedUsers.items));
    }
    getUsersData();
  }, [showArchived]);

  if (!!permission || isAdmin)
    return (
      <Container width={1200}>
        <div
          className="hor-center"
          style={{ justifyContent: "space-between", marginBottom: 30 }}
        >
          <div style={{ fontSize: 30, fontWeight: "600" }}>Users</div>
          {(isAdmin || permission !== "interviewer") && (
            <AdminControls
              {...{ navigate, showArchived, setShowArchived, setUsers }}
            />
          )}
        </div>
        <UsersTable
          users={users}
          showCompanyName={isAdmin}
          permission={permission}
          showArchived={showArchived}
        />
      </Container>
    );
}

function AdminControls(props) {
  const { navigate, showArchived, setShowArchived, setUsers } = props;

  return (
    <div
      className="hor-center"
      style={{ width: 300, justifyContent: "space-between" }}
    >
      <Checkbox
        label="Show Archived"
        checked={showArchived}
        onClick={() => {
          setShowArchived(null);
          setUsers(null);
          setShowArchived(!showArchived);
        }}
        style={{ cursor: "pointer" }}
      />
      <Button filled onClick={() => navigate("/admin/user")}>
        Create User
      </Button>
    </div>
  );
}
